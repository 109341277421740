













import {computed} from '@nuxtjs/composition-api'
import { TypeDisplay, TypeHeading } from '@bambeehr/pollen';
import { PlanProductKey, PayrollProductKey } from '@/hooks/usePricePlans';
import {ProductKey} from '@/gql/generated'

export default {
  name: 'PlanTitle',
  components: {
    TypeDisplay,
    TypeHeading,
  },
  props: {
    planType: {
      type: String,
      default: PlanProductKey.DEDICATED,
    },
  },
  setup(props) {
    const productType = computed(()=>{
      // @ts-ignore
      const isPayroll = [ProductKey.PayrollBasic, ProductKey.PayrollPremium].includes(props.planType)
      if (isPayroll) {
        return 'Payroll'
      }

      return 'Bambee'
    })

    const label = computed(()=>{
      switch (props.planType) {
        case PlanProductKey.LITE:
          return 'Lite'
      
        case PlanProductKey.ELITE:
          return 'Elite'

        case ProductKey.PayrollBasic:
          return 'Basic'

        case ProductKey.PayrollPremium:
          return 'Premium'
      
        default:
          return 'Dedicated'
      }
    })
    return { PlanProductKey, label, productType };
  },
};
