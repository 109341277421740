/**
 * Takes the list of referrers and shuffles them, while maintaining the "other"
 * option at the end of the array. Used by the "How did you hear about us"
 * dropdown.
 */
import shuffle from 'lodash/shuffle';
import Referrers from '@/constants/Referrers';

const shuffleReferrers = (brand = null) => {
  let other;
  let everythingElse = [];
  Referrers.forEach((item) => {
    if (item.value === 'other') {
      other = item;
    } else {
      everythingElse.push(item);
    }
  });

  if (brand) {
    everythingElse = shuffle(everythingElse);
    everythingElse.push({
      label: brand.charAt(0).toUpperCase() + brand.slice(1),
      value: brand,
    });
    return [...everythingElse, other];
  }
  return [...shuffle(everythingElse), other];
};

export default shuffleReferrers;
