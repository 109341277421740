




import {
  computed,
  defineComponent,
  useContext,
} from '@nuxtjs/composition-api';
import usePrice from '@/hooks/usePrice';
import usePricePlans, { PlanProductKey } from '@/hooks/usePricePlans';
import useTrials from '@/hooks/useTrials';
import ShoppingCartAccordionItem from '@/modules/SelfServiceRegistration/components/ShoppingCart/ShoppingCartAccordionItem.vue';
import ShoppingCart, {LineItem} from '@/modules/SelfServiceRegistration/components/ShoppingCart/ShoppingCart.vue';
import ShoppingCartLineItem from '@/modules/SelfServiceRegistration/components/ShoppingCart/ShoppingCartLineItem.vue';
import ShoppingCartSalesTax from '@/modules/SelfServiceRegistration/components/ShoppingCart/ShoppingCartSalesTax.vue';
import { TypeBody } from '@bambeehr/pollen';
import { BillingPeriod } from '@/constants/Billing';
import { format } from 'date-fns';
import DateFormat from '@/constants/DateFormat';

export default defineComponent({
  name: 'OptionsShoppingCart',
  components: {
    ShoppingCart,
    ShoppingCartAccordionItem,
    ShoppingCartLineItem,
    ShoppingCartSalesTax,
    TypeBody,
  },
  setup() {
    // @ts-ignore
    const { $axios } = useContext();

    const {
      taxes,
      isReady,
    } = usePrice($axios);
    
    const { selectedPlan } = usePricePlans();
    const { trialEndDate } = useTrials();

    const bambeePlanType = computed(() => {
      switch (selectedPlan.value?.productKey) {
        case PlanProductKey.LITE:
          return `Bambee Lite`
        case PlanProductKey.ELITE:
          return `Bambee Elite`
      
        default:
          return `Bambee Dedicated`
      }
    });

    const billingPeriod = computed(() => {
      return selectedPlan.value?.billingPeriod === BillingPeriod.MONTH
        ? `Monthly`
        : `Yearly`;
    });

    const planItemLabel = computed(() => {
      const base = `${bambeePlanType.value} ${billingPeriod.value} Payment`
      const eeSize = selectedPlan.value?.productKey === PlanProductKey.LITE ? '' : `(${selectedPlan.value?.employeeSizeMapping})`
      return [base, eeSize].filter(Boolean).join(' ')
    });

    const chargedAtLabel = computed(()=>{
      return `Starting on ${format( trialEndDate.value, DateFormat.MMMM_DO_YYYYY )}`
    })

    const lineItems = computed(()=>{
      const planAmt = selectedPlan.value?.price || 0;
      const planItem: LineItem =  {
        label: planItemLabel.value,
        price: planAmt,
      }

      const taxItem: LineItem = {
        label: `Estimated Sales Tax`,
        price: taxes.value,
        tooltip: `The final tax and total is determined by your business address.`,
        type: 'tax',
      }

      const chargeAt: LineItem = {
        label: chargedAtLabel.value,
        price: planAmt + taxes.value,
        bold: true,
        classes: 'border-t pt-1'
      }

      return [
        planItem,
        taxItem,
        chargeAt,
      ]
    })

    return {
      isReady,
      lineItems,
    };
  },
});
