<template>
  <div class="mobile-menu px-6 py-8">
    <span v-if="show">
      <MobileMenuItem
        v-for="(item, i) in menuItems"
        :key="`menu-item-${i}`"
        :label="item.label"
        :show="menuIndex.includes(i)"
        class="mb-8"
        :color="color"
        @toggle="toggleItem(i)"
      >
        <div
          v-for="(section, index) in item.data"
          :key="`panel-${index}`"
          class="col-span-12"
        >
          <TypeOverline
            variant="large"
            class="mb-3"
            :class="
              color === 'primary' ? 'text-gray-4' : 'text-secondaryBrandDefault'
            "
            tag="p"
          >
            <span>{{ section.label }}</span>
          </TypeOverline>
          <div class="panel-items grid grid-cols-12 w-100">
            <NavigationPanelItem
              v-for="(sitem, pI) in section.items"
              :key="`panel-item-${pI}`"
              v-bind="sitem"
              class="col-span-12"
              :panelItemTitle="panelItemTitle"
              :panelItemBody="panelItemBody"
              :class="color === 'primary' ? 'text-black' : ''"
              @reset="$emit('set-panel', null)"
            />
          </div>
        </div>
      </MobileMenuItem>
    </span>
    <div class="login-buttons mb-8">
      <BaseButton
        variant="secondary"
        size="large"
        class="block w-full mb-4"
        @click="$router.push('/registration/company-size')"
        >{{ text }}</BaseButton
      >
      <BaseButton
        variant="inverted"
        size="large"
        class="block w-full"
        @click="sendTo"
        >{{ mobileMenuButtonText }}</BaseButton
      >
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
  useRoute,
} from '@nuxtjs/composition-api';
import { BaseButton, TypeOverline } from '@bambeehr/pollen';
import NavigationPanelItem from './NavigationPanelItem.vue';
import MobileMenuItem from '@/modules/HomePage/components/Navigation/MobileMenuItem';
import { NavPanels } from '@/modules/HomePage/constants';
import Partners from '@/modules/SplashPage/constants/Partners';

export default defineComponent({
  name: 'MobileMenu',
  components: {
    BaseButton,
    MobileMenuItem,
    NavigationPanelItem,
    TypeOverline,
  },
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    show: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: 'Try Bambee Now',
    },
    mobileMenuButtonText: {
      type: String,
      default: 'Log In',
    },
    panelItemTitle: {
      type: String,
      required: true,
    },
    panelItemBody: {
      type: String,
      required: true,
    },
  },
  setup() {
    const menuIndex = ref([]);
    const route = useRoute();
    const { autopilotsBusiness, hrManager, payroll, about, resources } =
      NavPanels;

    const toggleItem = (index) => {
      if (menuIndex.value.includes(index)) {
        const findIndex = menuIndex.value.findIndex((i) => i === index);
        menuIndex.value.splice(findIndex, 1);
      } else {
        menuIndex.value = [...menuIndex.value, index];
      }
    };

    const sendTo = () => {
      window.location.href = `https://${process.env.APP}`;
    };

    const legacyPages = computed(() => {
      if (
        [
          'terminations',
          'onboarding',
          'radio',
          'hr-policies',
          'square',
        ].includes(route.value.name) ||
        [
          Partners.DEEL,
          Partners.REMOTE,
          Partners.BUILT_FIRST,
          Partners.BREX,
          Partners.PAYROLLSETUP,
        ].includes(route.value.params.brand) ||
        route.value.params.brand
      ) {
        return true;
      }

      return false;
    });

    const menuItems = computed(() => {
      return legacyPages.value
        ? []
        : [
            {
              label: 'HR Autopilot',
              data: autopilotsBusiness,
            },
            {
              label: 'Dedicated HR Manager',
              data: hrManager,
            },
            {
              label: 'Bambee Guided Payroll',
              data: payroll,
            },
            {
              label: 'About',
              data: about,
            },
            {
              label: 'Resources',
              data: resources,
            },
          ];
    });

    return {
      menuItems,
      menuIndex,
      toggleItem,
      sendTo,
    };
  },
});
</script>

<style scoped>
.mobile-menu {
  height: calc(100vh - 72px);
  overflow-y: auto;
}
</style>
