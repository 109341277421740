var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cursor-pointer",class:( _obj = {}, _obj[_vm.optionClass] = true, _obj['cursor-default selected-option '] =  _vm.selected, _obj ),on:{"click":function($event){$event.preventDefault();return _vm.$emit('click')}}},[_c('GlowCard',{attrs:{"active":_vm.selected,"color":_vm.glowColor,"content-classes":"p-4 sm:p-6"}},[_c('TypeBody',{staticClass:"flex gap-3 sm:gap-6",attrs:{"variant":"small","tag":"div"}},[_c('div',[_c('div',{staticClass:"selector h-5 w-5 sm:h-6 sm:w-6 rounded-full mt-0.5 transition-all duration-75",class:_vm.selected
              ? 'border-[5px] sm:border-[7px]  selected'
              : 'border-2 border-gray-3'})]),_vm._v(" "),_c('div',{staticClass:"space-y-2"},[_c('header',{staticClass:"flex flex-col-reverse sm:flex-row gap-2 sm:items-center mb-3"},[_c('PlanTitle',{attrs:{"plan-type":_vm.planType}})],1),_vm._v(" "),_c('main',[_c('div',{staticClass:"space-y-5"},[_c('div',[_vm._t("default")],2),_vm._v(" "),(_vm.$slots.expand)?_c('div',{staticClass:"flex items-center gap-1 text-primary-tint-1 font-bold cursor-pointer"},[_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleLearnMore.apply(null, arguments)}}},[(!_vm.isExpanded)?_c('span',[_vm._v("Learn More...")]):_c('span',[_vm._v("Hide Details")])])]):_vm._e()]),_vm._v(" "),(_vm.$slots.expand)?_c('div',{staticClass:"overflow-hidden transition-all",class:{
              'h-0 mt-0': !_vm.isExpanded,
              'h-auto mt-4': _vm.isExpanded,
            }},[_vm._t("expand")],2):_vm._e()])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }