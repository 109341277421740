<template>
  <div class="advisor-dialog sm:rounded-md bg-white mt-11 px-4 sm:px-8 pb-8 shadow-6 w-full">
    <AvatarImage
      :image="require('~/assets/images/ashley-headshot.jpg')"
      :size="86"
      class="-mb-9 sm:-mb-5 relative -top-11 mx-auto"
    />
    <slot />
  </div>
</template>

<script>
import { AvatarImage } from '@bambeehr/pollen';

export default {
  components: { AvatarImage },
};
</script>

<style scoped>


@media (min-width: 480px) {
  .advisor-dialog {
    max-width: 420px;
  }
}
</style>
