


























import { TypeBody } from '@bambeehr/pollen';
import { defineComponent, useRouter } from '@nuxtjs/composition-api';

const redirect = (link, router): void => {
  if (link?.includes('about/careers')) {
    window?.open('https://boards.greenhouse.io/bambee', '_blank');
  } else {
    router.push(link);
    window?.scrollTo(0, 0);
  }
};

export default defineComponent({
  name: 'NavigationPanelItem',

  components: { TypeBody },
  props: {
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    scrollId: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
    panelItemTitle: {
      type: String,
      required: true,
    },
    panelItemBody: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const routeUser = (id) => {
      if (props.link) {
        redirect(props?.link, router);
      } else {
        router.push(`/?#${id}`);
      }
    };

    const handleClick = (id: string) => {
      emit('reset');
      const el = document?.getElementById(id);

      if (el && !id.includes('about')) {
        el.scrollIntoView({ behavior: 'smooth' });
      } else {
        routeUser(id);
      }
    };

    return {
      handleClick,
    };
  },
});
