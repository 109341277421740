// const contentful = require('contentful');
import { createClient } from 'contentful';
import ContentfulEntryIDs from '@/constants/ContentfulEntryIDs';

export { default as ContentfulTypes } from '@/constants/ContentfulTypes';
export { default as ContentfulEntryIDs } from '@/constants/ContentfulEntryIDs';

let Contentful;

if (process.env.CONTENTFUL_SPACE && process.env.CONTENTFUL_TOKEN) {
  Contentful = createClient({
    space: process.env.CONTENTFUL_SPACE,
    accessToken: process.env.CONTENTFUL_TOKEN,
    host: process.env.CONTENTFUL_HOST,
  });
}

export const fetchTos = async () => {
  const result = await Contentful.getEntry(ContentfulEntryIDs.PAGE_TOS, {
    include: 10,
  });
  const { sections } = result.fields;

  return {
    sections,
  };
};

export const fetchPrivacyPolicy = async () => {
  const { fields } = await Contentful.getEntry(ContentfulEntryIDs.PAGE_PRIVACY);
  const { header, effectiveDate, sections } = fields;

  return {
    header,
    sections,
    effectiveDate: new Date(effectiveDate),
  };
};

export default Contentful;
