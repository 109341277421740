import Cookie from 'js-cookie';
import { getCookie } from '@/lib/cookie-helpers';
import { QueryParamNames, TrialDurationOverrides } from '@/constants/QueryParams'
import CookieNames from '@/constants/CookieNames';


/* eslint-disable no-else-return */
import { computed, ComputedRef } from '@nuxtjs/composition-api';
import { addDays } from 'date-fns';
import useRegistration, {RegistrationStateNames} from '@/modules/SelfServiceRegistration/hooks/useRegistration';


export const trialPrice = 1; // in dollars
const shortTrialDuration = 7; // in days
const longTrialDuration = 30; // in days
const longestTrialDuration = 90; // in days

const tiersWithShortTrial = [1, 2];
const tiersWithLongTrial = [0, 3, 4, 5, 6, 7];
const tiersWithLongestTrial = [];

const hasTierInTrialList = (tierList: number[] = [], tierNumber: (number | null)): boolean => {
  if (tierNumber === null) {
    return false
  }
  return tierList.includes(tierNumber);
};

const jpMorgan = 'jp-morgan';

const useTrials = () => {
  const {state: registrationState} = useRegistration()

  // @ts-ignore
  const selectedTierNumber = computed(() => registrationState[RegistrationStateNames.TIER_NUMBER] as number);
  
  const hasShortTrial: ComputedRef<boolean> = computed(
    () => hasTierInTrialList(tiersWithShortTrial, selectedTierNumber.value)
  );

  const hasLongTrial: ComputedRef<boolean> = computed(() =>
    hasTierInTrialList(tiersWithLongTrial, selectedTierNumber.value)
);

const hasLongestTrial: ComputedRef<boolean> = computed(() =>
    hasTierInTrialList(tiersWithLongestTrial, selectedTierNumber.value) || getCookie('referredByCode') === jpMorgan
  );

  const trialDuration: ComputedRef<number> = computed(() => {
    // If query params, look for the trial duration cookie
    const cookieQuery= Cookie.get(CookieNames.QUERY_PARAMS)
    if (!!cookieQuery) {
      const cookieQueryStr = cookieQuery || '{}';
      const cookieQueryObj = JSON.parse(cookieQueryStr);
      const trialCookie = cookieQueryObj[QueryParamNames.TRIAL_DURATION_OVERRIDE] || ''
      switch (trialCookie) {
        case TrialDurationOverrides.NINETY:
          return 90
        case TrialDurationOverrides.SIXTY:
          return 60
        case TrialDurationOverrides.THIRTY:
          return 30
        case TrialDurationOverrides.FIFTEEN:
          return 15
        case TrialDurationOverrides.SEVEN:
          return 7
      }
    }

    if (hasLongestTrial.value) {
      return longestTrialDuration;
    }

    if (hasShortTrial.value) {
      return shortTrialDuration;
    }

    if (hasLongTrial.value) {
      return longTrialDuration;
    }

    return shortTrialDuration;
  });

  const trialEndDate: ComputedRef<Date> = computed(()=>{
    const today = new Date();
    const endDate = addDays(today, trialDuration.value || 0)

    return endDate
  })

  return {
    trialDuration,
    trialEndDate,
  };
};


export default useTrials;
