import Cookie from 'js-cookie';
import CookieNames from '@/constants/CookieNames'

// TODO replace 'production'
const useNull =
  !['stage'].includes(process.env.ENV) ||
  process.env.NETLIFY_CONTEXT === 'deploy-preview';

const domain = useNull ? null : process.env.PRIMARY_DOMAIN;

export function getCookie(name) {
  return Cookie.get(name, { domain });
}

export function setCookie(name, value, options = {}) {
  const config = domain
    ? {
        domain,
        sameSite: 'None',
        secure: true,
        ...options,
      }
    : options;

  Cookie.set(name, value, config);
}

export function deleteCookie(name) {
  Cookie.remove(name, { domain });
}

export function getQueryCookie() {
  const cookieStr = getCookie(CookieNames.QUERY_PARAMS) || '{}'
  const cookieObj = JSON.parse(cookieStr);

  return cookieObj || {};
}

export const setQueryCookie = (toSave = {})=>{
  
  const currentCookie = getQueryCookie();
  const cookie = {
    ...currentCookie, 
    ...toSave,
  }
  
  setCookie(CookieNames.QUERY_PARAMS, cookie);
}