export const formatDuration = (duration) => {
    let minutes = Math.floor(duration / 60);
    let seconds = duration % 60;
    let timerStr = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

    return timerStr;
}



const createTimer = (seconds = 5 * 60, { onComplete = () => { }, onUpdate = (payload) => { } }) => {
    let timerDuration = seconds;
    let intervalId;

    const startCountdown = () => {
        intervalId = setInterval(function () {
            let timerStr = formatDuration(timerDuration);
            onUpdate({ duration: timerDuration, timerStr })
            timerDuration--;

            if (timerDuration < 0) {
                clearInterval(intervalId);
                onComplete();
            }
        }, 1000);
    }

    const stopCountdown = () => {
        intervalId && clearInterval(intervalId);
    }

    return {
        startCountdown,
        stopCountdown,
    }
}


export default function useTimer(seconds, callbacks = {}) {
    return {
        timer: createTimer(seconds, callbacks),
    };
}
