




































































































import { computed, ref, watch, Ref } from '@nuxtjs/composition-api';
import {
  BaseButton,
  BaseLink,
  TypeBody,
  TypeDisplay,
  TypeHeading,
  BaseIcon,
  RadioToggle,
} from '@bambeehr/pollen';
import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration';
import LayoutApplicationStyle from '@/modules/SelfServiceRegistration/components/LayoutApplicationStyle.vue';
import BaseCard from '@/components/BaseCard/BaseCard.vue';
import { ProductKey } from '@/gql/generated';
import useTrials from '@/hooks/useTrials';
import Analytics from '@/services/Analytics';
import PricePlanSelector from '@/modules/SelfServiceRegistration/components/PricePlanSelector/PricePlanSelector.vue';
import PlanTitle from '@/modules/SelfServiceRegistration/components/PlanTitle/PlanTitle.vue';
import ShoppingCart, {
  LineItem,
  TotalItem,
} from '@/modules/SelfServiceRegistration/components/ShoppingCart/ShoppingCart.vue';
import DateFormat from '@/constants/DateFormat';
import { format } from 'date-fns';
import usePricePlans, { PlanProductKey } from '@/hooks/usePricePlans';
import GlowCard from '@/modules/SelfServiceRegistration/components/GlowCard.vue';
import useAddons from '@/modules/SelfServiceRegistration/hooks/useAddons';
import { PayrollTrackEvents } from '@/modules/SelfServiceRegistration/constants/TrackEvents';

export default {
  name: 'PlanSelectionPage',
  components: {
    GlowCard,
    BaseButton,
    BaseLink,
    BaseCard,
    BaseIcon,
    LayoutApplicationStyle,
    ShoppingCart,
    TypeBody,
    TypeDisplay,
    TypeHeading,
    PricePlanSelector,
    PlanTitle,
    RadioToggle,
  },

  setup(_, { emit }) {
    /**
     * HOOKS
     */
    // @ts-ignore
    const { state: registrationState, updateRegistration } = useRegistration();
    const { bambeeLitePlans } = usePricePlans();

    const canLoad = ref(false);

    const next = (regState = {}) => {
      emit('submit', regState);
    };

    const {
      eligibleAddOns,
      purchasePayrollProduct,
      isLoading: isLoadingAddons,
    } = useAddons();

    watch(
      bambeeLitePlans,
      (newVal) => {
        if (!newVal) return;

        // Bambee Lite and T0 plans will not get offered Payroll
        const ids = newVal.map((plan) => {
          return plan.value;
        });

        if (
          ids.includes(
            // @ts-ignore
            registrationState[RegistrationStateNames.PLAN_ID]
          ) ||
          !registrationState[RegistrationStateNames.TIER_NUMBER]
        ) {
          next();
        } else {
          canLoad.value = true;
        }
      },
      {
        immediate: true,
      }
    );

    const { trialDuration, trialEndDate } = useTrials();

    // const selectedPlan = ref(PayrollProductKey.BASIC) as Ref<PayrollProductKey>
    const selectedPlan = ref() as Ref<ProductKey>;

    const saving = ref(false);

    const trackEvent = (eventName: PayrollTrackEvents, additionalData = {}) => {
      Analytics.track(eventName, {
        registrationState,
        ...additionalData,
      });
    };

    const isReady = computed(() => {
      return (
        !isLoadingAddons.value && eligibleAddOns.value && canLoad.value
      );
    });

    const planOptions = computed(() => {
      const basicConfig = {
        id: ProductKey.PayrollBasic,
        key: ProductKey.PayrollBasic,
        html: `
        <b class="text-gray-2">No-frills payroll</b> for customers that just want basic functionality to pay their employees. Time and Attendance included with payroll allows you to track employee timekeeping, sick-time, vacation, and all other important payroll information.
        `,
        expandableList: [
          `<b>On-Time Payroll</b> Basic payroll tools to ensure payroll is processed on-time and accurately`,
          `<b>Chat-only support</b> Create requests with the Bambee Payroll Support Team via chat or in-app (no phone support).`,
          `<b>2-Day Response Time</b>`,
          `<b>Automated and Accurate Time Tracking</b> Bambee ensures accurate employee clock-ins and outs with GPS and facial recognition, automating timesheet creation and export to payroll systems`,
          `<b>Compliance with Labor Rules</b> Ensures wage, overtime, and break calculations to ensure compliance with local labor rules, reducing the risk of disputes and fines`,
          `<b>Automated and Accurate Time Tracking</b> Integrates seamlessly with Bambee Payroll Basic, so you spend no time or effort importing hours tracked by hand or from other systems`,
        ],
      };

      const premiumConfig = {
        id: ProductKey.PayrollPremium,
        key: ProductKey.PayrollPremium,
        html: `
        <b class="text-gray-2">Best-in-market payroll.</b> A dedicated payroll manager, next-day phone availability, comprehensive wage guidance, and a powerful time and attendance product to track employee timekeeping, sick-time, vacation, and other payroll information.
        `,
        expandableList: [
          `<b>Dedicated Payroll Manager</b> Gets to know you and your business just like your dedicated HR Manager`,
          `<b>Comprehensive Wage Guidance</b> Know whether your employee rates are above, at, or below market to retain and recruit the very best people`,
          `<b>Next-Day Phone Support Guarantee</b>`,
          `<b>Automated and Accurate Time Tracking</b> Bambee ensures accurate employee clock-ins and outs with GPS and facial recognition, automating timesheet creation and export to payroll systems`,
          `<b>Compliance with Labor Rules</b> Ensures wage, overtime, and break calculations to ensure compliance with local labor rules, reducing the risk of disputes and fines`,
          `<b>Integration and Flexibility</b> Integrates seamlessly with Bambee Payroll Premium, so you spend no time or effort importing hours tracked by hand or from other systems`,
        ],
      };

      const isEligibleForBasic = eligibleAddOns.value?.find(
        (addOn) => addOn.productKey === ProductKey.PayrollBasic
      );

      if (!isEligibleForBasic) {
        return [premiumConfig];
      }

      return [basicConfig, premiumConfig];
    });

    const purchaseButtonText = computed(() => {
      if (saving.value) {
        return `Adding Payroll`;
      }
      return `Add Payroll`;
    });

    /**
     * Methods
     */

    const handleSwitchPlan = (productKey: ProductKey) => {
      if (!productKey) {
        return;
      }

      selectedPlan.value = productKey;

      trackEvent(PayrollTrackEvents.PLAN_CLICK, { target: productKey });
    };

    const handlePayrollPurchase = async () => {
      saving.value = true;

      // TODO Make purchase, respond to success, error, and finally
      // replace timeout with a mutate function. preferably from a payroll hook or something.
      const res = await purchasePayrollProduct(selectedPlan.value).catch(
        (e) => {
          // Put the track event in the actual call
          saving.value = false;
        }
      );

      // Only move to the next screen if there was a successful result
      if (!res) return;
      next();
    };
    const handleSkip = async () => {
      trackEvent(PayrollTrackEvents.SKIP_CLICK);
      next();
    };

    const handlePlanLearnMoreToggle = (planType) => {
      trackEvent(PayrollTrackEvents.LEARN_MORE_CLICK, {
        planType,
      });
    };

    const chargedAtLabel = computed(() => {
      return `Starting after ${format(
        trialEndDate.value,
        DateFormat.MMMM_DO_YYYYY
      )}`;
    });

    const isBasic = computed(() => {
      return selectedPlan.value === ProductKey.PayrollBasic;
    });

    const price = computed(
      () =>
        eligibleAddOns.value?.find(
          (addon) => addon.productKey === selectedPlan.value
        )?.price || 0
    );

    // Price per employee
    const pepm = computed(
      () =>
        eligibleAddOns.value?.find(
          (addon) => addon.productKey === selectedPlan.value
        )?.unitPrice || 0
    );

    const lineItems = computed(() => {
      const planLabel = isBasic.value ? 'Basic Payroll' : 'Premium Payroll';

      const plan: LineItem = {
        label: `${planLabel} Monthly Company Fee`,
        price: price.value,
        originalPrice: 0, //
        bold: true,
        priceDescriptor: `After ${trialDuration.value} day trial`,
        tooltip: chargedAtLabel.value,
      };
      const payrollPepm: LineItem = {
        label: `+ Payroll Usage`,
        price: pepm.value,
        priceDescriptor: `Per Employee`,
      };
      const taPepm: LineItem = {
        label: `+ Time and Attendance Usage`,
        price: isBasic.value ? 4 : 0,
        originalPrice: 4, //
        priceDescriptor: `Per Employee`,
      };

      return [plan, payrollPepm, taPepm];
    });
    const total = computed(() => {
      return {
        label: `Total`,
        price: 100,
      };
    });

    /**
     * Watchers/Executions
     */

    return {
      planOptions,
      selectedPlan,
      isReady,
      purchaseButtonText,
      trialDuration,
      saving,
      handleSwitchPlan,
      handlePlanLearnMoreToggle,
      handlePayrollPurchase,
      handleSkip,
      lineItems,
      total,
    };
  },
};
