<template>
  <div
    class="
      hero-registration
      flex flex-col
      lg:flex-row lg:items-center lg:justify-between
    "
  >
    <slot />
    <RegistrationPrompt
      v-if="registration"
      button-id="hero-company-size-button"
      class="flex-shrink-0 mx-auto lg:ml-16"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import RegistrationPrompt from './RegistrationPrompt.vue';

export default defineComponent({
  name: 'HeroRegistration',
  components: { RegistrationPrompt },
  props: {
    registration: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
});
</script>
