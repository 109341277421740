
























import {
  defineComponent,
} from '@nuxtjs/composition-api';
import ShoppingCartLineItem from '@/modules/SelfServiceRegistration/components/ShoppingCart/ShoppingCartLineItem.vue';
import ShoppingCartSalesTax from '@/modules/SelfServiceRegistration/components/ShoppingCart/ShoppingCartSalesTax.vue';
import { TypeBody } from '@bambeehr/pollen';

export type LineItem = {
  label: string,
  price: number,
  originalPrice?: number,
  description?: string,
  bold?: boolean,
  type?: 'tax' | undefined,
  tooltip?: string,
  priceDescriptor?: string,
  classes?: string,
}

export type TotalItem = {
  label: string,
  price: number,
}

export default defineComponent({
  name: 'ShoppingCart',
  components: {
    ShoppingCartLineItem,
    ShoppingCartSalesTax,
    TypeBody,
  },
  props: {
    isReady: {
      type: Boolean,
      default: false,
    },

    lineItems: {
      type: Array,
    },

    total: {
      type: Object
    },

    bg: {
      type: String,
      default: ''
    }
  },
});
