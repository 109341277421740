










































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { TypeBody, ToolTip } from '@bambeehr/pollen';
import currency from '@bambeehr/currency';

export default defineComponent({
  name: 'ShoppingCartSalesTax',
  components: {
    TypeBody,
    ToolTip,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: null,
    },
    amount: {
      type: Number,
      default: 0,
    },
    originalAmount: {
      type: Number,
      default: undefined,
    },
    calculating: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const showAmount = computed(() => typeof props.amount === 'number');

    return {
      formatCurrency: (value, precision) => currency(value, { precision }),
      showAmount,
    };
  },
});
