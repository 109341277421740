








import { computed, watch } from '@nuxtjs/composition-api';
import { TypeBody, BaseButton } from '@bambeehr/pollen'
import useCookiePolicy from '@/modules/CookiePolicy/hooks/useCookiePolicy/useCookiePolicy'
import { TrackEvents as CookieTrackEvents } from '@/modules/CookiePolicy/constants/analytics'


export default {
    name: 'CookiePolicyBanner',
    components: {
        TypeBody,
        BaseButton,
    },

    setup() {
        const { isReady: isCookiePolicyReady, hasAcknowledged, acknowledge, trackCookieEvent, policy } = useCookiePolicy();

        const handleAcknowledgeClick = () => {
            acknowledge();
            trackCookieEvent(CookieTrackEvents.POLICY_ACKNOWLEDGED);
        }
        
        const shouldShow = computed(() => {
            /**
             * TODO: Hardcoding for now, as we do not want to show until a rollout strategy is defined here: CX-1051
             * This means that we will force it to not show so that we can release the additional work: pages, routing, logic.
             * When the strategy is confirmed, we'll remove the `return false` and uncomment the logic `return isCookiePolicyReady.value && !hasAcknowledged.value`
             */
            return false;
            // return isCookiePolicyReady.value && !hasAcknowledged.value
        })
        
        watch(shouldShow, (shouldShowVal) => {
            if (shouldShowVal) {
                trackCookieEvent(CookieTrackEvents.BANNER_SHOWN);
            }
        }, { immediate: true });

        return {
            handleAcknowledgeClick,
            shouldShow,
            policy,
        };
    },
};
