<template>
    <SelectInput v-bind="$attrs" :value="value" :options="options" :invalid="isInvalid" :placeholder="placeholder" 
      :show-reset="false" :searchable="false" class="w-full" data-qa="employee-dropdown-select" @input="handleInput" />
</template>

<script>
import { SelectInput } from '@bambeehr/pollen';
import uniqBy from 'lodash/uniqBy';
import { computed } from '@nuxtjs/composition-api';
import { EmployeeNotLookingOption } from '@/constants/Registration'
import usePricePlans from '@/hooks/usePricePlans'

import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration';


export default {
  components: { SelectInput },
  props: {
    force: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Number of employees',
    },
  },
  setup(_, { emit }) {
    const { plans } = usePricePlans();

    const sortedPlans = computed(() => plans.value.sort((a, b) => a.maxSize - b.maxSize));
    const { updateRegistration, state: registration } = useRegistration();

    const handleInput = (e) => {
      updateRegistration({
        [RegistrationStateNames.NUM_EMPLOYEES]: e,
      });

      emit('input', e);
    };

    const options = computed(() => {
      const employerOptions = uniqBy(sortedPlans.value, 'employeeSizeMapping').map(
        ({ employeeSizeName, employeeSizeMapping }) => {
          const [first, second] = employeeSizeName.split(' - ');
          if (first === second) {
            return {
              label: '0 (Just me right now)',
              value: employeeSizeMapping,
            };
          }

          return {
            label: employeeSizeName,
            value: employeeSizeMapping,
          };
        }
      ).filter(({value})=>!!value)

      const employeeOptions = [
        EmployeeNotLookingOption,
      ]

      return [...employerOptions, ...employeeOptions];
    });
    const value = computed(() => registration.numOfEmployees);

    return {
      handleInput,
      options,
      value,
    };
  },
};
</script>
