import * as FullStory from '@fullstory/browser';

const PRODUCTION = 'production';

export default () => {
  window.onNuxtReady(() => {
    FullStory.init({
      orgId: process.env.FULLSTORY_ORG_ID,
      devMode: process.env.NODE_ENV !== PRODUCTION,
    });
  });
};
