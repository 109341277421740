



























































import {
  computed,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import {
  BaseButton,
  TypeBody,
  TypeDisplay,
  TypeHeading,
  BaseIcon,
  RadioToggle,
} from '@bambeehr/pollen';
import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration';
import OptionsShoppingCart from '@/modules/SelfServiceRegistration/components/ShoppingCart/OptionsShoppingCart.vue';
import LayoutApplicationStyle from '@/modules/SelfServiceRegistration/components/LayoutApplicationStyle.vue';
import BaseCard from '@/components/BaseCard/BaseCard.vue';
import usePricePlans, { PlanProductKey } from '@/hooks/usePricePlans';
import useTrials from '@/hooks/useTrials';
import Analytics from '@/services/Analytics';
import PricePlanSelector from '@/modules/SelfServiceRegistration/components/PricePlanSelector/PricePlanSelector.vue';
import PlanTitle from '@/modules/SelfServiceRegistration/components/PlanTitle/PlanTitle.vue'
import currency from '@bambeehr/currency';
import { BillingPeriod } from '@/constants/Billing';
import { updatePlan } from '@/services/Payment'

enum TrackEvents {
  PLAN_CLICK = `plan_option_click`,
  BILLING_PERIOD_CLICK = `billing_period_toggle_click`,
  LEARN_MORE_CLICK = `plan_learn_more_toggle_click`,
  UPDATE_PLAN_INITIALIZED = `plan_update_initialized`,
  UPDATE_PLAN_SUCCESS = `plan_update_success`,
  UPDATE_PLAN_ERROR = `plan_update_error`,
  UPDATE_COMPLETE = `plan_update_complete`
}


export default {
  name: 'PlanSelectionPage',
  components: {
    BaseButton,
    BaseCard,
    BaseIcon,
    LayoutApplicationStyle,
    OptionsShoppingCart,
    TypeBody,
    TypeDisplay,
    TypeHeading,
    PricePlanSelector,
    PlanTitle,
    RadioToggle,
  },

  setup(_, { emit }) {
    /**
     * HOOKS
     */
    // @ts-ignore
    const { state: registrationState, updateRegistration } = useRegistration();
    const {
      selectedPlan,
      setSelectedPlanById,
      plans,
      dedicatedPlans,
      elitePlans,
      bambeeLitePlans,
      removeSelectedPlan,
    } = usePricePlans();

    const { trialDuration } = useTrials()
    const updatingPlan = ref(false)

    const trackEvent = (eventName: TrackEvents, additionalData = {}) => {
      Analytics.track(eventName, {
        registrationState,
        ...additionalData
      })
    }

    const isReady = computed(() => {
      return plans.value && visibleLitePlan.value
    })

    const purchasedPlanId = computed(() => {
      return registrationState[RegistrationStateNames.PURCHASED_PLAN_ID]
    })



    const planToggleOptions: { label: string, value: BillingPeriod }[] = [
      {
        label: 'Monthly',
        value: BillingPeriod.MONTH
      },
      {
        label: `Annual <b class="ml px-2 bg-secondary-tint-4 text-secondary-shade-1 rounded">Save 15%</b>`,
        value: BillingPeriod.YEAR
      }
    ]

    const selectedBillingPeriod = ref(BillingPeriod.MONTH)



    /**
     * Set state for payment plans
     */

    const dedicatedPlansByTier = computed(() => {
      // @ts-ignore
      const filtered = dedicatedPlans.value.filter((p) => p.employeeSizeMapping === registrationState[RegistrationStateNames.NUM_EMPLOYEES]);
      return filtered;
    });

    const monthlyDedicatedPlanByTier = computed(() => {
      // Extra precaution because of a bug that is returning `twoYear` plans as well for T0 https://bambee.atlassian.net/browse/BB-1934
      return dedicatedPlansByTier.value.find(
        (p) => p.billingPeriod === BillingPeriod.MONTH
      );
    });

    const annualDedicatedPlanByTier = computed(() => {
      // Extra precaution because of a bug that is returning `twoYear` plans as well for T0 https://bambee.atlassian.net/browse/BB-1934
      return dedicatedPlansByTier.value.find(
        (p) => !!p.annual && p.billingPeriod === BillingPeriod.YEAR
      );
    });

    const visibleDedicatedPlan = computed(() => {
      if (selectedBillingPeriod.value === BillingPeriod.YEAR) {
        return annualDedicatedPlanByTier.value
      } else {
        return monthlyDedicatedPlanByTier.value
      }
    })
    /**
     * Set state for payment plans
     */

    const elitePlansByTier = computed(() => {
      // @ts-ignore
      const filtered = elitePlans.value.filter((p) => p.employeeSizeMapping === registrationState[RegistrationStateNames.NUM_EMPLOYEES]);
      return filtered;
    });

    const monthlyElitePlanByTier = computed(() => {
      return elitePlansByTier.value.find(
        (p) => p.billingPeriod === BillingPeriod.MONTH
      );
    });

    const annualElitePlanByTier = computed(() => {
      return elitePlansByTier.value.find(
        (p) => !!p.annual && p.billingPeriod === BillingPeriod.YEAR
      );
    });

    const visibleElitePlan = computed(() => {
      if (selectedBillingPeriod.value === BillingPeriod.YEAR) {
        return annualElitePlanByTier.value
      } else {
        return monthlyElitePlanByTier.value
      }
    })

    const visibleLitePlan = computed(() => {
      return bambeeLitePlans.value?.find(p => p.billingPeriod === selectedBillingPeriod.value)
    })


    const planOptions = computed(() => {
      const liteConfig = {
        id: visibleLitePlan.value?.value,
        key: PlanProductKey.LITE,
        html: `
        HR call center support only for ${currency(visibleLitePlan.value?.monthlyPrice).format({ precision: 0 })}/mo after your ${trialDuration.value}-day trial. Quick answers to complex questions answered in round-robin fashion by the first available HR Manager.
        `,
        expandableList: [
          `<b>First available HR manager</b> for urgent HR issues or when questions arise, our HR managers can provide peace of mind, clarity and swift resolution.`,
          `<b>Schedule 15 minute call with same day call back</b> from compensation or termination guidance, leave of absence support, employee issues and more`,
          `<b>California based call center</b> ensuring professional training, quality assurance standards, and knowledge of US labor regulation.`,
        ],
      }

      const dedicatedConfig = {
        id: visibleDedicatedPlan.value?.value,
        key: PlanProductKey.DEDICATED,
        html: `
        A dedicated HR Manager that works directly with you or an admin in your company for just ${currency(visibleDedicatedPlan.value?.monthlyPrice).format({ precision: 0 })}/mo after your ${trialDuration.value}-day trial. Your HR Manager will craft HR policy and deliver 1:1 HR Guidance by phone email, or chat. Plus access to our HR Autopilot platform to streamline HR.`,
        expandableList: [
          `<b>Dedicated HR Manager for Owner/Admin:</b> Your Dedicated HR Manager is your main point of contact at Bambee and will always work with you when in need of HR support. Your HR Manager will act as support to you or an admin in your company to guide you through all HR related matters, from ensuring new employees are onboarded properly to guidance for terminations.`,
          `<b>Craft and Implement HR Policy:</b> Your HR Manager will design your company's policies to fit the standards of your business, while ensuring they are in line with all local, state and federal regulations.`,
          `<b>HR Strategy Guidance:</b> Your Dedicated HR Manager will design a best-practice HR strategy for your business and then coach you through any difficult issues that may arise over time including challenging employee issues, manager training, performance management, and much more.`,
        ],
      }

      const eliteConfig = visibleElitePlan.value && {
        id: visibleElitePlan.value?.value,
        key: PlanProductKey.ELITE,
        html: `Our HR concierge plan. With Bambee Elite your dedicated HR manager provides best-in-class HR Guidance <b><i>and</i></b> will execute HR decisions and resolve HR issues directly with your employees, on your behalf. All at less than 20% of the cost of an internal HR Manager, starting at ${currency(visibleElitePlan.value?.monthlyPrice).format({ precision: 0 })}/mo after your ${trialDuration.value}-day trial.`,
        expandableList: [
          `<b>Uncomfortable Conversations and Expert Coaching:</b> Your Dedicated HR Manager will handle those uncomfortable employee conversations and coach your supervisors on HR best practices, whether it's issuing difficult feedback or executing a complex employee termination. Since you can't be in two places at once, your HR Manager ensures supervisors follow HR best practices across your organization. You focus on running your business, and let Bambee take care of your HR.`,
          `<b>Employee Performance Management and Complex Employee Terminations:</b> Your Bambee HR Manager will write and deliver performance management conversations for employees struggling in your business. When appropriate, and with your blessing, they will also terminate an employee directly, always with dignity, giving you peace-of-mind that challenging HR moments are handled directly by an HR expert on your behalf.`,
          `<b>Uncover and Solve Employee Issues:</b> Your HR Manager will reach out to employees directly to discover issues that have been festering in your business. Once uncovered, your HR Manager gets to work directly with impacted employees and managers to fully understand the issue and come up with a solution for all involved, making sure to keep you updated along the way.`,
          `<b>Investigation and Employee Mediation:</b> Your HR Manager will investigate and mediate any serious allegations made by an employee against a peer or supervisor. Your HR Manager will handle everything from interviewing impacted employees to documenting allegations and resolving any issues that are uncovered during the investigation.`,
        ],
      }

      const options = {
        [PlanProductKey.LITE]: liteConfig,
        [PlanProductKey.DEDICATED]: dedicatedConfig,
      }

      // Elite is not available for T0
      if (eliteConfig) {
        options[PlanProductKey.ELITE] = eliteConfig
      }

      return options;
    })

    const buttonText = computed(() => {
      if (updatingPlan.value) {
        return `Personalizing Your Trial`
      }
      return `Continue`
    })

    /**
     * Methods
     */

    const handleSwitchPlan = (productKey: (PlanProductKey | undefined)) => {
      if (!productKey) {
        return;
      }
      let newPlanId = visibleDedicatedPlan.value?.value;

      if (productKey === PlanProductKey.LITE) {
        newPlanId = visibleLitePlan.value?.value
      } else if (productKey === PlanProductKey.ELITE) {
        newPlanId = visibleElitePlan.value?.value
      }


      if (!newPlanId || newPlanId === selectedPlan.value?.value) {
        return
      }

      setSelectedPlanById(newPlanId);
      updateRegistration({
        [RegistrationStateNames.PLAN_ID]: newPlanId
      })

      trackEvent(TrackEvents.PLAN_CLICK, { target: newPlanId })
    };

    const handlePlanFinalization = async () => {

      // If the plan is not the default plan, then progress, otherwise update the plan before progressing
      if (selectedPlan.value?.value !== registrationState[RegistrationStateNames.PURCHASED_PLAN_ID]) {
        trackEvent(TrackEvents.UPDATE_PLAN_INITIALIZED, { target: selectedPlan.value?.value, from: registrationState[RegistrationStateNames.PURCHASED_PLAN_ID] })
        updatingPlan.value = true;

        const res = await updatePlan({
          companyId: registrationState[RegistrationStateNames.COMPANY_ID],
          planValue: selectedPlan.value?.value
        })
          .catch((e) => {
            console.error(e)
            trackEvent(TrackEvents.UPDATE_PLAN_ERROR)
          })
          .finally(() => {
            updatingPlan.value = false;
          })

        trackEvent(TrackEvents.UPDATE_PLAN_SUCCESS)
      }

      trackEvent(TrackEvents.UPDATE_COMPLETE)
      emit('submit')
    }

    const handlePlanLearnMoreToggle = (planType) => {
      trackEvent(TrackEvents.LEARN_MORE_CLICK, {
        planType,
      })

    }

    /**
     * Watchers/Executions
     */

    removeSelectedPlan()

    watch(
      selectedBillingPeriod,
      (n, o) => {
        if (n && n !== o) {
          handleSwitchPlan(selectedPlan.value?.productKey)
          trackEvent(TrackEvents.BILLING_PERIOD_CLICK, { target: n, from: o })
        }
      },
      {
        immediate: true,
      }
    );

    return {
      planOptions,
      selectedPlan,
      isReady,
      planToggleOptions,
      selectedBillingPeriod,
      buttonText,
      purchasedPlanId,
      trialDuration,
      updatingPlan,
      handleSwitchPlan,
      handlePlanLearnMoreToggle,
      handlePlanFinalization,
    };
  },
};
