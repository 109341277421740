















import { defineComponent } from '@nuxtjs/composition-api';
import { TypeBody, ToolTip } from '@bambeehr/pollen';

export default defineComponent({
  name: 'ShoppingCartLineLabel',
  components: {
    TypeBody,
    ToolTip,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
});
