import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@nuxtjs/composition-api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  _FieldSet: any;
};

export type AcceptOfferInput = {
  /** When customer provides a customer reason for cancelling */
  clientOtherReasonNote?: InputMaybe<Scalars['String']>;
  /** The plan value to accept. */
  planValue: Scalars['String'];
  /** Reason Codes the customer wanted to cancel before accepting offer */
  reasonCodes?: InputMaybe<Array<Scalars['Float']>>;
  /** The cancellation request wizard session id */
  sessionId?: InputMaybe<Scalars['String']>;
};

export type AcceptRecommendationInput = {
  /** The id of the recommendation to be accepted. */
  recommendationId: Scalars['String'];
};

export type AcceptTrialOfferInput = {
  /** When customer provides a customer reason for cancelling */
  clientOtherReasonNote?: InputMaybe<Scalars['String']>;
  /** Reason Codes the customer wanted to cancel before accepting offer */
  reasonCodes?: InputMaybe<Array<Scalars['Float']>>;
  /** The cancellation request wizard session id */
  sessionId?: InputMaybe<Scalars['String']>;
  /** The date the trial should be extended to */
  trialEndDate: Scalars['DateTime'];
};

export type AddCreditsInput = {
  chargeCustomer?: InputMaybe<Scalars['Boolean']>;
  companyId: Scalars['String'];
  creationDescription: Scalars['String'];
  creationReason: ProductCreditCreationReason;
  productKey: ProductKey;
  quantity: Scalars['Int'];
};

export type AddCreditsResult = {
  __typename?: 'AddCreditsResult';
  amountCharged?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  creditsCreated: Scalars['Int'];
  productKey: ProductKey;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type AddSubscriptionInput = {
  billingPeriod?: InputMaybe<BillingPeriod>;
  chargeCustomer?: InputMaybe<Scalars['Boolean']>;
  companyId: Scalars['String'];
  creationDescription: Scalars['String'];
  creationReason: ProductCreditCreationReason;
  productKey: ProductKey;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  id: Scalars['ID'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type AddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type AdvisorNextAvailableSlotInput = {
  /** The company identifier */
  companyId: Scalars['String'];
  /** The duration of the slot in minutes */
  duration: Scalars['Int'];
  /** The role for the company advisor */
  role: CompanyAdvisorRole;
};

export type AdvisorNextTimeSlot = {
  __typename?: 'AdvisorNextTimeSlot';
  /** The calendly slug for the advisor */
  calendlySlug?: Maybe<Scalars['String']>;
  /** The role for the advisor */
  role: CompanyAdvisorRole;
  /** The next available time slot for the advisor */
  slot?: Maybe<TimeSlot>;
  /** The advisor user identifier */
  userId: Scalars['String'];
};

export type AiGenerated = {
  __typename?: 'AiGenerated';
  /** Extracted json parsed data generated by the AI */
  body?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Raw response provided by AI */
  response: Scalars['JSON'];
};

/** The status of the prompt */
export enum AiGeneratedStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type AiPromptInput = {
  /** The prompts to send to OpenAI */
  prompts: Array<RawAiPrompt>;
  /** The side effects to trigger after the prompt is completed */
  sideEffects?: InputMaybe<Array<InputMaybe<AiPromptSideEffect>>>;
};

export type AiPromptResponse = {
  __typename?: 'AiPromptResponse';
  /** The id of the prompt */
  id: Scalars['String'];
  /** The status of the prompt */
  status: AiGeneratedStatus;
};

/** Side effects that can be triggered by an AI prompt completion */
export enum AiPromptSideEffect {
  CallTranscriptionSummary = 'CALL_TRANSCRIPTION_SUMMARY'
}

export type AnalyticEvent = {
  /** name or key of the event */
  event: Scalars['String'];
  /** The id of the analytic event */
  id: Scalars['ID'];
  /** additional properties associated with the event */
  properties?: InputMaybe<Scalars['JSONObject']>;
  /** the identity associated with the data */
  userId?: InputMaybe<Scalars['String']>;
};

export type AnalyticEventTracked = {
  __typename?: 'AnalyticEventTracked';
  /** name or key of the event */
  event: Scalars['String'];
  /** The id of the analytic event */
  id: Scalars['ID'];
  /** indicator if event was tracked successfully */
  success: Scalars['Boolean'];
  /** the identity associated with the data */
  userId?: Maybe<Scalars['String']>;
};

export type ApplyCorePayrollDiscountInput = {
  stripeCustomerId: Scalars['String'];
};

/** The result of the decision. */
export enum ApprovalResult {
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export type ApprovePayrollInput = {
  id: Scalars['ID'];
  /** Optimistic locking to prevent stale data clobbering fresher data */
  version: Scalars['Int'];
};

export type ArchivePolicyInput = {
  policyId: Scalars['ID'];
};

export type AskNicelySurveyInfo = {
  __typename?: 'AskNicelySurveyInfo';
  emailHash?: Maybe<Scalars['String']>;
  force: Scalars['Boolean'];
  show: Scalars['Boolean'];
};

export type AssignUserToPoliciesInput = {
  /** Company ID of the policy */
  companyId: Scalars['ID'];
  /** Employee ID to assign policy to */
  employeeId: Scalars['ID'];
  /** Policy IDs */
  policyIds: Array<Scalars['ID']>;
};

export type AssignUsersToPoliciesInput = {
  /** Company ID of the policy */
  companyId: Scalars['ID'];
  /** Employee IDs to assign policy to */
  employeeIds: Array<Scalars['ID']>;
  /** Policy IDs */
  policyIds: Array<Scalars['ID']>;
};

export type AssignWorkerRolesInput = {
  add?: InputMaybe<Array<CreateWorkerRoleInput>>;
  delete?: InputMaybe<Array<Scalars['ID']>>;
  update?: InputMaybe<Array<UpdateWorkerRoleInput>>;
  userId: Scalars['ID'];
};

export type AuthMfaChallenge = {
  __typename?: 'AuthMfaChallenge';
  deviceHeader: Scalars['String'];
  deviceId: Scalars['String'];
  expiration: Scalars['DateTime'];
  metadata?: Maybe<AuthMfaMetadata>;
  nonce: Scalars['String'];
};

export type AuthMfaChallengeResponseInput = {
  nonce: Scalars['String'];
  response: Scalars['String'];
};

export type AuthMfaConfig = {
  __typename?: 'AuthMfaConfig';
  /** When enrolling the first MFA device, a backup code will also be created and presented to the user. */
  backupCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  lastChallengedAt?: Maybe<Scalars['DateTime']>;
  lastDevice?: Maybe<Scalars['String']>;
  mfaType: AuthMfaType;
};

export type AuthMfaMetadata = {
  __typename?: 'AuthMfaMetadata';
  phoneLast4?: Maybe<Scalars['String']>;
};

export type AuthMfaSmsEnrollmentInput = {
  mfaType: AuthMfaType;
  phoneNumber: Scalars['String'];
};

export enum AuthMfaType {
  /** The backup code (which should be printed and securely stored!) in case the phone number becomes unavailable. */
  Backup = 'BACKUP',
  /** Certain challenges (like creating an MFA enrollment, or running a process without any other MFA) require using a password. */
  Password = 'PASSWORD',
  /** Perform MFA verification via SMS text message */
  Sms = 'SMS'
}

/** The user role of the decision maker. */
export enum AuthRole {
  Admin = 'ADMIN',
  Employee = 'EMPLOYEE'
}

export type AuthorizationDocument = Document & {
  __typename?: 'AuthorizationDocument';
  /** Date that this document was filed */
  filedOn: Scalars['String'];
  id: Scalars['ID'];
  /** will be either a two-character state abbreviation or "FED" */
  jurisdiction?: Maybe<Scalars['String']>;
  /** Document’s type */
  label: Scalars['String'];
  /** Year that this document was filed */
  year: Scalars['Float'];
};

export type AutoAssignHrManager = {
  __typename?: 'AutoAssignHrManager';
  coordinator: CoreUser;
  lastAssignedOn?: Maybe<Scalars['DateTime']>;
  stateGroup: Scalars['ID'];
  user: CoreUser;
};

export type AutoAssignHrManagerInput = {
  coordinatorUserId: Scalars['ID'];
  stateGroup: Scalars['ID'];
  userId: Scalars['ID'];
};

export type AvailableProduct = {
  __typename?: 'AvailableProduct';
  /** Number of credits provided on core subscription. Null if single purchase */
  allowance?: Maybe<Scalars['Int']>;
  /** Billing period of the product. Null if single purchase */
  billingPeriod?: Maybe<Scalars['String']>;
  /** One-time discounted price of product, if available. Null if subscription */
  discountedPrice?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  key: ProductKey;
  /** Number of credits provided per month, if applicable. Null if single purchase */
  monthlyAllowance?: Maybe<Scalars['Int']>;
  /** Name of product */
  name?: Maybe<Scalars['String']>;
  /** One-time price of product. Null if subscription */
  price?: Maybe<Scalars['Int']>;
  /** The price to subscribe, based on billing period. Null if single purchase */
  subscriptionPrice?: Maybe<Scalars['Int']>;
  /** Company tier */
  tier?: Maybe<Scalars['Int']>;
};

export type AvailableProducts = {
  __typename?: 'AvailableProducts';
  bundles: Array<BundleResponse>;
  id: Scalars['ID'];
  topics: Array<CourseTopic>;
};

export type BackfillBambeePlusTopicsInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  topicIds: Array<Scalars['String']>;
};

export type BambeePlus = {
  __typename?: 'BambeePlus';
  eligible: Scalars['Boolean'];
  hasExtendedOnboardingDeadline: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Late fee implementation price in units i.e $10.00 = 1000 */
  lateFeeImplementationPrice?: Maybe<Scalars['Float']>;
  onboardingDueDate?: Maybe<Scalars['DateTime']>;
  /** Price related to core subscription in units i.e $10.00 = 1000 */
  price?: Maybe<Scalars['Float']>;
  status: BambeePlusStatuses;
};

export enum BambeePlusStatuses {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Inactive = 'INACTIVE'
}

export type BankAccount = {
  __typename?: 'BankAccount';
  /** Type of bank account (e.g. CHECKING, SAVINGS, etc */
  accountSubType: BankAccountSubtype;
  /** Check ID of bank account */
  checkId?: Maybe<Scalars['ID']>;
  /** Bambee bank account ID */
  id: Scalars['ID'];
  /** The name of the bank holding the account */
  institutionName: Scalars['String'];
  /** Bank's mask number or last 4 of the account number */
  mask: Scalars['String'];
  /** Bank nickname */
  name?: Maybe<Scalars['String']>;
  /**
   * Type of bank account (e.g. CHECKING, SAVINGS, etc
   * @deprecated Use accountSubType instead
   */
  subType: Subtype;
};

export enum BankAccountSteps {
  BankAccountDebitAuthorizationSubmitted = 'BANK_ACCOUNT_DEBIT_AUTHORIZATION_SUBMITTED',
  BankAccountExists = 'BANK_ACCOUNT_EXISTS',
  BankAccountVerified = 'BANK_ACCOUNT_VERIFIED'
}

export enum BankAccountSubtype {
  Checking = 'CHECKING',
  Savings = 'SAVINGS'
}

export type Benefit = {
  __typename?: 'Benefit';
  /** Indicates whether the benefit is active for this employee. */
  active: Scalars['Boolean'];
  /** The Check ID associated with the benefit. */
  checkId?: Maybe<Scalars['ID']>;
  companyBenefit?: Maybe<CompanyBenefit>;
  /** Company Benefit ID of the benefit */
  companyBenefitId: Scalars['ID'];
  /** If provided, the timestamp the benefit was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The timestamp of the benefit end date */
  effectiveEnd?: Maybe<Scalars['DateTime']>;
  /** The timestamp of the benefit start date */
  effectiveStart?: Maybe<Scalars['DateTime']>;
  /** The amount the employee is contributing per benefit */
  employeeContribution: Scalars['Float'];
  /** The amount the employer is contributing per benefit */
  employerContribution: Scalars['Float'];
  id: Scalars['ID'];
  /** Optimistic locking to prevent stale data clobbering fresher data */
  version: Scalars['Int'];
};

export type BenefitCompanyOnboarding = {
  __typename?: 'BenefitCompanyOnboarding';
  coverageStartedAtChanged?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: CompanyBenefitOnboardStatus;
  targetEffectiveStart?: Maybe<Scalars['String']>;
};

export type BenefitCostEstimated = {
  __typename?: 'BenefitCostEstimated';
  /** The lowest cost per zip (for a 30 year old). */
  monthlyPremium: Scalars['Float'];
  /** The state abbreviation of the benefit cost. */
  state: Scalars['String'];
  /** Optimistic locking to prevent stale data clobbering fresher data */
  version: Scalars['Int'];
  /** The zip of the benefit cost. */
  zip: Scalars['ID'];
};

export enum BenefitOnboardStatus {
  Enrolled = 'ENROLLED',
  Enrolling = 'ENROLLING',
  Ineligible = 'INELIGIBLE',
  NotEnrolling = 'NOT_ENROLLING',
  OptedOut = 'OPTED_OUT'
}

export enum BenefitPeriod {
  Monthly = 'MONTHLY'
}

export enum BenefitType {
  Dental = 'DENTAL',
  Medical = 'MEDICAL',
  Vision = 'VISION'
}

export type BenefitWorkerOnboarding = {
  __typename?: 'BenefitWorkerOnboarding';
  status: BenefitOnboardStatus;
};

export type BillingInfo = {
  __typename?: 'BillingInfo';
  addonSubscriptions: Array<SubscriptionModel>;
  coreSubscription?: Maybe<CoreSubscriptionModel>;
  id: Scalars['ID'];
  pause: Paused;
  paymentMethods: Array<BillingPaymentMethod>;
};

export type BillingPaymentMethod = {
  __typename?: 'BillingPaymentMethod';
  default: Scalars['Boolean'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  issueReason?: Maybe<PaymentMethodIssueReason>;
  name: Scalars['String'];
  nextAction?: Maybe<PaymentMethodNextAction>;
  status: PaymentMethodStatus;
  type: PaymentMethodType;
};

/** Billing Period */
export enum BillingPeriod {
  HalfYear = 'HALF_YEAR',
  Month = 'MONTH',
  NineMonths = 'NINE_MONTHS',
  Quarter = 'QUARTER',
  ThreeYears = 'THREE_YEARS',
  TwoYears = 'TWO_YEARS',
  Year = 'YEAR'
}

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
};

export type BulkCompleteEnrollmentInput = {
  doceboCourseIds: Array<Scalars['Float']>;
  userIds: Array<Scalars['String']>;
};

export type BulkEarningsImportErrors = {
  __typename?: 'BulkEarningsImportErrors';
  errorMsg: Scalars['String'];
  staffId: Scalars['ID'];
};

export type BulkEarningsImportInput = {
  /** Bambee payroll id */
  payrollId: Scalars['ID'];
  /** FlatfileResults JSON */
  results: Scalars['JSON'];
};

export type BulkEarningsImportResult = {
  __typename?: 'BulkEarningsImportResult';
  errors: Array<Maybe<BulkEarningsImportErrors>>;
  payroll: Payroll;
  successfulStaffIds: Array<Maybe<Scalars['ID']>>;
};

export type BulkEnrollUsersInput = {
  companyId: Scalars['String'];
  enrollments: Array<EnrollmentInput>;
};

export type BulkEnrollUsersResponse = {
  __typename?: 'BulkEnrollUsersResponse';
  enrolledUsers: Array<EnrollUsersResponse>;
  errors: Array<EnrollUsersError>;
};

export type BulkMessageStaff = {
  active?: InputMaybe<Scalars['Boolean']>;
  signatureRequired?: InputMaybe<Scalars['Boolean']>;
  staffId: Scalars['ID'];
  status?: InputMaybe<StaffStatus>;
};

export type BulkMessageStaffError = {
  __typename?: 'BulkMessageStaffError';
  errorMsg: Scalars['String'];
  staffId: Scalars['ID'];
};

export type BulkMessageStaffInput = {
  messageBody: Scalars['String'];
  messageGroupType: MessageGroupType;
  messageType: MessageType;
  pageSource?: InputMaybe<Scalars['String']>;
  staff: Array<BulkMessageStaff>;
  textMessage?: InputMaybe<Scalars['Boolean']>;
};

export type BulkMessageStaffResult = {
  __typename?: 'BulkMessageStaffResult';
  errors: Array<Maybe<BulkMessageStaffError>>;
  successfulStaffIds: Array<Maybe<Scalars['ID']>>;
};

export type BulkPurchaseSeatsInput = {
  purchases: Array<InputMaybe<PurchaseSeatsInput>>;
};

export type BulkRequestResult = {
  __typename?: 'BulkRequestResult';
  errors?: Maybe<Array<Maybe<BulkRequestResultError>>>;
  requests?: Maybe<Array<Maybe<CoreRequest>>>;
};

export type BulkRequestResultError = {
  __typename?: 'BulkRequestResultError';
  message: Scalars['String'];
  requestId: Scalars['String'];
};

export type BulkSetupStaffInput = {
  /** Bambee company id */
  companyId: Scalars['ID'];
  /** IDs of Bambee staff (w2/1099) to be setup for payroll */
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type BundleResponse = {
  __typename?: 'BundleResponse';
  annualStripePriceId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  global: Scalars['Boolean'];
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  singleStripePriceId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  topics: Array<Maybe<CourseTopic>>;
  updatedAt: Scalars['DateTime'];
};

export type BusinessManager = {
  __typename?: 'BusinessManager';
  lastAssignedOn?: Maybe<Scalars['DateTime']>;
  tier: Scalars['Int'];
  user: CoreUser;
};

export type BusinessManagerForTierInput = {
  tier: Scalars['Int'];
  userId: Scalars['ID'];
};

export type Cadence = {
  __typename?: 'Cadence';
  value: Scalars['String'];
};

export type Calendly = {
  __typename?: 'Calendly';
  url: Scalars['String'];
};

export type CallInformation = {
  /** The reason for requesting a new call */
  reasonForCall: Scalars['String'];
};

export type CancelRecommendationInput = {
  /** The id of the recommendation to be canceled. */
  recommendationId: Scalars['String'];
};

export type CancelSubscriptionInput = {
  companyId: Scalars['String'];
  consumptionDescription: Scalars['String'];
  consumptionReason: ProductCreditConsumptionReason;
  productKey: ProductKey;
};

export type CancelTimeAttendanceInput = {
  companyId: Scalars['String'];
  currentUserId: Scalars['String'];
};

export type CancellationModel = {
  __typename?: 'CancellationModel';
  /** A note from the client */
  clientReasonNote: Scalars['String'];
  /** User who requested the cancellation */
  closedBy?: Maybe<Scalars['String']>;
  /** When was the cancellation closed */
  closedDate?: Maybe<Scalars['String']>;
  /** A final note when the cancellation request is closed by either confirming or rescueing it */
  closingNote: Scalars['String'];
  /** User who created the cancellation */
  createdBy: Scalars['String'];
  /** When was the cancellation created */
  createdDate: Scalars['String'];
  /** Numbers of months credited upon reinstatement */
  creditMonths: Scalars['Float'];
  /** If cancellation is confirmed, when is the subscription's effective last day */
  effectiveDate: Scalars['String'];
  /** Unique identifier for the cancellation */
  id: Scalars['String'];
  /** An internal note made by a Bambee user */
  internalReasonNote: Scalars['String'];
  /** Flag stating if company was in trial when cancellation was requested */
  isTrialCancellation: Scalars['Boolean'];
  netAmount: Scalars['Float'];
  /** List of reason codes specified by the user */
  reasonCodes: Array<Scalars['Float']>;
  rebuttalReasonCode: Scalars['String'];
  /** amount to be refunded */
  refundAmount: Scalars['Float'];
  /** Amount to be charged upon reinstatement */
  reinstatementAmount: Scalars['Float'];
  /** User who requested the cancellation, usually the company owner */
  requestedBy: Scalars['String'];
  /** When was the cancellation requested */
  requestedDate: Scalars['String'];
  /** The status of the cancellation */
  source: CancellationSource;
  /** The status of the cancellation */
  status: CancellationStatus;
};

export type CancellationOffersModel = {
  __typename?: 'CancellationOffersModel';
  /** The downgrade plan available for the customer */
  downgradePlan?: Maybe<CorePlanModel>;
  /** The downgrade plans available for the customer */
  downgradePlans: Array<CorePlanModel>;
  /** The downgrade plan available for the customer */
  pausePlan?: Maybe<CorePlanModel>;
  /** The downgrade plan available for the customer */
  storagePlan?: Maybe<CorePlanModel>;
  /** Flag indicating if the customer can extend their trial */
  trialExtension: Scalars['Boolean'];
};

export type CancellationReasonGroupModel = {
  __typename?: 'CancellationReasonGroupModel';
  /** Identifier for the cancellation reason group */
  code: Scalars['String'];
  /** Text to be displayed in UI */
  externalText: Scalars['String'];
  /** List of cancellation reasons for the group */
  reasons: Array<CancellationReasonModel>;
};

export type CancellationReasonModel = {
  __typename?: 'CancellationReasonModel';
  /** Flag indicating whether or the reason is active */
  active: Scalars['Boolean'];
  /** Identifier for the cancellation reason */
  code: Scalars['Float'];
  /** Text to be displayed in UI */
  externalText: Scalars['String'];
  /** Flag indicating if reason should be displayed only to Bambee users in Honey */
  internalOnly: Scalars['Boolean'];
};

export enum CancellationSource {
  Chat = 'CHAT',
  Email = 'EMAIL',
  Internal = 'INTERNAL',
  None = 'NONE',
  Phone = 'PHONE',
  Stripe = 'STRIPE',
  Web = 'WEB'
}

export enum CancellationStatus {
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Rescued = 'RESCUED'
}

export enum CardVerificationStatus {
  Fail = 'FAIL',
  Pass = 'PASS',
  Unknown = 'UNKNOWN'
}

export enum CaseStatus {
  Backedout = 'BACKEDOUT',
  Blocked = 'BLOCKED',
  Blockedcheck = 'BLOCKEDCHECK',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Closederror = 'CLOSEDERROR',
  Closedinactive = 'CLOSEDINACTIVE',
  ConfirmResolution = 'CONFIRM_RESOLUTION',
  Contacting = 'CONTACTING',
  Customerreplied = 'CUSTOMERREPLIED',
  Escalated = 'ESCALATED',
  InProgress = 'IN_PROGRESS',
  Merged = 'MERGED',
  Negotiationreview = 'NEGOTIATIONREVIEW',
  New = 'NEW',
  Onhold = 'ONHOLD',
  Pendingsummary = 'PENDINGSUMMARY',
  Reopened = 'REOPENED',
  ResponseReceived = 'RESPONSE_RECEIVED',
  Saved = 'SAVED',
  Summaryreview = 'SUMMARYREVIEW',
  WithCustomer = 'WITH_CUSTOMER',
  Wontdo = 'WONTDO'
}

export enum CaseSubType {
  Adaaccommodation = 'ADAACCOMMODATION',
  Adjustapprovedpayroll = 'ADJUSTAPPROVEDPAYROLL',
  Apphelp = 'APPHELP',
  Bambeeplusinitial = 'BAMBEEPLUSINITIAL',
  Bambeeplusofferquestion = 'BAMBEEPLUSOFFERQUESTION',
  BenefitsCancelation = 'BENEFITS_CANCELATION',
  Billingissue = 'BILLINGISSUE',
  Bonuscommission = 'BONUSCOMMISSION',
  Cabinet = 'CABINET',
  Callrequest = 'CALLREQUEST',
  Check = 'CHECK',
  Checkembeddedsetup = 'CHECKEMBEDDEDSETUP',
  Checkimplementation = 'CHECKIMPLEMENTATION',
  Checkkyb = 'CHECKKYB',
  CheckIn = 'CHECK_IN',
  Companyonboarding = 'COMPANYONBOARDING',
  Companyonboardingt0 = 'COMPANYONBOARDINGT0',
  Companyonboardingtrial = 'COMPANYONBOARDINGTRIAL',
  Completeonboarding = 'COMPLETEONBOARDING',
  Customerfeedback = 'CUSTOMERFEEDBACK',
  Delinquent = 'DELINQUENT',
  Detractor = 'DETRACTOR',
  Employeebenefits = 'EMPLOYEEBENEFITS',
  Employeechat = 'EMPLOYEECHAT',
  Employeeonboarding = 'EMPLOYEEONBOARDING',
  Employeeonboardingrequested = 'EMPLOYEEONBOARDINGREQUESTED',
  Employeerequest = 'EMPLOYEEREQUEST',
  Employeeresignationstarted = 'EMPLOYEERESIGNATIONSTARTED',
  Employeevoices = 'EMPLOYEEVOICES',
  Endemploymentemergency = 'ENDEMPLOYMENTEMERGENCY',
  Existingpolicyrevision = 'EXISTINGPOLICYREVISION',
  FederalEinValidation = 'FEDERAL_EIN_VALIDATION',
  Finalpay = 'FINALPAY',
  Firingtermination = 'FIRINGTERMINATION',
  FollowUp = 'FOLLOW_UP',
  General = 'GENERAL',
  Golive = 'GOLIVE',
  Hrmreasignment = 'HRMREASIGNMENT',
  Hrteamissue = 'HRTEAMISSUE',
  HrEscalation = 'HR_ESCALATION',
  HrReassignment = 'HR_REASSIGNMENT',
  I9Employmenteligibilityform = 'I9EMPLOYMENTELIGIBILITYFORM',
  Inapptraining = 'INAPPTRAINING',
  Incident = 'INCIDENT',
  Incorrectbilling = 'INCORRECTBILLING',
  Initialhrrequest = 'INITIALHRREQUEST',
  Initialonboarding = 'INITIALONBOARDING',
  Introcall = 'INTROCALL',
  Invoicereceipt = 'INVOICERECEIPT',
  Jobdescription = 'JOBDESCRIPTION',
  Keyaccountsemployeeonboarding = 'KEYACCOUNTSEMPLOYEEONBOARDING',
  Latecancellationprocessing = 'LATECANCELLATIONPROCESSING',
  Layoff = 'LAYOFF',
  Leaveabsence = 'LEAVEABSENCE',
  Loginaccess = 'LOGINACCESS',
  Managedservicesbriefcorrectiveaction = 'MANAGEDSERVICESBRIEFCORRECTIVEACTION',
  Managedservicesbrieftermination = 'MANAGEDSERVICESBRIEFTERMINATION',
  Managedservicesother = 'MANAGEDSERVICESOTHER',
  Missedpayroll = 'MISSEDPAYROLL',
  Missingemployees = 'MISSINGEMPLOYEES',
  Newcustompolicy = 'NEWCUSTOMPOLICY',
  Newpolicy = 'NEWPOLICY',
  Other = 'OTHER',
  Otherissue = 'OTHERISSUE',
  Payment = 'PAYMENT',
  Paymentmethodchange = 'PAYMENTMETHODCHANGE',
  Payroll = 'PAYROLL',
  Payrollpreactivation = 'PAYROLLPREACTIVATION',
  Payrollsupport = 'PAYROLLSUPPORT',
  PayrollCancelation = 'PAYROLL_CANCELATION',
  Pipcap = 'PIPCAP',
  Planchange = 'PLANCHANGE',
  Policies = 'POLICIES',
  Policyreview = 'POLICYREVIEW',
  Policyrevision = 'POLICYREVISION',
  Poorservice = 'POORSERVICE',
  Precancelation = 'PRECANCELATION',
  Preemployment = 'PREEMPLOYMENT',
  Productortechissues = 'PRODUCTORTECHISSUES',
  Pto = 'PTO',
  Ptomigration = 'PTOMIGRATION',
  Refundcredit = 'REFUNDCREDIT',
  Reportcards = 'REPORTCARDS',
  Resignation = 'RESIGNATION',
  Retentionsave = 'RETENTIONSAVE',
  Salesmisalignment = 'SALESMISALIGNMENT',
  Settingupbambeepayroll = 'SETTINGUPBAMBEEPAYROLL',
  Sickleave = 'SICKLEAVE',
  Staffcomplaint = 'STAFFCOMPLAINT',
  Subscriptionchange = 'SUBSCRIPTIONCHANGE',
  Subscriptionplanquestions = 'SUBSCRIPTIONPLANQUESTIONS',
  Taxamendment = 'TAXAMENDMENT',
  Taxquestion = 'TAXQUESTION',
  Technologyissue = 'TECHNOLOGYISSUE',
  Terminationrequest = 'TERMINATIONREQUEST',
  Timeandattendance = 'TIMEANDATTENDANCE',
  Training = 'TRAINING',
  Triage = 'TRIAGE',
  Unemploymentclaims = 'UNEMPLOYMENTCLAIMS',
  Userpermissions = 'USERPERMISSIONS',
  Voicemailmissedcalls = 'VOICEMAILMISSEDCALLS',
  Wagehour = 'WAGEHOUR',
  Workerscompensation = 'WORKERSCOMPENSATION',
  Workplaceposting = 'WORKPLACEPOSTING',
  Ytdreporting = 'YTDREPORTING',
  '3Rdpartytraining' = '_3RDPARTYTRAINING'
}

export enum CaseType {
  Adminsupport = 'ADMINSUPPORT',
  Bambeeguidedpayroll = 'BAMBEEGUIDEDPAYROLL',
  Bambeeplusofferquestion = 'BAMBEEPLUSOFFERQUESTION',
  BusinessManagement = 'BUSINESS_MANAGEMENT',
  Cancellationrequest = 'CANCELLATIONREQUEST',
  Compensationbenefits = 'COMPENSATIONBENEFITS',
  Credit = 'CREDIT',
  Customeractivation = 'CUSTOMERACTIVATION',
  Emailautoresponder = 'EMAILAUTORESPONDER',
  Employeefeedback = 'EMPLOYEEFEEDBACK',
  Employeerelations = 'EMPLOYEERELATIONS',
  Endingemployment = 'ENDINGEMPLOYMENT',
  Generalrequest = 'GENERALREQUEST',
  Hiringonboarding = 'HIRINGONBOARDING',
  Hrmsupport = 'HRMSUPPORT',
  Issuereporting = 'ISSUEREPORTING',
  Laborlaws = 'LABORLAWS',
  Legacy = 'LEGACY',
  Payrollonboarding = 'PAYROLLONBOARDING',
  Payrollsupport = 'PAYROLLSUPPORT',
  Performancemanagement = 'PERFORMANCEMANAGEMENT',
  Policies = 'POLICIES',
  Policyrequest = 'POLICYREQUEST',
  Refund = 'REFUND',
  Service = 'SERVICE',
  Techsupport = 'TECHSUPPORT',
  Terminationrequest = 'TERMINATIONREQUEST',
  Trainingdevelopment = 'TRAININGDEVELOPMENT',
  Trainingenrollment = 'TRAININGENROLLMENT',
  Typebilling = 'TYPEBILLING',
  Unknownrequest = 'UNKNOWNREQUEST'
}

export type ChangeTrialEndDateForCoreSubscriptionInput = {
  companyId: Scalars['String'];
  trialEndDate: Scalars['DateTime'];
};

export type ChannelPartipants = {
  __typename?: 'ChannelPartipants';
  user: CoreUser;
};

export enum ChannelType {
  /** A channel representing a group of employees at a company. */
  Group = 'Group',
  /** A channel representing a one-on-one conversation at a company. */
  OneOnOne = 'OneOnOne'
}

/** The role of the prompt */
export enum ChatCompletionRequestMessageRoleEnum {
  Assistant = 'Assistant',
  Function = 'Function',
  System = 'System',
  User = 'User'
}

export type ChatMessageInterface = {
  channelId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isThreadMessage: Scalars['Boolean'];
  isThreadParent: Scalars['Boolean'];
  rawType: SendbirdMessageType;
  /** Recipieint, if a one-on-one message */
  recipient?: Maybe<CoreUser>;
  sender: CoreUser;
  threadParentId?: Maybe<Scalars['ID']>;
  type: ChatMessageTypeEnum;
};

/** The message type as applicable to Bambee Chat */
export enum ChatMessageTypeEnum {
  /** This message represents a customized feedback item, containing a feedback value. */
  Feedback = 'Feedback',
  /** This message is just simple text and does not to be decoded or enriched. */
  Text = 'Text'
}

export type ChatTopicSuggestion = {
  __typename?: 'ChatTopicSuggestion';
  /** A list of CoreUsers that a suggested topic could be sent to. */
  recipients: Array<CoreUser>;
  type: Scalars['String'];
};

export type CheckCompanyImplementation = {
  __typename?: 'CheckCompanyImplementation';
  id: Scalars['ID'];
  remainingSteps?: Maybe<CheckCompanyImplementationSteps>;
  status: Scalars['String'];
};

export type CheckCompanyImplementationSteps = {
  __typename?: 'CheckCompanyImplementationSteps';
  kyb: CheckCompanyKyb;
};

export type CheckCompanyKyb = {
  __typename?: 'CheckCompanyKYB';
  status: Scalars['String'];
};

export type CheckCompanyOnboarding = {
  __typename?: 'CheckCompanyOnboarding';
  bankAccount?: Maybe<Array<Maybe<BankAccountSteps>>>;
  blockingSteps: Array<Maybe<CheckCompanyOnboardingSteps>>;
  filingAuthorization?: Maybe<Array<Maybe<IdNamePair>>>;
  id: Scalars['ID'];
  remainingSteps: Array<Maybe<CheckCompanyOnboardingSteps>>;
  setupParameters?: Maybe<Array<Maybe<IdNamePair>>>;
  status: CheckOnboardSteps;
};

export enum CheckCompanyOnboardingSteps {
  BankAccount = 'BANK_ACCOUNT',
  FilingAuthorization = 'FILING_AUTHORIZATION',
  SetupParameters = 'SETUP_PARAMETERS'
}

export enum CheckComponent {
  CompanyAccountingIntegration = 'CompanyAccountingIntegration',
  CompanyAuthorizationDocuments = 'CompanyAuthorizationDocuments',
  CompanyDetails = 'CompanyDetails',
  CompanyEmployeeSetup = 'CompanyEmployeeSetup',
  CompanyFilingAuthorization = 'CompanyFilingAuthorization',
  CompanyOnboard = 'CompanyOnboard',
  CompanyPaymentSetup = 'CompanyPaymentSetup',
  CompanyReports = 'CompanyReports',
  CompanyTaxDocuments = 'CompanyTaxDocuments',
  CompanyTaxSetup = 'CompanyTaxSetup',
  CompanyTermsOfService = 'CompanyTermsOfService',
  ContractorOnboard = 'ContractorOnboard',
  ContractorTaxDocuments = 'ContractorTaxDocuments',
  EmployeeBenefits = 'EmployeeBenefits',
  EmployeeOnboard = 'EmployeeOnboard',
  EmployeePaymentSetup = 'EmployeePaymentSetup',
  EmployeePaystubs = 'EmployeePaystubs',
  EmployeePostTaxDeductions = 'EmployeePostTaxDeductions',
  EmployeeProfile = 'EmployeeProfile',
  EmployeeSsnSetup = 'EmployeeSSNSetup',
  EmployeeTaxDocuments = 'EmployeeTaxDocuments',
  EmployeeWithholdingsSetup = 'EmployeeWithholdingsSetup',
  PreviousProviderAccess = 'PreviousProviderAccess',
  RunPayroll = 'RunPayroll'
}

export type CheckComponentInput = {
  /** The contractor id to generate a link for */
  companyId?: InputMaybe<Scalars['String']>;
  /** The component to generate a link for */
  component: CheckComponent;
  /** The contractor id to generate a link for */
  contractorId?: InputMaybe<Scalars['String']>;
  /** Email of the signatory; Required for CompanyOnboard component */
  email?: InputMaybe<Scalars['String']>;
  /** The employee id to generate a link for */
  employeeId?: InputMaybe<Scalars['String']>;
  /** Name of the signatory; Required for CompanyOnboard component */
  signerName?: InputMaybe<Scalars['String']>;
  /** Title of the signatory; Required for CompanyOnboard component */
  signerTitle?: InputMaybe<Scalars['String']>;
};

export type CheckComponentLink = {
  __typename?: 'CheckComponentLink';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type CheckContractorOnboarding = {
  __typename?: 'CheckContractorOnboarding';
  blockingSteps: Array<Maybe<ContractorOnboardingSteps>>;
  ein?: Maybe<Array<Maybe<EinSteps>>>;
  id: Scalars['ID'];
  paymentMethod?: Maybe<Array<Maybe<PaymentMethodSteps>>>;
  remainingSteps: Array<Maybe<ContractorOnboardingSteps>>;
  ssn?: Maybe<Array<Maybe<SsnSteps>>>;
  status: OnboardStatus;
  type?: Maybe<Array<Maybe<TypeSteps>>>;
};

export type CheckEmployeeOnboarding = {
  __typename?: 'CheckEmployeeOnboarding';
  blockingSteps: Array<EmployeeOnboardingSteps>;
  companyDefinedAttributes?: Maybe<Array<Maybe<IdNamePair>>>;
  id: Scalars['ID'];
  paymentMethod?: Maybe<Array<Maybe<PaymentMethodSteps>>>;
  remainingSteps: Array<EmployeeOnboardingSteps>;
  ssn?: Maybe<Array<Maybe<SsnSteps>>>;
  status: OnboardStatus;
  withholdings?: Maybe<Array<Maybe<IdNamePair>>>;
};

export type CheckFeinVerification = {
  __typename?: 'CheckFEINVerification';
  company: Scalars['String'];
  endTime?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  federalEin: Scalars['String'];
  id: Scalars['ID'];
  legalName: Scalars['String'];
  startTime: Scalars['String'];
  status: Scalars['String'];
};

export type CheckIntegration = {
  __typename?: 'CheckIntegration';
  active: Scalars['Boolean'];
  autoSync: Scalars['Boolean'];
  id: Scalars['ID'];
  qboCompanyId: Scalars['ID'];
};

export enum CheckOnboardSteps {
  Blocking = 'BLOCKING',
  Completed = 'COMPLETED',
  NeedsAttention = 'NEEDS_ATTENTION'
}

export type CheckSyncCompaniesInput = {
  correctData: Scalars['Boolean'];
};

export type CheckSyncCompanyData = {
  __typename?: 'CheckSyncCompanyData';
  onboard?: Maybe<Scalars['String']>;
  processingPeriod?: Maybe<ProcessingPeriod>;
};

export type CheckSyncCompanyFanoutResponse = {
  __typename?: 'CheckSyncCompanyFanoutResponse';
  count: Scalars['Float'];
  id: Scalars['ID'];
  ids: Array<Maybe<Scalars['String']>>;
  messages: Array<Maybe<CompanySyncMessage>>;
  queue: Scalars['String'];
};

export type CheckSyncCompanyInput = {
  /** Bambee company id */
  companyId: Scalars['ID'];
  correctData: Scalars['Boolean'];
};

export type ClearPayrollsInput = {
  /** Bambee company id */
  companyId: Scalars['ID'];
};

export type Company = {
  __typename?: 'Company';
  authorizationDocuments: Array<Maybe<AuthorizationDocument>>;
  availableProducts: AvailableProducts;
  bankAccounts?: Maybe<Array<Maybe<BankAccount>>>;
  benefitOnboarding?: Maybe<BenefitCompanyOnboarding>;
  boxInfo?: Maybe<CompanyBoxInfo>;
  checkId?: Maybe<Scalars['ID']>;
  checkImplementation?: Maybe<CheckCompanyImplementation>;
  checkIndustry?: Maybe<IndustryType>;
  checkIntegration?: Maybe<CheckIntegration>;
  checkOnboarding?: Maybe<CheckCompanyOnboarding>;
  companyBenefits: Array<Maybe<CompanyBenefit>>;
  companyId?: Maybe<Scalars['String']>;
  companyMngId?: Maybe<Scalars['String']>;
  complexPayrollOnboarding?: Maybe<Array<Maybe<ComplexPayrollOnboarding>>>;
  contractors: Array<Maybe<Contractor>>;
  defaultPayScheduleDays?: Maybe<Array<Maybe<DefaultPayScheduleDays>>>;
  /** Has this company established a connection to Deputy */
  deputyConnection?: Maybe<DeputyConnection>;
  /** Timestamp for when company accepted deputy terms of service */
  deputyTosAcceptedAt?: Maybe<Scalars['DateTime']>;
  employees: Array<Maybe<Employee>>;
  excludedStaffIds: Array<Maybe<Scalars['ID']>>;
  feinVerification?: Maybe<CheckFeinVerification>;
  feinVerificationAttempts: Scalars['Int'];
  /** Has this company established a connection to Finch */
  finchConnection?: Maybe<CompanyFinchAccessToken>;
  goals: Array<Goal>;
  hasDraftPayroll: Scalars['Boolean'];
  hasRunPayroll: Scalars['Boolean'];
  hasTraining: Scalars['Boolean'];
  /** Bambee company id */
  id: Scalars['ID'];
  /** Needs pay schedule assistance */
  needsPayScheduleAssistance?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  onboardingGarnishmentsOrDeductions: Array<OnboardingGarnishmentOrDeduction>;
  onboardingGarnishmentsOrDeductionsOther: Scalars['String'];
  onboardingHasComplexScenario?: Maybe<Scalars['Boolean']>;
  onboardingHasComplexTimeAttendanceScenario?: Maybe<Scalars['Boolean']>;
  onboardingHasGarnishmentsOrDeductions?: Maybe<Scalars['Boolean']>;
  onboardingOffersEmployeeBenefits: Scalars['Boolean'];
  /** Past payroll usage ( prior to Bambee ) */
  pastPayrollUsage?: Maybe<PastPayrollUsage>;
  /** Frequency by which the employees get paid. */
  payFrequency: PayFrequency;
  paySchedules: Array<Maybe<PaySchedule>>;
  paydays?: Maybe<Array<Maybe<CompanyPaydays>>>;
  processedPayrollsThisYear: Scalars['Boolean'];
  /** Frequency by which the employees get paid. */
  processingPeriod: ProcessingPeriod;
  purchasedProducts: PurchasedProducts;
  seatsSummary: SeatsSummary;
  signatoryEmail: Scalars['String'];
  signatoryFirstName: Scalars['String'];
  signatoryLastName: Scalars['String'];
  signatoryTitle: Scalars['String'];
  /** Date the company will start using Check as their payroll processor. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Bambee payroll status of the company. */
  status?: Maybe<CompanyStatus>;
  taxDocYears: Array<Scalars['String']>;
  taxDocuments: Array<Maybe<TaxDocument>>;
  taxExemptStatus?: Maybe<TaxExemptStatus>;
  timesheets: Array<Timesheet>;
  topics: Array<CourseTopic>;
  /** version of payroll terms last signed */
  tosVersion?: Maybe<Scalars['String']>;
  /** Total count of employees by category */
  total?: Maybe<Total>;
  users: Array<User>;
  /** Company workplaces */
  workplaces?: Maybe<Array<Workplace>>;
};


export type CompanyComplexPayrollOnboardingArgs = {
  take?: InputMaybe<Scalars['Float']>;
};


export type CompanyDefaultPayScheduleDaysArgs = {
  input?: InputMaybe<DefaultPayScheduleDaysInput>;
};


export type CompanySeatsSummaryArgs = {
  data: GetCompanyInput;
};


export type CompanyTaxDocumentsArgs = {
  data?: InputMaybe<CompanyTaxDocumentsInput>;
};


export type CompanyTimesheetsArgs = {
  filter?: InputMaybe<TimesheetFilter>;
};

export enum CompanyAdvisorRole {
  AccountCoordinator = 'ACCOUNT_COORDINATOR',
  AccountExecutive = 'ACCOUNT_EXECUTIVE',
  BusinessManager = 'BUSINESS_MANAGER',
  HandbookSpecialist = 'HANDBOOK_SPECIALIST',
  HrCoordinator = 'HR_COORDINATOR',
  HrManager = 'HR_MANAGER',
  InsuranceSalesperson = 'INSURANCE_SALESPERSON',
  OnboardingCoordinator = 'ONBOARDING_COORDINATOR',
  OnboardingSpecialist = 'ONBOARDING_SPECIALIST',
  OpsManager = 'OPS_MANAGER',
  PayrollManager = 'PAYROLL_MANAGER'
}

export type CompanyBenefit = {
  __typename?: 'CompanyBenefit';
  /** The SimplyInsured type the benefit. */
  benefitType: BenefitType;
  /** The SimplyInsured type of cost. */
  costType?: Maybe<CostType>;
  /** If provided, the timestamp the companyBenefit was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The timestamp of the company benefit end date */
  effectiveEnd?: Maybe<Scalars['DateTime']>;
  /** The timestamp of the company benefit start date */
  effectiveStart?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The SimplyInsured name of the benefit. */
  name: Scalars['String'];
  /** Optimistic locking to prevent stale data clobbering fresher data */
  version: Scalars['Int'];
};

export enum CompanyBenefitOnboardStatus {
  BorCompleted = 'BOR_COMPLETED',
  BorSubmitted = 'BOR_SUBMITTED',
  Completed = 'COMPLETED',
  CoverageStartDateAtChanged = 'COVERAGE_START_DATE_AT_CHANGED',
  DocumentsUploaded = 'DOCUMENTS_UPLOADED',
  Enrolling = 'ENROLLING',
  FinishedEmployeeEnrollment = 'FINISHED_EMPLOYEE_ENROLLMENT',
  IframeLoaded = 'IFRAME_LOADED',
  PendingEmployeeEnrollment = 'PENDING_EMPLOYEE_ENROLLMENT',
  Submitted = 'SUBMITTED'
}

export type CompanyBoxInfo = {
  __typename?: 'CompanyBoxInfo';
  /** Folder ID to use when uploading to Box. */
  boxFolderId?: Maybe<Scalars['String']>;
  /** Token to use when uploading to Box. */
  token?: Maybe<Scalars['String']>;
};

export type CompanyChannel = {
  __typename?: 'CompanyChannel';
  channelUrl: Scalars['ID'];
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  lastMessage?: Maybe<ChatMessageInterface>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  messages: Array<ChatMessageInterface>;
  participants: Array<ChannelPartipants>;
  type: ChannelType;
  unreadMentionCount?: Maybe<Scalars['Int']>;
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

export type CompanyCourseTopic = {
  __typename?: 'CompanyCourseTopic';
  enrollments: Array<CompanyTopicEnrollments>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CompanyDelinquency = {
  __typename?: 'CompanyDelinquency';
  defaultCardVerificationStatus: CardVerificationStatus;
  delinquent: Scalars['Boolean'];
  delinquentSince?: Maybe<Scalars['DateTime']>;
  lockout: Scalars['Boolean'];
  originalPaymentDate?: Maybe<Scalars['DateTime']>;
};

export type CompanyFinchAccessToken = {
  __typename?: 'CompanyFinchAccessToken';
  createdAt: Scalars['DateTime'];
  finchProviderId: Scalars['ID'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type CompanyPaydays = {
  __typename?: 'CompanyPaydays';
  approvalDeadline: Scalars['DateTime'];
  impactedByHoliday: Scalars['Boolean'];
  payday: Scalars['DateTime'];
};

export type CompanyRole = {
  __typename?: 'CompanyRole';
  /** Bambee Core Company Id */
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  /** Date of role deletion */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Bambee Core Company Role id */
  id: Scalars['ID'];
  /** Bambee Core Company Role title */
  title: Scalars['String'];
  workerRoles?: Maybe<Array<WorkerRole>>;
};

export type CompanySetting = {
  __typename?: 'CompanySetting';
  companyId: Scalars['String'];
  id: Scalars['Int'];
  key: CompanySettingKey;
  type: SettingType;
  value: Scalars['String'];
};

export enum CompanySettingKey {
  HrPracticeAuditActivated = 'HR_PRACTICE_AUDIT_ACTIVATED',
  HrPracticeAuditCadence = 'HR_PRACTICE_AUDIT_CADENCE',
  HrPracticeDocumentActivated = 'HR_PRACTICE_DOCUMENT_ACTIVATED',
  HrPracticeDocumentFilesUploaded = 'HR_PRACTICE_DOCUMENT_FILES_UPLOADED',
  HrPracticePayrollActivated = 'HR_PRACTICE_PAYROLL_ACTIVATED',
  HrPracticePayrollPurchase = 'HR_PRACTICE_PAYROLL_PURCHASE',
  HrPracticePolicyActivated = 'HR_PRACTICE_POLICY_ACTIVATED',
  HrPracticePolicyCadence = 'HR_PRACTICE_POLICY_CADENCE',
  HrPracticeReportCardActivated = 'HR_PRACTICE_REPORT_CARD_ACTIVATED',
  HrPracticeReportCardCadence = 'HR_PRACTICE_REPORT_CARD_CADENCE',
  HrPracticeTrainingActivated = 'HR_PRACTICE_TRAINING_ACTIVATED',
  HrPracticeTrainingPurchase = 'HR_PRACTICE_TRAINING_PURCHASE',
  HrPracticeVoicesActivated = 'HR_PRACTICE_VOICES_ACTIVATED',
  HrPracticeVoicesCadence = 'HR_PRACTICE_VOICES_CADENCE',
  HrPracticeVoicesManuallyDisabled = 'HR_PRACTICE_VOICES_MANUALLY_DISABLED'
}

export enum CompanyStatus {
  BackedOut = 'BACKED_OUT',
  Cancelled = 'CANCELLED',
  Disqualified = 'DISQUALIFIED',
  Initialized = 'INITIALIZED',
  Live = 'LIVE',
  Provisioned = 'PROVISIONED',
  SetupComplete = 'SETUP_COMPLETE'
}

export type CompanySyncMessage = {
  __typename?: 'CompanySyncMessage';
  body: CompanySyncMessageBody;
  id: Scalars['ID'];
};

export type CompanySyncMessageBody = {
  __typename?: 'CompanySyncMessageBody';
  companyId: Scalars['String'];
  correctData: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type CompanyTaxDocumentsInput = {
  /** Return tax documents for this class */
  documentClass?: InputMaybe<TaxDocumentClass>;
  /** Return tax documents for this jurisdiction */
  jurisdiction?: InputMaybe<Jurisdiction>;
  /** Return tax documents for this quarter */
  quarter?: InputMaybe<Quarter>;
  /** Return tax documents for this year (must be greater than or equal to 2019) */
  year?: InputMaybe<Scalars['Int']>;
};

export type CompanyTopicEnrollments = {
  __typename?: 'CompanyTopicEnrollments';
  course: CourseEnrollmentDetails;
  user: CoreUser;
};

/** The type of the company */
export enum CompanyType {
  CCorp = 'CCorp',
  Llc = 'LLC',
  NonProfit = 'NonProfit',
  Other = 'Other',
  Partnership = 'Partnership',
  SCorp = 'SCorp',
  SoleProprietorship = 'SoleProprietorship'
}

export enum CompanyV1EmploymentType {
  /** default behavior is all */
  All = 'All',
  /** A 1099 contractor */
  Contractor = 'Contractor',
  /** A W2 employee */
  W2 = 'W2'
}

export type CompleteCompanySurveyInput = {
  companyId: Scalars['String'];
  surveyId: Scalars['Float'];
};

export type CompleteGoalInput = {
  completionStatus: GoalCompletionStatus;
  id: Scalars['String'];
};

export type CompletePracticeInput = {
  companyId: Scalars['String'];
  practiceId: Scalars['String'];
};

/** Complete the Report Card for all employees, pending or not */
export type CompleteReportCardInput = {
  /** Report Card Id */
  reportCardId: Scalars['String'];
};

export type CompleteTaskInput = {
  completionStatus: TaskCompletionStatus;
  id: Scalars['ID'];
};

export enum ComplexActivity {
  AdultEntertainment = 'ADULT_ENTERTAINMENT',
  ArmsDealing = 'ARMS_DEALING',
  CannabisSales = 'CANNABIS_SALES',
  DrugParaphernalia = 'DRUG_PARAPHERNALIA',
  Gambling = 'GAMBLING',
  NoneApply = 'NONE_APPLY',
  SellingHazardousSubstances = 'SELLING_HAZARDOUS_SUBSTANCES',
  UnauthorizedMediaDistribution = 'UNAUTHORIZED_MEDIA_DISTRIBUTION',
  VirtualCurrency = 'VIRTUAL_CURRENCY'
}

export enum ComplexCompanyType {
  DomesticPayroll = 'DOMESTIC_PAYROLL',
  EmployerOfRecord = 'EMPLOYER_OF_RECORD',
  FederalContractor = 'FEDERAL_CONTRACTOR',
  GovernmentEntity = 'GOVERNMENT_ENTITY',
  NevadaFinancialInstitution = 'NEVADA_FINANCIAL_INSTITUTION',
  NoneApply = 'NONE_APPLY',
  NonProfit = 'NON_PROFIT',
  ProfessionalEmployerOrganization = 'PROFESSIONAL_EMPLOYER_ORGANIZATION'
}

export enum ComplexEmployeeType {
  AgriculturalWorkers = 'AGRICULTURAL_WORKERS',
  NoneApply = 'NONE_APPLY',
  NonResidentAliens = 'NON_RESIDENT_ALIENS',
  PieceRateWages = 'PIECE_RATE_WAGES',
  SimpleIra = 'SIMPLE_IRA',
  StateSpecificExemptions = 'STATE_SPECIFIC_EXEMPTIONS',
  TaxWithholdingForContractors = 'TAX_WITHHOLDING_FOR_CONTRACTORS'
}

export enum ComplexFinancialPractice {
  CourtesyWithholding = 'COURTESY_WITHHOLDING',
  NoneApply = 'NONE_APPLY',
  OwnersDraw = 'OWNERS_DRAW'
}

export type ComplexPayrollOnboarding = {
  __typename?: 'ComplexPayrollOnboarding';
  activity: Array<ComplexActivity>;
  companyType: Array<ComplexCompanyType>;
  employeeType: Array<ComplexEmployeeType>;
  financialPractice: Array<ComplexFinancialPractice>;
  id: Scalars['ID'];
  specializedEarningCodes?: Maybe<Scalars['Boolean']>;
  workerCompensationRateReports?: Maybe<Scalars['Boolean']>;
};

export type ComplexPayrollOnboardingInput = {
  activity: Array<ComplexActivity>;
  companyId: Scalars['ID'];
  companyType: Array<ComplexCompanyType>;
  employeeType: Array<ComplexEmployeeType>;
  financialPractice: Array<ComplexFinancialPractice>;
  specializedEarningCodes?: InputMaybe<Scalars['Boolean']>;
  workerCompensationRateReports?: InputMaybe<Scalars['Boolean']>;
};

export type ConfirmMicroDepositInput = {
  depositValues?: InputMaybe<Array<Scalars['Float']>>;
  descriptorCode?: InputMaybe<Scalars['String']>;
  paymentMethodId: Scalars['String'];
};

export type Contractor = {
  __typename?: 'Contractor';
  bankAccounts?: Maybe<Array<Maybe<BankAccount>>>;
  benefitOnboarding?: Maybe<BenefitWorkerOnboarding>;
  checkId?: Maybe<Scalars['ID']>;
  checkOnboarding?: Maybe<CheckContractorOnboarding>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deputyId?: Maybe<Scalars['ID']>;
  /** Bambee contractor id */
  id: Scalars['ID'];
  payRate: Scalars['Float'];
  payType?: Maybe<ContractorPayType>;
  paymentMethodPreference?: Maybe<PaymentMethod>;
  payments: Array<ContractorPayment>;
  profile?: Maybe<EmployeeProfile>;
  /** @deprecated checkOnboarding is a superset */
  remainingOnboardingSteps: Array<ContractorOnboardingSteps>;
  taxDocuments?: Maybe<Array<Maybe<TaxDocument>>>;
  timesheets: Array<Timesheet>;
  user?: Maybe<CoreUser>;
  workplaces: Array<Workplace>;
};


export type ContractorPaymentsArgs = {
  excludeZeroGross?: InputMaybe<Scalars['Boolean']>;
};


export type ContractorTaxDocumentsArgs = {
  data?: InputMaybe<ContractorTaxDocumentsInput>;
};


export type ContractorTimesheetsArgs = {
  filter?: InputMaybe<ContractorTimesheetFilter>;
};

export enum ContractorOnboardingSteps {
  Address = 'ADDRESS',
  Ein = 'EIN',
  PaymentMethod = 'PAYMENT_METHOD',
  Ssn = 'SSN',
  Type = 'TYPE'
}

export enum ContractorPayType {
  Contract = 'CONTRACT',
  Hourly = 'HOURLY',
  Salary = 'SALARY'
}

export type ContractorPayment = {
  __typename?: 'ContractorPayment';
  addedAfterInitialization: Scalars['Boolean'];
  basePayRate: Scalars['Float'];
  /** For accounting. The check number associated with any printed checks. Can be updated even after the associated payroll has been approved. */
  checkNumber?: Maybe<Scalars['String']>;
  contractor: Contractor;
  entries: Array<PaymentEntry>;
  grossPay: Scalars['Float'];
  /** Determines if any worker role has been deleted in this payroll item */
  hasDeletedWorkerRole: Scalars['Boolean'];
  id: Scalars['ID'];
  netPay?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  noteVisible?: Maybe<Scalars['Boolean']>;
  payType: ContractorPayType;
  /** The way the contractor will be paid */
  paymentMethod?: Maybe<PaymentMethod>;
  payroll: Payroll;
  /** Determines if payment entry hours were synced from a timesheet */
  syncedFromDeputy: Scalars['Boolean'];
  timesheetHours?: Maybe<TimesheetAggregate>;
  timesheetHoursByRole?: Maybe<Array<Maybe<TimesheetAggregate>>>;
  /** Optimistic locking to prevent stale data clobbering fresher data */
  version: Scalars['Int'];
};

export type ContractorPaymentAdvancedWhereInput = {
  /** All conditions passed into this must return true for payrolls returned. */
  AND?: InputMaybe<Array<ContractorPaymentBaseWhereInput>>;
  /** All conditions passed into this must return false for payrolls returned. */
  NOT?: InputMaybe<Array<ContractorPaymentBaseWhereInput>>;
  /** One or more conditions passed into this must return true for payrolls returned. */
  OR?: InputMaybe<Array<ContractorPaymentBaseWhereInput>>;
};

export type ContractorPaymentArgs = {
  advancedWhere?: InputMaybe<ContractorPaymentAdvancedWhereInput>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ContractorPaymentOrderByInput>;
};

export type ContractorPaymentBaseWhereInput = {
  addedAfterInitialization?: InputMaybe<BoolNullableFilter>;
  basePayRate?: InputMaybe<NumberNullableFilter>;
  /** For accounting. The check number associated with any printed checks. Can be updated even after the associated payroll has been approved. */
  checkNumber?: InputMaybe<StringNullableFilter>;
  contractorId?: InputMaybe<StringNullableFilter>;
  grossPay?: InputMaybe<NumberNullableFilter>;
  /** Determines if any worker role has been deleted in this payroll item */
  hasDeletedWorkerRole?: InputMaybe<BoolNullableFilter>;
  note?: InputMaybe<StringNullableFilter>;
  noteVisible?: InputMaybe<BoolNullableFilter>;
  payType?: InputMaybe<EnumContractorPayTypeFilter>;
  /** The way the contractor will be paid */
  paymentMethod?: InputMaybe<EnumPaymentMethodFilter>;
  /** Determines if payment entry hours were synced from a timesheet */
  syncedFromDeputy?: InputMaybe<BoolNullableFilter>;
};

export type ContractorPaymentCheckNumbers = {
  checkNumber: Scalars['String'];
  /** The PayrollItem ID the check number is for. */
  id: Scalars['ID'];
};

export type ContractorPaymentConnection = {
  __typename?: 'ContractorPaymentConnection';
  edges: Array<ContractorPaymentEdge>;
  pageInfo: PageInfo;
};

export type ContractorPaymentEdge = {
  __typename?: 'ContractorPaymentEdge';
  cursor: Scalars['String'];
  node: ContractorPayment;
};

export type ContractorPaymentOrderByInput = {
  direction?: InputMaybe<OrderByDirection>;
  key: Scalars['String'];
};

export type ContractorPaymentPage = {
  __typename?: 'ContractorPaymentPage';
  /** Reflects the limit argument provided in the query. */
  limit: Scalars['Int'];
  /** Tells if there is a next page of results available. */
  nextPage: Scalars['Boolean'];
  /** Reflects the page argument provided in the query. */
  page: Scalars['Int'];
  /** Tells if there is a previous page of results available. */
  prevPage: Scalars['Boolean'];
  results: Array<ContractorPayment>;
  /** The total number of payrolls in the database or for the company when companyId is provided. */
  totalCount: Scalars['Int'];
};

export type ContractorPaymentSync = {
  __typename?: 'ContractorPaymentSync';
  bambee?: Maybe<Scalars['String']>;
  check?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ContractorSync = {
  __typename?: 'ContractorSync';
  bambee?: Maybe<Scalars['String']>;
  check?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ContractorTaxDocumentsInput = {
  /** Return tax documents for this year (must be greater than or equal to 2019) */
  year?: InputMaybe<Scalars['Int']>;
};

export type ContractorTimesheetFilter = {
  /** Find approved if true, find unapproved if false, do not filter on approval status if not provided. */
  approved?: InputMaybe<Scalars['Boolean']>;
  /** Find timesheets for a particular contractor (Deputy Employee Id) */
  contractorId?: InputMaybe<Scalars['String']>;
  /** Find timesheets for a particular payroll (this will overwrite start/end fields in filter (Bambee Payroll Id) */
  payrollId?: InputMaybe<Scalars['Int']>;
};

export type Conversation = {
  __typename?: 'Conversation';
  id: Scalars['ID'];
  intercomConversationId: Scalars['String'];
  messages: Array<ConversationMessage>;
  subject: Scalars['String'];
};

export type ConversationHistoryInput = {
  afterId?: InputMaybe<Scalars['ID']>;
  count?: InputMaybe<Scalars['Int']>;
  userId: Scalars['ID'];
};

export type ConversationMessage = {
  __typename?: 'ConversationMessage';
  body: Scalars['String'];
  inBound: Scalars['Boolean'];
};

export type CoreAddress = {
  __typename?: 'CoreAddress';
  city?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type CoreAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type CoreCompany = {
  __typename?: 'CoreCompany';
  address: CoreAddress;
  companyRoles?: Maybe<Array<CompanyRole>>;
  countPayroll: Scalars['Float'];
  dba?: Maybe<Scalars['String']>;
  documents: Array<CoreDocument>;
  employees: Array<CoreUser>;
  fein?: Maybe<Scalars['String']>;
  getPayroll?: Maybe<Payroll>;
  getPayrollWithCompanyRoles?: Maybe<Payroll>;
  goals: Array<CoreGoal>;
  groups: Array<CoreUserGroup>;
  id: Scalars['ID'];
  industry?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<CoreLegalAddress>;
  listPayroll?: Maybe<PayrollPage>;
  mailingAddress?: Maybe<CoreMailingAddress>;
  name: Scalars['String'];
  numOfContractors?: Maybe<Scalars['Int']>;
  numOfFullTimeHourlyEmployees?: Maybe<Scalars['Int']>;
  numOfFullTimeSalaryEmployees?: Maybe<Scalars['Int']>;
  numOfPartTimeEmployees?: Maybe<Scalars['Int']>;
  onboarding?: Maybe<CoreOnboarding>;
  owner: CoreUser;
  paginatedEmployees: PaginatedCoreUsers;
  payDayOfMonth?: Maybe<Scalars['Int']>;
  payDayOfWeek?: Maybe<Scalars['Int']>;
  payrollCompany?: Maybe<PayrollCompany>;
  phone?: Maybe<Scalars['String']>;
  policies: Array<CorePolicy>;
  tasks: Array<CoreTask>;
  users: Array<CoreUser>;
  website?: Maybe<Scalars['String']>;
  whatBroughtYouToBambee?: Maybe<LeadReason>;
};


export type CoreCompanyCountPayrollArgs = {
  advancedWhere?: InputMaybe<PayrollAdvancedWhereInput>;
  companyId?: InputMaybe<Scalars['ID']>;
  companyStatus?: InputMaybe<CompanyStatus>;
  deletedAt?: InputMaybe<ListPayrollDeletedOptions>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PayrollOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PayrollWhereInput>;
};


export type CoreCompanyEmployeesArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  employmentType?: InputMaybe<CompanyV1EmploymentType>;
};


export type CoreCompanyGetPayrollArgs = {
  id: Scalars['ID'];
};


export type CoreCompanyGetPayrollWithCompanyRolesArgs = {
  id: Scalars['ID'];
};


export type CoreCompanyListPayrollArgs = {
  advancedWhere?: InputMaybe<PayrollAdvancedWhereInput>;
  companyId?: InputMaybe<Scalars['ID']>;
  companyStatus?: InputMaybe<CompanyStatus>;
  deletedAt?: InputMaybe<ListPayrollDeletedOptions>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PayrollOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PayrollWhereInput>;
};


export type CoreCompanyOnboardingArgs = {
  args: CoreOnboardingVersion;
};


export type CoreCompanyPaginatedEmployeesArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  employmentType?: InputMaybe<CompanyV1EmploymentType>;
  limit?: Scalars['Float'];
  page?: Scalars['Float'];
};


export type CoreCompanyPoliciesArgs = {
  isCPD?: InputMaybe<Scalars['Boolean']>;
  limit?: Scalars['Int'];
  page?: Scalars['Int'];
  status?: Array<Scalars['String']>;
};


export type CoreCompanyUsersArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  employmentType?: InputMaybe<CompanyV1EmploymentType>;
};

export type CoreCompanyTraining = {
  __typename?: 'CoreCompanyTraining';
  enrolledTopics?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
};

export enum CoreContractorType {
  Business = 'Business',
  Individual = 'Individual'
}

export type CoreDocument = {
  __typename?: 'CoreDocument';
  id: Scalars['ID'];
  name: Scalars['String'];
  policy?: Maybe<CorePolicy>;
  reaffirmations?: Maybe<Array<Scalars['DateTime']>>;
  signature?: Maybe<CoreDocumentSignature>;
  signer?: Maybe<CoreUser>;
};

export type CoreDocumentSignature = {
  __typename?: 'CoreDocumentSignature';
  id: Scalars['ID'];
  requestedAt: Scalars['DateTime'];
  signedAt: Scalars['DateTime'];
  signer?: Maybe<CoreUser>;
  /** @deprecated In favor of signer CoreUser */
  signerId?: Maybe<Scalars['String']>;
  signingOrder?: Maybe<Scalars['Int']>;
};

export enum CoreEmployeeClassification {
  Exempt = 'Exempt',
  NonExempt = 'NonExempt'
}

export enum CoreEmployeePaytype {
  Contractor = 'Contractor',
  Hourly = 'Hourly',
  Salary = 'Salary'
}

export enum CoreEmployeeType {
  Contractor = 'Contractor',
  FullTime = 'FullTime',
  PartTime = 'PartTime'
}

export type CoreGoal = {
  __typename?: 'CoreGoal';
  assignedTo?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completionStatus?: Maybe<GoalCompletionStatus>;
  copy: CoreGoalCopy;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** @deprecated Use copy.description instead */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  name: GoalName;
  taskNames: Array<TaskName>;
  tasks: Array<CoreTask>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CoreGoalCopy = {
  __typename?: 'CoreGoalCopy';
  cta: Scalars['String'];
  description: Scalars['String'];
  estimated: Scalars['String'];
};

export type CoreLegalAddress = {
  __typename?: 'CoreLegalAddress';
  city?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type CoreLegalAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type CoreMailingAddress = {
  __typename?: 'CoreMailingAddress';
  city?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type CoreMailingAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type CoreOnboarding = {
  __typename?: 'CoreOnboarding';
  /** Company ID of the user who created the Onboarding */
  companyId?: Maybe<Scalars['String']>;
  /** Creation timestamp */
  createdAt: Scalars['DateTime'];
  /** Data for the Onboarding */
  data: CoreOnboardingData;
  /** Unique identifier for the Onboarding */
  id: Scalars['ID'];
  /** Status of the Onboarding */
  status: OnboardingStatus;
  /** Last update timestamp */
  updatedAt: Scalars['DateTime'];
  /** The user who owns the onboarding */
  user: CoreUser;
  /** ID of the user who created the Onboarding */
  userId: Scalars['String'];
  /** The onboarding version */
  version: Scalars['Float'];
};

export type CoreOnboardingData = {
  __typename?: 'CoreOnboardingData';
  /** The company address */
  address?: Maybe<CoreAddress>;
  /** The ID of the call event */
  callEventId?: Maybe<Scalars['String']>;
  /** The legal name of the company */
  companyLegalName?: Maybe<Scalars['String']>;
  /** The name of the company */
  companyName?: Maybe<Scalars['String']>;
  /** The type of company */
  companyType?: Maybe<CompanyType>;
  /** The name of the company */
  discussionTopicDetails?: Maybe<Array<Maybe<DisucssionTopicDetail>>>;
  /** The name of the company */
  discussionTopics?: Maybe<Array<Maybe<DiscussionTopic>>>;
  /** The company FEIN */
  fein?: Maybe<Scalars['String']>;
  /** The company has remote employees */
  hasRemoteEmployees?: Maybe<Scalars['Boolean']>;
  /** The company has a scheduled call */
  hasScheduledCall?: Maybe<Scalars['Boolean']>;
  /** ID used for apollo caching; e.g. userId from parent object */
  id: Scalars['ID'];
  /** The company is incorporated in another state */
  incorporatedInOtherState?: Maybe<Scalars['Boolean']>;
  /** The state the company is incorporated in */
  incorporatedState?: Maybe<StateAbbreviation>;
  /** The user is the owner of the company */
  isOwner?: Maybe<Scalars['Boolean']>;
  /** The company needs help converting contractors */
  needsHelpConvertingContractors?: Maybe<Scalars['Boolean']>;
  /** The next action for the company */
  nextAction?: Maybe<NextOnboardingAction>;
  /** The company does not have an FEIN or does not know it */
  noFeinOrDoesNotKnow?: Maybe<Scalars['Boolean']>;
  /** The number of contractors in the company */
  numOfContractors?: Maybe<Scalars['Float']>;
  /** The number of employees in the company */
  numOfEmployees?: Maybe<Scalars['Float']>;
  /** The user is the primary contact for the company */
  primaryContact?: Maybe<Scalars['Boolean']>;
  /** The progress the company has made through onboarding */
  progress?: Maybe<Scalars['Float']>;
  /** The states the company has remote workers in */
  remoteWorkerStates?: Maybe<Array<Maybe<StateAbbreviation>>>;
  /** The company will have additional contacts */
  willHaveAdditionalContact?: Maybe<Scalars['Boolean']>;
};

export type CoreOnboardingDataInput = {
  /** The company address */
  address?: InputMaybe<CoreAddressInput>;
  /** The ID of the call event */
  callEventId?: InputMaybe<Scalars['String']>;
  /** The legal name of the company */
  companyLegalName?: InputMaybe<Scalars['String']>;
  /** The name of the company */
  companyName?: InputMaybe<Scalars['String']>;
  /** The type of company */
  companyType?: InputMaybe<CompanyType>;
  /** The name of the company */
  discussionTopicDetails?: InputMaybe<Array<InputMaybe<DisucssionTopicDetailInput>>>;
  /** The name of the company */
  discussionTopics?: InputMaybe<Array<InputMaybe<DiscussionTopic>>>;
  /** The company FEIN */
  fein?: InputMaybe<Scalars['String']>;
  /** The company has remote employees */
  hasRemoteEmployees?: InputMaybe<Scalars['Boolean']>;
  /** The company has a scheduled call */
  hasScheduledCall?: InputMaybe<Scalars['Boolean']>;
  /** ID used for apollo caching; e.g. userId from parent object */
  id?: InputMaybe<Scalars['ID']>;
  /** The company is incorporated in another state */
  incorporatedInOtherState?: InputMaybe<Scalars['Boolean']>;
  /** The state the company is incorporated in */
  incorporatedState?: InputMaybe<StateAbbreviation>;
  /** The user is the owner of the company */
  isOwner?: InputMaybe<Scalars['Boolean']>;
  /** The company needs help converting contractors */
  needsHelpConvertingContractors?: InputMaybe<Scalars['Boolean']>;
  /** The next action for the company */
  nextAction?: InputMaybe<NextOnboardingAction>;
  /** The company does not have an FEIN or does not know it */
  noFeinOrDoesNotKnow?: InputMaybe<Scalars['Boolean']>;
  /** The number of contractors in the company */
  numOfContractors?: InputMaybe<Scalars['Float']>;
  /** The number of employees in the company */
  numOfEmployees?: InputMaybe<Scalars['Float']>;
  /** The user is the primary contact for the company */
  primaryContact?: InputMaybe<Scalars['Boolean']>;
  /** The progress the company has made through onboarding */
  progress?: InputMaybe<Scalars['Float']>;
  /** The states the company has remote workers in */
  remoteWorkerStates?: InputMaybe<Array<InputMaybe<StateAbbreviation>>>;
  /** The company will have additional contacts */
  willHaveAdditionalContact?: InputMaybe<Scalars['Boolean']>;
};

export type CoreOnboardingInput = {
  /** Company ID of the user who created the Onboarding */
  companyId?: InputMaybe<Scalars['String']>;
  /** Data for the Onboarding */
  data: CoreOnboardingDataInput;
  /** Unique identifier for the Onboarding */
  id?: InputMaybe<Scalars['ID']>;
  /** Status of the Onboarding */
  status: OnboardingStatus;
  /** ID of the user who created the Onboarding */
  userId: Scalars['String'];
  /** The onboarding version */
  version: Scalars['Float'];
};

export type CoreOnboardingKeys = {
  /** ID of the user who created the Onboarding */
  userId: Scalars['String'];
  /** The onboarding version */
  version: Scalars['Float'];
};

export type CoreOnboardingVersion = {
  /** The onboarding version */
  version: Scalars['Float'];
};

export type CorePlanChangeInvoice = {
  __typename?: 'CorePlanChangeInvoice';
  /** Tax amount charged for the invoice */
  tax: Scalars['Float'];
  /** Total amount charged for the invoice */
  total: Scalars['Float'];
};

/** A model representing the core plan details. */
export type CorePlanModel = {
  __typename?: 'CorePlanModel';
  /** Indicates if the plan is annual. */
  annual?: Maybe<Scalars['Boolean']>;
  /** Billing period for the plan. */
  billingPeriod: BillingPeriod;
  /** Indicates if the plan is chat-only. */
  chatOnly?: Maybe<Scalars['Boolean']>;
  /** Indicates if the plan is configured to collect taxes. */
  collectTax: Scalars['Boolean'];
  /** Contract period for the plan, if applicable. */
  contractPeriod?: Maybe<BillingPeriod>;
  /** DRP (Disaster Recovery Plan) identifier, if applicable. */
  drp?: Maybe<Scalars['String']>;
  /** Mapping of the plan to the size of the employee base. */
  employeeSizeMapping?: Maybe<Scalars['String']>;
  /** Indicates if the plan is for internal use only. */
  internal: Scalars['Boolean'];
  /** Maximum size of the employee base for this plan. */
  maxSize?: Maybe<Scalars['Float']>;
  /** Minimum size of the employee base for this plan. */
  minSize?: Maybe<Scalars['Float']>;
  /** Monthly price of the plan. */
  monthlyPrice: Scalars['Float'];
  /** Name of the plan. */
  name: Scalars['String'];
  /** Partner associated with the plan, if any. */
  partner?: Maybe<Scalars['String']>;
  /** Indicates if the plan can be paused. */
  pause?: Maybe<Scalars['Boolean']>;
  /** Total price of the plan. */
  price: Scalars['Float'];
  /** Price identifier in Stripe. */
  priceId: Scalars['String'];
  /** The product key associated to the plan object (Lite, Dedicated, Plus, Elite). */
  productKey: ProductKey;
  /** Indicates if the plan is regulated. */
  regulated: Scalars['Boolean'];
  /** The service key associated to the plan object (always Core here). */
  serviceKey: ServiceKey;
  /** Initial setup fee for the plan. */
  setupFee?: Maybe<Scalars['Float']>;
  /** Discount on the setup fee, if applicable. */
  setupFeeDiscount?: Maybe<Scalars['Float']>;
  /** Current status of the plan. */
  status: PlanStatuses;
  /** Tier number of the plan. */
  tierNumber?: Maybe<Scalars['Float']>;
  /** Value proposition of the plan. */
  value: Scalars['String'];
  /** Version of the plan. */
  version: Scalars['String'];
  /** Indicates if the plan pricing is based on volume. */
  volumeBased?: Maybe<Scalars['Boolean']>;
};

export type CorePolicy = {
  __typename?: 'CorePolicy';
  /** Timestamp set when a policy is archived */
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  icon: Scalars['String'];
  id: Scalars['ID'];
  isCPD: Scalars['Boolean'];
  isContractor: Scalars['Boolean'];
  name: Scalars['String'];
  notSentTo: Array<CoreUser>;
  recipients: Array<CorePolicyRecipient>;
  status: Scalars['String'];
  step?: Maybe<PolicyStepNames>;
};

export type CorePolicyRecipient = {
  __typename?: 'CorePolicyRecipient';
  document: CoreDocument;
  employee: CoreUser;
  id: Scalars['ID'];
};

export type CoreRecommendation = {
  __typename?: 'CoreRecommendation';
  /** Permitted types of reactions */
  allowedReactions: Array<ReactionType>;
  /** ID of the company for which the recommendation is made */
  companyId?: Maybe<Scalars['String']>;
  /** Creation timestamp */
  createdAt: Scalars['DateTime'];
  /** Demographic target of the recommendation */
  demographic?: Maybe<RecommendationDemographic>;
  /** Expiration timestamp */
  expiresAt?: Maybe<Scalars['DateTime']>;
  /** Unique identifier for the recommendation */
  id: Scalars['ID'];
  /** Note about the recommendation */
  note?: Maybe<Scalars['String']>;
  /** Roles allowed to react */
  reactorRoles: Array<Scalars['String']>;
  /** Timestamps of recommendation */
  recommendedAt: Array<Scalars['String']>;
  /** ID of the recommended resource */
  resourceId: Scalars['String'];
  /** Type of recommended resource */
  resourceType: Scalars['String'];
  /** The scope of the recommendation */
  scope: RecommendationScope;
  /** Status of the recommendation */
  status: RecommendationStatus;
  /** List of targets for the recommendation */
  targets: Array<Scalars['String']>;
  /** Last update timestamp */
  updatedAt: Scalars['DateTime'];
  user: CoreUser;
  /** ID of the user who created the recommendation */
  userId: Scalars['String'];
};

export type CoreRequest = {
  __typename?: 'CoreRequest';
  /** The number of approvals required to approve the request. */
  approvalCriteria: Scalars['Int'];
  /** The roles of the decision makers. */
  approverRoles?: Maybe<Array<Maybe<AuthRole>>>;
  /** The company id of the employee requesting training. */
  companyId: Scalars['String'];
  /** The date the request was created. */
  createdAt: Scalars['DateTime'];
  decisions?: Maybe<Array<Maybe<CoreRequestDecision>>>;
  /** The description of the request. */
  description?: Maybe<Scalars['String']>;
  /** The date the request was expired. Null if the request does not expire */
  expiresAt?: Maybe<Scalars['DateTime']>;
  /** The id of the request. */
  id: Scalars['ID'];
  /** The number of rejections required to reject the request. */
  rejectionCriteria: Scalars['Int'];
  /** The dates the request was requested at. */
  requestedAt: Array<Scalars['DateTime']>;
  /** The id of the resource. */
  resourceId: Scalars['String'];
  /** The resource type of the request. */
  resourceType: RequestResourceType;
  /** The status of the request. */
  status: RequestStatus;
  /** The date the request was last updated. */
  updatedAt: Scalars['DateTime'];
  user: CoreUser;
  /** The user id of the employee requesting training. */
  userId: Scalars['String'];
};

export type CoreRequestDecision = {
  __typename?: 'CoreRequestDecision';
  /** The date the decision was created. */
  createdAt: Scalars['DateTime'];
  /** The id of the decision. */
  id: Scalars['ID'];
  /** The id of the request. */
  requestId: Scalars['String'];
  /** The result of the decision. */
  result: ApprovalResult;
  /** The role of the decision maker. */
  role: AuthRole;
  /** The date the decision was last updated. */
  updatedAt: Scalars['DateTime'];
  /** The user id of the decision maker. */
  userId: Scalars['String'];
};

export type CoreSubscriptionModel = {
  __typename?: 'CoreSubscriptionModel';
  /** Subscription Status */
  active: Scalars['Boolean'];
  /** True when there is a pending cancellation request */
  cancellationRequested: Scalars['Boolean'];
  /** A scheduled date for when the subscription will be canceled */
  cancelsAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the subscription. */
  id: Scalars['ID'];
  /** Date when the current period will end */
  periodEndsAt: Scalars['DateTime'];
  plan: CorePlanModel;
  /** The quantity of the plan to which the customer is subscribed to. */
  quantity?: Maybe<Scalars['Float']>;
  /** Scheduled plan change, if any. */
  scheduledPlanChange?: Maybe<ScheduledCorePlanChangeModel>;
  /** Date when the subscription trial will end */
  trialEndsAt?: Maybe<Scalars['DateTime']>;
  /** True if they are actively trialing */
  trialing: Scalars['Boolean'];
};

export type CoreTask = {
  __typename?: 'CoreTask';
  assignedTo?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completionStatus?: Maybe<TaskCompletionStatus>;
  copy: CoreTaskCopy;
  /** @deprecated Tasks will not have descriptions. */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  name: TaskName;
  startedAt?: Maybe<Scalars['DateTime']>;
  startedBy?: Maybe<Scalars['String']>;
};

export type CoreTaskCopy = {
  __typename?: 'CoreTaskCopy';
  confirmCta?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  denyCta?: Maybe<Scalars['String']>;
  details?: Maybe<Array<CoreTaskCopyDetails>>;
  estimated?: Maybe<Scalars['String']>;
};

export type CoreTaskCopyDetails = {
  __typename?: 'CoreTaskCopyDetails';
  items: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type CoreUser = {
  __typename?: 'CoreUser';
  active: Scalars['Boolean'];
  chat: CoreUserChatConfig;
  company?: Maybe<CoreCompany>;
  contractor?: Maybe<Contractor>;
  createdAt: Scalars['DateTime'];
  documents?: Maybe<Array<CoreDocument>>;
  employee?: Maybe<Employee>;
  employment?: Maybe<CoreUserEmployment>;
  feedback: Array<FeedbackHistory>;
  groups: Array<CoreUserGroup>;
  id: Scalars['ID'];
  permissions?: Maybe<CoreUserPermissions>;
  profile?: Maybe<CoreUserProfile>;
  role: Scalars['String'];
  roles: Array<Scalars['String']>;
  settings?: Maybe<CoreUserSettings>;
  states?: Maybe<CoreUserStates>;
  timeZone: Scalars['String'];
  training?: Maybe<CoreUserTraining>;
};


export type CoreUserFeedbackArgs = {
  count?: InputMaybe<Scalars['Int']>;
};

export type CoreUserChatConfig = {
  __typename?: 'CoreUserChatConfig';
  token?: Maybe<Scalars['String']>;
};

export type CoreUserEmployment = {
  __typename?: 'CoreUserEmployment';
  classification?: Maybe<CoreEmployeeClassification>;
  contractorBusinessName?: Maybe<Scalars['String']>;
  contractorType?: Maybe<CoreContractorType>;
  directReports: Array<CoreUser>;
  employeeType?: Maybe<CoreEmployeeType>;
  hoursPerWeek?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isContractor?: Maybe<Scalars['Boolean']>;
  manager?: Maybe<CoreUser>;
  payDay?: Maybe<Scalars['String']>;
  payFrequency?: Maybe<Scalars['String']>;
  payRate?: Maybe<Scalars['String']>;
  payType?: Maybe<CoreEmployeePaytype>;
  startDate?: Maybe<Scalars['String']>;
  stateWorksIn?: Maybe<Scalars['String']>;
  supervisor: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  workerRoles: Array<WorkerRole>;
};


export type CoreUserEmploymentWorkerRolesArgs = {
  showDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type CoreUserEmploymentInput = {
  classification?: InputMaybe<CoreEmployeeClassification>;
  contractorBusinessName?: InputMaybe<Scalars['String']>;
  contractorType?: InputMaybe<CoreContractorType>;
  employeeType?: InputMaybe<CoreEmployeeType>;
  hoursPerWeek?: InputMaybe<Scalars['String']>;
  isContractor?: InputMaybe<Scalars['Boolean']>;
  payDay?: InputMaybe<Scalars['String']>;
  payFrequency?: InputMaybe<Scalars['String']>;
  payRate?: InputMaybe<Scalars['String']>;
  payType?: InputMaybe<CoreEmployeePaytype>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  stateWorksIn?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CoreUserGroup = {
  __typename?: 'CoreUserGroup';
  id: Scalars['ID'];
  members: Array<CoreUser>;
  name: Scalars['String'];
  ownerId?: Maybe<Scalars['ID']>;
  type: CoreUserGroupType;
};

export enum CoreUserGroupType {
  Admin = 'Admin',
  Contractors = 'Contractors',
  Custom = 'Custom',
  Employees = 'Employees',
  Managers = 'Managers',
  ManagersWithReports = 'ManagersWithReports'
}

export type CoreUserPermissions = {
  __typename?: 'CoreUserPermissions';
  approver: Scalars['Boolean'];
  canCancelAccount: Scalars['Boolean'];
  canEditGlobalPolicies: Scalars['Boolean'];
  canRetractSignedPolicies: Scalars['Boolean'];
  canViewPayrollTab: Scalars['Boolean'];
  id: Scalars['ID'];
  manager: Scalars['Boolean'];
};

export type CoreUserProfile = {
  __typename?: 'CoreUserProfile';
  address?: Maybe<CoreAddress>;
  avatarUrl?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  emergencyContact?: Maybe<CoreUserProfileEmergencyContact>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type CoreUserProfileEmergencyContact = {
  __typename?: 'CoreUserProfileEmergencyContact';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
};

export type CoreUserProfileEmergencyContactInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Scalars['String']>;
};

export type CoreUserProfileInput = {
  address?: InputMaybe<CoreAddressInput>;
  dob?: InputMaybe<Scalars['String']>;
  emergencyContact?: InputMaybe<CoreUserProfileEmergencyContactInput>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type CoreUserSettings = {
  __typename?: 'CoreUserSettings';
  calendlySlug?: Maybe<Scalars['String']>;
  hrEmailAlias?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intercomEmail?: Maybe<Scalars['String']>;
  intercomUserId?: Maybe<Scalars['Int']>;
  pandaContactId?: Maybe<Scalars['String']>;
  salesforceContactId?: Maybe<Scalars['String']>;
  salesforceUserId?: Maybe<Scalars['String']>;
  slackUserId?: Maybe<Scalars['String']>;
  vonageExtension?: Maybe<Scalars['String']>;
  vonageNumber?: Maybe<Scalars['String']>;
  zendeskEmail?: Maybe<Scalars['String']>;
  zendeskOrgMembershipId?: Maybe<Scalars['Int']>;
  zendeskUserId?: Maybe<Scalars['Int']>;
};

export type CoreUserStates = {
  __typename?: 'CoreUserStates';
  emailNotification: Scalars['Boolean'];
  id: Scalars['ID'];
  interestedInPersonalInsurance: Scalars['Boolean'];
  onboardRequest: Scalars['Boolean'];
  onboarded: Scalars['Boolean'];
  resignationLastDate?: Maybe<Scalars['DateTime']>;
  resignationStatus?: Maybe<Scalars['String']>;
  selfSignupActivated: Scalars['Boolean'];
  selfSignupRegistered: Scalars['Boolean'];
  terminated: Scalars['Boolean'];
  terminationRequest: Scalars['Boolean'];
  viewedBciToHrPracticeModal: Scalars['Boolean'];
  viewedBciToHrPracticeReportCardModal: Scalars['Boolean'];
  viewedCovid: Scalars['Boolean'];
  viewedInAppUpgradeModal: Scalars['Boolean'];
  viewedInsuranceIntroduction: Scalars['Boolean'];
  viewedOnboardingInstructions?: Maybe<CoreUserViewedOnboardingInstructions>;
  viewedPersonalInsurance: Scalars['Boolean'];
  viewedTaskCenter: Scalars['Boolean'];
  viewedVideoTourBusinessHealth: Scalars['Boolean'];
  viewedVideoTourCabinet: Scalars['Boolean'];
  viewedVideoTourPolicy: Scalars['Boolean'];
  viewedVideoTourReportCards: Scalars['Boolean'];
  viewedVideoTourStaffFolder: Scalars['Boolean'];
};

export type CoreUserStatesInput = {
  emailNotification?: InputMaybe<Scalars['Boolean']>;
  interestedInPersonalInsurance?: InputMaybe<Scalars['Boolean']>;
  onboardRequest?: InputMaybe<Scalars['Boolean']>;
  onboarded?: InputMaybe<Scalars['Boolean']>;
  resignationLastDate?: InputMaybe<Scalars['DateTime']>;
  resignationStatus?: InputMaybe<Scalars['String']>;
  selfSignupActivated?: InputMaybe<Scalars['Boolean']>;
  selfSignupRegistered?: InputMaybe<Scalars['Boolean']>;
  terminated?: InputMaybe<Scalars['Boolean']>;
  terminationRequest?: InputMaybe<Scalars['Boolean']>;
  viewedBciToHrPracticeModal?: InputMaybe<Scalars['Boolean']>;
  viewedBciToHrPracticeReportCardModal?: InputMaybe<Scalars['Boolean']>;
  viewedCovid?: InputMaybe<Scalars['Boolean']>;
  viewedInAppUpgradeModal?: InputMaybe<Scalars['Boolean']>;
  viewedInsuranceIntroduction?: InputMaybe<Scalars['Boolean']>;
  viewedOnboardingInstructions?: InputMaybe<CoreUserViewedOnboardingInstructions>;
  viewedPersonalInsurance?: InputMaybe<Scalars['Boolean']>;
  viewedTaskCenter?: InputMaybe<Scalars['Boolean']>;
  viewedVideoTourBusinessHealth?: InputMaybe<Scalars['Boolean']>;
  viewedVideoTourCabinet?: InputMaybe<Scalars['Boolean']>;
  viewedVideoTourPolicy?: InputMaybe<Scalars['Boolean']>;
  viewedVideoTourReportCards?: InputMaybe<Scalars['Boolean']>;
  viewedVideoTourStaffFolder?: InputMaybe<Scalars['Boolean']>;
};

export type CoreUserTraining = {
  __typename?: 'CoreUserTraining';
  enrolledTopics: Array<UserCourseTopic>;
  id?: Maybe<Scalars['ID']>;
};

export enum CoreUserViewedOnboardingInstructions {
  FirstTime = 'FirstTime',
  LastTime = 'LastTime'
}

export enum CostType {
  Monthly = 'MONTHLY'
}

export type CourseEnrollmentDetails = {
  __typename?: 'CourseEnrollmentDetails';
  courseId: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  ticketId: Scalars['ID'];
};

export enum CourseRole {
  Employee = 'EMPLOYEE',
  Manager = 'MANAGER'
}

export type CourseTopic = {
  __typename?: 'CourseTopic';
  /** The topic description */
  description?: Maybe<Scalars['String']>;
  /** The topic duration e.g. play time */
  duration?: Maybe<Scalars['Int']>;
  /** Helpers for fields that will commonly be used for filtering topics */
  filterHelpers?: Maybe<CourseTopicFilterHelpers>;
  /** The topic id */
  id: Scalars['String'];
  /** The topic image url */
  imageUrl?: Maybe<Scalars['String']>;
  /** List of industries */
  industries?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The topic key used to sync with external systems */
  key: Scalars['String'];
  /** The topic name */
  name: Scalars['String'];
  /** The topic price */
  price?: Maybe<Scalars['Float']>;
  /** List of roles with specific course content for the topic */
  roles?: Maybe<Array<Maybe<CourseRole>>>;
  /**
   * deprecated search tag
   * @deprecated use key instead
   */
  searchTag?: Maybe<Scalars['String']>;
  /** List of states that the topic has state specific course content */
  states?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** List of search tags */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CourseTopicFilterHelpers = {
  __typename?: 'CourseTopicFilterHelpers';
  /** Whether this topic offers a manager specific course */
  hasManagerCourse?: Maybe<Scalars['Boolean']>;
  /** Whether this topic offers a course version in Spanish */
  hasSpanish?: Maybe<Scalars['Boolean']>;
  /** States for which this topic offers a specific course for */
  states?: Maybe<Array<Scalars['String']>>;
};

export type CreateBundleInput = {
  /** Core Company ID to associate with the bundle. If not provided, the bundle will be global */
  companyId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  name: Scalars['String'];
  topicIds: Array<Scalars['String']>;
};

export type CreateCancellationRequestInput = {
  /** A list of plan values that were offered in order to prevent the cancellation (downgrade, pause, storage) */
  cancellationOffers?: InputMaybe<Array<Scalars['String']>>;
  /** Additional details if "Other" reason is chosen */
  clientOtherReasonNote?: InputMaybe<Scalars['String']>;
  /** A note from the client about why they requested the cancellation */
  clientReasonNote?: InputMaybe<Scalars['String']>;
  /** The company ID */
  companyId: Scalars['String'];
  /** A note from a Bambee user about */
  internalReasonNote?: InputMaybe<Scalars['String']>;
  /** The list of reason codes chosen by the requestor */
  reasonCodes: Array<Scalars['Float']>;
  /** Actual user requesting the cancellation, usually the company owner */
  requestedBy: Scalars['String'];
  /** When the cancellation request was made, defaults to "now" */
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  /** Send email about the cancellation request */
  requestedEmail?: InputMaybe<Scalars['Boolean']>;
  /** The cancellation wizard session ID */
  sessionId?: InputMaybe<Scalars['String']>;
  /** The company ID */
  source?: InputMaybe<CancellationSource>;
};

export type CreateCompanyInCheckInput = {
  /** Bambee company id */
  id: Scalars['ID'];
  /** Boolean indicating whether to send an email to the customer. */
  notifyCustomer?: InputMaybe<Scalars['Boolean']>;
  /** Date the company will start using Check as their payroll processor. */
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type CreateEnrollmentProfileRequest = {
  /** Company ID */
  id: Scalars['String'];
  numOfContractors?: InputMaybe<Scalars['Int']>;
  numOfFullTimeHourlyEmployees?: InputMaybe<Scalars['Int']>;
  numOfFullTimeSalaryEmployees?: InputMaybe<Scalars['Int']>;
  numOfPartTimeEmployees?: InputMaybe<Scalars['Int']>;
};

export type CreateHrRequestByInternalUserInput = {
  autoRespond?: InputMaybe<Scalars['Boolean']>;
  companyId: Scalars['String'];
  description: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<HrRequestOrigin>;
  originFeature?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<HrRequestSubType>;
  subject: Scalars['String'];
  type: HrRequestType;
};

export type CreateHrRequestFromSalesforceCaseInput = {
  message?: InputMaybe<Scalars['String']>;
  salesforceCaseId: Scalars['String'];
};

export type CreateHrRequestInput = {
  answers?: InputMaybe<Scalars['String']>;
  autoRespond?: InputMaybe<Scalars['Boolean']>;
  chatNow: Scalars['Boolean'];
  description: Scalars['String'];
  origin?: InputMaybe<HrRequestOrigin>;
  originFeature?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<HrRequestSubType>;
  subject: Scalars['String'];
  type: HrRequestType;
};

export type CreateHrRequestsFromIntercomConversationsInput = {
  intercomConversationIds: Array<Scalars['String']>;
};

export type CreateJobDescriptionInput = {
  body: Scalars['JSON'];
  title: Scalars['String'];
};

export type CreateMultipleRolesForCompanyInput = {
  /** Bambee Core Company id */
  companyId: Scalars['ID'];
  /** Bambee Core Company Role title */
  titles: Array<Scalars['String']>;
};

export type CreatePayScheduleInput = {
  companyId: Scalars['ID'];
  /** The first date on which employees will be paid for the payroll. */
  firstPayday: Scalars['String'];
  /** The end of the date range that the payroll covers. YYYY-MM-DD */
  firstPeriodEnd: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  payFrequency: PayScheduleFrequency;
  /** The payday date of the the second payroll the company will run on this pay schedule. This field should only be used with the semimonthly frequency to specify custom paydays besides the 15th and last day of the month. second_payday must be between one day and one month after first_payday. */
  secondPayday?: InputMaybe<Scalars['String']>;
};

export type CreatePayrollInput = {
  /** The approval deadline provided in paydays */
  approvalDeadline: Scalars['DateTime'];
  /** Bambee company id */
  companyId: Scalars['ID'];
  /** Additional configuration options controlling off-cycle payroll behavior. */
  offCycleOptions?: InputMaybe<OffcycleOptionsInput>;
  /** The date on which employees will be paid for the payroll. */
  payday: Scalars['String'];
  /** The type of payroll, regular or off-cycle. */
  payrollType?: InputMaybe<PayrollType>;
  /** The end of the date range that the payroll covers. YYYY-MM-DD */
  periodEnd: Scalars['String'];
  /** The start of the date range that the payroll covers. YYYY-MM-DD */
  periodStart: Scalars['String'];
};

export type CreatePayrollSubscriptionInput = {
  applyTrial?: InputMaybe<Scalars['Boolean']>;
  /** The Company ID. */
  companyId: Scalars['String'];
  /** The Payroll Plan identifier for the new subscription. */
  planValue?: InputMaybe<Scalars['String']>;
  /** The date to start billing after. */
  startBillingAfter?: InputMaybe<Scalars['DateTime']>;
};

export type CreatePlanLockInput = {
  active: Scalars['Boolean'];
  companyId: Scalars['String'];
  couponId: Scalars['String'];
  plan: Scalars['String'];
  setupFee: Scalars['Float'];
  trialDuration: Scalars['Float'];
};

export type CreateProductInterestInput = {
  productKey: ProductKey;
  productSubKey?: InputMaybe<ProductSubKey>;
};

export type CreateReportCardInput = {
  companyId: Scalars['String'];
};

export type CreateRoleForCompanyInput = {
  /** Bambee Core Company id */
  companyId: Scalars['ID'];
  /** Bambee Core Company Role title */
  title: Scalars['String'];
};

export type CreateSignatoryInput = {
  companyName?: InputMaybe<Scalars['String']>;
  companyPhone?: InputMaybe<Scalars['String']>;
  companyPlan?: InputMaybe<Scalars['String']>;
  sendEmail?: InputMaybe<Scalars['Boolean']>;
  signatoryEmail: Scalars['String'];
  signatoryFirstName: Scalars['String'];
  signatoryLastName: Scalars['String'];
  signatoryTitle: Scalars['String'];
  userFullName?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
  userRole?: InputMaybe<Scalars['String']>;
};

export type CreateVoicesFeedbackInput = {
  anonymous: Scalars['Boolean'];
  feedback: Scalars['String'];
};

export type CreateWebinarInstanceRequest = {
  /** The duration of the webinar in minutes */
  duration?: Scalars['Int'];
  /** The ID of the user who is hosting the webinar */
  hostUserId: Scalars['ID'];
  startTime: Scalars['DateTime'];
  /** The tiers that are allowed to attend the webinar */
  tiers: Array<Scalars['String']>;
  /** The topic of the webinar */
  topic: Scalars['String'];
};

export type CreateWorkerRoleInput = {
  companyRoleId?: InputMaybe<Scalars['ID']>;
  isPrimary: Scalars['Boolean'];
  newCompanyRole?: InputMaybe<CreateRoleForCompanyInput>;
  payRate: Scalars['Float'];
  payType: WorkerRolePaytype;
  /** Bambee Core User id */
  userId: Scalars['ID'];
};

export type CreateWorkplaceInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  /** Workplace address */
  address: AddressInput;
  /** Bambee company id */
  companyId: Scalars['ID'];
  default: Scalars['Boolean'];
  /** Workplace name */
  name: Scalars['String'];
};

export type Credit = {
  __typename?: 'Credit';
  companyId: Scalars['String'];
  consumedAt?: Maybe<Scalars['DateTime']>;
  consumedBy?: Maybe<Scalars['String']>;
  consumptionDescription?: Maybe<Scalars['String']>;
  consumptionReason?: Maybe<ProductCreditConsumptionReason>;
  /** Subkey of the product, if consuming managed service credit */
  consumptionSubKey?: Maybe<Scalars['String']>;
  consumptionType?: Maybe<ProductCreditConsumptionType>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  creationDescription?: Maybe<Scalars['String']>;
  creationReason?: Maybe<ProductCreditCreationReason>;
  creditType: ProductCreditType;
  id: Scalars['ID'];
  productKey: ProductKey;
  stripeCreditNoteId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  greaterThan?: InputMaybe<Scalars['DateTime']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['DateTime']>;
  /** The value for the field must be in the provided list. */
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lessThan?: InputMaybe<Scalars['DateTime']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['DateTime']>;
  /** The value for the field must not be in the provided list. */
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  greaterThan?: InputMaybe<Scalars['DateTime']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['DateTime']>;
  /** The value for the field must be in the provided list. */
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lessThan?: InputMaybe<Scalars['DateTime']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['DateTime']>;
  /** The value for the field must not be in the provided list. */
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DeclineRecommendationInput = {
  /** The id of the recommendation to be declined. */
  recommendationId: Scalars['String'];
};

export type DefaultPayScheduleDays = {
  __typename?: 'DefaultPayScheduleDays';
  approvalDeadline: Scalars['DateTime'];
  impactedByWeekendOrHoliday: Scalars['Boolean'];
  payday: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
};

export type DefaultPayScheduleDaysInput = {
  /** The number of pay days requested, defaults to 10. */
  limit?: InputMaybe<Scalars['Int']>;
};

export type DeleteBundleInput = {
  /** Core Company ID to associate with the bundle. If not provided, the bundle will be global */
  companyId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteBundleResponse = {
  __typename?: 'DeleteBundleResponse';
  deleted: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteCompanyRolesInput = {
  /** Bambee Core Company id */
  companyId: Scalars['ID'];
  /** Bambee Core Company Role title */
  roleIds: Array<Scalars['String']>;
};

export type DeleteContractorInput = {
  /** Bambee contractor ID */
  contractorId: Scalars['ID'];
};

export type DeleteEmployeeInput = {
  /** Bambee employee ID */
  employeeId: Scalars['ID'];
};

export type DeletePayrollInput = {
  id: Scalars['ID'];
};

export type DeputyConnection = {
  __typename?: 'DeputyConnection';
  /** Is the client allowed to trigger cancellation; If the product is being billed a la carte then we would expect this to be true. Otherwise T&A is bundled with payroll and can not be cancelled independently */
  canCancel: Scalars['Boolean'];
  /** Date when the connection was created. */
  createdAt: Scalars['DateTime'];
  /** The edition of Deputy subscription */
  edition?: Maybe<Scalars['String']>;
  /** Validation error message, when validation fails */
  error?: Maybe<Scalars['String']>;
  hasAccessToken: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Deputy installation id. Used to communicate with Deputy API. */
  installId: Scalars['String'];
  isSupportEnabled: Scalars['Boolean'];
  /** Is support enabled */
  supportEnabled: Scalars['Boolean'];
  /** Date when the connection was last updated. */
  updatedAt: Scalars['DateTime'];
  /** Date when the connection was validated. */
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type DeputySyncError = {
  __typename?: 'DeputySyncError';
  /** meta data about conflicting timesheets */
  data?: Maybe<DeputySyncMetadata>;
  /** The error id */
  id: Scalars['ID'];
  /** The error message */
  message: Scalars['String'];
  /** The stack trace of the error */
  stack?: Maybe<Scalars['String']>;
};

export type DeputySyncInfo = {
  __typename?: 'DeputySyncInfo';
  /** number of contractors with synced timesheets */
  contractors: Scalars['Int'];
  /** number of earnings / payment entries associated to timesheets for this payroll */
  earnings: Scalars['Int'];
  /** number of deputy employees associated to timesheets for this payroll */
  employees: Scalars['Int'];
  /**
   * Sync errors
   * @deprecated Use syncErrors instead
   */
  errors: Array<Scalars['String']>;
  /** The payroll id */
  id: Scalars['ID'];
  /** Indicates if approved timesheets where converted into earnings */
  imported: Scalars['Boolean'];
  /** The last sync update */
  lastUpdate?: Maybe<Scalars['DateTime']>;
  /** number of contractor payment entries with synced timesheets */
  paymentEntries: Scalars['Int'];
  /** Sync errors */
  syncErrors: Array<DeputySyncError>;
  /** Indicates if approved timesheets where converted into earnings */
  synced: Scalars['Boolean'];
};

export type DeputySyncMetadata = {
  __typename?: 'DeputySyncMetadata';
  /** The dollar amount of the conflicting deputy timesheet */
  deputyAmount: Scalars['Float'];
  /** The employee id in deputy */
  deputyEmployeeId: Scalars['String'];
  /** The number of hours of the conflicting deputy timesheet */
  deputyHours: Scalars['Float'];
  /** The dollar amount of the imported timesheet */
  importedAmount: Scalars['Float'];
  /** The number of hours of the imported timesheet */
  importedHours: Scalars['Float'];
};

export type DisableDeputySupportInput = {
  companyId: Scalars['ID'];
};

/** The discussion topics for the Onboarding */
export enum DiscussionTopic {
  CreatingCompanyPolicies = 'CreatingCompanyPolicies',
  EnsuringMyBusinessIsCompliant = 'EnsuringMyBusinessIsCompliant',
  EstablishingANewBusiness = 'EstablishingANewBusiness',
  ExpandingMyBusiness = 'ExpandingMyBusiness',
  ManagingAnActiveHrIssue = 'ManagingAnActiveHRIssue',
  ManagingEmployees = 'ManagingEmployees',
  SomethingElse = 'SomethingElse'
}

export type DisucssionTopicDetail = {
  __typename?: 'DisucssionTopicDetail';
  /** The details of the discussion topic */
  detail: Scalars['String'];
  /** The discussion topic */
  topic: DiscussionTopic;
};

export type DisucssionTopicDetailInput = {
  /** The details of the discussion topic */
  detail: Scalars['String'];
  /** The discussion topic */
  topic: DiscussionTopic;
};

export type DoceboTokenResponse = {
  __typename?: 'DoceboTokenResponse';
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  token: Scalars['String'];
};

export type Document = {
  /** Date that this document was filed */
  filedOn: Scalars['String'];
  id: Scalars['ID'];
  /** will be either a two-character state abbreviation or "FED" */
  jurisdiction?: Maybe<Scalars['String']>;
  /** Document’s type */
  label: Scalars['String'];
  /** Year that this document was filed */
  year: Scalars['Float'];
};

export type DocumentOption = {
  __typename?: 'DocumentOption';
  id: DocumentOptionType;
  label: Scalars['String'];
  name: Scalars['String'];
};

export enum DocumentOptionType {
  ArticlesOfIncorporation = 'ARTICLES_OF_INCORPORATION',
  ArticlesOfIncorporationSignatoryAmendment = 'ARTICLES_OF_INCORPORATION_SIGNATORY_AMENDMENT',
  BalanceSheet = 'BALANCE_SHEET',
  BankLetter = 'BANK_LETTER',
  BankStatement = 'BANK_STATEMENT',
  CashFlowStatement = 'CASH_FLOW_STATEMENT',
  Cp_575 = 'CP_575',
  FiveZeroOneC_3 = 'FIVE_ZERO_ONE_C_3',
  NineForty = 'NINE_FORTY',
  NineFortyFive = 'NINE_FORTY_FIVE',
  NineFortyFour = 'NINE_FORTY_FOUR',
  NineFortyOne = 'NINE_FORTY_ONE',
  NineFortyThree = 'NINE_FORTY_THREE',
  OneFortySevenC = 'ONE_FORTY_SEVEN_C',
  ProfitAndLoss = 'PROFIT_AND_LOSS',
  SignatoryPhotoId = 'SIGNATORY_PHOTO_ID',
  Ss4 = 'SS4',
  StateRegistration = 'STATE_REGISTRATION',
  VoidedCheck = 'VOIDED_CHECK'
}

export enum DocumentType {
  ArticlesOfIncorporation = 'ARTICLES_OF_INCORPORATION',
  ArticlesOfIncorporationSignatoryAmendment = 'ARTICLES_OF_INCORPORATION_SIGNATORY_AMENDMENT',
  BalanceSheet = 'BALANCE_SHEET',
  BankLetter = 'BANK_LETTER',
  BankStatement = 'BANK_STATEMENT',
  CashFlowStatement = 'CASH_FLOW_STATEMENT',
  Cp_575 = 'CP_575',
  FiveZeroOneC_3 = 'FIVE_ZERO_ONE_C_3',
  NineForty = 'NINE_FORTY',
  NineFortyFive = 'NINE_FORTY_FIVE',
  NineFortyFour = 'NINE_FORTY_FOUR',
  NineFortyOne = 'NINE_FORTY_ONE',
  NineFortyThree = 'NINE_FORTY_THREE',
  OneFortySevenC = 'ONE_FORTY_SEVEN_C',
  ProfitAndLoss = 'PROFIT_AND_LOSS',
  SignatoryPhotoId = 'SIGNATORY_PHOTO_ID',
  Ss4 = 'SS4',
  StateRegistration = 'STATE_REGISTRATION',
  VoidedCheck = 'VOIDED_CHECK'
}

/** Downgrade Deputy installation from paid to free */
export type DowngradeDeputyInstallInput = {
  /** Bambee company id */
  companyId: Scalars['String'];
};

export enum EinSteps {
  EinSubmitted = 'EIN_SUBMITTED'
}

export type Earning = {
  __typename?: 'Earning';
  /** Amount earned for this earning */
  amount?: Maybe<Scalars['Float']>;
  /** The decimal value of the inputted number of hours worked */
  decimal?: Maybe<Scalars['Float']>;
  /** Readable description of the earning code that will show up on the paystub */
  description?: Maybe<Scalars['String']>;
  /** Bambee earning id */
  id: Scalars['ID'];
  /** Number of hours worked for this earning */
  minutes?: Maybe<Scalars['Float']>;
  /** Source of earning */
  source: SourceType;
  /** SubType of earning. */
  subtype?: Maybe<EarningSubtype>;
  /** Type of earning. */
  type: EarningType;
  /** Worker role id to be applied to this item */
  workerRoleId?: Maybe<Scalars['ID']>;
};

export type EarningInput = {
  /** Amount earned for this earning */
  amount?: InputMaybe<Scalars['Float']>;
  /** The decimal value of the inputted number of hours worked */
  decimal?: InputMaybe<Scalars['Float']>;
  /** Readable description of the earning code that will show up on the paystub */
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** Number of hours worked for this earning */
  minutes?: InputMaybe<Scalars['Float']>;
  /** Source of Earning */
  source?: InputMaybe<SourceType>;
  /** SubType of earning. */
  subtype?: InputMaybe<EarningSubtype>;
  /** Type of earning. */
  type: EarningType;
  /** Worker role id to be applied to this item */
  workerRoleId?: InputMaybe<Scalars['ID']>;
};

export enum EarningSubtype {
  Discretionary = 'DISCRETIONARY',
  Gift = 'GIFT',
  NonDiscretionary = 'NON_DISCRETIONARY'
}

export type EarningSummaryItem = {
  __typename?: 'EarningSummaryItem';
  /** The earning amount */
  amount?: Maybe<Scalars['Float']>;
  /** The earning description */
  description?: Maybe<Scalars['String']>;
  /** The earning type */
  type?: Maybe<Scalars['String']>;
};

export enum EarningType {
  Allowance = 'ALLOWANCE',
  Bonus = 'BONUS',
  CashTips = 'CASH_TIPS',
  CaSpsl = 'CA_SPSL',
  ClergyHousing = 'CLERGY_HOUSING',
  Commission = 'COMMISSION',
  DoubleOvertime = 'DOUBLE_OVERTIME',
  FfcraFamilyLeave = 'FFCRA_FAMILY_LEAVE',
  FfcraSickIndividual = 'FFCRA_SICK_INDIVIDUAL',
  FfcraSickOther = 'FFCRA_SICK_OTHER',
  GroupTermLife = 'GROUP_TERM_LIFE',
  Hourly = 'HOURLY',
  NonHourlyRegular = 'NON_HOURLY_REGULAR',
  NonProductive = 'NON_PRODUCTIVE',
  OtherImputed = 'OTHER_IMPUTED',
  Overtime = 'OVERTIME',
  PaidHoliday = 'PAID_HOLIDAY',
  PaidHoliday_1_5X = 'PAID_HOLIDAY_1_5X',
  PaycheckTips = 'PAYCHECK_TIPS',
  Piece = 'PIECE',
  PieceAdjustmentToMinimumWage = 'PIECE_ADJUSTMENT_TO_MINIMUM_WAGE',
  Pto = 'PTO',
  ReportedAllowance = 'REPORTED_ALLOWANCE',
  ReportedClergyHousing = 'REPORTED_CLERGY_HOUSING',
  RestAndRecovery = 'REST_AND_RECOVERY',
  Salaried = 'SALARIED',
  Severance = 'SEVERANCE',
  Sick = 'SICK',
  TipCreditAdjustmentToMinimumWage = 'TIP_CREDIT_ADJUSTMENT_TO_MINIMUM_WAGE',
  TwoPercentShareholderBenefits = 'TWO_PERCENT_SHAREHOLDER_BENEFITS',
  TwoPercentShareholderHsa = 'TWO_PERCENT_SHAREHOLDER_HSA',
  WeightedDoubleOvertime = 'WEIGHTED_DOUBLE_OVERTIME',
  WeightedOvertime = 'WEIGHTED_OVERTIME'
}

export type EligibleAddonsInput = {
  /** The company ID */
  companyId: Scalars['ID'];
};

export type EligibleAddonsResponse = {
  __typename?: 'EligibleAddonsResponse';
  /** The list of payroll plans available for subscribing */
  payroll: Array<PayrollPlanModel>;
  /** The list of time and attendance plans available for subscribing */
  time_attendance: Array<PayrollPlanModel>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
};

export type Employee = {
  __typename?: 'Employee';
  bankAccounts?: Maybe<Array<Maybe<BankAccount>>>;
  benefitOnboarding?: Maybe<BenefitWorkerOnboarding>;
  benefits: Array<Benefit>;
  checkId?: Maybe<Scalars['ID']>;
  /** @deprecated Use generateOnboardingLink */
  checkOnboardLink: Scalars['String'];
  checkOnboarding?: Maybe<CheckEmployeeOnboarding>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deputyId?: Maybe<Scalars['ID']>;
  /** Bambee employee id */
  id: Scalars['ID'];
  payRate: Scalars['Float'];
  payType?: Maybe<PaymentType>;
  paymentMethodPreference?: Maybe<PaymentMethod>;
  payrollItems: Array<PayrollItem>;
  profile?: Maybe<EmployeeProfile>;
  /** @deprecated checkOnboarding is a superset */
  remainingOnboardingSteps: Array<EmployeeOnboardingSteps>;
  taxDocuments?: Maybe<Array<Maybe<TaxDocument>>>;
  timesheets: Array<Timesheet>;
  user?: Maybe<CoreUser>;
  workplaces: Array<Workplace>;
};


export type EmployeePayrollItemsArgs = {
  excludeZeroGross?: InputMaybe<Scalars['Boolean']>;
};


export type EmployeeTaxDocumentsArgs = {
  data?: InputMaybe<EmployeeTaxDocumentsInput>;
};


export type EmployeeTimesheetsArgs = {
  filter?: InputMaybe<EmployeeTimesheetFilter>;
};

export enum EmployeeClassification {
  Empty = 'empty',
  Exempt = 'exempt',
  Nonexempt = 'nonexempt'
}

export type EmployeeJobDescription = {
  __typename?: 'EmployeeJobDescription';
  createdAt: Scalars['DateTime'];
  documentId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jobDescription: JobDescription;
  signedAt?: Maybe<Scalars['DateTime']>;
};

export enum EmployeeOnboardingSteps {
  BankAccount = 'BANK_ACCOUNT',
  CompanyDefinedAttributes = 'COMPANY_DEFINED_ATTRIBUTES',
  Ein = 'EIN',
  EmployeeDetails = 'EMPLOYEE_DETAILS',
  FilingAuthorization = 'FILING_AUTHORIZATION',
  PaymentMethod = 'PAYMENT_METHOD',
  SetupParameters = 'SETUP_PARAMETERS',
  Ssn = 'SSN',
  Withholdings = 'WITHHOLDINGS',
  Workplaces = 'WORKPLACES'
}

export type EmployeeProfile = {
  __typename?: 'EmployeeProfile';
  active?: Maybe<Scalars['Boolean']>;
  contractorBusinessName?: Maybe<Scalars['String']>;
  contractorType?: Maybe<PayrollContractorType>;
  dob?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  employeeType?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<ProfileAddress>;
  /** Bambee employee id */
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum EmployeeServiceContractorType {
  Business = 'business',
  Empty = 'empty',
  Individual = 'individual'
}

export enum EmployeeServiceEmployeeType {
  Contractor = 'contractor',
  Empty = 'empty',
  Fulltime = 'fulltime',
  Parttime = 'parttime'
}

export type EmployeeSync = {
  __typename?: 'EmployeeSync';
  bambee?: Maybe<Scalars['String']>;
  check?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type EmployeeTaxDocumentsInput = {
  /** Return tax documents for this year (must be greater than or equal to 2019) */
  year?: InputMaybe<Scalars['Int']>;
};

export type EmployeeTimesheetFilter = {
  /** Find approved if true, find unapproved if false, do not filter on approval status if not provided. */
  approved?: InputMaybe<Scalars['Boolean']>;
  /** Find timesheets for a particular employee (Deputy Employee Id) */
  employeeId?: InputMaybe<Scalars['String']>;
  /** Find timesheets for a particular payroll (this will overwrite start/end fields in filter (Bambee Payroll Id) */
  payrollId?: InputMaybe<Scalars['Int']>;
};

export type EnableDeputySupportInput = {
  companyId: Scalars['ID'];
};

export type EnqueueHotlineCallInput = {
  /** Additional information associated to the call that is being requested */
  information: CallInformation;
  /** An arbitrary string specifying where the request was originated */
  origin: Scalars['String'];
};

export type EnrollUserResponse = {
  __typename?: 'EnrollUserResponse';
  enrolled: Scalars['Boolean'];
  enrollments?: Maybe<EnrollUserTicket>;
  errors?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
};

export type EnrollUserTicket = {
  __typename?: 'EnrollUserTicket';
  bundleId?: Maybe<Scalars['ID']>;
  courseId: Scalars['ID'];
  ticketId: Scalars['ID'];
  topicId?: Maybe<Scalars['ID']>;
};

export type EnrollUsersError = {
  __typename?: 'EnrollUsersError';
  bundleId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  topicId?: Maybe<Scalars['String']>;
  userIds?: Maybe<Array<Scalars['String']>>;
};

export type EnrollUsersInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  topicId?: InputMaybe<Scalars['String']>;
  users: Array<UserInput>;
};

export type EnrollUsersResponse = {
  __typename?: 'EnrollUsersResponse';
  bundleId?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  enrolled: Scalars['Boolean'];
  errors?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  topicId?: Maybe<Scalars['String']>;
  userIds: Array<Scalars['String']>;
};

export type EnrolledUser = {
  __typename?: 'EnrolledUser';
  id: Scalars['String'];
  language?: Maybe<Language>;
  role?: Maybe<UserRole>;
  state?: Maybe<State>;
  /** @deprecated Use id instead */
  userId: Scalars['String'];
};

export type EnrollmentCompleted = {
  __typename?: 'EnrollmentCompleted';
  completedUserIds: Array<Maybe<Scalars['String']>>;
  errors: Array<Maybe<Scalars['String']>>;
  id: Scalars['ID'];
};

export type EnrollmentInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  topicId?: InputMaybe<Scalars['String']>;
  users: Array<UserInput>;
};

export type EnumContractorPayTypeFilter = {
  equals?: InputMaybe<ContractorPayType>;
  /** The value for the field must be in the provided list. */
  in?: InputMaybe<Array<ContractorPayType>>;
  /** The value for the field must not be in the provided list. */
  notIn?: InputMaybe<Array<ContractorPayType>>;
};

export type EnumPaymentMethodFilter = {
  equals?: InputMaybe<PaymentMethod>;
  /** The value for the field must be in the provided list. */
  in?: InputMaybe<Array<PaymentMethod>>;
  /** The value for the field must not be in the provided list. */
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type EnumPayrollItemPayTypeFilter = {
  equals?: InputMaybe<PaymentType>;
  /** The value for the field must be in the provided list. */
  in?: InputMaybe<Array<PaymentType>>;
  /** The value for the field must not be in the provided list. */
  notIn?: InputMaybe<Array<PaymentType>>;
};

export type EnumPayrollItemPaymentMethodFilter = {
  equals?: InputMaybe<PaymentMethod>;
  /** The value for the field must be in the provided list. */
  in?: InputMaybe<Array<PaymentMethod>>;
  /** The value for the field must not be in the provided list. */
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type EnumPayrollItemPayrollItemStatusFilter = {
  equals?: InputMaybe<PayrollItemStatus>;
  /** The value for the field must be in the provided list. */
  in?: InputMaybe<Array<PayrollItemStatus>>;
  /** The value for the field must not be in the provided list. */
  notIn?: InputMaybe<Array<PayrollItemStatus>>;
};

export type EnumPayrollStatusFilter = {
  equals?: InputMaybe<PayrollStatus>;
  /** The value for the field must be in the provided list. */
  in?: InputMaybe<Array<PayrollStatus>>;
  /** The value for the field must not be in the provided list. */
  notIn?: InputMaybe<Array<PayrollStatus>>;
};

export type EnumPayrollTypeFilter = {
  equals?: InputMaybe<PayrollType>;
  /** The value for the field must be in the provided list. */
  in?: InputMaybe<Array<PayrollType>>;
  /** The value for the field must not be in the provided list. */
  notIn?: InputMaybe<Array<PayrollType>>;
};

export type EoEHrRequestAnswer = {
  __typename?: 'EoEHrRequestAnswer';
  answers?: Maybe<Scalars['JSON']>;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  refId: Scalars['String'];
  sfSubType?: Maybe<Scalars['String']>;
  sfType?: Maybe<Scalars['String']>;
  type: EoeHrRequestType;
};

export enum EoeHrRequestType {
  HrRequest = 'HR_REQUEST',
  Resignation = 'RESIGNATION',
  Termination = 'TERMINATION'
}

export type ExtendBambeePlusOnboardingToSpecificDate = {
  companyId: Scalars['String'];
  newDeadline: Scalars['DateTime'];
};

export type Feed = {
  __typename?: 'Feed';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: CoreUser;
};

export type FeedbackHistory = {
  __typename?: 'FeedbackHistory';
  byUser: CoreUser;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  message: FeedbackMessage;
  value: FeedbackValue;
};

export type FeedbackMessage = ChatMessageInterface & {
  __typename?: 'FeedbackMessage';
  channelId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  feedbackId: Scalars['ID'];
  id: Scalars['ID'];
  isThreadMessage: Scalars['Boolean'];
  isThreadParent: Scalars['Boolean'];
  message: Scalars['String'];
  rawType: SendbirdMessageType;
  /** Recipieint, if a one-on-one message */
  recipient?: Maybe<CoreUser>;
  sender: CoreUser;
  threadParentId?: Maybe<Scalars['ID']>;
  type: ChatMessageTypeEnum;
  value: FeedbackValue;
};

export type FeedbackMessageInput = {
  message: Scalars['String'];
  value: FeedbackValue;
};

/** A representation of feedback values */
export enum FeedbackValue {
  AboveExpectations = 'AboveExpectations',
  BelowExpectations = 'BelowExpectations',
  MeetsExpectations = 'MeetsExpectations'
}

export type FileInput = {
  /** base64 encoded file data */
  file: Scalars['String'];
  /** file name */
  name: Scalars['String'];
  type: DocumentOptionType;
};

export type FileStackInput = {
  filename?: Scalars['String'];
  id?: Scalars['String'];
  mimetype?: Scalars['String'];
  name?: Scalars['String'];
  size?: Scalars['Int'];
  uploaded_date?: InputMaybe<Scalars['DateTime']>;
  url?: Scalars['String'];
};

export type FileStackModel = {
  __typename?: 'FileStackModel';
  filename: Scalars['String'];
  id: Scalars['String'];
  mimetype: Scalars['String'];
  name: Scalars['String'];
  size: Scalars['Int'];
  uploaded_date?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export enum FileType {
  Jpg = 'JPG',
  Pdf = 'PDF',
  Png = 'PNG'
}

export type FinchManualSetupCompletedInput = {
  companyName: Scalars['String'];
  payrollProvider: Scalars['String'];
};

export type GenerateAiJobDescriptionInput = {
  employeeType?: InputMaybe<JobDescriptionEmployeePayType>;
  employmentPayRate?: InputMaybe<JobDescriptionEmploymentPayRate>;
  employmentTaxType?: InputMaybe<JobDescriptionEmploymentTaxType>;
  industry: Scalars['String'];
  jobTitle: Scalars['String'];
  location?: InputMaybe<JobDescriptionLocation>;
  travelRequirements?: InputMaybe<Scalars['String']>;
  workingSchedule?: InputMaybe<Array<WorkingSchedule>>;
};

/** Generate AI job description attatching it to user */
export type GenerateAiJobDescriptionInputV2 = {
  employeeType?: InputMaybe<JobDescriptionEmployeePayType>;
  employmentPayRate?: InputMaybe<JobDescriptionEmploymentPayRate>;
  employmentTaxType?: InputMaybe<JobDescriptionEmploymentTaxType>;
  industry: Scalars['String'];
  /** Allows the AI generation process to use an existing job description */
  jobDescriptionId?: InputMaybe<Scalars['String']>;
  jobTitle: Scalars['String'];
  location?: InputMaybe<JobDescriptionLocation>;
  travelRequirements?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
  workingSchedule?: InputMaybe<Array<WorkingSchedule>>;
};

export type GenerateCompanyOnboardingLinkInput = {
  /** Bambee company id */
  companyId: Scalars['ID'];
  /** The email of the person who has permission to onboard on behalf of the company. */
  email: Scalars['String'];
  /** Should this trigger the sending of an e-mail to the individual? If left out an e-mail will NOT be sent. */
  sendEmail?: InputMaybe<Scalars['Boolean']>;
  /** The name of the person who has permission to onboard on behalf of the company. */
  signerName: Scalars['String'];
  /** The title of the person who has permission to onboard on behalf of the company (e.g. "Officer", "Manager", etc.). */
  signerTitle: Scalars['String'];
};

export type GetAvailableProductsInput = {
  companyId: Scalars['String'];
};

export type GetBillingInfoInput = {
  companyId: Scalars['String'];
};

export type GetBusinessManagerCalendlyUrlInput = {
  tierNumber: Scalars['Int'];
};

export type GetCompanyCreditsInputV2 = {
  active?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['String']>;
  consumed?: InputMaybe<Scalars['Boolean']>;
  creditType?: InputMaybe<ProductCreditType>;
  productKey?: InputMaybe<ProductKey>;
  refundable?: InputMaybe<Scalars['Boolean']>;
  stripeInvoiceId?: InputMaybe<Scalars['String']>;
};

export type GetCompanyDelinquency = {
  companyId: Scalars['String'];
};

export type GetCompanyInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  topicId?: InputMaybe<Scalars['String']>;
};

export type GetCompanyPaymentSources = {
  companyId: Scalars['String'];
};

export type GetCompanyProductsInput = {
  companyId: Scalars['String'];
};

export type GetCompanySubscriptionsInput = {
  productCode?: InputMaybe<ProductKey>;
  stripeCustomerId: Scalars['String'];
};

export type GetCompanySurveyAnswerInput = {
  companyId: Scalars['ID'];
  key: SurveyQuestionKey;
  type: Scalars['String'];
};

export type GetCompanySurveyInput = {
  companyId: Scalars['String'];
  type: Scalars['String'];
};

export type GetCompanySurveyListInput = {
  companyId: Scalars['String'];
};

export type GetConversationByIntercomIdInput = {
  intercomConversationId: Scalars['String'];
};

export type GetCoreUpgradeOptionsInput = {
  companyId: Scalars['String'];
};

export type GetCouponInput = {
  couponId: Scalars['String'];
};

export type GetEntityInput = {
  id: Scalars['String'];
};

export type GetEoeHrRequestInput = {
  companyId: Scalars['String'];
  refId: Scalars['String'];
  type: EoeHrRequestType;
};

export type GetExistingResignationByEmployeeInput = {
  employeeId: Scalars['String'];
};

export type GetExistingTerminationByEmployeeInput = {
  employeeId: Scalars['String'];
};

export type GetHrRequestByIdInput = {
  id: Scalars['String'];
};

export type GetHrRequestBySalesforceCaseIdInput = {
  salesforceCaseId: Scalars['String'];
};

export type GetPolicy = {
  policyId: Scalars['ID'];
};

export type GetPracticeInput = {
  companyId: Scalars['String'];
  practiceId: Scalars['String'];
};

export type GetProductInterestInput = {
  productKey?: InputMaybe<ProductKey>;
  productSubKey?: InputMaybe<ProductSubKey>;
};

export type GetReportCardInput = {
  /** employee id */
  employeeId?: InputMaybe<Scalars['String']>;
  reportCardId: Scalars['String'];
};

export type GetResignationHrRequestInput = {
  hrRequestId: Scalars['String'];
};

export type GetResignationInput = {
  resignationId: Scalars['String'];
};

export type GetResignationsInput = {
  companyId?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  status?: InputMaybe<ResignationStatus>;
};

export type GetSalesforceCasesForNewPolicyRequestInput = {
  companyId: Scalars['String'];
  excludedHrRequestIds: Array<Scalars['String']>;
};

export type GetScheduledEventsForUserInput = {
  /** User ID */
  userId: Scalars['String'];
};

export type GetTerminationHrRequestInput = {
  hrRequestId: Scalars['String'];
};

export type GetTerminationInput = {
  terminationId: Scalars['String'];
};

export type GetTerminationsInput = {
  companyId?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  status?: InputMaybe<TerminationStatus>;
};

export type GetUserCoursesInput = {
  userId?: InputMaybe<Scalars['String']>;
};

export type GetUserWarningDocumentsInput = {
  employeeId: Scalars['String'];
};

export type GetVoicesFeedbackListInput = {
  companyId: Scalars['String'];
};

export type Goal = {
  __typename?: 'Goal';
  category: GoalCategory;
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  requirements: Array<Requirement>;
  status: GoalStatus;
};

export enum GoalCategory {
  CompleteCompanyReview = 'COMPLETE_COMPANY_REVIEW',
  VerifyBankAccountOwnership = 'VERIFY_BANK_ACCOUNT_OWNERSHIP',
  VerifyCompanyFinances = 'VERIFY_COMPANY_FINANCES'
}

/** The completion status of the goal */
export enum GoalCompletionStatus {
  Automatic = 'Automatic',
  Cancelled = 'Cancelled',
  Manual = 'Manual',
  Skipped = 'Skipped'
}

/** The name of the goal */
export enum GoalName {
  BambeePlusSetup = 'BambeePlusSetup',
  EmployeeOnboardingKit = 'EmployeeOnboardingKit',
  GetReadyToGetPaid = 'GetReadyToGetPaid',
  GettingReadyToHire = 'GettingReadyToHire',
  HiringKit = 'HiringKit',
  HrAudit = 'HrAudit',
  ImproveStaffCommunication = 'ImproveStaffCommunication',
  IntroduceStaffToBambee = 'IntroduceStaffToBambee',
  MeetYourTeam = 'MeetYourTeam',
  PayrollOnboarding = 'PayrollOnboarding',
  SendJobOffer = 'SendJobOffer',
  WorkplaceViolenceCaHrmFollowUp = 'WorkplaceViolenceCaHrmFollowUp'
}

export enum GoalStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export enum GroupView {
  Default = 'Default',
  IAmMember = 'IAmMember',
  ICanSee = 'ICanSee',
  ThatIOwn = 'ThatIOwn'
}

export type HotlineCall = {
  __typename?: 'HotlineCall';
  agent?: Maybe<HotlineCallAgent>;
  callDuration?: Maybe<Scalars['Float']>;
  callPlacedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  status: HotlineCallStatus;
  summary?: Maybe<Scalars['String']>;
};

export type HotlineCallAgent = {
  __typename?: 'HotlineCallAgent';
  avatarUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export enum HotlineCallStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Missed = 'MISSED',
  Pending = 'PENDING'
}

export type HrRequest = {
  __typename?: 'HrRequest';
  conversation?: Maybe<Conversation>;
  conversationId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customerCompanyId: Scalars['String'];
  id: Scalars['String'];
  intercomConversationId: Scalars['String'];
};

export enum HrRequestAudience {
  Admin = 'ADMIN',
  Employee = 'EMPLOYEE'
}

export type HrRequestDetail = {
  __typename?: 'HrRequestDetail';
  caseNumber?: Maybe<Scalars['String']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expectationDate?: Maybe<Scalars['DateTime']>;
  hrRequestSurveyAnswers?: Maybe<Scalars['JSON']>;
  intercomId?: Maybe<Scalars['String']>;
  isComplete: Scalars['Boolean'];
  isOverdue: Scalars['Boolean'];
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  subType?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type HrRequestExpectation = {
  __typename?: 'HrRequestExpectation';
  cycleTime: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type HrRequestGroup = {
  __typename?: 'HrRequestGroup';
  icon: Scalars['String'];
  key: HrRequestGroupKey;
  title: Scalars['String'];
};

export enum HrRequestGroupKey {
  EmployeeFeedback = 'EMPLOYEE_FEEDBACK',
  EmployeeIssues = 'EMPLOYEE_ISSUES',
  EndingEmployment = 'ENDING_EMPLOYMENT',
  HiringOnboarding = 'HIRING_ONBOARDING',
  IssueReporting = 'ISSUE_REPORTING',
  PayBenefits = 'PAY_BENEFITS',
  Policies = 'POLICIES',
  Support = 'SUPPORT',
  TimeOff = 'TIME_OFF'
}

export type HrRequestOption = {
  __typename?: 'HrRequestOption';
  audience: Array<HrRequestAudience>;
  description: Scalars['String'];
  expectation?: Maybe<HrRequestExpectation>;
  groupKey: HrRequestGroupKey;
  keywords: Array<Scalars['String']>;
  popular?: Maybe<HrRequestOptionPopular>;
  requiresPrimaryHrm: Scalars['Boolean'];
  slug: HrRequestSlug;
  subType?: Maybe<HrRequestSubType>;
  title: Scalars['String'];
  type: HrRequestType;
  unlisted?: Maybe<Scalars['Boolean']>;
};

export type HrRequestOptionPopular = {
  __typename?: 'HrRequestOptionPopular';
  description?: Maybe<Scalars['String']>;
  order: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
};

export type HrRequestOptionsResponse = {
  __typename?: 'HrRequestOptionsResponse';
  groups: Array<HrRequestGroup>;
  options: Array<HrRequestOption>;
};

export enum HrRequestOrigin {
  Api1 = 'API1',
  Api2 = 'API2',
  App = 'APP',
  Email = 'EMAIL',
  Honey = 'HONEY',
  Intercom = 'INTERCOM',
  MobileApp = 'MOBILE_APP',
  Payrollservice = 'PAYROLLSERVICE',
  Phone = 'PHONE',
  Salesforceautomation = 'SALESFORCEAUTOMATION',
  Salesforcemanualinput = 'SALESFORCEMANUALINPUT',
  Taskservice = 'TASKSERVICE',
  Truly = 'TRULY',
  Webform = 'WEBFORM'
}

export enum HrRequestSlug {
  AdminSupport = 'ADMIN_SUPPORT',
  BillingIssue = 'BILLING_ISSUE',
  BillingPayments = 'BILLING_PAYMENTS',
  BonusesCommission = 'BONUSES_COMMISSION',
  DisabilityAccomodation = 'DISABILITY_ACCOMODATION',
  EmployeeBenefits = 'EMPLOYEE_BENEFITS',
  EmployeeChat = 'EMPLOYEE_CHAT',
  EmployeeCompliants = 'EMPLOYEE_COMPLIANTS',
  FinalPay = 'FINAL_PAY',
  FiringTermination = 'FIRING_TERMINATION',
  HrTeamIssue = 'HR_TEAM_ISSUE',
  I9EmploymentEligibility = 'I9_EMPLOYMENT_ELIGIBILITY',
  Incident = 'INCIDENT',
  JobDescription = 'JOB_DESCRIPTION',
  Layoff = 'LAYOFF',
  LeaveOfAbsence = 'LEAVE_OF_ABSENCE',
  MealAndRestBreaks = 'MEAL_AND_REST_BREAKS',
  NewPolicy = 'NEW_POLICY',
  OtherIssue = 'OTHER_ISSUE',
  Overtime = 'OVERTIME',
  PaidTimeOff = 'PAID_TIME_OFF',
  Payroll = 'PAYROLL',
  PayrollEmployee = 'PAYROLL_EMPLOYEE',
  PayrollImplementation = 'PAYROLL_IMPLEMENTATION',
  PerformanceDisciplinaryAction = 'PERFORMANCE_DISCIPLINARY_ACTION',
  PreEmployment = 'PRE_EMPLOYMENT',
  Resignation = 'RESIGNATION',
  SickLeave = 'SICK_LEAVE',
  TechnologyIssue = 'TECHNOLOGY_ISSUE',
  Tips = 'TIPS',
  TravelTime = 'TRAVEL_TIME',
  UnemploymentClaims = 'UNEMPLOYMENT_CLAIMS',
  WebsiteAppHelp = 'WEBSITE_APP_HELP',
  WorkplacePosting = 'WORKPLACE_POSTING',
  WorkInjury = 'WORK_INJURY'
}

export enum HrRequestSubType {
  AdaAccommodation = 'ADA_ACCOMMODATION',
  AdjustApprovedPayroll = 'ADJUST_APPROVED_PAYROLL',
  AppHelp = 'APP_HELP',
  BambeePlusOfferQuestion = 'BAMBEE_PLUS_OFFER_QUESTION',
  BillingIssue = 'BILLING_ISSUE',
  BonusCommission = 'BONUS_COMMISSION',
  EmployeeBenefits = 'EMPLOYEE_BENEFITS',
  EmployeeChat = 'EMPLOYEE_CHAT',
  EmployeeIncident = 'EMPLOYEE_INCIDENT',
  EmployeeRequest = 'EMPLOYEE_REQUEST',
  EndingEmploymentEmergency = 'ENDING_EMPLOYMENT_EMERGENCY',
  EndingEmploymentLayoff = 'ENDING_EMPLOYMENT_LAYOFF',
  EndingEmploymentResignation = 'ENDING_EMPLOYMENT_RESIGNATION',
  FinalPay = 'FINAL_PAY',
  FiringTermination = 'FIRING_TERMINATION',
  General = 'GENERAL',
  HiringOnboardingPreEmployment = 'HIRING_ONBOARDING_PRE_EMPLOYMENT',
  HrTeamIssue = 'HR_TEAM_ISSUE',
  I9EmploymentEligibilityForm = 'I9_EMPLOYMENT_ELIGIBILITY_FORM',
  InitialHrRequest = 'INITIAL_HR_REQUEST',
  JobDescription = 'JOB_DESCRIPTION',
  LeaveAbsence = 'LEAVE_ABSENCE',
  MissedPayroll = 'MISSED_PAYROLL',
  NewPolicy = 'NEW_POLICY',
  OtherIssue = 'OTHER_ISSUE',
  PayrollImplementation = 'PAYROLL_IMPLEMENTATION',
  SettingUpBambeePayroll = 'SETTING_UP_BAMBEE_PAYROLL',
  SickLeave = 'SICK_LEAVE',
  StaffComplaint = 'STAFF_COMPLAINT',
  TaxAmendment = 'TAX_AMENDMENT',
  TaxQuestion = 'TAX_QUESTION',
  TechnologyIssue = 'TECHNOLOGY_ISSUE',
  TimeAndAttendance = 'TIME_AND_ATTENDANCE',
  UnemploymentClaims = 'UNEMPLOYMENT_CLAIMS',
  WageHour = 'WAGE_HOUR',
  WorkersCompensation = 'WORKERS_COMPENSATION',
  WorkplacePosting = 'WORKPLACE_POSTING'
}

export enum HrRequestType {
  AdminSupport = 'ADMIN_SUPPORT',
  BambeePayroll = 'BAMBEE_PAYROLL',
  Billing = 'BILLING',
  CompensationBenefits = 'COMPENSATION_BENEFITS',
  CustomerActivation = 'CUSTOMER_ACTIVATION',
  EmailAutoresponder = 'EMAIL_AUTORESPONDER',
  EmployeeFeedback = 'EMPLOYEE_FEEDBACK',
  EmployeeRelations = 'EMPLOYEE_RELATIONS',
  EndingEmployment = 'ENDING_EMPLOYMENT',
  HiringOnboarindg = 'HIRING_ONBOARINDG',
  IssueReporting = 'ISSUE_REPORTING',
  LaborLaws = 'LABOR_LAWS',
  PayrollSupport = 'PAYROLL_SUPPORT',
  PerformanceManagement = 'PERFORMANCE_MANAGEMENT',
  Policies = 'POLICIES',
  Service = 'SERVICE',
  TechSupport = 'TECH_SUPPORT',
  UnknownRequest = 'UNKNOWN_REQUEST'
}

export enum HrScore {
  Fail = 'FAIL',
  Fair = 'FAIR',
  Good = 'GOOD',
  Great = 'GREAT',
  Poor = 'POOR'
}

export type IdNamePair = {
  __typename?: 'IdNamePair';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ImportTimesheetsInput = {
  /** Bambee company id */
  companyId: Scalars['ID'];
  /** Ids of Bambee contractors who's hourly, OT, and Holiday earnings will be imported from timesheets */
  contractorIds?: InputMaybe<Array<Scalars['String']>>;
  /** Ids of Bambee employees who's hourly, OT, and Holiday earnings will be imported from timesheets */
  employeeIds?: InputMaybe<Array<Scalars['String']>>;
  /** Bambee payroll ID */
  id: Scalars['Int'];
};

export enum IndustryType {
  ArtsOrEntertainmentOrRecreation = 'ARTS_OR_ENTERTAINMENT_OR_RECREATION',
  AutoOrMachineRepair = 'AUTO_OR_MACHINE_REPAIR',
  AutoOrMachineSales = 'AUTO_OR_MACHINE_SALES',
  CleaningServices = 'CLEANING_SERVICES',
  ConsultingServices = 'CONSULTING_SERVICES',
  EducationalServices = 'EDUCATIONAL_SERVICES',
  FamilyCareServices = 'FAMILY_CARE_SERVICES',
  FinancialServices = 'FINANCIAL_SERVICES',
  FoodAndBeverageRetailOrWholesale = 'FOOD_AND_BEVERAGE_RETAIL_OR_WHOLESALE',
  GeneralConstructionOrGeneralContracting = 'GENERAL_CONSTRUCTION_OR_GENERAL_CONTRACTING',
  HealthCare = 'HEALTH_CARE',
  HospitalityOrAccommodation = 'HOSPITALITY_OR_ACCOMMODATION',
  HvacOrPlumbingOrElectricalContracting = 'HVAC_OR_PLUMBING_OR_ELECTRICAL_CONTRACTING',
  LegalServices = 'LEGAL_SERVICES',
  NonFoodRetailOrWholesale = 'NON_FOOD_RETAIL_OR_WHOLESALE',
  Other = 'OTHER',
  PersonalCareServices = 'PERSONAL_CARE_SERVICES',
  RealEstate = 'REAL_ESTATE',
  Restaurant = 'RESTAURANT',
  ScientificOrTechnicalServices = 'SCIENTIFIC_OR_TECHNICAL_SERVICES',
  SecurityServices = 'SECURITY_SERVICES',
  TobaccoOrAlcoholSales = 'TOBACCO_OR_ALCOHOL_SALES',
  Transportation = 'TRANSPORTATION'
}

export type InitializePayrollInput = {
  /** Payroll ID */
  id: Scalars['ID'];
  /** If this payroll is importing hours from deputy */
  importHours?: Scalars['Boolean'];
};

export type IsEventScheduledForUser = {
  __typename?: 'IsEventScheduledForUser';
  /** The invitee email */
  email: Scalars['String'];
  /** The event slug to check */
  eventSlug: Scalars['String'];
  id: Scalars['ID'];
  /** True if event type has already been scheduled for the email. False otherwise. */
  isScheduled: Scalars['Boolean'];
};

export type IsEventScheduledForUserInput = {
  /** The invitee email */
  email: Scalars['String'];
  /** The event slug to check */
  eventSlug: Scalars['String'];
};

export type JobDescription = {
  __typename?: 'JobDescription';
  aiGenerated?: Maybe<AiGenerated>;
  aiGeneratedId?: Maybe<Scalars['String']>;
  body: Scalars['JSON'];
  completedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum JobDescriptionEmployeePayType {
  FullTime = 'FullTime',
  PartTime = 'PartTime'
}

export enum JobDescriptionEmploymentPayRate {
  Hourly = 'Hourly',
  Salary = 'Salary'
}

export enum JobDescriptionEmploymentTaxType {
  Report_1099 = 'REPORT_1099',
  ReportW2 = 'REPORT_W2'
}

export enum JobDescriptionLocation {
  Hybrid = 'Hybrid',
  InPerson = 'InPerson',
  Remote = 'Remote'
}

export enum Jurisdiction {
  All = 'ALL',
  Fed = 'FED',
  Local = 'LOCAL'
}

export enum Language {
  En = 'EN',
  Es = 'ES'
}

/** The reason the company was brought to Bambee */
export enum LeadReason {
  ActiveHrIssue = 'ACTIVE_HR_ISSUE',
  CompliancePolicies = 'COMPLIANCE_POLICIES',
  EeOnboardingTraining = 'EE_ONBOARDING_TRAINING',
  General = 'GENERAL',
  Other = 'OTHER',
  PerformanceManagement = 'PERFORMANCE_MANAGEMENT',
  RecruitingHiring = 'RECRUITING_HIRING',
  Terminations = 'TERMINATIONS',
  Wages = 'WAGES'
}

export type ListHrRequestDetails = {
  __typename?: 'ListHrRequestDetails';
  hrRequests: Array<Maybe<HrRequestDetail>>;
};

export type ListHrRequestsResponse = {
  __typename?: 'ListHrRequestsResponse';
  hrRequests: Array<Maybe<HrRequest>>;
};

export enum ListPayrollDeletedOptions {
  All = 'ALL',
  None = 'NONE',
  Only = 'ONLY'
}

export type ListReportCardsInput = {
  companyId: Scalars['String'];
  employeeId?: InputMaybe<Scalars['String']>;
};

export type ListSalesforceCasesResponse = {
  __typename?: 'ListSalesforceCasesResponse';
  cases: Array<Maybe<SalesforceCase>>;
};

export type ListTicketsInput = {
  companyId: Scalars['String'];
};

export type ListTicketsResponse = {
  __typename?: 'ListTicketsResponse';
  id: Scalars['ID'];
  tickets: Array<Maybe<Ticket>>;
  totalTickets: Scalars['Int'];
};

export type ManagedReport = {
  __typename?: 'ManagedReport';
  employees: Array<CoreUser>;
  /** Manager Employee Id */
  id: Scalars['ID'];
  manager: CoreUser;
};

export enum MessageGroupType {
  Custom = 'CUSTOM',
  Inactive = 'INACTIVE',
  SignatureRequired = 'SIGNATURE_REQUIRED'
}

export enum MessageType {
  Custom = 'CUSTOM',
  Default = 'DEFAULT'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept a cancellation offer */
  acceptOffer: Scalars['Boolean'];
  /** Attempts to accept a recommendation. If the acceptr meets the criteria, the recommendation is acceptd, setting the status to ACCEPTED. */
  acceptRecommendation: CoreRecommendation;
  /** Accept a cancellation trial offer */
  acceptTrialOffer: Scalars['Boolean'];
  addAutoassignHrManagerConfiguration: Array<AutoAssignHrManager>;
  addBusinessManagerConfiguration: Array<BusinessManager>;
  addCredits: AddCreditsResult;
  addSubscription: AddCreditsResult;
  adminEnqueueTicketForSync: Scalars['Boolean'];
  applyCorePayrollDiscountToCompanySubscription: Subscription;
  approvePayroll: Payroll;
  /** Attempts to approve a request. If the approver meets the criteria, the request is approved, setting the status to APPROVED. */
  approveRequest: CoreRequest;
  archiveCompanyPolicy?: Maybe<CorePolicy>;
  archivePolicy?: Maybe<CorePolicy>;
  assignUserToPolicies: Array<CoreDocument>;
  assignUsersToPolicies: Array<CoreUser>;
  assignWorkerRoles: CoreUser;
  attachResignationFiles: Resignation;
  attachTerminationFiles: Termination;
  backfillBambeePlusTopics: Scalars['Boolean'];
  /** Attempts to bulk approve requests. If the approver meets the criteria, the requests are approved, setting their statuses to APPROVED.If an approval fails, the operation continues and the error is returned in the response. */
  bulkApproveRequests: BulkRequestResult;
  /** Attempts to bulk cancel requests. If a cancellation fails, the operation continues and the error is returned in the response. */
  bulkCancelRequests: BulkRequestResult;
  bulkCompleteEnrollments: EnrollmentCompleted;
  bulkEarningsImport: BulkEarningsImportResult;
  bulkEnrollUsers: BulkEnrollUsersResponse;
  bulkMessageStaff: BulkMessageStaffResult;
  bulkPurchaseSeats: Array<PurchaseSeatsResponse>;
  /** Attempts to bulk reject requests. If the rejector meets the criteria, the requests are rejected, setting their statuses to REJECTED.If a rejection fails, the operation continues and the error is returned in the response. */
  bulkRejectRequests: BulkRequestResult;
  bulkSetupStaff: Scalars['Boolean'];
  cancelBambeePlus: BambeePlus;
  cancelNectarSubscription: Product;
  cancelPendingHotlineCall: Scalars['Boolean'];
  /** Attempts to cancel a recommendation. If canceler is the user who created the recommendation, the recommendation is cancelled, setting the status to CANCELLED. */
  cancelRecommendation: CoreRecommendation;
  /** Attempts to cancel a request. If canceler is the user who created the request, the request is cancelled, setting the status to CANCELLED. */
  cancelRequest: CoreRequest;
  cancelResignation: Resignation;
  cancelTermination: Termination;
  cancelTimeAttendance: Scalars['Boolean'];
  changeTrialEndDateForCoreSubscription: Subscription;
  checkSyncCompanies: CheckSyncCompanyFanoutResponse;
  /** Sync Company FROM check */
  checkSyncCompany?: Maybe<Company>;
  clearBenefits: Scalars['Boolean'];
  clearPayrolls: Scalars['Boolean'];
  /** Record that a user clicked on an action */
  clickedOnNotificationAction: Scalars['Boolean'];
  completeCompanyPractice: Practice;
  completeCompanySurvey: Survey;
  completeGoal: CoreGoal;
  /** Completes a job description. Will no longer be editable. */
  completeJobDescription: JobDescription;
  completeReportCard: ReportCard;
  completeTask?: Maybe<CoreTask>;
  confirmMicroDeposit: BillingPaymentMethod;
  confirmResignation: Resignation;
  confirmTermination: Termination;
  confirmUserMfaEnrollment: AuthMfaConfig;
  createBundle: BundleResponse;
  createCancellationRequest: CancellationModel;
  createChallenge: AuthMfaChallenge;
  createCompany: Company;
  createCompanyInCheck: Company;
  createCompanyRoles: Array<CompanyRole>;
  createEnrollmentProfile: Scalars['Boolean'];
  createHrRequest: HrRequest;
  createHrRequestByInternalUser: HrRequest;
  createHrRequestFromSalesforceCase: HrRequest;
  createHrRequestsFromIntercomConversations: ListHrRequestsResponse;
  createInitialPayrollsBackfill: Scalars['Boolean'];
  /** Creates a new job description */
  createJobDescription: JobDescription;
  createPaySchedule: PaySchedule;
  createPayroll: Payroll;
  createPayrollSubscription: Subscription;
  createPlanLock: PlanLock;
  createProductInterest: ProductInterest;
  createReportCard: NewReportCard;
  createResignation: Resignation;
  createSignatoryInCheck: Company;
  createTermination: Termination;
  createVoicesFeedback: VoicesFeedback;
  createWebinar: Webinar;
  createWorkplace: Workplace;
  /** Attempts to decline a recommendation. If the declineor meets the criteria, the recommendation is declined, setting the status to DECLINED. */
  declineRecommendation: CoreRecommendation;
  deleteBundle: DeleteBundleResponse;
  deleteCompanyRolesById?: Maybe<Array<CompanyRole>>;
  deleteContractor: Contractor;
  deleteEmployee: Employee;
  /** Soft delete a job description */
  deleteJobDescription: JobDescription;
  deletePayroll: Payroll;
  /** Enable Deputy support for the install associated to input companyId */
  disableDeputySupport: Scalars['Boolean'];
  disableMfaEnrollment: Array<AuthMfaConfig>;
  /** Dismiss a notification */
  dismissNotification: Notification;
  /** Upgrade a deputy install for a company. If successful, will trigger the creation of workplaces and the upsert of users in deputy */
  downgradeInstall?: Maybe<DeputyConnection>;
  editResignationDetails: Resignation;
  editTerminationDetails: Termination;
  /** Enable Deputy support for the install associated to input companyId */
  enableDeputySupport: Scalars['Boolean'];
  enqueueHotlineCall: Scalars['Boolean'];
  enrollMfaDevice: AuthMfaChallenge;
  enrollUsers: EnrollUsersResponse;
  existingCompanyPurchasedPayroll: Scalars['Boolean'];
  extendBambeePlusOnboardingDueDate: BambeePlus;
  extendBambeePlusOnboardingWithSpecificDate: BambeePlus;
  finchManualSetupCompleted: Scalars['Boolean'];
  forceInitializeUserGroups: Scalars['Boolean'];
  /** Calls our ai provider to generate a job description powered by AI taking user input into consideration */
  generateAiJobDescription: JobDescription;
  /** Kicks off the AI generation process in the background returning quickly with a partial job description */
  generateAiJobDescriptionV2: JobDescription;
  generateCheckComponentLink: CheckComponentLink;
  generateCompanyOnboardingLink: Scalars['String'];
  /** Generates a link to the company's Payroll Report in Check */
  generateCompanyPayrollReportLink: Scalars['String'];
  generateContractorOnboardingLink: Scalars['String'];
  generateEmployeeOnboardingLink: Scalars['String'];
  /** Generates a link to the employee's post-tax deductions page in Check */
  generateEmployeePostTaxDeductionsLink: Scalars['String'];
  ignoreEligiblityAndPurchaseBambeePlus: BambeePlus;
  importTimesheets: DeputySyncInfo;
  initializePayroll: Payroll;
  markCourseTicketAsIncomplete: Ticket;
  migrateUsersToMultiplePayRates: Scalars['Boolean'];
  optInToElectronicDeliveryInCheck: Scalars['Boolean'];
  overrideCheckOnboarding: Scalars['Boolean'];
  performAutoHrmPodAssignment: Scalars['Boolean'];
  /** Ping deputy to test access token, and refresh if necessary */
  pingDeputy: PingDeputyResponse;
  previewPayroll: Payroll;
  productInterestNotificationDismissedInApp: ProductInterest;
  /** Provision a deputy install for a company. If successful, will trigger the creation of workplaces and the upsert of users in deputy */
  provisionDeputyInstall: DeputyConnection;
  purchaseBambeePlus: BambeePlus;
  purchaseCredits: PurchaseResult;
  purchaseSeats: PurchaseSeatsResponse;
  /** @deprecated Use `purchaseSeats` mutation instead */
  purchaseSeatsForBundle: PurchaseSeatsResponse;
  purchaseSubscription: PurchaseResult;
  /** @deprecated Use `purchaseSeats` mutation instead */
  purchaseTickets: PurchaseTicketsResponse;
  purchaseTimeAttendance: TimeAttendanceSubscriptionResponse;
  qaDeleteWebinar: Scalars['Boolean'];
  qaMutateDocumentAsUnsigned?: Maybe<CoreDocument>;
  qaMutateFeedsForCurrentDocumentStatus: Array<Feed>;
  qaMutatePolicyIncomplete?: Maybe<CorePolicy>;
  qaMutateRemoveManagerFeedsForDocument: Array<Feed>;
  quickbooksSync?: Maybe<SyncAttempt>;
  /** A generic reaction to a recommendation. If the reactor meets the criteria, the reaction is recorded and the recommendation status may be updated */
  reactToRecommendation: CoreRecommendation;
  reactivatePausedCompany: Scalars['Boolean'];
  reaffirmDocument: CoreDocument;
  /** Creates a generic recommendation based on the input provided. This is a raw recommendation and does not have any business logic applied to it. Admins only */
  recommendation: CoreRecommendation;
  refundCreditPurchase: RefundResult;
  registerForWebinar: WebinarRegistration;
  reinstateEmployee: Resignation;
  /** Attempts to reject a request. If the rejector meets the criteria, the request is rejected, setting the status to REJECTED. */
  rejectRequest: CoreRequest;
  remove: RemoveCompanyTrainingResponse;
  removeActiveCredits: Array<Credit>;
  removeAutoassignHrManagerConfiguration: Array<AutoAssignHrManager>;
  removeBusinessManagerConfiguration: Array<BusinessManager>;
  removeCourseCertificate?: Maybe<QaTrainingCertificates>;
  removePaymentMethod: Scalars['Boolean'];
  removeS3fileFromCourseCertificate?: Maybe<QaTrainingCertificates>;
  removeSeats: RemoveSeatsResponse;
  /** @deprecated Use `removeSeats` mutation instead */
  removeSeatsFromBundle: RemoveSeatsResponse;
  reopenPayroll: Payroll;
  replyToConversation: Conversation;
  replyToHrRequestAsAdmin: HrRequest;
  /** Creates a generic request based on the input provided. This is a raw request and does not have any business logic applied to it. Admins only */
  request: CoreRequest;
  requestPayrollCancellation: Scalars['Boolean'];
  resendChallenge: AuthMfaChallenge;
  resetCompany: Scalars['Boolean'];
  /** Reset deputy locations and departments */
  resetDeputy: Scalars['Boolean'];
  saveCompanyAnswer: Survey;
  saveCompanySetting: CompanySetting;
  saveComplexPayrollOnboarding: Scalars['Boolean'];
  /** Saves job description latest state associating to an employee if not already associated */
  saveJobDescriptionOnEmployee: EmployeeJobDescription;
  scratchToggleServiceAddon: ScratchServiceAddonsModel;
  sendFeedbackMessage: ChatMessageInterface;
  /** Finalizes a job description and sends it for acknowledgment to an employee */
  sendJobDescriptionToEmployee: EmployeeJobDescription;
  sendMessageToUser: ChatMessageInterface;
  sendReplyToUserThread: ChatMessageInterface;
  sendStaffOnboardInvite: Scalars['Boolean'];
  setDefaultPaymentMethod: BillingPaymentMethod;
  setupContractor: Contractor;
  setupEmployee: Employee;
  /** User acknowleges the job description sent by employer */
  signEmployeeJobDescription: EmployeeJobDescription;
  startTask: CoreTask;
  submitAiPrompt: AiPromptResponse;
  sync: SyncResponse;
  /** Sync Company TO check */
  syncCompany: Company;
  syncContractor: Contractor;
  syncDocuments?: Maybe<Array<CoreDocument>>;
  syncEmployee: Employee;
  syncPayroll: Payroll;
  syncRequirements: Array<Requirement>;
  syncTimesheets: DeputySyncInfo;
  syncUserEnrollments: SyncUserEnrollmentsResponse;
  syncWorkerRoles: Scalars['Boolean'];
  track: AnalyticEventTracked;
  trackProductInterest: Scalars['String'];
  /** Creates a training recommendation based on the input provided */
  trainingRecommendation: CoreRecommendation;
  /** Creates a training request based on the input provided */
  trainingRequest: CoreRequest;
  triggerFixUserBundleEnrollments: EnrollUserResponse;
  triggerPayrollRunSNSTopicPublish: Scalars['Boolean'];
  unenrollCompanyBundle: Scalars['Boolean'];
  unenrollUsers: UnenrolledUsers;
  /** @deprecated use unenrollUsers */
  unenrollUsersFromBundle: UnenrolledUsers;
  updateApprovalDeadline: Payroll;
  updateCompany: Company;
  updateCompanyNotes: Company;
  updateCompanyRoles: Array<CompanyRole>;
  updateCompanyStatus: Company;
  updateContractorPayment: ContractorPayment;
  updateContractorWorkplace: Contractor;
  updateCoreCompany: CoreCompany;
  updateCoreUserEmployment: CoreUserEmployment;
  updateCoreUserProfile: CoreUserProfile;
  updateCoreUserState: CoreUserStates;
  updateEmployeeWorkplace: Employee;
  /** Updates the title and body of a job description that is still in draft mode. */
  updateJobDescription: JobDescription;
  updatePaySchedule: PaySchedule;
  updatePaymentSourceBillingAddress: PaymentSource;
  updatePayroll: Payroll;
  updatePayrollChecks: Payroll;
  updatePayrollItem: PayrollItem;
  updatePlanLock: PlanLock;
  updateReportCard: ReportCard;
  updateWorkplace: Workplace;
  /** Upgrade a company subscription from one plan to another one. */
  upgradeCorePlan: Subscription;
  upgradeFinchCode: CompanyFinchAccessToken;
  /** Upgrade a deputy install for a company. If successful, will trigger the creation of workplaces and the upsert of users in deputy */
  upgradeInstall?: Maybe<DeputyConnection>;
  uploadFile: Array<Goal>;
  /** Trigger the upsert of users in deputy */
  upsertDeputyAreas: Scalars['Boolean'];
  /** Upsert a deputy connection for a company. If successful, will trigger the creation of workplaces and the upsert of users in deputy */
  upsertDeputyConnection?: Maybe<DeputyConnection>;
  /** Trigger the upsert of users in deputy */
  upsertDeputyLocations: Scalars['Boolean'];
  /** Trigger the upsert of users in deputy */
  upsertDeputyUsers: Scalars['Boolean'];
  /** Creates a generic onboarding based on the input provided. This is a raw onboarding and does not have any business logic applied to it. Admins only */
  upsertOnboarding: CoreOnboarding;
  userToken: DoceboTokenResponse;
  verifyFEINInCheck?: Maybe<Company>;
};


export type MutationAcceptOfferArgs = {
  input: AcceptOfferInput;
};


export type MutationAcceptRecommendationArgs = {
  data: AcceptRecommendationInput;
};


export type MutationAcceptTrialOfferArgs = {
  input: AcceptTrialOfferInput;
};


export type MutationAddAutoassignHrManagerConfigurationArgs = {
  input: AutoAssignHrManagerInput;
};


export type MutationAddBusinessManagerConfigurationArgs = {
  input: BusinessManagerForTierInput;
};


export type MutationAddCreditsArgs = {
  data: AddCreditsInput;
};


export type MutationAddSubscriptionArgs = {
  data: AddSubscriptionInput;
};


export type MutationAdminEnqueueTicketForSyncArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationApplyCorePayrollDiscountToCompanySubscriptionArgs = {
  input: ApplyCorePayrollDiscountInput;
};


export type MutationApprovePayrollArgs = {
  data: ApprovePayrollInput;
};


export type MutationApproveRequestArgs = {
  data: RequestApprovalInput;
};


export type MutationArchiveCompanyPolicyArgs = {
  input: ArchivePolicyInput;
};


export type MutationArchivePolicyArgs = {
  input: ArchivePolicyInput;
};


export type MutationAssignUserToPoliciesArgs = {
  data: AssignUserToPoliciesInput;
};


export type MutationAssignUsersToPoliciesArgs = {
  data: AssignUsersToPoliciesInput;
};


export type MutationAssignWorkerRolesArgs = {
  input: AssignWorkerRolesInput;
};


export type MutationAttachResignationFilesArgs = {
  data: ResignationAttachInput;
};


export type MutationAttachTerminationFilesArgs = {
  data: TerminationAttachInput;
};


export type MutationBackfillBambeePlusTopicsArgs = {
  input: BackfillBambeePlusTopicsInput;
};


export type MutationBulkApproveRequestsArgs = {
  data: Array<RequestApprovalInput>;
};


export type MutationBulkCancelRequestsArgs = {
  data: Array<RequestCancelInput>;
};


export type MutationBulkCompleteEnrollmentsArgs = {
  data: BulkCompleteEnrollmentInput;
};


export type MutationBulkEarningsImportArgs = {
  data: BulkEarningsImportInput;
};


export type MutationBulkEnrollUsersArgs = {
  data: BulkEnrollUsersInput;
};


export type MutationBulkMessageStaffArgs = {
  data: BulkMessageStaffInput;
};


export type MutationBulkPurchaseSeatsArgs = {
  data: BulkPurchaseSeatsInput;
};


export type MutationBulkRejectRequestsArgs = {
  data: Array<RequestRejectionInput>;
};


export type MutationBulkSetupStaffArgs = {
  data: BulkSetupStaffInput;
};


export type MutationCancelBambeePlusArgs = {
  input: PurchaseBambeePlusInput;
};


export type MutationCancelNectarSubscriptionArgs = {
  data: CancelSubscriptionInput;
};


export type MutationCancelRecommendationArgs = {
  data: CancelRecommendationInput;
};


export type MutationCancelRequestArgs = {
  data: RequestCancelInput;
};


export type MutationCancelResignationArgs = {
  data: ResignationCancelInput;
};


export type MutationCancelTerminationArgs = {
  data: TerminationCancelInput;
};


export type MutationCancelTimeAttendanceArgs = {
  data: CancelTimeAttendanceInput;
};


export type MutationChangeTrialEndDateForCoreSubscriptionArgs = {
  data: ChangeTrialEndDateForCoreSubscriptionInput;
};


export type MutationCheckSyncCompaniesArgs = {
  data: CheckSyncCompaniesInput;
};


export type MutationCheckSyncCompanyArgs = {
  data: CheckSyncCompanyInput;
};


export type MutationClearBenefitsArgs = {
  companyId: Scalars['ID'];
};


export type MutationClearPayrollsArgs = {
  data: ClearPayrollsInput;
};


export type MutationClickedOnNotificationActionArgs = {
  actionId: Scalars['String'];
};


export type MutationCompleteCompanyPracticeArgs = {
  data: CompletePracticeInput;
};


export type MutationCompleteCompanySurveyArgs = {
  data: CompleteCompanySurveyInput;
};


export type MutationCompleteGoalArgs = {
  data: CompleteGoalInput;
};


export type MutationCompleteJobDescriptionArgs = {
  id: Scalars['String'];
};


export type MutationCompleteReportCardArgs = {
  data: CompleteReportCardInput;
};


export type MutationCompleteTaskArgs = {
  data: CompleteTaskInput;
};


export type MutationConfirmMicroDepositArgs = {
  input: ConfirmMicroDepositInput;
};


export type MutationConfirmResignationArgs = {
  data: ResignationConfirmationInput;
};


export type MutationConfirmTerminationArgs = {
  data: TerminationConfirmationInput;
};


export type MutationConfirmUserMfaEnrollmentArgs = {
  input: AuthMfaChallengeResponseInput;
};


export type MutationCreateBundleArgs = {
  data: CreateBundleInput;
};


export type MutationCreateCancellationRequestArgs = {
  input: CreateCancellationRequestInput;
};


export type MutationCreateChallengeArgs = {
  reason: Scalars['String'];
  type: AuthMfaType;
};


export type MutationCreateCompanyArgs = {
  id: Scalars['ID'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type MutationCreateCompanyInCheckArgs = {
  data: CreateCompanyInCheckInput;
};


export type MutationCreateCompanyRolesArgs = {
  data: CreateMultipleRolesForCompanyInput;
};


export type MutationCreateEnrollmentProfileArgs = {
  data: CreateEnrollmentProfileRequest;
};


export type MutationCreateHrRequestArgs = {
  input: CreateHrRequestInput;
};


export type MutationCreateHrRequestByInternalUserArgs = {
  input: CreateHrRequestByInternalUserInput;
};


export type MutationCreateHrRequestFromSalesforceCaseArgs = {
  input: CreateHrRequestFromSalesforceCaseInput;
};


export type MutationCreateHrRequestsFromIntercomConversationsArgs = {
  input: CreateHrRequestsFromIntercomConversationsInput;
};


export type MutationCreateJobDescriptionArgs = {
  data: CreateJobDescriptionInput;
};


export type MutationCreatePayScheduleArgs = {
  data: CreatePayScheduleInput;
};


export type MutationCreatePayrollArgs = {
  data: CreatePayrollInput;
};


export type MutationCreatePayrollSubscriptionArgs = {
  data: CreatePayrollSubscriptionInput;
};


export type MutationCreatePlanLockArgs = {
  input: CreatePlanLockInput;
};


export type MutationCreateProductInterestArgs = {
  input: CreateProductInterestInput;
};


export type MutationCreateReportCardArgs = {
  data: CreateReportCardInput;
};


export type MutationCreateResignationArgs = {
  data: ResignationCreateInput;
};


export type MutationCreateSignatoryInCheckArgs = {
  companyCheckId: Scalars['String'];
  data: CreateSignatoryInput;
};


export type MutationCreateTerminationArgs = {
  data: TerminationCreateInput;
};


export type MutationCreateVoicesFeedbackArgs = {
  input: CreateVoicesFeedbackInput;
};


export type MutationCreateWebinarArgs = {
  input: CreateWebinarInstanceRequest;
};


export type MutationCreateWorkplaceArgs = {
  data: CreateWorkplaceInput;
};


export type MutationDeclineRecommendationArgs = {
  data: DeclineRecommendationInput;
};


export type MutationDeleteBundleArgs = {
  data: DeleteBundleInput;
};


export type MutationDeleteCompanyRolesByIdArgs = {
  data: DeleteCompanyRolesInput;
};


export type MutationDeleteContractorArgs = {
  data: DeleteContractorInput;
};


export type MutationDeleteEmployeeArgs = {
  data: DeleteEmployeeInput;
};


export type MutationDeleteJobDescriptionArgs = {
  id: Scalars['String'];
};


export type MutationDeletePayrollArgs = {
  data: DeletePayrollInput;
};


export type MutationDisableDeputySupportArgs = {
  data: DisableDeputySupportInput;
};


export type MutationDisableMfaEnrollmentArgs = {
  authId?: InputMaybe<Scalars['ID']>;
  enrollmentId: Scalars['ID'];
};


export type MutationDismissNotificationArgs = {
  id: Scalars['String'];
};


export type MutationDowngradeInstallArgs = {
  data: DowngradeDeputyInstallInput;
};


export type MutationEditResignationDetailsArgs = {
  data: ResignationEditDetailsInput;
};


export type MutationEditTerminationDetailsArgs = {
  data: TerminationEditDetailsInput;
};


export type MutationEnableDeputySupportArgs = {
  data: EnableDeputySupportInput;
};


export type MutationEnqueueHotlineCallArgs = {
  data: EnqueueHotlineCallInput;
};


export type MutationEnrollMfaDeviceArgs = {
  input: AuthMfaSmsEnrollmentInput;
};


export type MutationEnrollUsersArgs = {
  data: EnrollUsersInput;
};


export type MutationExistingCompanyPurchasedPayrollArgs = {
  id: Scalars['ID'];
};


export type MutationExtendBambeePlusOnboardingDueDateArgs = {
  input: PurchaseBambeePlusInput;
};


export type MutationExtendBambeePlusOnboardingWithSpecificDateArgs = {
  input: ExtendBambeePlusOnboardingToSpecificDate;
};


export type MutationFinchManualSetupCompletedArgs = {
  data: FinchManualSetupCompletedInput;
};


export type MutationGenerateAiJobDescriptionArgs = {
  data: GenerateAiJobDescriptionInput;
};


export type MutationGenerateAiJobDescriptionV2Args = {
  data: GenerateAiJobDescriptionInputV2;
};


export type MutationGenerateCheckComponentLinkArgs = {
  data: CheckComponentInput;
};


export type MutationGenerateCompanyOnboardingLinkArgs = {
  data: GenerateCompanyOnboardingLinkInput;
};


export type MutationGenerateCompanyPayrollReportLinkArgs = {
  id: Scalars['ID'];
};


export type MutationGenerateContractorOnboardingLinkArgs = {
  id: Scalars['ID'];
};


export type MutationGenerateEmployeeOnboardingLinkArgs = {
  id: Scalars['ID'];
};


export type MutationGenerateEmployeePostTaxDeductionsLinkArgs = {
  id: Scalars['ID'];
};


export type MutationIgnoreEligiblityAndPurchaseBambeePlusArgs = {
  input: PurchaseBambeePlusInput;
};


export type MutationImportTimesheetsArgs = {
  data: ImportTimesheetsInput;
};


export type MutationInitializePayrollArgs = {
  data: InitializePayrollInput;
};


export type MutationMarkCourseTicketAsIncompleteArgs = {
  id: Scalars['String'];
};


export type MutationOptInToElectronicDeliveryInCheckArgs = {
  data: OptInToElectronicDeliveryInCheckInput;
};


export type MutationOverrideCheckOnboardingArgs = {
  data: OverrideCheckOnboardingInput;
};


export type MutationPerformAutoHrmPodAssignmentArgs = {
  input: PerformAutoHrmPodAssignmentInput;
};


export type MutationPingDeputyArgs = {
  data: PingDeputyInput;
};


export type MutationPreviewPayrollArgs = {
  id: Scalars['ID'];
};


export type MutationProductInterestNotificationDismissedInAppArgs = {
  input: UpdateProductInterestWasAppNotificationDismissedInput;
};


export type MutationProvisionDeputyInstallArgs = {
  data: ProvisionDeputyInstallInput;
};


export type MutationPurchaseBambeePlusArgs = {
  input: PurchaseBambeePlusInput;
};


export type MutationPurchaseCreditsArgs = {
  data: PurchaseInput;
};


export type MutationPurchaseSeatsArgs = {
  data: PurchaseSeatsInput;
};


export type MutationPurchaseSeatsForBundleArgs = {
  data: PurchaseBundleSeatsInput;
};


export type MutationPurchaseSubscriptionArgs = {
  data: PurchaseSubscriptionInput;
};


export type MutationPurchaseTicketsArgs = {
  data: TicketsInput;
};


export type MutationPurchaseTimeAttendanceArgs = {
  data: PurchaseTimeAttendanceInput;
};


export type MutationQaDeleteWebinarArgs = {
  id: Scalars['ID'];
};


export type MutationQaMutateDocumentAsUnsignedArgs = {
  documentId: Scalars['ID'];
  who?: QaPolicyViewer;
};


export type MutationQaMutateFeedsForCurrentDocumentStatusArgs = {
  documentId: Scalars['ID'];
};


export type MutationQaMutatePolicyIncompleteArgs = {
  policyId: Scalars['ID'];
};


export type MutationQaMutateRemoveManagerFeedsForDocumentArgs = {
  documentId: Scalars['ID'];
};


export type MutationQuickbooksSyncArgs = {
  data: SyncAttemptInput;
};


export type MutationReactToRecommendationArgs = {
  data: RecommendationReactionInput;
};


export type MutationReactivatePausedCompanyArgs = {
  input: ReactivatePausedCompanyInput;
};


export type MutationReaffirmDocumentArgs = {
  id: Scalars['ID'];
};


export type MutationRecommendationArgs = {
  data: RecommendationInput;
};


export type MutationRefundCreditPurchaseArgs = {
  data: RefundCreditsInput;
};


export type MutationRegisterForWebinarArgs = {
  id: Scalars['ID'];
  userID?: InputMaybe<Scalars['ID']>;
};


export type MutationReinstateEmployeeArgs = {
  data: ReinstateEmployeeInput;
};


export type MutationRejectRequestArgs = {
  data: RequestRejectionInput;
};


export type MutationRemoveArgs = {
  data: RemoveCompanyTrainingInput;
};


export type MutationRemoveActiveCreditsArgs = {
  data: RemoveCreditsInput;
};


export type MutationRemoveAutoassignHrManagerConfigurationArgs = {
  input: RemoveAutoAssignHrManagerInput;
};


export type MutationRemoveBusinessManagerConfigurationArgs = {
  input: BusinessManagerForTierInput;
};


export type MutationRemoveCourseCertificateArgs = {
  id: Scalars['String'];
};


export type MutationRemovePaymentMethodArgs = {
  input: RemovePaymentMethodInput;
};


export type MutationRemoveS3fileFromCourseCertificateArgs = {
  id: Scalars['String'];
};


export type MutationRemoveSeatsArgs = {
  data: RemoveSeatsInput;
};


export type MutationRemoveSeatsFromBundleArgs = {
  data: RemoveBundleSeatsInput;
};


export type MutationReopenPayrollArgs = {
  data: ReopenPayrollInput;
};


export type MutationReplyToConversationArgs = {
  input: ReplyToConversationInput;
};


export type MutationReplyToHrRequestAsAdminArgs = {
  input: ReplyToHrRequestAsAdminInput;
};


export type MutationRequestArgs = {
  data: RequestInput;
};


export type MutationRequestPayrollCancellationArgs = {
  data: PayrollCancellationRequestInput;
};


export type MutationResendChallengeArgs = {
  nonceId: Scalars['String'];
  reason: Scalars['String'];
  type: AuthMfaType;
};


export type MutationResetCompanyArgs = {
  data: ResetCompanyInput;
};


export type MutationResetDeputyArgs = {
  data: ResetDeputyInput;
};


export type MutationSaveCompanyAnswerArgs = {
  data: SaveCompanyAnswerInput;
};


export type MutationSaveCompanySettingArgs = {
  input: SaveCompanySettingInput;
};


export type MutationSaveComplexPayrollOnboardingArgs = {
  data: ComplexPayrollOnboardingInput;
};


export type MutationSaveJobDescriptionOnEmployeeArgs = {
  data: SaveJobDescriptionOnEmployeeInput;
};


export type MutationScratchToggleServiceAddonArgs = {
  input: ScratchToggleServiceAddonInput;
};


export type MutationSendFeedbackMessageArgs = {
  id: Scalars['ID'];
  message: FeedbackMessageInput;
};


export type MutationSendJobDescriptionToEmployeeArgs = {
  data: SendJobDescriptionToEmployeeInput;
};


export type MutationSendMessageToUserArgs = {
  id: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationSendReplyToUserThreadArgs = {
  message: Scalars['String'];
  parentId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationSendStaffOnboardInviteArgs = {
  data: SendStaffOnboardInviteInput;
};


export type MutationSetDefaultPaymentMethodArgs = {
  input: SetDefaultPaymentMethodInput;
};


export type MutationSetupContractorArgs = {
  data: SetupContractorInput;
};


export type MutationSetupEmployeeArgs = {
  data: SetupEmployeeInput;
};


export type MutationSignEmployeeJobDescriptionArgs = {
  id: Scalars['String'];
};


export type MutationStartTaskArgs = {
  id: Scalars['String'];
};


export type MutationSubmitAiPromptArgs = {
  data: AiPromptInput;
};


export type MutationSyncCompanyArgs = {
  data: SyncCompanyInput;
};


export type MutationSyncContractorArgs = {
  data: SyncContractorInput;
};


export type MutationSyncDocumentsArgs = {
  input: PandadocDocumentSyncInput;
};


export type MutationSyncEmployeeArgs = {
  data: SyncEmployeeInput;
};


export type MutationSyncPayrollArgs = {
  id: Scalars['ID'];
};


export type MutationSyncRequirementsArgs = {
  data: SyncRequirementInput;
};


export type MutationSyncTimesheetsArgs = {
  data: SyncTimesheetsInput;
};


export type MutationSyncUserEnrollmentsArgs = {
  data: SyncUserEnrollmentInput;
};


export type MutationTrackArgs = {
  data: AnalyticEvent;
};


export type MutationTrackProductInterestArgs = {
  data: ProductInterestInput;
};


export type MutationTrainingRecommendationArgs = {
  data: TrainingRecommendationInput;
};


export type MutationTrainingRequestArgs = {
  data: TrainingRequestInput;
};


export type MutationTriggerFixUserBundleEnrollmentsArgs = {
  userId: Scalars['ID'];
};


export type MutationTriggerPayrollRunSnsTopicPublishArgs = {
  data: TriggerPayrollRunSnsTopicPublishInput;
};


export type MutationUnenrollCompanyBundleArgs = {
  bundleId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  topicId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type MutationUnenrollUsersArgs = {
  data: UnenrollUsersInput;
};


export type MutationUnenrollUsersFromBundleArgs = {
  data: UnenrollUsersFromBundleInput;
};


export type MutationUpdateApprovalDeadlineArgs = {
  data: UpdatePayrollApprovalDeadlineInput;
};


export type MutationUpdateCompanyArgs = {
  data: UpdateCompanyInput;
};


export type MutationUpdateCompanyNotesArgs = {
  data: UpdateCompanyNotesInput;
};


export type MutationUpdateCompanyRolesArgs = {
  data: UpdateMultipleRolesForCompanyInput;
};


export type MutationUpdateCompanyStatusArgs = {
  data: UpdateCompanyStatusInput;
};


export type MutationUpdateContractorPaymentArgs = {
  data: UpdateContractorPaymentInput;
};


export type MutationUpdateContractorWorkplaceArgs = {
  data: UpdateContractorWorkplaceInput;
};


export type MutationUpdateCoreCompanyArgs = {
  data: UpdateCoreCompanyInput;
};


export type MutationUpdateCoreUserEmploymentArgs = {
  id: Scalars['ID'];
  input: CoreUserEmploymentInput;
};


export type MutationUpdateCoreUserProfileArgs = {
  id: Scalars['ID'];
  input: CoreUserProfileInput;
};


export type MutationUpdateCoreUserStateArgs = {
  input: CoreUserStatesInput;
};


export type MutationUpdateEmployeeWorkplaceArgs = {
  data: UpdateEmployeeWorkplaceInput;
};


export type MutationUpdateJobDescriptionArgs = {
  data: UpdateJobDescriptionInput;
};


export type MutationUpdatePayScheduleArgs = {
  data: UpdatePayScheduleInput;
};


export type MutationUpdatePaymentSourceBillingAddressArgs = {
  input: UpdatePaymentSourceBillingAddress;
};


export type MutationUpdatePayrollArgs = {
  data: UpdatePayrollInput;
};


export type MutationUpdatePayrollChecksArgs = {
  data: UpdatePayrollChecksInput;
};


export type MutationUpdatePayrollItemArgs = {
  data: UpdatePayrollItemInput;
};


export type MutationUpdatePlanLockArgs = {
  input: UpdatePlanLockInput;
};


export type MutationUpdateReportCardArgs = {
  data: UpdateReportCardInput;
};


export type MutationUpdateWorkplaceArgs = {
  data: UpdateWorkplaceInput;
};


export type MutationUpgradeCorePlanArgs = {
  input: UpgradeCorePlanInput;
};


export type MutationUpgradeFinchCodeArgs = {
  data: UserFinchCodeInput;
};


export type MutationUpgradeInstallArgs = {
  data: UpgradeDeputyInstallInput;
};


export type MutationUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationUpsertDeputyAreasArgs = {
  data: UpsertDeputyAreasInput;
};


export type MutationUpsertDeputyConnectionArgs = {
  data: UpsertDeputyConnectionInput;
};


export type MutationUpsertDeputyLocationsArgs = {
  data: UpsertDeputyLocationsInput;
};


export type MutationUpsertDeputyUsersArgs = {
  data: UpsertDeputyUsersInput;
};


export type MutationUpsertOnboardingArgs = {
  data: CoreOnboardingInput;
};


export type MutationVerifyFeinInCheckArgs = {
  data: VerifyFeinInCheckInput;
};

export type MyRecommendationFilterInput = {
  /** The target demographic of the recommendation */
  demographic?: InputMaybe<RecommendationDemographic>;
  /** The description of the recommendation. */
  note?: InputMaybe<Scalars['String']>;
  /** The id of the resource. */
  resourceId?: InputMaybe<Scalars['String']>;
  /** The resource type of the recommendation. */
  resourceType?: InputMaybe<RequestResourceType>;
  /** The scope of the recommendation */
  scope?: InputMaybe<RecommendationScope>;
  /** The status of the recommendation. */
  status?: InputMaybe<RecommendationStatus>;
  /** The targets of the recommendation */
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MyRequestFilterInput = {
  /** The description of the request. */
  description?: InputMaybe<Scalars['String']>;
  /** The id of the resource. */
  resourceId?: InputMaybe<Scalars['String']>;
  /** The resource type of the request. */
  resourceType?: InputMaybe<RequestResourceType>;
  /** The status of the request. */
  status?: InputMaybe<RequestStatus>;
};

export type MyTrainingRecommendationFilterInput = {
  /** The target demographic of the recommendation */
  demographic?: InputMaybe<RecommendationDemographic>;
  /** The description of the recommendation. */
  note?: InputMaybe<Scalars['String']>;
  /** The id of the resource. */
  resourceId?: InputMaybe<Scalars['String']>;
  /** The status of the recommendation. */
  status?: InputMaybe<RecommendationStatus>;
  /** The targets of the recommendation */
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MyTrainingRequestFilterInput = {
  /** The description of the request. */
  description?: InputMaybe<Scalars['String']>;
  /** The id of the resource. */
  resourceId?: InputMaybe<Scalars['String']>;
  /** The status of the request. */
  status?: InputMaybe<RequestStatus>;
};

export type NewReportCard = {
  __typename?: 'NewReportCard';
  /** new report created when is false */
  existing: Scalars['Boolean'];
  report: ReportCard;
};

/** The next action for onboarding */
export enum NextOnboardingAction {
  ExploreOnYourOwn = 'EXPLORE_ON_YOUR_OWN',
  MeetHrManager = 'MEET_HR_MANAGER',
  ScheduleConsultation = 'SCHEDULE_CONSULTATION'
}

export type Notification = {
  __typename?: 'Notification';
  actions?: Maybe<Array<NotificationAction>>;
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dismissedAt?: Maybe<Scalars['DateTime']>;
  dismissedBy?: Maybe<Scalars['String']>;
  dismissible: Scalars['Boolean'];
  id: Scalars['ID'];
  level: NotificationLevel;
  metadata?: Maybe<Scalars['JSON']>;
  subject?: Maybe<Scalars['String']>;
  targets: Array<NotificationTarget>;
  userId: Scalars['String'];
};

export type NotificationAction = {
  __typename?: 'NotificationAction';
  id: Scalars['ID'];
  label: Scalars['String'];
  type: NotificationActionType;
  value: Scalars['String'];
};

export enum NotificationActionType {
  Event = 'EVENT',
  Link = 'LINK'
}

export enum NotificationLevel {
  Error = 'ERROR',
  Notice = 'NOTICE',
  Warning = 'WARNING'
}

export type NotificationTarget = {
  __typename?: 'NotificationTarget';
  id: Scalars['ID'];
  type: NotificationTargetType;
};

export enum NotificationTargetType {
  Dashboard = 'DASHBOARD',
  Global = 'GLOBAL'
}

export type NumberNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
};

export type OffcycleOptions = {
  __typename?: 'OffcycleOptions';
  /** Whether to apply benefit deductions and contributions to the payroll. */
  applyBenefits?: Maybe<Scalars['Boolean']>;
  /** Whether to apply post-tax deductions to the payroll. */
  applyPostTaxDeductions?: Maybe<Scalars['Boolean']>;
  /** Whether to tax all earnings as supplemental earnings. */
  forceSupplementalWithholding?: Maybe<Scalars['Boolean']>;
};

export type OffcycleOptionsInput = {
  /** Whether to apply benefit deductions and contributions to the payroll. */
  applyBenefits?: InputMaybe<Scalars['Boolean']>;
  /** Whether to apply post-tax deductions to the payroll. */
  applyPostTaxDeductions?: InputMaybe<Scalars['Boolean']>;
  /** Whether to tax all earnings as supplemental earnings. */
  forceSupplementalWithholding?: InputMaybe<Scalars['Boolean']>;
};

export enum OnboardStatus {
  Blocking = 'BLOCKING',
  Completed = 'COMPLETED',
  NeedsAttention = 'NEEDS_ATTENTION'
}

export type OnboardingCalendlyConfig = {
  __typename?: 'OnboardingCalendlyConfig';
  link: Scalars['String'];
  tierNumber: Scalars['Float'];
  zone: SchedulerZone;
};

export type OnboardingCalendlyConfigInput = {
  tierNumber: Scalars['Float'];
  zip: Scalars['String'];
};

export enum OnboardingGarnishmentOrDeduction {
  Alimony = 'ALIMONY',
  CharitableContributions = 'CHARITABLE_CONTRIBUTIONS',
  ChildSupportGarnishments = 'CHILD_SUPPORT_GARNISHMENTS',
  EmployerLoanRepayment = 'EMPLOYER_LOAN_REPAYMENT',
  FederalTaxLiens = 'FEDERAL_TAX_LIENS',
  Other = 'OTHER'
}

/** The status of the Onboarding */
export enum OnboardingStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Skipped = 'SKIPPED'
}

export type OptInToElectronicDeliveryInCheckInput = {
  /** Bambee employee IDs */
  employeeIds: Array<Scalars['ID']>;
};

export enum OrderByDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type OverrideCheckOnboardingInput = {
  /** Bambee company id */
  companyId?: InputMaybe<Scalars['ID']>;
  /** Bambee contractor id */
  contractorId?: InputMaybe<Scalars['ID']>;
  /** Bambee employee id */
  employeeId?: InputMaybe<Scalars['ID']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PaginatedCoreUsers = {
  __typename?: 'PaginatedCoreUsers';
  id: Scalars['ID'];
  /** Page number to be used for pagination */
  page: Scalars['Float'];
  /** Actual list of users */
  result: Array<CoreUser>;
  /** Total count of all users */
  totalCount: Scalars['Float'];
  /** Total number of pages based on limit passed */
  totalPages: Scalars['Float'];
};

export type PandadocDocumentSyncInput = {
  documentIds: Array<Scalars['ID']>;
};

export enum PastPayrollUsage {
  HaveCurrentPayrollProvider = 'HAVE_CURRENT_PAYROLL_PROVIDER',
  NewToPayingEmployees = 'NEW_TO_PAYING_EMPLOYEES',
  NewToPayrollWithCurrentCompany = 'NEW_TO_PAYROLL_WITH_CURRENT_COMPANY'
}

export type Paused = {
  __typename?: 'Paused';
  id: Scalars['ID'];
  isPaused: Scalars['Boolean'];
  reactivatesOn?: Maybe<Scalars['DateTime']>;
};

export enum PayFrequency {
  Annually = 'ANNUALLY',
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Semimonthly = 'SEMIMONTHLY',
  Weekly = 'WEEKLY'
}

export type PaySchedule = {
  __typename?: 'PaySchedule';
  checkId: Scalars['ID'];
  default: Scalars['Boolean'];
  firstPayday: Scalars['DateTime'];
  firstPeriodEnd: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  payFrequency: PayScheduleFrequency;
  /** The payday date of the the second payroll the company will run on this pay schedule. This field should only be used with the semimonthly frequency to specify custom paydays besides the 15th and last day of the month. second_payday must be between one day and one month after first_payday. */
  secondPayday?: Maybe<Scalars['DateTime']>;
};

export enum PayScheduleFrequency {
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Semimonthly = 'SEMIMONTHLY',
  Weekly = 'WEEKLY'
}

export enum PayType {
  Contractor = 'contractor',
  Empty = 'empty',
  Hourly = 'hourly',
  Salary = 'salary'
}

export enum Payer {
  Company = 'COMPANY',
  Employee = 'EMPLOYEE'
}

export type PaymentEntry = {
  __typename?: 'PaymentEntry';
  /** Amount earned for this entry */
  amount?: Maybe<Scalars['Float']>;
  /** The decimal value of the inputted number of hours worked */
  decimal?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Number of hours worked for this entry */
  minutes?: Maybe<Scalars['Float']>;
  /** Source of PaymentEntry */
  source: SourceType;
  /** Type of entry. */
  type: PaymentEntryType;
  /** Worker role id to be applied to this item */
  workerRoleId?: Maybe<Scalars['ID']>;
};

export type PaymentEntryInput = {
  /** Amount earned for this earning */
  amount?: InputMaybe<Scalars['Float']>;
  /** The decimal value of the inputted number of hours worked */
  decimal?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  /** Number of hours worked for this earning */
  minutes?: InputMaybe<Scalars['Float']>;
  /** Source of PaymentEntry */
  source?: InputMaybe<SourceType>;
  /** Type of entry. */
  type: PaymentEntryType;
  /** Worker role id to be applied to this item */
  workerRoleId?: InputMaybe<Scalars['ID']>;
};

export enum PaymentEntryType {
  AmountTime = 'AMOUNT_TIME',
  AmountValue = 'AMOUNT_VALUE',
  Reimbursement = 'REIMBURSEMENT'
}

export enum PaymentMethod {
  DirectDeposit = 'DIRECT_DEPOSIT',
  Manual = 'MANUAL'
}

export enum PaymentMethodIssueReason {
  DefaultAchTransfer = 'DEFAULT_ACH_TRANSFER',
  DefaultDebitCard = 'DEFAULT_DEBIT_CARD',
  DefaultExpired = 'DEFAULT_EXPIRED',
  DefaultInvalid = 'DEFAULT_INVALID',
  DefaultUpcomingExpiration = 'DEFAULT_UPCOMING_EXPIRATION',
  Other = 'OTHER'
}

export enum PaymentMethodNextAction {
  MicroDepositValidation = 'MICRO_DEPOSIT_VALIDATION',
  MicroDepositValidationDescriptor = 'MICRO_DEPOSIT_VALIDATION_DESCRIPTOR'
}

export enum PaymentMethodStatus {
  ActionRequired = 'ACTION_REQUIRED',
  Active = 'ACTIVE'
}

export enum PaymentMethodSteps {
  BankAccountExists = 'BANK_ACCOUNT_EXISTS',
  BankAccountVerified = 'BANK_ACCOUNT_VERIFIED',
  PaymentMethodPreferenceSet = 'PAYMENT_METHOD_PREFERENCE_SET'
}

export enum PaymentMethodType {
  BankAccount = 'BANK_ACCOUNT',
  Card = 'CARD',
  Unknown = 'UNKNOWN'
}

export type PaymentSource = {
  __typename?: 'PaymentSource';
  addressCity?: Maybe<Scalars['String']>;
  addressCountry?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressState?: Maybe<Scalars['String']>;
  addressZip?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  cvcCheck?: Maybe<Scalars['String']>;
  default: Scalars['Boolean'];
  dynamicLast4?: Maybe<Scalars['String']>;
  expMonth: Scalars['Float'];
  expYear: Scalars['Float'];
  funding?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  last4?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PaymentSources = {
  __typename?: 'PaymentSources';
  companyId: Scalars['String'];
  sources: Array<PaymentSource>;
};

export enum PaymentType {
  Hourly = 'HOURLY',
  Salary = 'SALARY'
}

export type Payroll = {
  __typename?: 'Payroll';
  /** The date by which the payroll must be approved. */
  approvalDeadline?: Maybe<Scalars['DateTime']>;
  /** Datetime at which the payroll was approved. */
  approvedAt?: Maybe<Scalars['DateTime']>;
  /** The check ID of the payroll. */
  checkId?: Maybe<Scalars['ID']>;
  /** The Bambee company ID associated with the payroll. */
  companyId: Scalars['ID'];
  contractorCount?: Maybe<Scalars['Int']>;
  contractorPayment?: Maybe<ContractorPayment>;
  /** @deprecated Use contractorPaymentsPage instead */
  contractorPayments: Array<ContractorPayment>;
  contractorPaymentsConnection: ContractorPaymentConnection;
  contractorPaymentsPage: ContractorPaymentPage;
  coreCompany?: Maybe<CoreCompany>;
  /** The timestamp the payroll was created */
  createdAt: Scalars['DateTime'];
  /** If provided, the timestamp the payroll was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Deptuy sync info */
  deputySync?: Maybe<DeputySyncInfo>;
  /** Total liability for a payroll with a status of DRAFT */
  draftLiability?: Maybe<Scalars['Float']>;
  employeeCount?: Maybe<Scalars['Int']>;
  hasEmployeesWithMissingSSN: Scalars['Boolean'];
  id: Scalars['ID'];
  /** If the dates are impacted by a weekend or holiday. */
  impactedByWeekendOrHoliday?: Maybe<Scalars['Boolean']>;
  /** The set of payroll items associated with the payroll. */
  items?: Maybe<Array<Maybe<PayrollItem>>>;
  itemsPage: PayrollItemPage;
  /** An indicator after payroll approval of manual payroll items without associated check numbers */
  needsChecksEntered?: Maybe<Scalars['Boolean']>;
  /** Additional configuration options controlling off-cycle payroll behavior. */
  offCycleOptions?: Maybe<OffcycleOptions>;
  /** The date on which employees will be paid for the payroll. */
  payday: Scalars['DateTime'];
  payrollItem?: Maybe<PayrollItem>;
  payrollItems: PayrollItemConnection;
  /** The end of the date range that the payroll covers. */
  periodEnd: Scalars['DateTime'];
  /** The start of the date range that the payroll covers. */
  periodStart: Scalars['DateTime'];
  /** latest sync attempt from webhook */
  quickbooksSyncAttempt?: Maybe<SyncAttempt>;
  /** Indicates where the payroll is in its lifecycle. */
  status: PayrollStatus;
  /** Date when the status changed. */
  statusChangedAt: Scalars['DateTime'];
  syncAttempts?: Maybe<Array<SyncAttempt>>;
  timesheetHours?: Maybe<TimesheetAggregate>;
  timesheets: Array<Timesheet>;
  /** Calculated totals for various payroll categories. null for payrolls with a status of draft outside of the preview endpoint. */
  totals?: Maybe<Totals>;
  /** Indicates whether the payroll is a regular or off-cycle payroll. */
  type: PayrollType;
  /** Optimistic locking to prevent stale data clobbering fresher data */
  version: Scalars['Int'];
  withdrawDate?: Maybe<Scalars['DateTime']>;
};


export type PayrollContractorPaymentArgs = {
  id: Scalars['ID'];
};


export type PayrollContractorPaymentsConnectionArgs = {
  args?: InputMaybe<ContractorPaymentArgs>;
};


export type PayrollContractorPaymentsPageArgs = {
  advancedWhere?: InputMaybe<ContractorPaymentAdvancedWhereInput>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ContractorPaymentOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type PayrollItemsPageArgs = {
  advancedWhere?: InputMaybe<PayrollItemAdvancedWhereInput>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PayrollItemOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
};


export type PayrollPayrollItemArgs = {
  id: Scalars['ID'];
};


export type PayrollPayrollItemsArgs = {
  args?: InputMaybe<PayrollItemArgs>;
};


export type PayrollTimesheetHoursArgs = {
  filter?: InputMaybe<TimesheetFilter>;
};


export type PayrollTimesheetsArgs = {
  filter?: InputMaybe<TimesheetFilter>;
};

export type PayrollAdvancedWhereInput = {
  /** All conditions passed into this must return true for payrolls returned. */
  AND?: InputMaybe<Array<PayrollBaseWhereInput>>;
  /** All conditions passed into this must return false for payrolls returned. */
  NOT?: InputMaybe<Array<PayrollBaseWhereInput>>;
  /** One or more conditions passed into this must return true for payrolls returned. */
  OR?: InputMaybe<Array<PayrollBaseWhereInput>>;
};

export type PayrollBaseWhereInput = {
  approvalDeadline?: InputMaybe<DateTimeNullableFilter>;
  approvedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  needsChecksEntered?: InputMaybe<BoolNullableFilter>;
  paidAt?: InputMaybe<DateTimeNullableFilter>;
  partiallyPaidAt?: InputMaybe<DateTimeNullableFilter>;
  payday?: InputMaybe<DateTimeFilter>;
  periodEnd?: InputMaybe<DateTimeFilter>;
  periodStart?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumPayrollStatusFilter>;
  statusChangedAt?: InputMaybe<DateTimeFilter>;
  type?: InputMaybe<EnumPayrollTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PayrollBenefit = {
  __typename?: 'PayrollBenefit';
  benefit: Scalars['String'];
  companyContributionAmount?: Maybe<Scalars['String']>;
  companyPeriodAmount?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employeeContributionAmount?: Maybe<Scalars['String']>;
  employeePeriodAmount?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  period?: Maybe<BenefitPeriod>;
};

export type PayrollCancellationRequestInput = {
  /** Bambee company id */
  companyId: Scalars['ID'];
};

export type PayrollCompany = {
  __typename?: 'PayrollCompany';
  authorizationDocuments: Array<Maybe<AuthorizationDocument>>;
  bankAccounts?: Maybe<Array<Maybe<BankAccount>>>;
  benefitOnboarding?: Maybe<BenefitCompanyOnboarding>;
  boxInfo?: Maybe<CompanyBoxInfo>;
  checkId?: Maybe<Scalars['ID']>;
  checkImplementation?: Maybe<CheckCompanyImplementation>;
  checkIndustry?: Maybe<IndustryType>;
  checkIntegration?: Maybe<CheckIntegration>;
  checkOnboarding?: Maybe<CheckCompanyOnboarding>;
  companyBenefits: Array<Maybe<CompanyBenefit>>;
  complexPayrollOnboarding?: Maybe<Array<Maybe<ComplexPayrollOnboarding>>>;
  contractors: Array<Maybe<Contractor>>;
  coreCompany?: Maybe<CoreCompany>;
  defaultPayScheduleDays?: Maybe<Array<Maybe<DefaultPayScheduleDays>>>;
  /** Has this company established a connection to Deputy */
  deputyConnection?: Maybe<DeputyConnection>;
  /** Timestamp for when company accepted deputy terms of service */
  deputyTosAcceptedAt?: Maybe<Scalars['DateTime']>;
  employees: Array<Maybe<Employee>>;
  excludedStaffIds: Array<Maybe<Scalars['ID']>>;
  feinVerification?: Maybe<CheckFeinVerification>;
  feinVerificationAttempts: Scalars['Int'];
  /** Has this company established a connection to Finch */
  finchConnection?: Maybe<CompanyFinchAccessToken>;
  goals: Array<Goal>;
  hasDraftPayroll: Scalars['Boolean'];
  hasRunPayroll: Scalars['Boolean'];
  /** Bambee company id */
  id: Scalars['ID'];
  /** Needs pay schedule assistance */
  needsPayScheduleAssistance?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  onboardingGarnishmentsOrDeductions: Array<OnboardingGarnishmentOrDeduction>;
  onboardingGarnishmentsOrDeductionsOther: Scalars['String'];
  onboardingHasComplexScenario?: Maybe<Scalars['Boolean']>;
  onboardingHasComplexTimeAttendanceScenario?: Maybe<Scalars['Boolean']>;
  onboardingHasGarnishmentsOrDeductions?: Maybe<Scalars['Boolean']>;
  onboardingOffersEmployeeBenefits: Scalars['Boolean'];
  /** Past payroll usage ( prior to Bambee ) */
  pastPayrollUsage?: Maybe<PastPayrollUsage>;
  /** Frequency by which the employees get paid. */
  payFrequency: PayFrequency;
  paySchedules: Array<Maybe<PaySchedule>>;
  paydays?: Maybe<Array<Maybe<CompanyPaydays>>>;
  processedPayrollsThisYear: Scalars['Boolean'];
  /** Frequency by which the employees get paid. */
  processingPeriod: ProcessingPeriod;
  signatoryEmail: Scalars['String'];
  signatoryFirstName: Scalars['String'];
  signatoryLastName: Scalars['String'];
  signatoryTitle: Scalars['String'];
  /** Date the company will start using Check as their payroll processor. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Bambee payroll status of the company. */
  status?: Maybe<CompanyStatus>;
  taxDocYears: Array<Scalars['String']>;
  taxDocuments: Array<Maybe<TaxDocument>>;
  taxExemptStatus?: Maybe<TaxExemptStatus>;
  timesheets: Array<Timesheet>;
  /** version of payroll terms last signed */
  tosVersion?: Maybe<Scalars['String']>;
  /** Total count of employees by category */
  total?: Maybe<Total>;
  /** Company workplaces */
  workplaces?: Maybe<Array<Workplace>>;
};


export type PayrollCompanyComplexPayrollOnboardingArgs = {
  take?: InputMaybe<Scalars['Float']>;
};


export type PayrollCompanyDefaultPayScheduleDaysArgs = {
  input?: InputMaybe<DefaultPayScheduleDaysInput>;
};


export type PayrollCompanyTaxDocumentsArgs = {
  data?: InputMaybe<CompanyTaxDocumentsInput>;
};


export type PayrollCompanyTimesheetsArgs = {
  filter?: InputMaybe<TimesheetFilter>;
};

export enum PayrollContractorType {
  Business = 'BUSINESS',
  Individual = 'INDIVIDUAL',
  Unknown = 'UNKNOWN'
}

export type PayrollItem = {
  __typename?: 'PayrollItem';
  addedAfterInitialization: Scalars['Boolean'];
  basePayRate: Scalars['Float'];
  benefits?: Maybe<Array<Maybe<PayrollBenefit>>>;
  checkNumber?: Maybe<Scalars['String']>;
  earnings: Array<Earning>;
  employee: Employee;
  employeeId: Scalars['ID'];
  grossPay: Scalars['Float'];
  /** Determines if any worker role has been deleted in this payroll item */
  hasDeletedWorkerRole: Scalars['Boolean'];
  id: Scalars['ID'];
  netPay?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  noteVisible?: Maybe<Scalars['Boolean']>;
  payType: PaymentType;
  /** The way the employee will be paid */
  paymentMethod?: Maybe<PaymentMethod>;
  payroll: Payroll;
  postTaxDeductions?: Maybe<Array<Maybe<PostTaxDeductionItem>>>;
  /** The employee's remaining PTO hour balance, for display on the paystub */
  ptoBalanceHours?: Maybe<Scalars['Float']>;
  reimbursements: Array<Maybe<Reimbursement>>;
  /** The employee's remaining sick hour balance, for display on the paystub */
  sickBalanceHours?: Maybe<Scalars['Float']>;
  /** Indicates where the payroll item is in its lifecycle. */
  status: PayrollItemStatus;
  /** Determines if this item earnings were synced from a timesheet */
  syncedFromDeputy: Scalars['Boolean'];
  taxes?: Maybe<Array<Maybe<Tax>>>;
  timesheetHours?: Maybe<TimesheetAggregate>;
  timesheetHoursByRole?: Maybe<Array<Maybe<TimesheetAggregate>>>;
  /** Optimistic locking to prevent stale data clobbering fresher data */
  version: Scalars['Int'];
  warnings?: Maybe<Array<Maybe<Warning>>>;
  workerRoles?: Maybe<CoreCompany>;
};


export type PayrollItemWorkerRolesArgs = {
  showDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type PayrollItemAdvancedWhereInput = {
  /** All conditions passed into this must return true for payrolls returned. */
  AND?: InputMaybe<Array<PayrollItemBaseWhereInput>>;
  /** All conditions passed into this must return false for payrolls returned. */
  NOT?: InputMaybe<Array<PayrollItemBaseWhereInput>>;
  /** One or more conditions passed into this must return true for payrolls returned. */
  OR?: InputMaybe<Array<PayrollItemBaseWhereInput>>;
};

export type PayrollItemArgs = {
  advancedWhere?: InputMaybe<PayrollItemAdvancedWhereInput>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PayrollItemOrderByInput>;
};

export type PayrollItemBaseWhereInput = {
  addedAfterInitialization?: InputMaybe<BoolNullableFilter>;
  basePayRate?: InputMaybe<NumberNullableFilter>;
  checkNumber?: InputMaybe<StringNullableFilter>;
  employeeId?: InputMaybe<StringNullableFilter>;
  grossPay?: InputMaybe<NumberNullableFilter>;
  /** Determines if any worker role has been deleted in this payroll item */
  hasDeletedWorkerRole?: InputMaybe<BoolNullableFilter>;
  netPay?: InputMaybe<NumberNullableFilter>;
  note?: InputMaybe<StringNullableFilter>;
  noteVisible?: InputMaybe<BoolNullableFilter>;
  payType?: InputMaybe<EnumPayrollItemPayTypeFilter>;
  /** The way the employee will be paid */
  paymentMethod?: InputMaybe<EnumPayrollItemPaymentMethodFilter>;
  /** The employee's remaining PTO hour balance, for display on the paystub */
  ptoBalanceHours?: InputMaybe<NumberNullableFilter>;
  /** The employee's remaining sick hour balance, for display on the paystub */
  sickBalanceHours?: InputMaybe<NumberNullableFilter>;
  /** Indicates where the payroll item is in its lifecycle. */
  status?: InputMaybe<EnumPayrollItemPayrollItemStatusFilter>;
  /** Determines if this item earnings were synced from a timesheet */
  syncedFromDeputy?: InputMaybe<BoolNullableFilter>;
};

export type PayrollItemCheckNumbers = {
  checkNumber: Scalars['String'];
  /** The PayrollItem ID the check number is for. */
  id: Scalars['ID'];
};

export type PayrollItemConnection = {
  __typename?: 'PayrollItemConnection';
  edges: Array<PayrollItemEdge>;
  pageInfo: PageInfo;
};

export type PayrollItemEdge = {
  __typename?: 'PayrollItemEdge';
  cursor: Scalars['String'];
  node: PayrollItem;
};

export type PayrollItemOrderByInput = {
  direction?: InputMaybe<OrderByDirection>;
  key: Scalars['String'];
};

export type PayrollItemPage = {
  __typename?: 'PayrollItemPage';
  /** Reflects the limit argument provided in the query. */
  limit: Scalars['Int'];
  /** Tells if there is a next page of results available. */
  nextPage: Scalars['Boolean'];
  /** Reflects the page argument provided in the query. */
  page: Scalars['Int'];
  /** Tells if there is a previous page of results available. */
  prevPage: Scalars['Boolean'];
  results: Array<PayrollItem>;
  /** The total number of payrolls in the database or for the company when companyId is provided. */
  totalCount: Scalars['Int'];
};

export enum PayrollItemStatus {
  Draft = 'DRAFT',
  Failed = 'FAILED',
  Initial = 'INITIAL',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  Pending = 'PENDING',
  Processing = 'PROCESSING'
}

export type PayrollItemSync = {
  __typename?: 'PayrollItemSync';
  bambee?: Maybe<Scalars['String']>;
  check?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type PayrollOrderByInput = {
  direction?: InputMaybe<OrderByDirection>;
  key?: InputMaybe<Scalars['String']>;
};

export type PayrollPage = {
  __typename?: 'PayrollPage';
  /** Reflects the limit argument provided in the query. */
  limit: Scalars['Int'];
  /** Tells if there is a next page of results available. */
  nextPage: Scalars['Boolean'];
  /** Reflects the page argument provided in the query. */
  page: Scalars['Int'];
  /** Tells if there is a previous page of results available. */
  prevPage: Scalars['Boolean'];
  results: Array<Maybe<Payroll>>;
  totalContractors: Scalars['Int'];
  /** The total number of payrolls in the database or for the company when companyId is provided. */
  totalCount: Scalars['Int'];
  totalEmployees: Scalars['Int'];
};

/** A model representing a payroll plan. */
export type PayrollPlanModel = {
  __typename?: 'PayrollPlanModel';
  /** Billing period for the plan. */
  billingPeriod: BillingPeriod;
  /** Maximum size of employees (legacy payroll) or maximum tier number (basic & premium). */
  maxSize?: Maybe<Scalars['Float']>;
  /** Minimum size of employees (legacy payroll) or minimum tier number (basic & premium). */
  minSize: Scalars['Float'];
  /** Name of the plan. */
  name: Scalars['String'];
  /** Total price of the plan. */
  price?: Maybe<Scalars['Float']>;
  /** Price identifier in Stripe. */
  priceId: Scalars['String'];
  /** The payroll product key associated to the plan object. */
  productKey: ProductKey;
  /** The service key associated to the plan object (always "payroll"). */
  serviceKey: ServiceKey;
  /** Current status of the plan. */
  status: PlanStatuses;
  /** Unit price of the plan. */
  unitPrice?: Maybe<Scalars['Float']>;
  /** Unit Price identifier in Stripe. */
  unitPriceId?: Maybe<Scalars['String']>;
  /** Value proposition of the plan. */
  value: Scalars['String'];
  /** Version of the plan. */
  version: Scalars['String'];
};

export enum PayrollStatus {
  Draft = 'DRAFT',
  Failed = 'FAILED',
  Initial = 'INITIAL',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  Pending = 'PENDING',
  Processing = 'PROCESSING'
}

export type PayrollSync = {
  __typename?: 'PayrollSync';
  bambee?: Maybe<Scalars['String']>;
  check?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export enum PayrollType {
  OffCycle = 'OFF_CYCLE',
  Regular = 'REGULAR'
}

export type PayrollWhereInput = {
  condition: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type PerformAutoHrmPodAssignmentInput = {
  companyId: Scalars['String'];
  pricingPackageType?: InputMaybe<PricingPackageType>;
  stateGroup: Scalars['String'];
  tierNumber: Scalars['Float'];
};

/** Ping Deputy installation to validate / refresh access token */
export type PingDeputyInput = {
  /** Bambee company id */
  companyId: Scalars['String'];
};

/** Ping Deputy response object */
export type PingDeputyResponse = {
  __typename?: 'PingDeputyResponse';
  /** deputy version */
  deputyVersion?: Maybe<Scalars['String']>;
  /** deputy employeeId */
  employeeID?: Maybe<Scalars['Float']>;
  /** deputy user first name */
  firstName?: Maybe<Scalars['String']>;
  /** deputy username */
  id: Scalars['ID'];
  /** deputy user last name */
  lastName?: Maybe<Scalars['String']>;
  /** deputy username */
  login?: Maybe<Scalars['String']>;
  /** deputy user name */
  name?: Maybe<Scalars['String']>;
  /** dupty user primary email */
  primaryEmail?: Maybe<Scalars['String']>;
  /** dupty user primary phone */
  primaryPhone?: Maybe<Scalars['String']>;
  /** deputy userId */
  userID?: Maybe<Scalars['Float']>;
};

export type Plan = {
  __typename?: 'Plan';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PlanCoupon = {
  __typename?: 'PlanCoupon';
  amountOff: Scalars['Float'];
  appliesToPlans: Array<Plan>;
  id: Scalars['String'];
  name: Scalars['String'];
  percentOff?: Maybe<Scalars['Float']>;
  setupFeeOff: Scalars['Float'];
};

export type PlanLock = {
  __typename?: 'PlanLock';
  active: Scalars['Boolean'];
  couponId?: Maybe<Scalars['String']>;
  plan: Scalars['String'];
  setupFee: Scalars['Float'];
  trialDuration: Scalars['Float'];
};

/** A model representing a generic plan. */
export type PlanModel = {
  __typename?: 'PlanModel';
  /** Indicates if the plan is annual. */
  annual?: Maybe<Scalars['Boolean']>;
  /** Billing period for the plan. */
  billingPeriod: BillingPeriod;
  /** Indicates if the plan is chat-only. */
  chatOnly?: Maybe<Scalars['Boolean']>;
  /** Indicates if the plan is configured to collect taxes. */
  collectTax?: Maybe<Scalars['Boolean']>;
  /** Contract period for the plan, if applicable. */
  contractPeriod?: Maybe<BillingPeriod>;
  /** DRP (Disaster Recovery Plan) identifier, if applicable. */
  drp?: Maybe<Scalars['String']>;
  /** Mapping of the plan to the size of the employee base. */
  employeeSizeMapping?: Maybe<Scalars['String']>;
  /** Indicates if the plan is for internal use only. */
  internal?: Maybe<Scalars['Boolean']>;
  /** Maximum size of the employee base for this plan. */
  maxSize?: Maybe<Scalars['Float']>;
  /** Minimum size of the employee base for this plan. */
  minSize?: Maybe<Scalars['Float']>;
  /** Monthly price of the plan. */
  monthlyPrice?: Maybe<Scalars['Float']>;
  /** Name of the plan. */
  name: Scalars['String'];
  /** Partner associated with the plan, if any. */
  partner?: Maybe<Scalars['String']>;
  /** Indicates if the plan can be paused. */
  pause?: Maybe<Scalars['Boolean']>;
  /** Total price of the plan. */
  price?: Maybe<Scalars['Float']>;
  /** Price identifier in Stripe. */
  priceId: Scalars['String'];
  /** The product key associated to the plan object (Lite, Dedicated, Plus, Elite). */
  productKey: ProductKey;
  /** Indicates if the plan is regulated. */
  regulated?: Maybe<Scalars['Boolean']>;
  /** The service key associated to the plan object (Core, Payroll, Training, ...). */
  serviceKey: ServiceKey;
  /** Initial setup fee for the plan. */
  setupFee?: Maybe<Scalars['Float']>;
  /** Discount on the setup fee, if applicable. */
  setupFeeDiscount?: Maybe<Scalars['Float']>;
  /** Current status of the plan. */
  status: PlanStatuses;
  /** Tier number of the plan. */
  tierNumber?: Maybe<Scalars['Float']>;
  /** Value proposition of the plan. */
  value: Scalars['String'];
  /** Version of the plan. */
  version?: Maybe<Scalars['String']>;
  /** Indicates if the plan pricing is based on volume. */
  volumeBased?: Maybe<Scalars['Boolean']>;
};

/** Plan statuses */
export enum PlanStatuses {
  Active = 'ACTIVE',
  Deprecated = 'DEPRECATED',
  Inactive = 'INACTIVE'
}

export enum PolicyStepNames {
  Created = 'CREATED',
  Implemented = 'IMPLEMENTED',
  Ready = 'READY',
  Requested = 'REQUESTED',
  Sent = 'SENT',
  Unknown = 'UNKNOWN'
}

export type PostTaxDeductionItem = {
  __typename?: 'PostTaxDeductionItem';
  /** Per pay period amount to deduct. */
  amount: Scalars['Float'];
  description: Scalars['String'];
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type Practice = {
  __typename?: 'Practice';
  companyId: Scalars['String'];
  description: Scalars['String'];
  iconUrl: Scalars['String'];
  id: Scalars['String'];
  illustrationUrl: Scalars['String'];
  introDescription: Scalars['String'];
  outroIllustrationUrl: Scalars['String'];
  setupProgress: PracticeSetupProgress;
  shortDescription: Scalars['String'];
  shortName: Scalars['String'];
  state: PracticeState;
  status: PracticeStatuses;
  successOutroTitle: Scalars['String'];
  survey: Survey;
  title: Scalars['String'];
};

export enum PracticeCadenceValue {
  Annually = 'ANNUALLY',
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  SemiAnnually = 'SEMI_ANNUALLY',
  Weekly = 'WEEKLY'
}

export enum PracticeSetupProgress {
  Completed = 'COMPLETED',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED'
}

export enum PracticeState {
  Activated = 'ACTIVATED',
  NotActivated = 'NOT_ACTIVATED'
}

export enum PracticeStatuses {
  Completed = 'COMPLETED',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED'
}

export type PreviewCorePlanChangeModel = {
  __typename?: 'PreviewCorePlanChangeModel';
  /** Details for the following invoice after the upcoming one */
  next: CorePlanChangeInvoice;
  /** The core plan for the upgrade calculation */
  plan: CorePlanModel;
  /** Proration date considered for this plan change */
  prorationDate: Scalars['DateTime'];
  /** Details for the upcoming invoice if plan is applied */
  upcoming: UpcomingCorePlanChangeInvoice;
};

export type PricingPackage = {
  __typename?: 'PricingPackage';
  /** The pricing plans available for the package */
  plans: Array<Maybe<CorePlanModel>>;
  /** The type of the package */
  type: PricingPackageType;
};

export type PricingPackageFilterInput = {
  /** The billing period */
  billingPeriod?: InputMaybe<Scalars['String']>;
  /** The company ID */
  companyId: Scalars['ID'];
  /** The number of employees */
  employeeCount?: InputMaybe<Scalars['Float']>;
  /** Include internal plans ( defaults to external only ) */
  includeInternal?: InputMaybe<Scalars['Boolean']>;
  /** The partner */
  partner?: InputMaybe<Scalars['String']>;
  /** The status of the plan e.g. active, inactive etc.  */
  statuses?: InputMaybe<Array<PlanStatuses>>;
};

export type PricingPackageResponse = {
  __typename?: 'PricingPackageResponse';
  /** The pricing packages */
  packages: Array<PricingPackage>;
};

/** Pricing package type */
export enum PricingPackageType {
  Business = 'BUSINESS',
  Elite = 'ELITE',
  Lite = 'LITE',
  Pause = 'PAUSE',
  Plus = 'PLUS',
  Storage = 'STORAGE'
}

export enum ProcessingPeriod {
  FourDay = 'FOUR_DAY',
  OneDay = 'ONE_DAY',
  TwoDay = 'TWO_DAY'
}

export type Product = {
  __typename?: 'Product';
  activeSubscription?: Maybe<Scalars['Boolean']>;
  /** Number of credits provided on core subscription. Null if single purchase */
  allowance?: Maybe<Scalars['Int']>;
  /** Billing period of the product. Null if single purchase */
  billingPeriod?: Maybe<Scalars['String']>;
  creditBalance: Scalars['Float'];
  /** One-time discounted price of product, if available. Null if subscription */
  discountedPrice?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  key: ProductKey;
  /** Number of credits provided per month, if applicable. Null if single purchase */
  monthlyAllowance?: Maybe<Scalars['Int']>;
  /** Name of product */
  name?: Maybe<Scalars['String']>;
  /** One-time price of product. Null if subscription */
  price?: Maybe<Scalars['Int']>;
  /** The price to subscribe, based on billing period. Null if single purchase */
  subscriptionPrice?: Maybe<Scalars['Int']>;
  /** Company tier */
  tier?: Maybe<Scalars['Int']>;
};

export enum ProductCreditConsumptionReason {
  CustomerSatisfaction = 'CUSTOMER_SATISFACTION',
  HumanError = 'HUMAN_ERROR',
  Other = 'OTHER',
  ProcessRelated = 'PROCESS_RELATED',
  TechError = 'TECH_ERROR',
  UpsellingCustomer = 'UPSELLING_CUSTOMER'
}

export enum ProductCreditConsumptionType {
  Expiration = 'EXPIRATION',
  Manual = 'MANUAL',
  Refund = 'REFUND',
  Usage = 'USAGE',
  Void = 'VOID'
}

export enum ProductCreditCreationReason {
  CustomerSatisfaction = 'CUSTOMER_SATISFACTION',
  HumanError = 'HUMAN_ERROR',
  Other = 'OTHER',
  ProcessRelated = 'PROCESS_RELATED',
  TechError = 'TECH_ERROR',
  UpsellingCustomer = 'UPSELLING_CUSTOMER'
}

export enum ProductCreditType {
  Allowance = 'ALLOWANCE',
  Manual = 'MANUAL',
  OnetimeTransaction = 'ONETIME_TRANSACTION',
  SubscriptionRefillAdditional = 'SUBSCRIPTION_REFILL_ADDITIONAL',
  SubscriptionRefillLimit = 'SUBSCRIPTION_REFILL_LIMIT',
  SubscriptionUnlimited = 'SUBSCRIPTION_UNLIMITED'
}

export type ProductInterest = {
  __typename?: 'ProductInterest';
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  productKey: ProductKey;
  productSubKey?: Maybe<ProductSubKey>;
  userId: Scalars['String'];
  /** Whether the user has dismissed the app notification for this product interest */
  wasAppNotificationDismissed: Scalars['Boolean'];
};

export type ProductInterestInput = {
  companyId: Scalars['String'];
  interests: Scalars['JSON'];
  origin: Scalars['String'];
};

export enum ProductKey {
  BambeePlus = 'BAMBEE_PLUS',
  Core = 'CORE',
  Dedicated = 'DEDICATED',
  Elite = 'ELITE',
  JobDescription = 'JOB_DESCRIPTION',
  JobDescriptionAi = 'JOB_DESCRIPTION_AI',
  Lite = 'LITE',
  ManagedHrServices = 'MANAGED_HR_SERVICES',
  MissedCall = 'MISSED_CALL',
  NewPolicy = 'NEW_POLICY',
  Pause = 'PAUSE',
  Payroll = 'PAYROLL',
  PayrollBasic = 'PAYROLL_BASIC',
  PayrollPremium = 'PAYROLL_PREMIUM',
  Plus = 'PLUS',
  Storage = 'STORAGE',
  TimeAttendance = 'TIME_ATTENDANCE',
  Training = 'TRAINING',
  WebinarWorkplaceViolence = 'WEBINAR_WORKPLACE_VIOLENCE'
}

export enum ProductSubKey {
  BenefitsAdministration = 'BENEFITS_ADMINISTRATION',
  CorrectiveActions = 'CORRECTIVE_ACTIONS',
  EmployeeSupport = 'EMPLOYEE_SUPPORT',
  HiringGuidance = 'HIRING_GUIDANCE',
  HrPolicies = 'HR_POLICIES',
  ImmigrationConsultation = 'IMMIGRATION_CONSULTATION',
  JobDescriptions = 'JOB_DESCRIPTIONS',
  LeaveOfAbsenceStrategy = 'LEAVE_OF_ABSENCE_STRATEGY',
  ManagedTermination = 'MANAGED_TERMINATION',
  ManagerLiveSupport = 'MANAGER_LIVE_SUPPORT',
  OrgDesignConsultation = 'ORG_DESIGN_CONSULTATION',
  SettingUpHrInvestigations = 'SETTING_UP_HR_INVESTIGATIONS'
}

export type ProfileAddress = {
  __typename?: 'ProfileAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** Provision Deputy installation to enable T&A features */
export type ProvisionDeputyInstallInput = {
  /** Bambee company id */
  companyId: Scalars['String'];
};

export type PurchaseBambeePlusInput = {
  companyId: Scalars['String'];
};

export type PurchaseBundleSeatsInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  numSeats: Scalars['Int'];
  skipPayment?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseInput = {
  productKey: ProductKey;
  quantity: Scalars['Float'];
};

export type PurchaseResult = {
  __typename?: 'PurchaseResult';
  createdAt: Scalars['DateTime'];
  creditsPurchased: Scalars['Int'];
  id: Scalars['ID'];
  productKey: ProductKey;
  status: StatementStatus;
  totalAmount: Scalars['Int'];
};

export type PurchaseSeatsInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  numSeats: Scalars['Int'];
  skipPayment?: InputMaybe<Scalars['Boolean']>;
  topicId?: InputMaybe<Scalars['String']>;
};

export type PurchaseSeatsResponse = {
  __typename?: 'PurchaseSeatsResponse';
  bundleId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  numPurchased: Scalars['Int'];
  topicId?: Maybe<Scalars['String']>;
  totalSeats: Scalars['Int'];
};

export type PurchaseSubscriptionInput = {
  billingPeriod?: InputMaybe<BillingPeriod>;
  productKey: ProductKey;
};

export type PurchaseTicketsResponse = {
  __typename?: 'PurchaseTicketsResponse';
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  numPurchased: Scalars['Int'];
  totalTickets: Scalars['Int'];
};

export type PurchaseTimeAttendanceInput = {
  companyId: Scalars['String'];
  currentUserId: Scalars['String'];
};

export type PurchasedBundle = {
  __typename?: 'PurchasedBundle';
  /** @deprecated Use bundleId instead */
  bundleId: Scalars['String'];
  counts: UserCounts;
  description?: Maybe<Scalars['String']>;
  global: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  topics: Array<Maybe<PurchasedTopic>>;
  totalSeats: Scalars['Float'];
  unfilledSeats: Scalars['Float'];
};

export type PurchasedProducts = {
  __typename?: 'PurchasedProducts';
  bundles: Array<Maybe<PurchasedBundle>>;
  id: Scalars['ID'];
  topics: Array<Maybe<PurchasedTopic>>;
};

export type PurchasedTopic = {
  __typename?: 'PurchasedTopic';
  counts?: Maybe<UserCounts>;
  enrollments: Array<Maybe<TopicEnrolledTickets>>;
  id: Scalars['String'];
  name: Scalars['String'];
  /** @deprecated Use id instead */
  topicId: Scalars['String'];
  totalSeats?: Maybe<Scalars['Float']>;
  unfilledSeats?: Maybe<Scalars['Float']>;
};

export enum QaPolicyViewer {
  /** employee */
  Employee = 'Employee',
  /** manager */
  Manager = 'Manager'
}

export type QaTrainingCertificates = {
  __typename?: 'QaTrainingCertificates';
  id: Scalars['ID'];
  s3FileName?: Maybe<Scalars['String']>;
};

export enum Quarter {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export type Query = {
  __typename?: 'Query';
  activeCancellationReasonGroups: Array<CancellationReasonGroupModel>;
  advisorNextAvailableSlot: AdvisorNextTimeSlot;
  availableCadence: Array<Cadence>;
  availableSettings: Array<Setting>;
  cancellationOffers: CancellationOffersModel;
  cancellationReasonGroups: Array<CancellationReasonGroupModel>;
  company: Company;
  companySubscriptions: Array<Subscription>;
  companySurvey: Survey;
  companySurveyAnswer: SurveyAnswer;
  companySurveyList: Array<Survey>;
  contractor?: Maybe<Contractor>;
  coreCompany: CoreCompany;
  coreUpgradeOptions: Array<PreviewCorePlanChangeModel>;
  coupon: PlanCoupon;
  couponList: Array<PlanCoupon>;
  downloadCompanyAuthorizationDocument: Scalars['String'];
  employee?: Maybe<Employee>;
  /** Fetches onboarding for the user and version provided if it exists */
  findOnboarding?: Maybe<CoreOnboarding>;
  getAdminCompanyGoals: Array<CoreGoal>;
  getAdminCompanyTasks: Array<CoreTask>;
  getAiGeneratedById: AiGenerated;
  getAskNicelyWebSurveyInfo: AskNicelySurveyInfo;
  getAvailableProducts: Array<AvailableProduct>;
  getBambeePlusInfo: BambeePlus;
  /** Lists statements submitted to a company */
  getBillingHistory: Array<Statement>;
  getBillingInfo: BillingInfo;
  getBundle: BundleResponse;
  getBusinessManagerCalendly: Calendly;
  getChannelHistory: Array<ChatMessageInterface>;
  /** @deprecated Use payrollCompany instead */
  getCompany?: Maybe<Company>;
  getCompanyChannels: Array<CompanyChannel>;
  getCompanyCreditsV2: Array<Credit>;
  getCompanyDelinquency: CompanyDelinquency;
  getCompanyEstimatedBenefitsCost?: Maybe<BenefitCostEstimated>;
  getCompanyProducts: Array<Product>;
  getCompanySettings: Array<CompanySetting>;
  getCompanySources: PaymentSources;
  /** @deprecated Use "contractor" instead */
  getContractor?: Maybe<Contractor>;
  /** @deprecated Use getCoreCompanyWithCompanyRoles { getPayroll {contractorPayment() } } instead. This query does not contain worker role data */
  getContractorPayment?: Maybe<ContractorPayment>;
  getContractorsByEmail: Array<Contractor>;
  getConversationByIntercomId: Conversation;
  getConversationHistory: Array<ChatMessageInterface>;
  /** @deprecated Use coreCompany instead */
  getCoreCompany: CoreCompany;
  getCoreUserById: CoreUser;
  getDocumentById: CoreDocument;
  getEligibleAddons: EligibleAddonsResponse;
  /** @deprecated Use "employee" instead */
  getEmployee?: Maybe<Employee>;
  /** Retrieves one job description assigned to an employee */
  getEmployeeJobDescription?: Maybe<EmployeeJobDescription>;
  getEmployeesByEmail: Array<Employee>;
  getEoeHrRequestAnswer?: Maybe<EoEHrRequestAnswer>;
  getHrRequestBySalesforceCaseId?: Maybe<HrRequest>;
  getHrRequestOptions: HrRequestOptionsResponse;
  getHrRequests: ListHrRequestsResponse;
  getHrScore: HrScore;
  getJobDescriptionById: JobDescription;
  getMyChatRecipients: Array<CoreUser>;
  getMyCompanyChannels: Array<CompanyChannel>;
  getMyCompanyGoals: Array<CoreGoal>;
  getMyCompanyTasks: Array<CoreTask>;
  getMyCompanyVoicesFeedbackList: Array<VoicesFeedback>;
  getMyCoreUser: CoreUser;
  getMyMfaEnrollments: Array<AuthMfaConfig>;
  getMySuggestedTopics: Array<ChatTopicSuggestion>;
  getMyVoicesFeedbackList: Array<VoicesFeedback>;
  getOnboardingCalendlyConfig: OnboardingCalendlyConfig;
  /** @deprecated Use "payroll" instead */
  getPayroll?: Maybe<Payroll>;
  /** @deprecated Use payrollItem instead */
  getPayrollItem?: Maybe<PayrollItem>;
  getPlanLock: PlanLock;
  getPolicy?: Maybe<CorePolicy>;
  getPractice: Practice;
  getPricingPackages: PricingPackageResponse;
  getReportCard: ReportCard;
  getResignationByHrRequest: Resignation;
  getSalesforceCasesForNewPolicyRequest: ListSalesforceCasesResponse;
  getScheduledEventsForUser: Array<ScheduledEvent>;
  getSystemConfiguration: SystemConfiguration;
  getTerminationByHrRequest: Termination;
  getThreadHistory: Array<ChatMessageInterface>;
  getTickets: ListTicketsResponse;
  getTopic?: Maybe<CourseTopic>;
  getTopics: Array<CourseTopic>;
  getTrainingPrices: TrainingPricesResponse;
  /** @deprecated Use `user.training.courses` query instead */
  getUserCourses: Array<UserCourse>;
  getUserMfaEnrollments: Array<AuthMfaConfig>;
  /** @deprecated Use userToken mutation instead; Queries are inherently cachable and tokens should not be cached */
  getUserToken: DoceboTokenResponse;
  getVoicesFeedbackList: Array<VoicesFeedback>;
  geyMyGroups: Array<CoreUserGroup>;
  hotlineCalls: Array<HotlineCall>;
  hrRequest: HrRequest;
  hrRequestList: ListHrRequestDetails;
  isEventScheduledForUser: IsEventScheduledForUser;
  /** Lists the job descriptions created by a company */
  jobDescriptions: Array<JobDescription>;
  listPayroll: PayrollPage;
  listPractice: Array<Practice>;
  listReportCards: Array<ReportCard>;
  /** The workplaces associated with the companyId provided. By default only active === true workplaces will be returned. */
  listWorkplace: Array<Workplace>;
  me?: Maybe<Employee>;
  /** Lists notification for a user */
  myNotifications: Array<Notification>;
  myProducts: Array<Product>;
  /** Gets all recommendations for current user with optional filtering */
  myRecommendations?: Maybe<Array<CoreRecommendation>>;
  /** Gets all requests for current user with optional filtering */
  myRequests?: Maybe<Array<CoreRequest>>;
  /** Gets all training recommendations for current user with optional filtering; Aggregates training recommendations from all scopes ( SYSTEM, COMPANY, EMPLOYEE) */
  myTrainingRecommendations?: Maybe<Array<CoreRecommendation>>;
  /** Gets all training requests for current user with optional filtering */
  myTrainingRequests?: Maybe<Array<CoreRequest>>;
  payroll?: Maybe<Payroll>;
  payrollCompany?: Maybe<PayrollCompany>;
  payrollItem?: Maybe<PayrollItem>;
  pendingHotlineCall?: Maybe<HotlineCall>;
  productInterest: Array<ProductInterest>;
  qaQueryTrainingCertificates: Array<QaTrainingCertificates>;
  queryEnrolledTopics: Array<CompanyCourseTopic>;
  queryWebinars: Array<Webinar>;
  /** Gets all recommendations for provided query */
  recommendations?: Maybe<Array<CoreRecommendation>>;
  removeSeatsInfo: RemoveSeatsInfoResponse;
  /** Gets all requests for current user's company with optional filtering */
  requests?: Maybe<Array<CoreRequest>>;
  resignationDownload: ResignationDownload;
  resignationExists: ResignationExist;
  resignationInfo: Resignation;
  resignationList: Array<Resignation>;
  scratchListServiceAddons: Array<ScratchServiceAddonsModel>;
  terminationDownload: TerminationDownload;
  terminationExists: TerminationExist;
  terminationInfo: Termination;
  terminationList: Array<Termination>;
  timesheets: Array<Timesheet>;
  user: User;
  /** @deprecated Use `user.training.courses` query instead */
  userCourses: Array<UserCourse>;
  warningDocuments: Array<WarningDocument>;
};


export type QueryAdvisorNextAvailableSlotArgs = {
  input: AdvisorNextAvailableSlotInput;
};


export type QueryCompanyArgs = {
  data: GetCompanyInput;
};


export type QueryCompanySubscriptionsArgs = {
  data: GetCompanySubscriptionsInput;
};


export type QueryCompanySurveyArgs = {
  data: GetCompanySurveyInput;
};


export type QueryCompanySurveyAnswerArgs = {
  data: GetCompanySurveyAnswerInput;
};


export type QueryCompanySurveyListArgs = {
  input: GetCompanySurveyListInput;
};


export type QueryContractorArgs = {
  id: Scalars['ID'];
};


export type QueryCoreCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCoreUpgradeOptionsArgs = {
  input: GetCoreUpgradeOptionsInput;
};


export type QueryCouponArgs = {
  data: GetCouponInput;
};


export type QueryDownloadCompanyAuthorizationDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryFindOnboardingArgs = {
  data: CoreOnboardingKeys;
};


export type QueryGetAdminCompanyGoalsArgs = {
  companyId: Scalars['String'];
};


export type QueryGetAdminCompanyTasksArgs = {
  companyId: Scalars['String'];
};


export type QueryGetAiGeneratedByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAskNicelyWebSurveyInfoArgs = {
  userId: Scalars['String'];
};


export type QueryGetAvailableProductsArgs = {
  data: GetAvailableProductsInput;
};


export type QueryGetBambeePlusInfoArgs = {
  input: PurchaseBambeePlusInput;
};


export type QueryGetBillingHistoryArgs = {
  companyId: Scalars['String'];
};


export type QueryGetBillingInfoArgs = {
  input: GetBillingInfoInput;
};


export type QueryGetBundleArgs = {
  data: GetEntityInput;
};


export type QueryGetBusinessManagerCalendlyArgs = {
  input: GetBusinessManagerCalendlyUrlInput;
};


export type QueryGetChannelHistoryArgs = {
  afterId?: InputMaybe<Scalars['ID']>;
  channelId: Scalars['ID'];
};


export type QueryGetCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryGetCompanyChannelsArgs = {
  companyId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetCompanyCreditsV2Args = {
  data: GetCompanyCreditsInputV2;
};


export type QueryGetCompanyDelinquencyArgs = {
  data: GetCompanyDelinquency;
};


export type QueryGetCompanyEstimatedBenefitsCostArgs = {
  zip: Scalars['String'];
};


export type QueryGetCompanyProductsArgs = {
  data: GetCompanyProductsInput;
};


export type QueryGetCompanySettingsArgs = {
  companyId: Scalars['String'];
};


export type QueryGetCompanySourcesArgs = {
  data: GetCompanyPaymentSources;
};


export type QueryGetContractorArgs = {
  id: Scalars['ID'];
};


export type QueryGetContractorPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryGetContractorsByEmailArgs = {
  emails: Array<Scalars['String']>;
};


export type QueryGetConversationByIntercomIdArgs = {
  input: GetConversationByIntercomIdInput;
};


export type QueryGetConversationHistoryArgs = {
  input: ConversationHistoryInput;
};


export type QueryGetCoreCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryGetCoreUserByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDocumentByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetEligibleAddonsArgs = {
  input: EligibleAddonsInput;
};


export type QueryGetEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryGetEmployeeJobDescriptionArgs = {
  id: Scalars['String'];
};


export type QueryGetEmployeesByEmailArgs = {
  emails: Array<Scalars['String']>;
};


export type QueryGetEoeHrRequestAnswerArgs = {
  data: GetEoeHrRequestInput;
};


export type QueryGetHrRequestBySalesforceCaseIdArgs = {
  input: GetHrRequestBySalesforceCaseIdInput;
};


export type QueryGetHrScoreArgs = {
  companyId: Scalars['String'];
};


export type QueryGetJobDescriptionByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetMyMfaEnrollmentsArgs = {
  includeUnconfirmed?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetOnboardingCalendlyConfigArgs = {
  input: OnboardingCalendlyConfigInput;
};


export type QueryGetPayrollArgs = {
  id: Scalars['ID'];
};


export type QueryGetPayrollItemArgs = {
  id: Scalars['ID'];
};


export type QueryGetPlanLockArgs = {
  companyId: Scalars['String'];
};


export type QueryGetPolicyArgs = {
  input: GetPolicy;
};


export type QueryGetPracticeArgs = {
  input: GetPracticeInput;
};


export type QueryGetPricingPackagesArgs = {
  input: PricingPackageFilterInput;
};


export type QueryGetReportCardArgs = {
  data: GetReportCardInput;
};


export type QueryGetResignationByHrRequestArgs = {
  data: GetResignationHrRequestInput;
};


export type QueryGetSalesforceCasesForNewPolicyRequestArgs = {
  input: GetSalesforceCasesForNewPolicyRequestInput;
};


export type QueryGetScheduledEventsForUserArgs = {
  input: GetScheduledEventsForUserInput;
};


export type QueryGetTerminationByHrRequestArgs = {
  data: GetTerminationHrRequestInput;
};


export type QueryGetThreadHistoryArgs = {
  input: ThreadHistoryInput;
};


export type QueryGetTicketsArgs = {
  data: ListTicketsInput;
};


export type QueryGetTopicArgs = {
  data: GetEntityInput;
};


export type QueryGetUserCoursesArgs = {
  data: GetUserCoursesInput;
};


export type QueryGetUserMfaEnrollmentsArgs = {
  authId: Scalars['String'];
  includeUnconfirmed?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetVoicesFeedbackListArgs = {
  input: GetVoicesFeedbackListInput;
};


export type QueryGeyMyGroupsArgs = {
  view?: GroupView;
};


export type QueryHrRequestArgs = {
  input: GetHrRequestByIdInput;
};


export type QueryIsEventScheduledForUserArgs = {
  input: IsEventScheduledForUserInput;
};


export type QueryListPayrollArgs = {
  advancedWhere?: InputMaybe<PayrollAdvancedWhereInput>;
  companyId?: InputMaybe<Scalars['ID']>;
  companyStatus?: InputMaybe<CompanyStatus>;
  deletedAt?: InputMaybe<ListPayrollDeletedOptions>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PayrollOrderByInput>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PayrollWhereInput>;
};


export type QueryListPracticeArgs = {
  companyId: Scalars['String'];
};


export type QueryListReportCardsArgs = {
  data: ListReportCardsInput;
};


export type QueryListWorkplaceArgs = {
  companyId: Scalars['ID'];
  showAll?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMeArgs = {
  id: Scalars['ID'];
};


export type QueryMyRecommendationsArgs = {
  data: MyRecommendationFilterInput;
};


export type QueryMyRequestsArgs = {
  data: MyRequestFilterInput;
};


export type QueryMyTrainingRecommendationsArgs = {
  data: MyTrainingRecommendationFilterInput;
};


export type QueryMyTrainingRequestsArgs = {
  data: MyTrainingRequestFilterInput;
};


export type QueryPayrollArgs = {
  id: Scalars['ID'];
};


export type QueryPayrollCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryPayrollItemArgs = {
  id: Scalars['ID'];
};


export type QueryProductInterestArgs = {
  input: GetProductInterestInput;
};


export type QueryQaQueryTrainingCertificatesArgs = {
  courseTicketId: Scalars['String'];
};


export type QueryQueryEnrolledTopicsArgs = {
  companyId: Scalars['String'];
};


export type QueryQueryWebinarsArgs = {
  advisor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  tier?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryRecommendationsArgs = {
  data: RecommendationFilterInput;
};


export type QueryRemoveSeatsInfoArgs = {
  data: RemoveSeatsInfoInput;
};


export type QueryRequestsArgs = {
  data: RequestFilterInput;
};


export type QueryResignationDownloadArgs = {
  data: GetResignationInput;
};


export type QueryResignationExistsArgs = {
  data: GetExistingResignationByEmployeeInput;
};


export type QueryResignationInfoArgs = {
  data: GetResignationInput;
};


export type QueryResignationListArgs = {
  data: GetResignationsInput;
};


export type QueryScratchListServiceAddonsArgs = {
  input: ScratchServiceAddonListInput;
};


export type QueryTerminationDownloadArgs = {
  data: GetTerminationInput;
};


export type QueryTerminationExistsArgs = {
  data: GetExistingTerminationByEmployeeInput;
};


export type QueryTerminationInfoArgs = {
  data: GetTerminationInput;
};


export type QueryTerminationListArgs = {
  data: GetTerminationsInput;
};


export type QueryTimesheetsArgs = {
  companyId: Scalars['ID'];
  filter?: InputMaybe<TimesheetFilter>;
};


export type QueryUserArgs = {
  data: UserQueryInput;
};


export type QueryUserCoursesArgs = {
  data: UserCoursesInput;
};


export type QueryWarningDocumentsArgs = {
  data: GetUserWarningDocumentsInput;
};

export type RawAiPrompt = {
  /** The text of the prompt, optionally included, containing questions, statements, or relevant information for the chat interaction. */
  content?: InputMaybe<Scalars['String']>;
  /** An optional identifier for the prompt, used for reference or categorization within the chat framework. */
  name?: InputMaybe<Scalars['String']>;
  /** Defines the prompt's function within the chat, such as initiating a request or guiding the conversation, based on ChatCompletionRequestMessageRoleEnum. */
  role: ChatCompletionRequestMessageRoleEnum;
};

/** Type of reaction to a recommendation. */
export enum ReactionType {
  Accept = 'ACCEPT',
  Decline = 'DECLINE'
}

export type ReactivatePausedCompanyInput = {
  companyId: Scalars['String'];
};

/** The demographic target of the recommendation. */
export enum RecommendationDemographic {
  Id = 'ID',
  Industry = 'INDUSTRY',
  Role = 'ROLE',
  State = 'STATE',
  Tier = 'TIER'
}

export type RecommendationFilterInput = {
  /** The id of the company to fetch recommendations for. */
  companyId?: InputMaybe<Scalars['String']>;
  /** The target demographic of the recommendation */
  demographic?: InputMaybe<RecommendationDemographic>;
  /** The description of the recommendation. */
  note?: InputMaybe<Scalars['String']>;
  /** The id of the resource. */
  resourceId?: InputMaybe<Scalars['String']>;
  /** The resource type of the recommendation. */
  resourceType?: InputMaybe<RequestResourceType>;
  /** The scope of the recommendation */
  scope: RecommendationScope;
  /** The status of the recommendation. */
  status?: InputMaybe<RecommendationStatus>;
  /** The targets of the recommendation */
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The user id of the employee to fetch recommendations for. */
  userId?: InputMaybe<Scalars['String']>;
};

export type RecommendationInput = {
  /** The types of reactions allowed. */
  allowedReactions?: InputMaybe<Array<InputMaybe<ReactionType>>>;
  /** The company id of the employee recommending training. */
  companyId: Scalars['String'];
  /** The target demographic of the recommendation */
  demographic: RecommendationDemographic;
  /** The description of the recommendation. */
  note?: InputMaybe<Scalars['String']>;
  /** The roles of the users allowed to react. */
  reactorRoles?: InputMaybe<Array<InputMaybe<AuthRole>>>;
  /** The id of the resource. */
  resourceId: Scalars['String'];
  /** The resource type of the recommendation. */
  resourceType: RequestResourceType;
  /** The scope of the recommendation */
  scope: RecommendationScope;
  /** The targets of the recommendation */
  targets: Array<Scalars['String']>;
  /** The user id of the employee recommending training. */
  userId: Scalars['String'];
};

export type RecommendationReactionInput = {
  /** The reaction type */
  reaction: ReactionType;
  /** The id of the recommendation to be reacted to. */
  recommendationId: Scalars['String'];
};

/** The scope of the recommendation. */
export enum RecommendationScope {
  Company = 'COMPANY',
  Employee = 'EMPLOYEE',
  System = 'SYSTEM'
}

/** The status of the recommendation. */
export enum RecommendationStatus {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

export type RefundCreditsInput = {
  companyId: Scalars['String'];
  consumptionDescription: Scalars['String'];
  consumptionReason: ProductCreditConsumptionReason;
  productKey: ProductKey;
  stripeInvoiceId: Scalars['String'];
};

export type RefundInvoiceItem = {
  __typename?: 'RefundInvoiceItem';
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  refund: Scalars['Float'];
  unitPrice: Scalars['Float'];
};

export type RefundResult = {
  __typename?: 'RefundResult';
  creditsRefunded?: Maybe<Array<Credit>>;
  id: Scalars['ID'];
  totalRefunded: Scalars['Int'];
};

export type Reimbursement = {
  __typename?: 'Reimbursement';
  amount: Scalars['Float'];
  id: Scalars['ID'];
  /** Source of Reimbursement */
  source?: Maybe<SourceType>;
};

export type ReimbursementInput = {
  amount: Scalars['Float'];
  id?: InputMaybe<Scalars['ID']>;
  /** Source of Reimbursement */
  source?: InputMaybe<SourceType>;
};

export type ReinstateEmployeeInput = {
  employeeId: Scalars['String'];
};

export type RemoveAutoAssignHrManagerInput = {
  stateGroup: Scalars['ID'];
  userId: Scalars['ID'];
};

export type RemoveBundleSeatsInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  numSeats: Scalars['Int'];
  skipRefund?: InputMaybe<Scalars['Boolean']>;
};

export type RemoveCompanyTrainingInput = {
  companyId: Scalars['String'];
};

export type RemoveCompanyTrainingResponse = {
  __typename?: 'RemoveCompanyTrainingResponse';
  id: Scalars['ID'];
};

export type RemoveCreditsInput = {
  companyId: Scalars['String'];
  consumptionDescription: Scalars['String'];
  consumptionReason: ProductCreditConsumptionReason;
  /** Subkey of the product, if consuming managed service credit */
  consumptionSubKey?: InputMaybe<ProductSubKey>;
  productKey: ProductKey;
  quantity: Scalars['Int'];
};

export type RemovePaymentMethodInput = {
  companyId: Scalars['String'];
  paymentMethodId: Scalars['String'];
};

export type RemoveSeatsInfoInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  numSeats: Scalars['Int'];
  skipRefund?: InputMaybe<Scalars['Boolean']>;
  topicId?: InputMaybe<Scalars['String']>;
};

export type RemoveSeatsInfoResponse = {
  __typename?: 'RemoveSeatsInfoResponse';
  id: Scalars['ID'];
  invoices: Array<RefundInvoiceItem>;
  refund: Scalars['Float'];
  seats: Scalars['Int'];
};

export type RemoveSeatsInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  numSeats: Scalars['Int'];
  skipRefund?: InputMaybe<Scalars['Boolean']>;
  topicId?: InputMaybe<Scalars['String']>;
};

export type RemoveSeatsResponse = {
  __typename?: 'RemoveSeatsResponse';
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  numRemoved: Scalars['Int'];
  totalSeats: Scalars['Int'];
};

export type ReopenPayrollInput = {
  id: Scalars['ID'];
};

export type ReplyToConversationInput = {
  conversationId: Scalars['String'];
  message: Scalars['String'];
};

export type ReplyToHrRequestAsAdminInput = {
  hrRequestId: Scalars['String'];
  message: Scalars['String'];
};

export type ReportCard = {
  __typename?: 'ReportCard';
  /** frequency of being created */
  cadence: Scalars['String'];
  company: CoreCompany;
  createdAt: Scalars['DateTime'];
  createdBy: CoreUser;
  directReports?: Maybe<Array<CoreUser>>;
  employees: Array<ReportCardEmployeeGrade>;
  /** ReportCardId */
  id: Scalars['ID'];
  managerReports?: Maybe<Array<ManagedReport>>;
  status: ReportCardStatusValues;
  updateHistory: Array<Maybe<ReportCardEmployeeGradeHistory>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReportCardEmployeeGrade = {
  __typename?: 'ReportCardEmployeeGrade';
  employee: CoreUser;
  /** External / public to employee feedback */
  feedback: Scalars['String'];
  /** Employee Grade */
  grade: Scalars['String'];
  /** The person who graded this report card */
  gradedBy: CoreUser;
  /** Unique Report Card Grade Id */
  id: Scalars['ID'];
  /** Owner / Manager Internal Employee Note */
  note: Scalars['String'];
  /** Status of employee grade filled out */
  status: Scalars['String'];
};

export type ReportCardEmployeeGradeHistory = {
  __typename?: 'ReportCardEmployeeGradeHistory';
  employee: CoreUser;
  /** External / public to employee feedback */
  feedback: Scalars['String'];
  /** Employee Grade */
  grade: Scalars['String'];
  gradedBy: CoreUser;
  /** Unique Report Card Grade History Id */
  id: Scalars['ID'];
  /** Owner / Manager Internal Employee Note */
  note: Scalars['String'];
};

export enum ReportCardGradeValues {
  A = 'A',
  B = 'B',
  C = 'C',
  Pending = 'PENDING',
  Skip = 'SKIP'
}

export enum ReportCardStatusValues {
  Completed = 'COMPLETED',
  Created = 'CREATED'
}

export type RequestApprovalInput = {
  /** The id of the request. */
  requestId: Scalars['String'];
};

export type RequestCancelInput = {
  /** The id of the request. */
  requestId: Scalars['String'];
};

export type RequestFilterInput = {
  /** The description of the request. */
  description?: InputMaybe<Scalars['String']>;
  /** The id of the resource. */
  resourceId?: InputMaybe<Scalars['String']>;
  /** The resource type of the request. */
  resourceType?: InputMaybe<RequestResourceType>;
  /** The status of the request. */
  status?: InputMaybe<RequestStatus>;
  /** The user id of the employee requesting training. */
  userId?: InputMaybe<Scalars['String']>;
};

export type RequestInput = {
  /** The number of approvals required to approve the request. */
  approvalCriteria: Scalars['Int'];
  /** The roles of the decision makers. */
  approverRoles?: InputMaybe<Array<InputMaybe<AuthRole>>>;
  /** The company id of the employee requesting training. */
  companyId: Scalars['String'];
  /** The description of the request. */
  description?: InputMaybe<Scalars['String']>;
  /** The number of rejections required to reject the request. */
  rejectionCriteria: Scalars['Int'];
  /** The id of the resource. */
  resourceId: Scalars['String'];
  /** The resource type of the request. */
  resourceType: RequestResourceType;
  /** The user id of the employee requesting training. */
  userId: Scalars['String'];
};

export type RequestRejectionInput = {
  /** The id of the request. */
  requestId: Scalars['String'];
};

/** The resource type of the request. */
export enum RequestResourceType {
  TrainingBundle = 'TRAINING_BUNDLE',
  TrainingTopic = 'TRAINING_TOPIC'
}

/** The status of the request. */
export enum RequestStatus {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type Requirement = {
  __typename?: 'Requirement';
  checkId?: Maybe<Scalars['ID']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  documentOptions: Array<DocumentOption>;
  errors: Array<RequirementError>;
  id: Scalars['ID'];
  /** Human readable name of the requirement type */
  requirementName: Scalars['String'];
  requirementType: RequirementType;
  requirementTypeExt?: Maybe<Scalars['String']>;
  status: RequirementStatus;
  /** @deprecated Use files array instead */
  uploadedDocumentType?: Maybe<DocumentType>;
  /** @deprecated Use files array instead */
  uploadedFileName?: Maybe<Scalars['String']>;
  /** @deprecated Use files array instead */
  uploadedFileType?: Maybe<FileType>;
  /** Array of uploaded files */
  uploadedFiles?: Maybe<Array<UploadedFile>>;
};

export type RequirementError = {
  __typename?: 'RequirementError';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  type: RequirementErrorConst;
};

export enum RequirementErrorConst {
  ProvidedDocumentBankAccountMissing = 'PROVIDED_DOCUMENT_BANK_ACCOUNT_MISSING',
  ProvidedDocumentBankNameMissing = 'PROVIDED_DOCUMENT_BANK_NAME_MISSING',
  ProvidedDocumentBankRoutingMissing = 'PROVIDED_DOCUMENT_BANK_ROUTING_MISSING',
  ProvidedDocumentEdited = 'PROVIDED_DOCUMENT_EDITED',
  ProvidedDocumentFailedOther = 'PROVIDED_DOCUMENT_FAILED_OTHER',
  ProvidedDocumentFederalEinMissing = 'PROVIDED_DOCUMENT_FEDERAL_EIN_MISSING',
  ProvidedDocumentIncorrectPerson = 'PROVIDED_DOCUMENT_INCORRECT_PERSON',
  ProvidedDocumentLegalNameMissing = 'PROVIDED_DOCUMENT_LEGAL_NAME_MISSING',
  ProvidedDocumentNotReadable = 'PROVIDED_DOCUMENT_NOT_READABLE',
  ProvidedDocumentTypeNotSupported = 'PROVIDED_DOCUMENT_TYPE_NOT_SUPPORTED'
}

export enum RequirementStatus {
  Closed = 'CLOSED',
  Failed = 'FAILED',
  Open = 'OPEN',
  Pending = 'PENDING',
  Resolved = 'RESOLVED'
}

export enum RequirementType {
  BankAccountVerification = 'BANK_ACCOUNT_VERIFICATION',
  CompanyFinancialVerification = 'COMPANY_FINANCIAL_VERIFICATION',
  CompanyIdentityVerification = 'COMPANY_IDENTITY_VERIFICATION',
  FederalEinVerification = 'FEDERAL_EIN_VERIFICATION',
  NonProfitOrganization = 'NON_PROFIT_ORGANIZATION',
  PreviousPayrollProviderAccess = 'PREVIOUS_PAYROLL_PROVIDER_ACCESS',
  ResolveFundingFailure = 'RESOLVE_FUNDING_FAILURE',
  SignatoryAuthorizationVerification = 'SIGNATORY_AUTHORIZATION_VERIFICATION',
  SignatoryIdentityVerification = 'SIGNATORY_IDENTITY_VERIFICATION',
  StateRegistrationVerification = 'STATE_REGISTRATION_VERIFICATION',
  TaxParameter = 'TAX_PARAMETER'
}

export type ResetCompanyInput = {
  /** Bambee company id */
  companyId: Scalars['ID'];
};

export type ResetDeputyInput = {
  companyId: Scalars['ID'];
};

export type Resignation = {
  __typename?: 'Resignation';
  complete: Scalars['Boolean'];
  confirmed: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  employee: User;
  employeeId: Scalars['String'];
  hrRequestId?: Maybe<Scalars['String']>;
  hrRequestVersion: Scalars['Boolean'];
  isLegacy: Scalars['Boolean'];
  manager: User;
  resignationDate?: Maybe<Scalars['DateTime']>;
  resignationId: Scalars['String'];
  resignationState?: Maybe<Scalars['String']>;
  selfService: Scalars['Boolean'];
  signedFiles: Array<FileStackModel>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: ResignationStatus;
  step: ResignationStep;
  user: User;
  userId: Scalars['String'];
};

export type ResignationAttachInput = {
  files: Array<FileStackInput>;
  resignationId: Scalars['String'];
};

export type ResignationCancelInput = {
  resignationId: Scalars['String'];
};

export type ResignationConfirmationInput = {
  resignationDate: Scalars['String'];
  resignationId: Scalars['String'];
  userId: Scalars['String'];
};

export type ResignationCreateInput = {
  answers?: InputMaybe<Scalars['JSON']>;
  employeeId: Scalars['String'];
  hrRequestId?: InputMaybe<Scalars['String']>;
  resignationDate?: InputMaybe<Scalars['String']>;
  selfService?: InputMaybe<Scalars['Boolean']>;
  state: Scalars['String'];
  userId: Scalars['String'];
};

export type ResignationDownload = {
  __typename?: 'ResignationDownload';
  file: Scalars['String'];
  resignationId: Scalars['String'];
};

export type ResignationEditDetailsInput = {
  resignationDate: Scalars['String'];
  resignationId: Scalars['String'];
  selfService?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type ResignationExist = {
  __typename?: 'ResignationExist';
  exists: Scalars['Boolean'];
  resignationId?: Maybe<Scalars['String']>;
};

export enum ResignationStatus {
  AwaitingAcknowledgement = 'AWAITING_ACKNOWLEDGEMENT',
  AwaitingAproval = 'AWAITING_APROVAL',
  AwaitingSignature = 'AWAITING_SIGNATURE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  HrComplete = 'HR_COMPLETE',
  SelfService = 'SELF_SERVICE',
  Started = 'STARTED'
}

export enum ResignationStep {
  None = 'NONE',
  Print = 'PRINT',
  Requested = 'REQUESTED',
  UploadSigned = 'UPLOAD_SIGNED'
}

export enum SsnSteps {
  SsnSubmitted = 'SSN_SUBMITTED'
}

export type SalesforceCase = {
  __typename?: 'SalesforceCase';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  hrRequestId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  status?: Maybe<CaseStatus>;
  subType?: Maybe<CaseSubType>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<CaseType>;
};

export type SaveCompanyAnswerInput = {
  companyId: Scalars['String'];
  key: Scalars['String'];
  surveyId: Scalars['Float'];
  value: Scalars['String'];
};

export type SaveCompanySettingInput = {
  boolValue?: InputMaybe<Scalars['Boolean']>;
  cadenceValue?: InputMaybe<PracticeCadenceValue>;
  companyId: Scalars['String'];
  key: CompanySettingKey;
  type?: InputMaybe<SettingType>;
  value?: InputMaybe<Scalars['String']>;
};

export type SaveJobDescriptionOnEmployeeInput = {
  jobDescriptionId: Scalars['String'];
  update?: InputMaybe<UpdateJobDescriptionInput>;
  userId: Scalars['String'];
};

export type ScheduledCorePlanChangeModel = {
  __typename?: 'ScheduledCorePlanChangeModel';
  effectiveOn: Scalars['DateTime'];
  /** The ID of the scheduled plan change. */
  id: Scalars['ID'];
  newPlan: CorePlanModel;
};

export type ScheduledEvent = {
  __typename?: 'ScheduledEvent';
  /** Unique Calendly Event ID */
  calendlyEventId: Scalars['String'];
  /** The invitee ID from Calendly */
  calendlyInviteeId: Scalars['String'];
  /** End time of the scheduled event */
  endTime: Scalars['DateTime'];
  /** Event type */
  eventLocation: SchedulerEventLocation;
  id: Scalars['ID'];
  /** The Contacts Phone number that was defined as part of the scheduled event */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Start time of the scheduled event */
  startTime: Scalars['DateTime'];
  /** Calendly Event Name + x */
  title: Scalars['String'];
  /** The video conference link (zoom) */
  videoConferenceLink?: Maybe<Scalars['String']>;
};

export type ScheduledPlanChangeModel = {
  __typename?: 'ScheduledPlanChangeModel';
  effectiveOn: Scalars['DateTime'];
  /** The ID of the scheduled plan change. */
  id: Scalars['ID'];
  newPlan: PlanModel;
};

export enum SchedulerEventLocation {
  Phone = 'PHONE',
  Video = 'VIDEO'
}

export enum SchedulerZone {
  East = 'EAST',
  West = 'WEST'
}

export type ScratchServiceAddonListInput = {
  companyId?: InputMaybe<Scalars['String']>;
};

export type ScratchServiceAddonsModel = {
  __typename?: 'ScratchServiceAddonsModel';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  key: Scalars['String'];
  label: Scalars['String'];
  priceLabel: Scalars['String'];
};

export type ScratchToggleServiceAddonInput = {
  companyId: Scalars['String'];
  id: Scalars['String'];
};

export type SeatsSummary = {
  __typename?: 'SeatsSummary';
  enrolledUsers: Array<EnrolledUser>;
  id: Scalars['ID'];
  totalSeats: Scalars['Float'];
  unfilledSeats: Scalars['Float'];
};

export type SendJobDescriptionToEmployeeInput = {
  jobDescriptionId: Scalars['String'];
  userId: Scalars['String'];
};

export type SendStaffOnboardInviteInput = {
  contractorIds: Array<InputMaybe<Scalars['ID']>>;
  employeeIds: Array<InputMaybe<Scalars['ID']>>;
};

/** The type of message according to sendbird */
export enum SendbirdMessageType {
  /** Not currently in use */
  Admin = 'Admin',
  /** A binary file, such as an image or other downloadable content. */
  File = 'File',
  /** A text message.  This can be a standard message of just text, or a serialized payload for Bambee customized messages */
  Message = 'Message'
}

export enum ServiceKey {
  AutoCompliance = 'AUTO_COMPLIANCE',
  BambeePlus = 'BAMBEE_PLUS',
  Calls = 'CALLS',
  Core = 'CORE',
  Nectar = 'NECTAR',
  Payroll = 'PAYROLL',
  TimeAttendance = 'TIME_ATTENDANCE',
  Training = 'TRAINING'
}

export type SetDefaultPaymentMethodInput = {
  companyId: Scalars['String'];
  paymentMethodId: Scalars['String'];
};

export type Setting = {
  __typename?: 'Setting';
  defaultValue: Scalars['String'];
  key: CompanySettingKey;
  type: SettingType;
};

export enum SettingType {
  BooleanType = 'BooleanType',
  StringType = 'StringType'
}

export type SetupContractorInput = {
  /** Check ID ( optionally provide if setup directly in Check and need to setup in Bambee after the fact ) */
  checkId?: InputMaybe<Scalars['ID']>;
  /** Bambee company ID */
  companyId: Scalars['ID'];
  /** Bambee contractor ID */
  contractorId: Scalars['ID'];
  /** Bambee workplace ID */
  workplaceId?: InputMaybe<Scalars['ID']>;
};

export type SetupEmployeeInput = {
  /** Check ID ( optionally provide if setup directly in Check and need to setup in Bambee after the fact ) */
  checkId?: InputMaybe<Scalars['ID']>;
  /** Bambee company ID */
  companyId: Scalars['ID'];
  /** Bambee employee ID */
  employeeId: Scalars['ID'];
  /** Bambee workplace ID */
  workplaceId?: InputMaybe<Scalars['ID']>;
};

export enum SourceType {
  Deputy = 'DEPUTY',
  Manual = 'MANUAL',
  Upload = 'UPLOAD'
}

export enum StaffStatus {
  /** @deprecated use the indiviudal active field instead */
  Active = 'ACTIVE',
  /** @deprecated use the indiviudal active field instead */
  Inactive = 'INACTIVE',
  /** @deprecated use the individual signatureåRequired field instead */
  SignatureRequired = 'SIGNATURE_REQUIRED'
}

export enum State {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Fm = 'FM',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mh = 'MH',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Pw = 'PW',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

/** The abbreviation of the US state or territory. */
export enum StateAbbreviation {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Fm = 'FM',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mh = 'MH',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Pw = 'PW',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

export type Statement = {
  __typename?: 'Statement';
  amount: Scalars['Float'];
  attemptCount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  hostedUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastAttemptError?: Maybe<Scalars['Float']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  pdfUrl?: Maybe<Scalars['String']>;
  productKey?: Maybe<ProductKey>;
  quantity?: Maybe<Scalars['Float']>;
  serviceKey?: Maybe<ServiceKey>;
  statementType: StatementType;
  status: StatementStatus;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
};

export enum StatementStatus {
  Failed = 'FAILED',
  Issued = 'ISSUED',
  Paid = 'PAID',
  Uncollectible = 'UNCOLLECTIBLE',
  Voided = 'VOIDED'
}

export enum StatementType {
  CreditNote = 'CREDIT_NOTE',
  Invoice = 'INVOICE'
}

export type StringNullableFilter = {
  equals?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  amount: Scalars['Int'];
  coupon?: Maybe<PlanCoupon>;
  createdAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  isDrp: Scalars['Boolean'];
  name: Scalars['String'];
  planValue: Scalars['String'];
  productCode: ProductKey;
  serviceKey: ServiceKey;
  startDate: Scalars['DateTime'];
  status: SubscriptionStatus;
  stripeId: Scalars['String'];
  stripePriceId: Scalars['String'];
};

export type SubscriptionModel = {
  __typename?: 'SubscriptionModel';
  /** Subscription Status */
  active: Scalars['Boolean'];
  /** True when there is a pending cancellation request */
  cancellationRequested: Scalars['Boolean'];
  /** A scheduled date for when the subscription will be canceled */
  cancelsAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the subscription. */
  id: Scalars['ID'];
  /** Date when the current period will end */
  periodEndsAt: Scalars['DateTime'];
  plan: PlanModel;
  /** The quantity of the plan to which the customer is subscribed to. */
  quantity?: Maybe<Scalars['Float']>;
  /** Scheduled plan change, if any. */
  scheduledPlanChange?: Maybe<ScheduledPlanChangeModel>;
  /** Date when the subscription trial will end */
  trialEndsAt?: Maybe<Scalars['DateTime']>;
  /** True if they are actively trialing */
  trialing: Scalars['Boolean'];
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
  Trialing = 'TRIALING'
}

export enum Subtype {
  Checking = 'CHECKING',
  Savings = 'SAVINGS'
}

export type Survey = {
  __typename?: 'Survey';
  answers: Scalars['JSON'];
  completedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  questions: Scalars['JSON'];
  type: Scalars['String'];
};

export type SurveyAnswer = {
  __typename?: 'SurveyAnswer';
  /** The id of the survey that contains the answer */
  id: Scalars['ID'];
  /** The key of the survey answer */
  key: Scalars['String'];
  /** The value of the survey answer */
  value?: Maybe<Scalars['String']>;
};

export enum SurveyQuestionKey {
  HrAuditBenefitsInterest_1 = 'HR_AUDIT_BENEFITS_INTEREST_1',
  HrAuditBenefitsInterest_2 = 'HR_AUDIT_BENEFITS_INTEREST_2',
  HrAuditPayrollInterest = 'HR_AUDIT_PAYROLL_INTEREST',
  HrAuditPayrollProvider = 'HR_AUDIT_PAYROLL_PROVIDER',
  HrAuditSelfServiceFullAudit = 'HR_AUDIT_SELF_SERVICE_FULL_AUDIT',
  HrPracticePayrollPrePurchased = 'HR_PRACTICE_PAYROLL_PRE_PURCHASED',
  HrPracticeTrainingPrePurchased = 'HR_PRACTICE_TRAINING_PRE_PURCHASED'
}

export type SyncAdded = {
  __typename?: 'SyncAdded';
  courses: Scalars['Int'];
  topics: Scalars['Int'];
};

export type SyncAttempt = {
  __typename?: 'SyncAttempt';
  /** null for sync attempts with status of pending */
  attemptedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  failureReason?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: Scalars['String'];
};

export type SyncAttemptInput = {
  id: Scalars['ID'];
};

export type SyncCompanyInput = {
  /** Bambee company id */
  id: Scalars['ID'];
};

export type SyncContractorInput = {
  /** Check ID ( optionally provide if setup directly in Check and need to setup in Bambee after the fact ) */
  checkId?: InputMaybe<Scalars['ID']>;
  /** Bambee company ID */
  companyId: Scalars['ID'];
  /** Bambee contractor ID */
  contractorId: Scalars['ID'];
  /** If the workplace values should be synced to Check as well */
  syncWorkplaces?: InputMaybe<Scalars['Boolean']>;
  /** Bambee workplace ID */
  workplaceId?: InputMaybe<Scalars['ID']>;
};

export type SyncEmployeeInput = {
  /** Check ID ( optionally provide if setup directly in Check and need to setup in Bambee after the fact ) */
  checkId?: InputMaybe<Scalars['ID']>;
  /** Bambee company ID */
  companyId: Scalars['ID'];
  /** Bambee employee ID */
  employeeId: Scalars['ID'];
  /** If the workplace values should be synced to Check as well */
  syncWorkplaces?: InputMaybe<Scalars['Boolean']>;
  /** Bambee workplace ID */
  workplaceId?: InputMaybe<Scalars['ID']>;
};

export type SyncRequirementInput = {
  companyId: Scalars['String'];
};

export type SyncResponse = {
  __typename?: 'SyncResponse';
  added: SyncAdded;
};

export type SyncTimesheetsInput = {
  /** Bambee company id */
  companyId: Scalars['ID'];
  /** Bambee payroll ID */
  id: Scalars['Int'];
  /** Import all timesheets as part of the sync ( enables backwards compatibility with alpha )  */
  import?: Scalars['Boolean'];
};

export type SyncUserEnrollmentInput = {
  userId: Scalars['String'];
};

export type SyncUserEnrollmentsResponse = {
  __typename?: 'SyncUserEnrollmentsResponse';
  errors: Array<UserEnrollmentSyncError>;
  skipped: Array<UserEnrollmentSync>;
  successes: Array<UserEnrollmentSync>;
};

export type SystemConfiguration = {
  __typename?: 'SystemConfiguration';
  businessManagers: Array<BusinessManager>;
  hrManagers: Array<AutoAssignHrManager>;
};

/** The enum name of the task completion status. */
export enum TaskCompletionStatus {
  Automatic = 'Automatic',
  Cancelled = 'Cancelled',
  Manual = 'Manual',
  Skipped = 'Skipped'
}

/** The enum name of the task. */
export enum TaskName {
  AddBankAndTaxInfo = 'AddBankAndTaxInfo',
  AddBenefits = 'AddBenefits',
  AddCompanyEin = 'AddCompanyEin',
  AddPostTaxDeductions = 'AddPostTaxDeductions',
  AddStaff = 'AddStaff',
  CheckForComplexPayrollScenarios = 'CheckForComplexPayrollScenarios',
  ConsiderGuidedPayroll = 'ConsiderGuidedPayroll',
  CreateJobDescriptions = 'CreateJobDescriptions',
  DownloadInterviewGuide = 'DownloadInterviewGuide',
  DownloadIntroductionMaterials = 'DownloadIntroductionMaterials',
  DownloadJobApplication = 'DownloadJobApplication',
  EnsureEmployeesReadyForPayroll = 'EnsureEmployeesReadyForPayroll',
  FinishAccountSetup = 'FinishAccountSetup',
  FinishBambeePlusSetup = 'FinishBambeePlusSetup',
  InviteAndEnrollEmployees = 'InviteAndEnrollEmployees',
  InviteStaffToSignPolicies = 'InviteStaffToSignPolicies',
  LearnEssentialBusinessPrep = 'LearnEssentialBusinessPrep',
  LearnHrAudit = 'LearnHrAudit',
  LearnImproveStaffCommunication = 'LearnImproveStaffCommunication',
  LearnIntroduceStaffToBambee = 'LearnIntroduceStaffToBambee',
  LearnMakeHiringEasy = 'LearnMakeHiringEasy',
  LearnOnboardingKitBasics = 'LearnOnboardingKitBasics',
  MigratePayrollData = 'MigratePayrollData',
  PayrollBasicSetup = 'PayrollBasicSetup',
  RunFirstPayroll = 'RunFirstPayroll',
  ScheduleHrmCall = 'ScheduleHrmCall',
  SchedulePayrollOverview = 'SchedulePayrollOverview',
  ScheduleTrainingDiscussion = 'ScheduleTrainingDiscussion',
  SetYourPayrollFrequency = 'SetYourPayrollFrequency',
  SetupCompanyPolicies = 'SetupCompanyPolicies',
  SetupEmployeeVoices = 'SetupEmployeeVoices',
  SetupReportCards = 'SetupReportCards',
  TakeHrAudit = 'TakeHrAudit',
  TrainingHiringLegallyForManagers = 'TrainingHiringLegallyForManagers',
  TrainingRecruitingAndInterviewingForSupervisors = 'TrainingRecruitingAndInterviewingForSupervisors',
  UploadDocuments = 'UploadDocuments'
}

export type Tax = {
  __typename?: 'Tax';
  amount: Scalars['Float'];
  checkId: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
  payer: Payer;
  remittable?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use id or checkId */
  tax: Scalars['ID'];
};

export type TaxDocument = Document & {
  __typename?: 'TaxDocument';
  companyId?: Maybe<Scalars['ID']>;
  contractorId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['ID']>;
  /** Date that this document was filed */
  filedOn: Scalars['String'];
  id: Scalars['ID'];
  jurisdiction?: Maybe<Scalars['String']>;
  /** Document’s type */
  label: Scalars['String'];
  /** Quarter that this document was filed; null if this document is filed annually */
  quarter?: Maybe<Quarter>;
  /** Year that this document was filed */
  year: Scalars['Float'];
};

export enum TaxDocumentClass {
  Company = 'COMPANY',
  Contractor = 'CONTRACTOR',
  Employee = 'EMPLOYEE'
}

export enum TaxExemptStatus {
  NonProfitExempt = 'NON_PROFIT_EXEMPT',
  ReligiousExempt = 'RELIGIOUS_EXEMPT'
}

export type Termination = {
  __typename?: 'Termination';
  complete: Scalars['Boolean'];
  confirmed: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  employee: User;
  employeeId: Scalars['String'];
  hrRequestId?: Maybe<Scalars['String']>;
  hrRequestVersion: Scalars['Boolean'];
  isLegacy: Scalars['Boolean'];
  manager: User;
  selfService: Scalars['Boolean'];
  signedFiles: Array<FileStackModel>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: TerminationStatus;
  step: TerminationStep;
  terminationDate?: Maybe<Scalars['DateTime']>;
  terminationId: Scalars['String'];
  terminationState?: Maybe<Scalars['String']>;
  user: User;
  userId: Scalars['String'];
};

export type TerminationAttachInput = {
  files: Array<FileStackInput>;
  terminationId: Scalars['String'];
};

export type TerminationCancelInput = {
  terminationId: Scalars['String'];
};

export type TerminationConfirmationInput = {
  terminationDate: Scalars['String'];
  terminationId: Scalars['String'];
};

export type TerminationCreateInput = {
  answers?: InputMaybe<Scalars['JSON']>;
  employeeId: Scalars['String'];
  hrRequestId?: InputMaybe<Scalars['String']>;
  selfService?: InputMaybe<Scalars['Boolean']>;
  state: Scalars['String'];
  terminationDate?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type TerminationDownload = {
  __typename?: 'TerminationDownload';
  file: Scalars['String'];
  terminationId: Scalars['String'];
};

export type TerminationEditDetailsInput = {
  selfService?: InputMaybe<Scalars['Boolean']>;
  terminationDate: Scalars['String'];
  terminationId: Scalars['String'];
};

export type TerminationExist = {
  __typename?: 'TerminationExist';
  exists: Scalars['Boolean'];
  terminationId?: Maybe<Scalars['String']>;
};

export enum TerminationStatus {
  Abandoned = 'ABANDONED',
  Active = 'ACTIVE',
  Broken = 'BROKEN',
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  HrComplete = 'HR_COMPLETE',
  SelfService = 'SELF_SERVICE'
}

export enum TerminationStep {
  None = 'NONE',
  Print = 'PRINT',
  Requested = 'REQUESTED',
  UploadSigned = 'UPLOAD_SIGNED'
}

export type TextMessage = ChatMessageInterface & {
  __typename?: 'TextMessage';
  channelId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isThreadMessage: Scalars['Boolean'];
  isThreadParent: Scalars['Boolean'];
  message: Scalars['String'];
  rawType: SendbirdMessageType;
  /** Recipieint, if a one-on-one message */
  recipient?: Maybe<CoreUser>;
  sender: CoreUser;
  threadParentId?: Maybe<Scalars['ID']>;
  type: ChatMessageTypeEnum;
};

export type ThreadHistoryInput = {
  afterId?: InputMaybe<Scalars['ID']>;
  count?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type Ticket = {
  __typename?: 'Ticket';
  bundleId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  courseId?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  startedAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  topicId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type TicketsInput = {
  companyId: Scalars['String'];
  numTickets: Scalars['Int'];
};

export type TimeAttendanceSubscriptionResponse = {
  __typename?: 'TimeAttendanceSubscriptionResponse';
  companyProductId: Scalars['String'];
  id: Scalars['String'];
  subscriptionId: Scalars['String'];
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  /** The duration in minutes for the slot */
  duration: Scalars['Int'];
  /** The end date for the slot */
  endAt: Scalars['DateTime'];
  /** The start date for the slot */
  startAt: Scalars['DateTime'];
};

export type Timesheet = {
  __typename?: 'Timesheet';
  /** Deputy employee id */
  deputyEmployeeId: Scalars['ID'];
  /**
   * Deputy employee id
   * @deprecated Use deputyEmployeeId instead
   */
  deputyId: Scalars['Int'];
  earnings: Array<Earning>;
  employee: Employee;
  /** The total time for the timesheet in hours */
  hours: Scalars['Float'];
  /** Deputy timesheet id */
  id: Scalars['ID'];
  payReturns?: Maybe<Array<TimesheetPayReturn>>;
  /** Role worker performed as */
  role?: Maybe<Scalars['String']>;
};

export type TimesheetAggregate = {
  __typename?: 'TimesheetAggregate';
  /** The holiday hours in timesheets */
  holidayHours: Scalars['Float'];
  /** The overtime hours in timesheets */
  overtimeHours: Scalars['Float'];
  /** The PTO hours in timesheets */
  ptoHours: Scalars['Float'];
  /** The regular hours in timesheets */
  regularHours: Scalars['Float'];
  /** Role worker performed as */
  role?: Maybe<TimesheetRole>;
  /** The sick hours in timesheets */
  sickHours: Scalars['Float'];
  /** The total hours in timesheets */
  totalHours: Scalars['Float'];
};

export type TimesheetFilter = {
  /** Find approved if true, find unapproved if false, do not filter on approval status if not provided. */
  approved?: InputMaybe<Scalars['Boolean']>;
  /** Find timesheets for a particular contractor */
  contractorId?: InputMaybe<Scalars['String']>;
  /** Find timesheets for a particular employee */
  employeeId?: InputMaybe<Scalars['String']>;
  /** Find timesheets less than or equal to end date */
  end?: InputMaybe<Scalars['DateTime']>;
  /** Find timesheets for a particular payroll (this will overwrite start/end fields in filter (Bambee Payroll Id) */
  payrollId?: InputMaybe<Scalars['Int']>;
  /** Find timesheets greater than or equal to start date */
  start?: InputMaybe<Scalars['DateTime']>;
};

export type TimesheetPayReturn = {
  __typename?: 'TimesheetPayReturn';
  /** The cost of the pay return */
  cost: Scalars['Float'];
  /** The multiplier value of the pay return */
  earningType: EarningType;
  /** Deputy pay return id */
  id: Scalars['ID'];
  /** The multiplier of the pay return */
  multiplier: Scalars['Float'];
  /** The value of the pay return */
  value: Scalars['Float'];
};

export type TimesheetRole = {
  __typename?: 'TimesheetRole';
  /** Pay rate of the role worker performed as */
  payRate: Scalars['String'];
  /** Title of the role worker performed as */
  title: Scalars['String'];
};

export type TopicEnrolledTickets = {
  __typename?: 'TopicEnrolledTickets';
  certificateDownloadUrl?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  enrolledAt: Scalars['DateTime'];
  id: Scalars['ID'];
  language: Scalars['String'];
  role: Scalars['String'];
  startedAt?: Maybe<Scalars['DateTime']>;
  state?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userMngId: Scalars['String'];
};

export type Total = {
  __typename?: 'Total';
  /** Employees who have not provided their EMPLOYEE_DETAILS */
  missingEmployeeDetails: Scalars['Int'];
  /** Employees who have completed the payroll setup */
  onboardedEmployees: Scalars['Int'];
  /** Employees with a checkId */
  payrollEmployees: Scalars['Int'];
  /** Employees who will be included in time and attendance PEPM */
  timeAttendanceEmployees: Scalars['Int'];
};

export type Totals = {
  __typename?: 'Totals';
  /** The total cash amount that Check will withdraw from the company's account for the payroll after it is approved. */
  cashRequirement: Scalars['Float'];
  /** The total amount of company benefit contributions. */
  companyBenefits: Scalars['Float'];
  /** summary of company earnings */
  companyEarningsSummary?: Maybe<Array<Array<EarningSummaryItem>>>;
  /** The total amount of company tax liability. */
  companyTaxes: Scalars['Float'];
  /** summary of contractor earnings */
  contractorEarningsSummary?: Maybe<Array<Array<EarningSummaryItem>>>;
  /** The total amount of all contractor gross compensation. This is the sum of amount for all contractor payments. */
  contractorGross: Scalars['Float'];
  /** The total amount that should be paid from the company to its contractors, including reimbursements. */
  contractorNet: Scalars['Float'];
  /** The total amount of all contractor reimbursements. */
  contractorReimbursements: Scalars['Float'];
  /** The total amount of all employee benefit contributions. */
  employeeBenefits: Scalars['Float'];
  /** summary of employee earnings */
  employeeEarningsSummary?: Maybe<Array<Array<EarningSummaryItem>>>;
  /** The total amount of all employee gross pay, including taxable and non-taxable components. This is the sum of gross_pay for all payroll items. */
  employeeGross: Scalars['Float'];
  /** The total amount that should be paid from the company to its employees, excluding employee taxes, employee benefit contributions, and imputed income. This is the sum of net_pay for all payroll items. */
  employeeNet: Scalars['Float'];
  /** The total amount of all employee reimbursements. */
  employeeReimbursements: Scalars['Float'];
  /** The total amount of all employee tax liability. */
  employeeTaxes: Scalars['Float'];
  id: Scalars['ID'];
  /** The total amount that the company owes to employees, contractors, tax agencies, and benefits providers. */
  liability: Scalars['Float'];
  /** The total amount of all employee post-tax deductions. */
  postTaxDeductions: Scalars['Float'];
};

export type TrainingPricesResponse = {
  __typename?: 'TrainingPricesResponse';
  bundle: Scalars['Int'];
  topic: Scalars['Int'];
};

export type TrainingRecommendationInput = {
  /** The id of the training bundle. */
  bundleId?: InputMaybe<Scalars['String']>;
  /** The company id of the employee recommending training. */
  companyId: Scalars['String'];
  /** The note of the training recommendation. May be used to provide additional context or instructions */
  note?: InputMaybe<Scalars['String']>;
  /** The id of the training topic. */
  topicId?: InputMaybe<Scalars['String']>;
  /** The user id of the employee recommending training. */
  userId: Scalars['String'];
};

export type TrainingRequestInput = {
  /** The id of the training bundle. */
  bundleId?: InputMaybe<Scalars['String']>;
  /** The company id of the employee requesting training. */
  companyId: Scalars['String'];
  /** The description of the training request. */
  description?: InputMaybe<Scalars['String']>;
  /** The id of the training topic. */
  topicId?: InputMaybe<Scalars['String']>;
  /** The user id of the employee requesting training. */
  userId: Scalars['String'];
};

export type TriggerPayrollRunSnsTopicPublishInput = {
  approvedAtEnd: Scalars['DateTime'];
  approvedAtStart: Scalars['DateTime'];
  companyId?: InputMaybe<Scalars['String']>;
};

export enum TypeSteps {
  TypeSubmitted = 'TYPE_SUBMITTED'
}

export type UnenrollUsersFromBundleInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type UnenrollUsersInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  topicId?: InputMaybe<Scalars['String']>;
  userIds: Array<Scalars['String']>;
};

export type UnenrolledUsers = {
  __typename?: 'UnenrolledUsers';
  errors: Array<Maybe<Scalars['String']>>;
  id: Scalars['ID'];
  successfullyUnenrolled: Array<Maybe<Scalars['String']>>;
};

export type UpcomingCorePlanChangeInvoice = {
  __typename?: 'UpcomingCorePlanChangeInvoice';
  /** Total amount for the remaining time in the new plan */
  remainingAmount: Scalars['Float'];
  /** Tax amount charged for the invoice */
  tax: Scalars['Float'];
  /** Total amount charged for the invoice */
  total: Scalars['Float'];
  /** Total amount due now */
  totalDue: Scalars['Float'];
  /** Total amount for the unused time in the current plan */
  unusedAmount: Scalars['Float'];
};

export type UpdateCompanyInput = {
  checkIndustry?: InputMaybe<IndustryType>;
  /** Timestamp for when company accepted deputy terms of service */
  deputyTosAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  /** A list of Bambee employee IDs who are not on Payroll+ with this company. */
  excludedStaffIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Bambee company id */
  id: Scalars['ID'];
  /** Needs pay schedule assistance */
  needsPayScheduleAssistance?: InputMaybe<Scalars['Boolean']>;
  notifyCustomer?: InputMaybe<Scalars['Boolean']>;
  onboardingGarnishmentsOrDeductions?: InputMaybe<Array<OnboardingGarnishmentOrDeduction>>;
  onboardingGarnishmentsOrDeductionsOther?: InputMaybe<Scalars['String']>;
  onboardingHasComplexScenario?: InputMaybe<Scalars['Boolean']>;
  onboardingHasComplexTimeAttendanceScenario?: InputMaybe<Scalars['Boolean']>;
  onboardingHasGarnishmentsOrDeductions?: InputMaybe<Scalars['Boolean']>;
  onboardingOffersEmployeeBenefits?: InputMaybe<Scalars['Boolean']>;
  /** Past payroll usage ( prior to Bambee ) */
  pastPayrollUsage?: InputMaybe<PastPayrollUsage>;
  processedPayrollsThisYear?: InputMaybe<Scalars['Boolean']>;
  signatoryEmail?: InputMaybe<Scalars['String']>;
  signatoryFirstName?: InputMaybe<Scalars['String']>;
  signatoryLastName?: InputMaybe<Scalars['String']>;
  signatoryTitle?: InputMaybe<Scalars['String']>;
  /** Version of payroll TOS last signed */
  tosVersion?: InputMaybe<Scalars['String']>;
};

export type UpdateCompanyNotesInput = {
  /** Bambee company id */
  id: Scalars['ID'];
  /** notes on the company payroll entity */
  notes: Scalars['String'];
};

export type UpdateCompanyStatusInput = {
  /** Bambee company id */
  id: Scalars['ID'];
  /** state the payroll company should be moved to */
  status: CompanyStatus;
};

export type UpdateContractorPaymentInput = {
  basePayRate?: InputMaybe<Scalars['Float']>;
  checkNumber?: InputMaybe<Scalars['String']>;
  entries: Array<PaymentEntryInput>;
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  noteVisible?: InputMaybe<Scalars['Boolean']>;
  /** The way the employee will be paid */
  paymentMethod?: InputMaybe<PaymentMethod>;
  /** Optimistic locking to prevent stale data clobbering fresher data */
  version: Scalars['Int'];
};

export type UpdateContractorWorkplaceInput = {
  /** Bambee contractor ID */
  contractorId: Scalars['ID'];
  /** Bambee workplace IDs */
  workplaceId: Scalars['ID'];
};

export type UpdateCoreCompanyInput = {
  address?: InputMaybe<CoreAddressInput>;
  dba?: InputMaybe<Scalars['String']>;
  fein?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  industry?: InputMaybe<Scalars['String']>;
  legalAddress?: InputMaybe<CoreLegalAddressInput>;
  mailingAddress?: InputMaybe<CoreMailingAddressInput>;
  name?: InputMaybe<Scalars['String']>;
  numOfContractors?: InputMaybe<Scalars['Int']>;
  numOfFullTimeHourlyEmployees?: InputMaybe<Scalars['Int']>;
  numOfFullTimeSalaryEmployees?: InputMaybe<Scalars['Int']>;
  numOfPartTimeEmployees?: InputMaybe<Scalars['Int']>;
  payDayOfMonth?: InputMaybe<Scalars['Int']>;
  payDayOfWeek?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateEmployeeWorkplaceInput = {
  /** Bambee employee ID */
  employeeId: Scalars['ID'];
  /** Bambee workplace IDs */
  workplaceId: Scalars['ID'];
};

export type UpdateJobDescriptionInput = {
  body?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateMultipleRolesForCompanyInput = {
  /** Bambee Company id */
  companyId: Scalars['ID'];
  /** Company Roles */
  roles: Array<UpdateRoleForCompanyInput>;
};

export type UpdatePayScheduleInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  /** The first date on which employees will be paid for the payroll. */
  firstPayday?: InputMaybe<Scalars['String']>;
  /** The end of the date range that the payroll covers. YYYY-MM-DD */
  firstPeriodEnd?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  payFrequency?: InputMaybe<PayScheduleFrequency>;
  /** The payday date of the the second payroll the company will run on this pay schedule. This field should only be used with the semimonthly frequency to specify custom paydays besides the 15th and last day of the month. second_payday must be between one day and one month after first_payday. */
  secondPayday?: InputMaybe<Scalars['String']>;
};

export type UpdatePaymentSourceAddress = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type UpdatePaymentSourceBillingAddress = {
  address: UpdatePaymentSourceAddress;
  companyId: Scalars['String'];
  paymentSourceId: Scalars['String'];
};

export type UpdatePayrollApprovalDeadlineInput = {
  /** Date by which the payroll must be approved. */
  approvalDeadline: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type UpdatePayrollChecksInput = {
  /** An array of payrollItem ID and check numbers to update */
  checkNumbers: Array<InputMaybe<PayrollItemCheckNumbers>>;
  needsChecksEntered: Scalars['Boolean'];
  /** An array of contractor payment ID and check numbers to update */
  paymentCheckNumbers?: InputMaybe<Array<InputMaybe<ContractorPaymentCheckNumbers>>>;
  payrollId: Scalars['ID'];
};

export type UpdatePayrollInput = {
  id: Scalars['ID'];
  /** Additional configuration options controlling off-cycle payroll behavior. */
  offCycleOptions?: InputMaybe<OffcycleOptionsInput>;
  /** The date on which employees will be paid for the payroll. */
  payday?: InputMaybe<Scalars['DateTime']>;
  /** The end of the date range that the payroll covers. */
  periodEnd?: InputMaybe<Scalars['DateTime']>;
  /** The start of the date range that the payroll covers. */
  periodStart?: InputMaybe<Scalars['DateTime']>;
  /** Optimistic locking to prevent stale data clobbering fresher data */
  version: Scalars['Int'];
};

export type UpdatePayrollItemInput = {
  basePayRate?: InputMaybe<Scalars['Float']>;
  checkNumber?: InputMaybe<Scalars['String']>;
  earnings: Array<EarningInput>;
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  noteVisible?: InputMaybe<Scalars['Boolean']>;
  /** The way the employee will be paid */
  paymentMethod?: InputMaybe<PaymentMethod>;
  /** The employee's remaining PTO hour balance, for display on the paystub */
  ptoBalanceHours?: InputMaybe<Scalars['Float']>;
  reimbursements?: InputMaybe<Array<InputMaybe<ReimbursementInput>>>;
  /** The employee's remaining sick hour balance, for display on the paystub */
  sickBalanceHours?: InputMaybe<Scalars['Float']>;
  /** Optimistic locking to prevent stale data clobbering fresher data */
  version: Scalars['Int'];
};

export type UpdatePlanLockInput = {
  active: Scalars['Boolean'];
  companyId: Scalars['String'];
  couponId: Scalars['String'];
  plan: Scalars['String'];
  setupFee: Scalars['Float'];
  trialDuration: Scalars['Float'];
};

export type UpdateProductInterestWasAppNotificationDismissedInput = {
  /** The ID of the product interest */
  id: Scalars['ID'];
};

export type UpdateReportCardInput = {
  /** employee id */
  employeeId: Scalars['String'];
  /** External Employee Feedback, they see this */
  feedback?: InputMaybe<Scalars['String']>;
  /** employee grade value */
  grade?: InputMaybe<ReportCardGradeValues>;
  /** Internal Note */
  note?: InputMaybe<Scalars['String']>;
  /** Report Card Id */
  reportCardId: Scalars['String'];
  /** Do we want to set the status of Report Card? This is independent of each employee graded status */
  status?: InputMaybe<ReportCardStatusValues>;
};

export type UpdateRoleForCompanyInput = {
  /** Bambee Company role id */
  id: Scalars['ID'];
  /** Bambee Core Company Role title */
  title: Scalars['String'];
};

export type UpdateWorkerRoleInput = {
  isPrimary: Scalars['Boolean'];
  payType: WorkerRolePaytype;
  workerRoleId: Scalars['ID'];
};

export type UpdateWorkplaceInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  /** Workplace address */
  address?: InputMaybe<AddressInput>;
  default?: InputMaybe<Scalars['Boolean']>;
  /** Bambee Workplace id */
  id: Scalars['ID'];
  /** Workplace name */
  name?: InputMaybe<Scalars['String']>;
};

export type UpgradeCorePlanInput = {
  companyId: Scalars['String'];
  newPlanId: Scalars['String'];
  /** The type of the package */
  newPricingPackageType: PricingPackageType;
};

/** Upgrade Deputy installation from trial to paid */
export type UpgradeDeputyInstallInput = {
  /** Bambee company id */
  companyId: Scalars['String'];
};

export type UploadFileInput = {
  companyId: Scalars['String'];
  /** base64 encoded file data */
  file?: InputMaybe<Scalars['String']>;
  /** Array of files to upload */
  files?: InputMaybe<Array<FileInput>>;
  /** file name */
  name?: InputMaybe<Scalars['String']>;
  requirementId: Scalars['Int'];
  type?: InputMaybe<DocumentOptionType>;
};

export type UploadedFile = {
  __typename?: 'UploadedFile';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: DocumentType;
};

export type UpsertDeputyAreasInput = {
  companyId: Scalars['ID'];
};

export type UpsertDeputyConnectionInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  installId?: InputMaybe<Scalars['String']>;
};

export type UpsertDeputyLocationsInput = {
  companyId: Scalars['ID'];
};

export type UpsertDeputyUsersInput = {
  companyId: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  error?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isContractor?: Maybe<Scalars['Boolean']>;
  language: Language;
  name?: Maybe<Scalars['String']>;
  profile?: Maybe<UserProfile>;
  role: UserRole;
  state?: Maybe<State>;
  training: UserTraining;
  userId: Scalars['String'];
  userMngId: Scalars['String'];
};

export type UserCounts = {
  __typename?: 'UserCounts';
  completed?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  inProgress?: Maybe<Scalars['Float']>;
  notStarted?: Maybe<Scalars['Float']>;
};

export type UserCourse = {
  __typename?: 'UserCourse';
  bundleId: Scalars['String'];
  bundleName: Scalars['String'];
  certificateDownloadUrl?: Maybe<Scalars['String']>;
  certificateId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  courseId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  doceboCourseId: Scalars['String'];
  enrolledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  removedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated use key instead */
  searchTag?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  state?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  ticketId: Scalars['String'];
  topicId: Scalars['String'];
  topicName: Scalars['String'];
  topics: Array<Maybe<CourseTopic>>;
  userRole: Scalars['String'];
};

export type UserCourseTopic = {
  __typename?: 'UserCourseTopic';
  courseId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  ticketId: Scalars['ID'];
};

export type UserCoursesInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  includeInactive?: InputMaybe<Scalars['Boolean']>;
  topicId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UserEnrollmentSync = {
  __typename?: 'UserEnrollmentSync';
  completedAt?: Maybe<Scalars['DateTime']>;
  ticketId: Scalars['String'];
  userId: Scalars['String'];
};

export type UserEnrollmentSyncError = {
  __typename?: 'UserEnrollmentSyncError';
  cause: Scalars['String'];
  message: Scalars['String'];
};

export type UserFinchCodeInput = {
  code: Scalars['String'];
  /** OAuth State parameter */
  state?: InputMaybe<Scalars['String']>;
};

export type UserInput = {
  language: Language;
  role: UserRole;
  state?: InputMaybe<State>;
  userId: Scalars['String'];
  userMngId?: InputMaybe<Scalars['String']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  classification: EmployeeClassification;
  conference?: Maybe<Scalars['String']>;
  contractorBusinessName?: Maybe<Scalars['String']>;
  contractorType: EmployeeServiceContractorType;
  dob: Scalars['DateTime'];
  emergencyContact: EmergencyContact;
  employeeType: EmployeeServiceEmployeeType;
  firstName?: Maybe<Scalars['String']>;
  hoursPerWeek?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastSocial?: Maybe<Scalars['String']>;
  payDay?: Maybe<Scalars['String']>;
  payFrequency?: Maybe<Scalars['String']>;
  payRate?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateWorkIn?: Maybe<Scalars['String']>;
  supervisor?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  type: PayType;
  yearsExperience?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type UserQueryInput = {
  id: Scalars['ID'];
};

export enum UserRole {
  Employee = 'EMPLOYEE',
  Manager = 'MANAGER'
}

export type UserTraining = {
  __typename?: 'UserTraining';
  courses: Array<Maybe<UserCourse>>;
  history: Array<Maybe<UserCourse>>;
  id: Scalars['ID'];
  userId: Scalars['String'];
};


export type UserTrainingCoursesArgs = {
  filter: UserTrainingFilterInput;
};


export type UserTrainingHistoryArgs = {
  filter: UserTrainingFilterInput;
};

export type UserTrainingFilterInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  topicId?: InputMaybe<Scalars['String']>;
};

export type VerifyFeinInCheckInput = {
  companyId: Scalars['String'];
  fein?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
};

export type VoicesFeedback = {
  __typename?: 'VoicesFeedback';
  anonymous: Scalars['Boolean'];
  /** This will be null if anonymous is true. The User MongoId. In the future we may deprecate this for Employee ID */
  companyPersonId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<CoreUser>;
  feedback: Scalars['String'];
  id: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type Warning = {
  __typename?: 'Warning';
  /** The action Check took. */
  code: WarningCode;
  /** Check ID that references the relevant deduction. */
  postTaxDeduction: Scalars['String'];
  /** Why the deduction was not applied. */
  reason: WarningReason;
};

export enum WarningCode {
  PartiallyApplied = 'PARTIALLY_APPLIED',
  Skipped = 'SKIPPED',
  SkippedPostTaxDeduction = 'SKIPPED_POST_TAX_DEDUCTION'
}

export type WarningDocument = {
  __typename?: 'WarningDocument';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export enum WarningReason {
  ExceedsMaxPercent = 'EXCEEDS_MAX_PERCENT',
  NegativeNetPay = 'NEGATIVE_NET_PAY'
}

export type Webinar = {
  __typename?: 'Webinar';
  date: Scalars['DateTime'];
  duration: Scalars['Int'];
  id: Scalars['ID'];
  registrants: Array<WebinarRegistrant>;
  title: Scalars['String'];
};

export type WebinarRegistrant = {
  __typename?: 'WebinarRegistrant';
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  user: CoreUser;
};

export type WebinarRegistration = {
  __typename?: 'WebinarRegistration';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type WorkerRole = {
  __typename?: 'WorkerRole';
  companyRole?: Maybe<CompanyRole>;
  createdAt: Scalars['DateTime'];
  /** Date of worker role deletion */
  deletedAt?: Maybe<Scalars['DateTime']>;
  employeeJobDescription?: Maybe<EmployeeJobDescription>;
  /** Bambee Worker Role id */
  id: Scalars['ID'];
  /** Determines whether or not worker role is primary */
  isPrimary: Scalars['Boolean'];
  /** Pay rate of the worker role */
  payRate: Scalars['Float'];
  /** Pay type of the worker role */
  payType: WorkerRolePaytype;
  /** Bambee User Id */
  userId: Scalars['ID'];
};

export enum WorkerRolePaytype {
  Contractor = 'Contractor',
  Hourly = 'Hourly',
  Salary = 'Salary'
}

export enum WorkingSchedule {
  DayShifts = 'DayShifts',
  EveningShifts = 'EveningShifts',
  Holidays = 'Holidays',
  MondayFriday = 'MondayFriday',
  OvernightShifts = 'OvernightShifts',
  Overtime = 'Overtime',
  Weekends = 'Weekends'
}

export type Workplace = {
  __typename?: 'Workplace';
  active: Scalars['Boolean'];
  /** Workplace address */
  address: Address;
  default: Scalars['Boolean'];
  deputyId?: Maybe<Scalars['String']>;
  /** Bambee workplace id */
  id: Scalars['ID'];
  /** Workplace name */
  name?: Maybe<Scalars['String']>;
};

export type ZoomConfiguration = {
  __typename?: 'ZoomConfiguration';
  tokenExpiration: Scalars['DateTime'];
};

export type CreatePayrollTierSubscriptionMutationVariables = Exact<{
  data: CreatePayrollSubscriptionInput;
}>;


export type CreatePayrollTierSubscriptionMutation = { __typename?: 'Mutation', createPayrollSubscription: { __typename?: 'Subscription', name: string, stripeId: string, productCode: ProductKey, serviceKey: ServiceKey, planValue: string, amount: number, createdAt: any, startDate: any, endDate: any, status: SubscriptionStatus, isDrp: boolean } };

export type GetBillingInfoQueryVariables = Exact<{
  input: GetBillingInfoInput;
}>;


export type GetBillingInfoQuery = { __typename?: 'Query', getBillingInfo: { __typename?: 'BillingInfo', addonSubscriptions: Array<{ __typename?: 'SubscriptionModel', id: string, active: boolean, quantity?: number | null, periodEndsAt: any, plan: { __typename?: 'PlanModel', annual?: boolean | null, value: string, name: string, productKey: ProductKey, price?: number | null, volumeBased?: boolean | null, billingPeriod: BillingPeriod, serviceKey: ServiceKey } }>, coreSubscription?: { __typename?: 'CoreSubscriptionModel', id: string, cancellationRequested: boolean, cancelsAt?: any | null, active: boolean, trialEndsAt?: any | null, trialing: boolean, scheduledPlanChange?: { __typename?: 'ScheduledCorePlanChangeModel', id: string, effectiveOn: any, newPlan: { __typename?: 'CorePlanModel', billingPeriod: BillingPeriod, name: string, productKey: ProductKey } } | null, plan: { __typename?: 'CorePlanModel', maxSize?: number | null, minSize?: number | null, price: number, productKey: ProductKey, billingPeriod: BillingPeriod, name: string, employeeSizeMapping?: string | null } } | null, pause: { __typename?: 'Paused', id: string, isPaused: boolean, reactivatesOn?: any | null } } };

export type GetEligibleAddonsQueryVariables = Exact<{
  input: EligibleAddonsInput;
}>;


export type GetEligibleAddonsQuery = { __typename?: 'Query', getEligibleAddons: { __typename?: 'EligibleAddonsResponse', payroll: Array<{ __typename?: 'PayrollPlanModel', serviceKey: ServiceKey, productKey: ProductKey, name: string, value: string, priceId: string, price?: number | null, billingPeriod: BillingPeriod, status: PlanStatuses, minSize: number, maxSize?: number | null, unitPriceId?: string | null, unitPrice?: number | null, version: string }> } };


export const CreatePayrollTierSubscriptionDocument = gql`
    mutation CreatePayrollTierSubscription($data: CreatePayrollSubscriptionInput!) {
  createPayrollSubscription(data: $data) {
    name
    stripeId
    productCode
    serviceKey
    planValue
    amount
    createdAt
    startDate
    endDate
    status
    isDrp
  }
}
    `;

/**
 * __useCreatePayrollTierSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreatePayrollTierSubscriptionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayrollTierSubscriptionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreatePayrollTierSubscriptionMutation({
 *   variables: {
 *     data: // value for 'data'
 *   },
 * });
 */
export function useCreatePayrollTierSubscriptionMutation(options: VueApolloComposable.UseMutationOptions<CreatePayrollTierSubscriptionMutation, CreatePayrollTierSubscriptionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreatePayrollTierSubscriptionMutation, CreatePayrollTierSubscriptionMutationVariables>>) {
  return VueApolloComposable.useMutation<CreatePayrollTierSubscriptionMutation, CreatePayrollTierSubscriptionMutationVariables>(CreatePayrollTierSubscriptionDocument, options);
}
export type CreatePayrollTierSubscriptionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreatePayrollTierSubscriptionMutation, CreatePayrollTierSubscriptionMutationVariables>;
export const GetBillingInfoDocument = gql`
    query GetBillingInfo($input: GetBillingInfoInput!) {
  getBillingInfo(input: $input) {
    addonSubscriptions {
      id
      active
      quantity
      periodEndsAt
      plan {
        annual
        value
        name
        productKey
        price
        volumeBased
        billingPeriod
        serviceKey
      }
    }
    coreSubscription {
      id
      cancellationRequested
      cancelsAt
      active
      trialEndsAt
      trialing
      scheduledPlanChange {
        id
        effectiveOn
        newPlan {
          billingPeriod
          name
          productKey
        }
      }
      plan {
        maxSize
        minSize
        price
        productKey
        billingPeriod
        name
        employeeSizeMapping
      }
    }
    pause {
      id
      isPaused
      reactivatesOn
    }
  }
}
    `;

/**
 * __useGetBillingInfoQuery__
 *
 * To run a query within a Vue component, call `useGetBillingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingInfoQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetBillingInfoQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetBillingInfoQuery(variables: GetBillingInfoQueryVariables | VueCompositionApi.Ref<GetBillingInfoQueryVariables> | ReactiveFunction<GetBillingInfoQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetBillingInfoQuery, GetBillingInfoQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetBillingInfoQuery, GetBillingInfoQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetBillingInfoQuery, GetBillingInfoQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetBillingInfoQuery, GetBillingInfoQueryVariables>(GetBillingInfoDocument, variables, options);
}
export type GetBillingInfoQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetBillingInfoQuery, GetBillingInfoQueryVariables>;
export const GetEligibleAddonsDocument = gql`
    query GetEligibleAddons($input: EligibleAddonsInput!) {
  getEligibleAddons(input: $input) {
    payroll {
      serviceKey
      productKey
      name
      value
      priceId
      price
      billingPeriod
      status
      minSize
      maxSize
      unitPriceId
      unitPrice
      version
    }
  }
}
    `;

/**
 * __useGetEligibleAddonsQuery__
 *
 * To run a query within a Vue component, call `useGetEligibleAddonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibleAddonsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetEligibleAddonsQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetEligibleAddonsQuery(variables: GetEligibleAddonsQueryVariables | VueCompositionApi.Ref<GetEligibleAddonsQueryVariables> | ReactiveFunction<GetEligibleAddonsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetEligibleAddonsQuery, GetEligibleAddonsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetEligibleAddonsQuery, GetEligibleAddonsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetEligibleAddonsQuery, GetEligibleAddonsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetEligibleAddonsQuery, GetEligibleAddonsQueryVariables>(GetEligibleAddonsDocument, variables, options);
}
export type GetEligibleAddonsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetEligibleAddonsQuery, GetEligibleAddonsQueryVariables>;
export const namedOperations = {
  Query: {
    GetBillingInfo: 'GetBillingInfo',
    GetEligibleAddons: 'GetEligibleAddons'
  },
  Mutation: {
    CreatePayrollTierSubscription: 'CreatePayrollTierSubscription'
  }
}