import { reactive } from '@nuxtjs/composition-api';

const state = reactive({
  mobileMenuToggle: false,
});

const header = reactive({
  header: '',
  subheader: '',
  headerImage: null,
  heroImage: null,
  metaTitle: '',
  registration: false,
  noHero: true,
  noOverlay: false,
  topNavToggle: false,
});

const getStateByKey = (key) => state[key];
const setStateByKey = (key, value) => (state[key] = value);

const getHeader = header;
const setHeaderByKey = (key, value) => (header[key] = value);
const setHeader = (value) => Object.assign(header, value);

const useGlobalSettings = () => {
  return {
    getStateByKey,
    setStateByKey,
    getHeader,
    setHeaderByKey,
    setHeader,
  };
};

export default useGlobalSettings;
