<template>
  <div class="faq-item overflow-hidden cursor-pointer" @click="$emit('toggle')">
    <div class="flex items-center justify-between mb-2">
      <TypeBody
        variant="large"
        weight="strong"
        class="pr-4"
        :class="[
          itemClass(label),
          {
            'is-primary': color === 'text-primary',
            'is-white': color === 'text-white',
          },
        ]"
      >
        {{ label }}
      </TypeBody>
    </div>
    <BaseDivider class="mb-2" />
    <transition
      name="accordion"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div
        v-show="show"
        class="answer overflow-hidden transition-all duration-300 pb-4"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { BaseDivider, TypeBody } from '@bambeehr/pollen';

export default defineComponent({
  name: 'MobileMenuItem',
  components: { BaseDivider, TypeBody },
  props: {
    label: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  setup(props) {
    const getColorClass = (color = '', defaultColor) => {
      return color === 'white' ? 'text-white' : `text-${defaultColor}`;
    };

    const itemClass = (label) => {
      switch (label) {
        case 'HR Autopilot':
          return getColorClass(props.color, 'primary');
        case 'Dedicated HR Manager':
          return getColorClass(props.color, 'secondary');
        case 'Bambee Payroll+':
          return getColorClass(props.color, 'mosaic');
        default:
          return getColorClass(props.color, 'primary');
      }
    };

    const beforeEnter = (el) => {
      el.style.height = '0';
      el.style.opacity = '0';
    };

    const enter = (el) => {
      el.style.height = `${el.scrollHeight}px`;
      el.style.opacity = '1';
    };

    const beforeLeave = (el) => {
      el.style.height = `${el.scrollHeight}px`;
      el.style.opacity = '1';
    };

    const leave = (el) => {
      el.style.height = '0';
      el.style.opacity = '0';
    };

    return {
      itemClass,
      beforeEnter,
      enter,
      beforeLeave,
      leave,
    };
  },
});
</script>
