export const LocalStorageKeys = Object.freeze({
  FUNNEL: 'funnel',
  INDUSTRY: 'ssu-industry',
  NAME: 'ssu-name',
  WHAT_BROUGHT_YOU: 'ssu-what-brought-you',
  NUM_OF_EMPLOYEES: 'ssu-numOfEmployees',
  PAGE_REGISTERED_ON: 'page-registered-on',
  PLAN: 'ssu-plan',
  ZIP: 'ssu-zip',
  HR_MANAGER: 'ssu-hrm',
  COMPANY_ID: 'company-id',
  PHONE: 'phone',
  USER_ID: 'user',
});

export const SelfServicePageSlugs = Object.freeze({  
  WHAT_BROUGHT_YOU: 'what-brought-you',
  COMPANY_SIZE: 'company-size',
  COMPANY_NAME: 'company-name',
  ZIP_CODE: 'zip-code',
  INDUSTRY: 'industry',
  CONTACT_INFO: 'contact-info',
  PAYMENT: 'payment',
  PLAN_SELECTION: 'plan-selection',
  PAYROLL_SELECTION: 'payroll-selection',
});

export const tiersThatGetSentToSelfService = [0, 1, 2, 3, 4, 5, 6, 7];

export const SelfServicePreRegistrationPages = Object.freeze([
  { slug: SelfServicePageSlugs.WHAT_BROUGHT_YOU },
  { slug: SelfServicePageSlugs.COMPANY_SIZE },
  { slug: SelfServicePageSlugs.COMPANY_NAME },
  { slug: SelfServicePageSlugs.ZIP_CODE },
  { slug: SelfServicePageSlugs.INDUSTRY },
  { slug: SelfServicePageSlugs.CONTACT_INFO },
]);

export const SelfServicePostRegistrationPages = Object.freeze([
  { slug: SelfServicePageSlugs.PAYMENT },
  { slug: SelfServicePageSlugs.PLAN_SELECTION },
  { slug: SelfServicePageSlugs.PAYROLL_SELECTION },
]);

const SelfServicePages = Object.freeze([
  ...SelfServicePreRegistrationPages,
  ...SelfServicePostRegistrationPages,
]);

export default SelfServicePages;
