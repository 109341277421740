import { ref } from '@nuxtjs/composition-api';
import useContentful from '@/hooks/useContentful/useContentful';
import ContentfulEntryIDs from '@/constants/ContentfulEntryIDs';

const isInitialized = false;

const bambeeHours = ref('');

const setupHook = async () => {
  const Contentful = useContentful();
  Contentful.fetchContent(ContentfulEntryIDs.BUSINESS_HOURS).then(
    (response) => {
      bambeeHours.value = response.text;
    }
  );
};

const useGlobalInfo = () => {
  if (!isInitialized) {
    setupHook();
  }

  return {
    bambeeHours,
  };
};

export default useGlobalInfo;
