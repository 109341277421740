














import { defineComponent } from '@nuxtjs/composition-api';
import { BaseBanner, BaseButton } from '@bambeehr/pollen';
import Analytics from '@/services/Analytics';

enum WVPPBannerTrackEvents {
	SHOWN = 'wvpp_banner_shown',
	DISMISSED = 'wvpp_banner_dismissed',
	CTA_CLICK = 'wvpp_banner_cta_click',
}

const trackEvent = (eventName: WVPPBannerTrackEvents, context = {}) =>{
	Analytics.track(eventName, {
		...context
	})
}

export default defineComponent({
	name: 'WVPPBanner',
	components: {
		BaseBanner,
		BaseButton,
	},
	setup(_, {emit}) {
		
		trackEvent(WVPPBannerTrackEvents.SHOWN)

		const handleCreateAccount = () => {
			trackEvent(WVPPBannerTrackEvents.CTA_CLICK)
			window.location.href = `https://join.bambee.com/compliance-events/2024/workplace-violence-CA`
		}

		const handleClose = () => {
			trackEvent(WVPPBannerTrackEvents.DISMISSED)
			emit('close')
		}

		return {
			handleCreateAccount,
			handleClose
		}
	},
});
