import { ref } from '@nuxtjs/composition-api';
import Contentful from '@/services/Contentful';
import ContentfulEntryIDs from '@/constants/ContentfulEntryIDs';

const isInitiated = ref(false);

const radioList = ref();
const streamingList = ref();
const podcastList = ref();
const smartSpeakerList = ref();

const fetchContent = async () => {
  await Contentful.getEntry(ContentfulEntryIDs.RADIO).then(
    ({ fields: radioStations }) => {
      radioList.value = radioStations.channel;
    }
  );
  await Contentful.getEntry(ContentfulEntryIDs.STREAMING).then(
    ({ fields: streamingStations }) => {
      streamingList.value = streamingStations.channel;
    }
  );
  await Contentful.getEntry(ContentfulEntryIDs.PODCASTS).then(
    ({ fields: podcastStations }) => {
      podcastList.value = podcastStations.channel;
    }
  );
  await Contentful.getEntry(ContentfulEntryIDs.SMART_SPEAKER).then(
    ({ fields: smartSpeakerStations }) => {
      smartSpeakerList.value = smartSpeakerStations.channel;
    }
  );
};

const setupHook = () => {
  isInitiated.value = true;
  fetchContent();
};

const useMediaStation = () => {
  if (!isInitiated.value) {
    isInitiated.value = true;
    setupHook();
  }

  return {
    radioList,
    streamingList,
    podcastList,
    smartSpeakerList,
  };
};

export default useMediaStation;
