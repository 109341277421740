import { getCookie, setCookie } from '@/lib/cookie-helpers';
import SessionStorage from '@/constants/SessionStorage';
import CookieNames from '@/constants/CookieNames';

import jwtDecode from 'jwt-decode';

export async function consumeToken(accessToken, { axios }) {
  try {
    const response = await axios.post('/v0/token/consume', {
      token: accessToken,
    });

    return response.data.token;
  } catch (err) {
    sessionStorage.removeItem(SessionStorage.EXPIRED_ACTIVATION_TOKEN);

    // Used to redirect users to the /activation-expired page in middleware/auth.js
    return sessionStorage.setItem(
      SessionStorage.EXPIRED_ACTIVATION_TOKEN,
      accessToken
    );
  }
}

export function jwtExpired(token, buffer = 0) {
  const decoded = jwtDecode(token);

  return decoded.exp * 1000 - buffer < Date.now();
}

export async function getRefreshedToken(axios) {
  const response = await axios.post(`/auth/v1/refresh`, null, {
    withCredentials: process.env.env !== 'dev',
  });

  const { token } = response.data;

  axios.setToken(token, 'Bearer');
  setCookie(CookieNames.ACCESS_TOKEN, token);

  return token;
}

export async function getV2Token(axios) {
  const response = await axios.get(`/auth/v1/upgrade`, {
    withCredentials: process.env.env !== 'dev',
  });

  const { token } = response.data;

  return token;
}

export async function handleToken(axios, route, redirect) {
  const { token: queryToken, redir } = route.query;

  const { path } = route;
  const { access_token: accessToken } = route.query;

  const cookieToken = getCookie(CookieNames.ACCESS_TOKEN);
  let token = queryToken || cookieToken;

  if (accessToken) {
    token = await consumeToken(accessToken, {
      axios,
      redirect,
    });
  }

  if (!token) {
    return false;
  }

  // set v1 token
  axios.setToken(token, 'Bearer');

  // upgrade to v2 token if required
  const decoded = jwtDecode(token);
  if (decoded.aud !== 'v2') {
    token = await getV2Token(axios);
    axios.setToken(token, 'Bearer');
    setCookie(CookieNames.ACCESS_TOKEN, token);
  } else if (queryToken) {
    setCookie(CookieNames.ACCESS_TOKEN, token);
  }

  return redirect(redir || path || '/');
}

export async function handleRefreshToken(err, { axios, store }) {
  const code = err.response?.status;

  const originalRequest = err.config;

  if (code !== 401 || originalRequest.url.includes('/auth/v1/login')) {
    return Promise.reject(err);
  }

  if (
    originalRequest.url.includes('/auth/v1/refresh') ||
    originalRequest.__isRetryRequest
  ) {
    store.dispatch('logout');

    return Promise.reject(err);
  }

  const token = await getRefreshedToken(axios);

  originalRequest.__isRetryRequest = true;
  originalRequest.headers.Authorization = `Bearer ${token}`;

  return axios(originalRequest);
}

export default function ({ $axios, route, redirect }, inject) {
  // https://github.com/nuxt-community/axios-module/issues/168
  $axios.onRequest((config) => {
    config.withCredentials = true;

    return config;
  });

  $axios.onResponseError((err) =>
    handleRefreshToken(err, {
      axios: $axios,
    })
  );

  return handleToken($axios, route, inject, redirect);
}
