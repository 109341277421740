<template>
  <div class="flex">
    <CheckboxInput
      id="acceptTOS"
      :value="value"
      :invalid="!value"
      class="caption mb-0"
      size="dense"
      label="I agree to receive communication from Bambee at the phone number provided above. Data rates may apply. Reply STOP to opt out. By creating an account you agree to our
    <u><a href='https://www.bambee.com/legal/tos' target='_blank'><u></u>Terms &amp; Conditions</u></a> &amp;
    <a href='https://www.bambee.com/legal/privacy' target='_blank'><u>Privacy Policy</u></a>"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import { CheckboxInput } from '@bambeehr/pollen';

export default {
  components: {
    CheckboxInput,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
};
</script>
