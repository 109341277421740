








































































import { ref, computed } from '@nuxtjs/composition-api';
import { TypeBody, TypeOverline, BaseIcon, BaseButton } from '@bambeehr/pollen';
import { PayrollProductKey, PlanProductKey } from '@/hooks/usePricePlans';
import PlanTitle from '@/modules/SelfServiceRegistration/components/PlanTitle/PlanTitle.vue';
import GlowCard from '@/modules/SelfServiceRegistration/components/GlowCard.vue';
import { ProductKey } from '@/gql/generated';

export default {
  name: 'PricePlanSelector',
  components: {
    TypeBody,
    TypeOverline,
    PlanTitle,
    BaseIcon,
    BaseButton,
    GlowCard,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    planType: {
      type: String,
      default: PlanProductKey.DEDICATED,
    },
  },
  setup(props, { emit }) {
    const isExpanded = ref(false);
    const toggleLearnMore = () => {
      emit('learnMore', props.planType);
      isExpanded.value = !isExpanded.value;
    };

    const optionClass = computed(() => `${props.planType}-option`);

    // Values map to tailwind colors
    const glowColor = computed(() => {
      switch (props.planType) {
        case PlanProductKey.LITE:
          return 'lite';
        case PlanProductKey.DEDICATED:
          return 'dedicated';
        case PlanProductKey.ELITE:
          return 'elite';
        case ProductKey.PayrollBasic:
          return 'payrollBasic';
        case ProductKey.PayrollPremium:
          return 'payrollPremium';

        default:
          'dedicated';
      }
    });

    return {
      PlanProductKey,
      isExpanded,
      toggleLearnMore,
      glowColor,
      optionClass,
    };
  },
};
