import Cookie from 'js-cookie';
import CookieNames from '@/constants/CookieNames';
import { action as DDAction } from '@/services/Datadog';

const identify = (userId, data = {}) => {
  if (!window.analytics) {
    return;
  }
  window.analytics.identify(userId, data);
};

// Sends Segment tracking event AND a custom DataDog action
const track = (event, data = {}, integrations = {}, callbackFn = () => { }) => {
  const companyId =
    Cookie.get(CookieNames.COMPANY_ID);

  const extraData = {
    companyId,
    'test-name': process.env.AB_TEST_NAME || null,
    'test-variant': process.env.AB_TEST_VARIANT || null,
    ...data,
  }

  DDAction(event, extraData);

  window?.analytics?.track(event, extraData,
    {
      integrations,
    },
    callbackFn
  );
};

const identifyUser = (user, data = {}) => {
  if (!window.analytics || !user) {
    return;
  }
  try {
    identify(user._id, {
      activation_status: user._auth
        ? user._auth.activation_status
        : user._user.activation_status,
      active: user.active,
      approver: user.permissions.approver,
      createdAt: user._auth ? user._auth.created_at : user._user.created_at,
      email: user._auth ? user._auth.email : user._user.email,
      emailNotifications: user.states.email_notification,
      environment: process.env.NODE_ENV,
      firstName: user.profile.first_name,
      lastName: user.profile.last_name,
      leadToken: user._auth ? user._auth.leadToken : user._user.leadToken,
      manager: user.permissions.manager,
      name: user.profile.full_name,
      phone: user.profile.phone,
      role: user.role,
      site_host: process.env.PRIMARY_DOMAIN,
      termsOfService: user._auth
        ? user._auth.TOSAccepted
        : user._user.TOSAccepted,
      timezone: user._auth
        ? user.profile.timezone
        : user._user.profile.timezone,
      title: user.profile.role,
      username: user._auth ? user._auth.username : user._user.email,
      ...data,
    });
  } catch (e) {
    track('identify error', {
      error: e,
      user,
    });
  }
};


export default {
  identify,
  identifyUser,
  track,
};
