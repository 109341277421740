const CookieNames = Object.freeze({
  ACCESS_TOKEN: 'access-token',
  COMPANY_SIZE: 'companySize',
  EXIT_INTENT: 'exit-intent',
  FUNNEL: 'funnel',
  PAGE_REGISTERED_ON: 'pageRegistered',
  PARTNER_REFERRAL_CODE: 'partnerReferralCode',
  PARTNER_STACK_ID: 'ps_xid',
  REFERRED_BY_CODE: 'referredByCode',
  QUERY_PARAMS: 'query',
  COMPANY_ID: 'company-id',
  PHONE: 'phone',
  USER_ID: 'user',
  MOUNTAIN_REFERRAL_CODE: 'referredByCode',
  MOUNTAIN_SOURCE: 'source',
  NEW_CUSTOMER_MEETING_ID: 'newCustomerMeetingId',
});

export default CookieNames;
