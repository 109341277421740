import { useVuelidate } from '@vuelidate/core';
import {
  required,
  email,
  helpers,
  minLength,
  maxLength,
} from '@vuelidate/validators';

import {
  mustIncludeInBetweenSpace,
  noLeadingOne,
  noLeadingZero,
} from '@/lib/global/ValidationRules';
import useRegistration from '@/modules/SelfServiceRegistration/hooks/useRegistration'


const useRegistrationForm = () => {
  const {state} = useRegistration()

  const rules = {
    fullName: {
      required,
      isName: helpers.withMessage(
        () => 'Name is not valid',
        mustIncludeInBetweenSpace
      ),
      minLength: minLength(3),
    },
    phone: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10),
      noLeadingOne: helpers.withMessage(
        () => 'Invalid number, please check for leading 1',
        noLeadingOne
      ),
      noLeadingZero: helpers.withMessage(
        () => 'Invalid number, please check for leading 0',
        noLeadingZero
      ),
    },
    email: {
      required,
      email,
    },
    howDidYouHear: { required },
  };

  const v$ = useVuelidate(rules, state);

  return {
    v$,
  };
};

export default useRegistrationForm;
