// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/poppins/Poppins-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/poppins/Poppins-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/poppins/Poppins-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/poppins/Poppins-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./fonts/poppins/Poppins-ExtraBold.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Poppins\";font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}@font-face{font-family:\"Poppins\";font-weight:500;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}@font-face{font-family:\"Poppins\";font-weight:600;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")}@font-face{font-family:\"Poppins\";font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\")}@font-face{font-family:\"Poppins\";font-weight:800;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\")}html{--tw-bg-opacity:1;--tw-text-opacity:1;font-feature-settings:\"ss07\" on,\"ss08\" on,\"ss02\" on;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#fff;background-color:rgba(255,255,255,var(--tw-bg-opacity));color:#333234;color:rgba(51,50,52,var(--tw-text-opacity));font-size:1rem;line-height:1.5;text-rendering:optimizeLegibility}body.overflow-hidden{max-height:100vh}a:focus,button:focus{outline:5px auto #c2c2c2}a:active,button:active{outline:2px solid transparent;outline-offset:2px}.fade-enter-active,.fade-leave-active{transition-duration:.15s;transition-duration:.24s;transition-property:opacity}.fade-enter,.fade-leave-to{opacity:0}span.richText ul{list-style-type:disc;padding-left:24px}span.richText ol{list-style-type:decimal;padding-left:24px}.intercom-lightweight-app{z-index:49!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
