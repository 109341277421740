<template>
  <div class="flex flex-col">
    <SkipLink />
    <HomeNav
      class="top-nav sticky left-0 right-0 top-0 z-20 sticky z-50"
      v-show="!noHero"
    />
    <BlandHero v-show="!noHero" />
    <!-- (kevin.lee): adding overflow-hidden prevents free trial -->
    <!-- ribbon (on mobile) from adding weird horizontal scrolling -->
    <main id="main-content" class="flex-grow">
      <Nuxt />
    </main>
    <CookiePolicyBanner />
    <client-only>
      <TheFooter />
    </client-only>
    <!-- Artsai Tracking Pixel -->
    <TrackingPixel
      pixel="https://data.adxcel-ec2.com/pixel/?ad_log=referer&action=misc&pixid=047b84a4-fac9-4150-8f4d-01006d162e76"
    />
    <!-- Claritas Tracking Pixel -->
    <TrackingPixel
      :pixel="`https://trkn.us/pixel/conv/ppt=19232;g=visit;gid=44723;ord=${randomNumber}`"
    />
    <!-- AudioHook Tracking Pixel -->
    <TrackingPixel
      pixel="https://listen.audiohook.com/b4d81c66-0b35-41e6-9c8c-9d5de456305b/pixel.png?type=pageview"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import CookiePolicyBanner from '@/modules/CookiePolicy/components/CookiePolicyBanner/CookiePolicyBanner.vue'

import SkipLink from '@/components/SkipLink';
import TheFooter from '@/components/Navigation/TheFooter';
import HomeNav from '@/modules/HomePage/components/Navigation/HomeNav';
import BlandHero from '@/components/BlandHero.vue';
import useGlobalSettings from '@/hooks/useGlobalSettings';
import TrackingPixel from '@/components/Marketing/TrackingPixel';

export default defineComponent({
  components: {
    BlandHero,
    HomeNav,
    SkipLink,
    TheFooter,
    TrackingPixel,
    CookiePolicyBanner,
  },
  setup() {
    const { getHeader } = useGlobalSettings();
    const noHero = computed(() => getHeader.noHero);
    // Claritas requires a random number for their orderID to prevent duplicate data.
    const randomNumber = ref(Math.floor(Math.random() * 1000000));

    return {
      noHero,
      randomNumber,
    };
  },
});
</script>
