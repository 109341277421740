/* eslint-disable */
/*
 * Include Chartable Script
 * https://chartable.com/web-api/bambee
 */
/* eslint-disable */
export default () => {
  window.onNuxtReady(() => {
    if (process.env.CHARTABLE_API_KEY) {
      !(function (t, e, c, n, o, r) {
        (t[n] =
          t[n] ||
          function () {
            (t[n].q = t[n].q || []).push(arguments);
          }),
          setTimeout(function () {
            (o = e.createElement('script')),
              (r = e.getElementsByTagName('script')[0]),
              (o.src = c),
              r?.parentNode.insertBefore(o, r);
          }, 0);
      })(window, document, 'https://ext.chtbl.com/trackable.js', 'trackable');

      // Initialize with your Trackable API key
      window.trackable('init', process.env.CHARTABLE_API_KEY);
    }
  });
};
