<template>
  <div
    class="
      modal
      absolute
      left-0
      top-0
      w-screen
      h-screen
      flex
      items-center
      justify-center
      z-40
    "
  >
    <div class="z-50">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
};
</script>

<style scoped>
.modal {
  background: rgba(72, 29, 114, 0.9);
}
</style>
