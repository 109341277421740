









import { } from '@bambeehr/pollen';
import {
	computed, ref,
} from '@nuxtjs/composition-api';

export default {
	name: 'GlowCard',
	components: {},

	props: {
		active: {
			type: Boolean,
			default: false
		},
		gradient: {
			type: Boolean,
			default: true
		},
		color: {
			type: String,
			default: 'primary' // Tailwind color. Resolves to bg-<props.color>
		},
		borderWidth: {
			type: String,
			default: '2px'
		},
		defaultBorderColor: {
			type: String,
			default: 'gray-4' // Tailwind color. Resolves to resemble a border
		},
		contentClasses: {
			type: String,
			default: ''
		},
	},

	setup(props, { }) {

		const finalContainerClass = computed(() => {

			return props.active ? `active bg-${props.color}` : `bg-${props.defaultBorderColor}`
		})
		const finalMainContentClasses = computed(() => {
			const required = [`border-[${props.borderWidth}]`]


			return [props.contentClasses, required].join(' ');
		})


		const mainEl = ref(null)

		// Used to set a CSS variable to be hooked into
		const mainBgColor = computed(() => {
			if (!mainEl.value) return '';

			// @ts-ignore
			const styles = window.getComputedStyle(mainEl.value)?.backgroundColor || '#fff'

			return styles
		})

		return {
			finalContainerClass,
			finalMainContentClasses,
			mainEl,
			mainBgColor,
		}
	}
};
