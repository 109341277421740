<template>
  <RegistrationContainerAlt v-if="$matchMedia.sm">
    <InfoPanel
      title="Let's learn more about your business"
      description="In order to match you with the best HR Manager for your business, we need to gather some information about you and your business"
      tag="Takes 60 seconds"
    />
    <RegistrationQuestionForm
      card
      :button-id="buttonId"
      title="What's the name of your company?"
      @submit="handleSubmit"
    >
      <TextInput
        ref="focus"
        :invalid="isInvalid"
        :value="value"
        size="large"
        placeholder="Company Name"
        data-qa="wizard-card-input-company-name"
        maxlength="255"
        @input="handleInput"
      />
    </RegistrationQuestionForm>
  </RegistrationContainerAlt>
  <RegistrationContainer v-else>
    <PurpleInfoPanel
      title="Let's learn more about your business"
      description="In order to match you with the best HR Manager for your 
      business, we need to gather some information about you and your company."
      tag="Takes 60 seconds"
    />
    <RegistrationQuestionForm
      :button-id="buttonId"
      title="What's the name of your company?"
      @submit="handleSubmit"
    >
      <TextInput
        ref="focus"
        :invalid="isInvalid"
        :value="value"
        size="large"
        placeholder="Company Name"
        maxlength="255"
        @input="handleInput"
        @blur="handleBlur"
      />
    </RegistrationQuestionForm>
  </RegistrationContainer>
</template>

<script>
import { TextInput } from '@bambeehr/pollen';
import { computed, nextTick, onMounted, ref } from '@nuxtjs/composition-api';

import InfoPanel from '../InfoPanel';
import PurpleInfoPanel from '../PurpleInfoPanel';
import RegistrationContainer from '../RegistrationContainer';

import RegistrationQuestionForm from '../RegistrationQuestionForm';
import RegistrationContainerAlt from '../alternative/RegistrationContainerAlt';
import useSelfService from '../../hooks/useSelfService';
import useHandlerOnEnter from '@/hooks/useHandlerOnEnter';
import Analytics from '@/services/Analytics';
import useRegistration, { RegistrationStateNames } from '@/modules/SelfServiceRegistration/hooks/useRegistration.js';

const focusElement = (element) => {
  element.focus();
};

export default {
  components: {
    InfoPanel,
    RegistrationContainerAlt,
    RegistrationQuestionForm,
    TextInput,
    PurpleInfoPanel,
    RegistrationContainer
  },

  setup(_, { emit }) {
    const focus = ref(null);
    const isInvalid = ref(false);

    const {state: registration, updateRegistration} = useRegistration()

    onMounted(() =>
      // (kevin.lee)
      // Causes input to focus after page transition
      nextTick(() => {
        focusElement(focus.value.$el);
      })
    );

    const field = computed(() => registration[RegistrationStateNames.COMPANY_NAME]);

    const handleInput = (e) => {      
      updateRegistration({[RegistrationStateNames.COMPANY_NAME]: e})
      if (e) {
        isInvalid.value = false;
      }
    };

    const handleSubmit = () => {
      Analytics.track('company_name_submitted', {
        name: field.value,
      });
      if (!field.value) {
        isInvalid.value = true;
      } else {
        emit('submit', { [RegistrationStateNames.COMPANY_NAME]: field.value });
      }
    };

    useHandlerOnEnter(handleSubmit);
    const handleBlur = () => {
      // On mobile, there is a bug where the keyboard pushes the content too far down. This ensures that the content is scrolled back up when the keyboard is closed.
      window?.scrollTo(0, 0);
    };

    return {
      buttonId: RegistrationStateNames.COMPANY_NAME,
      focus,
      handleInput,
      handleSubmit,
      isInvalid,
      value: field,
      handleBlur,
    };
  },
};
</script>
