export const mustIncludeInBetweenSpace = (value) => value.trim().includes(' ');

export const noLeadingOne = (value) => value.charAt(0) !== '1';
export const noLeadingZero = (value) => value.charAt(0) !== '0';

export default {
  mustIncludeInBetweenSpace,
  noLeadingOne,
  noLeadingZero,
};
