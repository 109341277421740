import Vue from 'vue'
import VueConfetti from 'vue-confetti'

Vue.use(VueConfetti)

export const celebrate = (confetti) => {
  confetti.start({
    defaultColors: ['#481d72', '#481d72', '#16a7a7', '#eae3f2', '#c2f4f4'],
    defaultDropRate: 25,
    particlesPerFrame: 5,
    defaultType: 'rect',
  });
  setTimeout(() => {
    confetti.stop();
  }, 2000);
};