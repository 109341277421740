import { computed, ref, onMounted } from '@nuxtjs/composition-api';

import useFreeTrialPromo from '@/hooks/useFreeTrialPromo';
import useRegistration from '@/modules/SelfServiceRegistration/hooks/useRegistration';
import UsStates from '@/constants/UsStates';

const useRegistrationSummary = () => {
  const { isFreeTrialPage } = useFreeTrialPromo();
  const { state } = useRegistration();
  const employeeCount = ref(state.numOfEmployees);
  const { numOfEmployees, industry } = state;
  onMounted(() => {
    const [first, second] = numOfEmployees.split('-');

    if (first === second) {
      employeeCount.value = first;
    }
  });

  const registrationSummary = computed(() => {
    if (isFreeTrialPage.value) {
      return [
        '<b>Paired with an HR Manager</b> to offer guidance that will help you manage your HR concerns',
        `<b>Assistance navigating compliance</b> for companies with ${employeeCount.value} employees in ${state} in ${industry}`,
        '<b>Help with crafting HR policy</b> and procedure between your company and its employees',
        '<b>A comprehensive HR audit</b> to uncover and fix critical compliance gaps in your business',
      ];
    }

    return [
      `Our HR Managers are experts in companies with ${employeeCount.value} employees`,
      `We have expertise in the regulatory complexities of ${
        industry === 'Other'
          ? 'many industries'
          : `the ${industry?.toLowerCase() || ''} industry`
      }`,
      `We’ll pair you with an HR Manager with experience in ${
        UsStates[state] || 'your area'
      }`,
    ];
  });

  return {
    registrationSummary,
  };
};

export default useRegistrationSummary;
