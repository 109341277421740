import { computed, ref, watch, watchEffect } from '@nuxtjs/composition-api';
import usePricePlans from '@/hooks/usePricePlans';
import useTrials, { trialPrice } from '@/hooks/useTrials';
import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration';
import currency from '@bambeehr/currency';
import BillingService, { calculateSalesTax } from '@/services/billing.service';

let instantiated = false;
const registrationState = ref({});
const trialDuration = ref(0);
const taxesFetched = ref(false);

const isTierZero = computed(
  () => registrationState.value[RegistrationStateNames.NUM_EMPLOYEES] === '0-0'
);

const { selectedPlan, calculateSetupFeeWithDiscount } = usePricePlans();
const isReady = computed(() => !!selectedPlan.value && !!taxesFetched.value)

const employeeCount = computed(
  () => selectedPlan.value?.employeeSizeMapping || ''
);


const taxes = ref(0);

const priceState = computed(() => {
  const state = {
    subscriptionPrice: trialDuration.value ? 0 : selectedPlan.value?.monthlyPrice,
    zipCode: registrationState.value[RegistrationStateNames.ZIP] || '',

    originalSubscriptionPrice: selectedPlan.value?.monthlyPrice,
    originalSetupFee: selectedPlan.value?.setupFee || 0,
    hasTrialPromotion: !!trialDuration.value,
    billingPeriod: '',
  };

  if (state.hasTrialPromotion) {
    state.subscriptionPrice = 1;
    state.setupFee = 0;
  } else {
    state.setupFee = calculateSetupFeeWithDiscount(
      selectedPlan.value?.setupFee || 0,
      selectedPlan.value?.setupFeeDiscount || 0
    );
  }

  state.subTotal = state.subscriptionPrice + state.setupFee;
  state.originalSubTotal =
    state.originalSubscriptionPrice + state.originalSetupFee;
  state.total = state.subTotal + taxes.value;
  state.originalTotal = state.originalSubTotal + taxes.value;

  return state;
});

const subscriptionLabel = computed(
  () => {
    if (employeeCount.value) {
      return `Monthly Price (${employeeCount.value} Employees)`
    }

    return `Monthly Price`
  }
);

const subscriptionDescription = computed(
  () => {
    if (selectedPlan.value?.billingPeriod === 'year') {
      return `Billed once annually ${currency(selectedPlan.value.price).format({
        precision: 2,
      })}`
    }

    return 'Billed monthly'
  }
);

const setupFeeDescription = computed(() => {
  const strArr = [
    `We understand trying a new business service is a big decision. That's why we're letting you <b>try Bambee for $${trialPrice} (seriously).</b> Within the first ${trialDuration.value} days, you'll understand why we're the fastest growing HR service in America.`,
  ];
  if (!isTierZero.value) {
    strArr.push(
      `We're so confident that you'll love Bambee, if you upgrade at the end of your ${trialDuration.value} day trial, we'll even waive your $${priceState.value.setupFee} setup fee.`
    );
  }

  return strArr.join(' ') || strArr;
});

const setupHook = () => {
  const { state: regState } = useRegistration();
  const { trialDuration: trialDurationVal } = useTrials();

  // Set the registration state
  watch(
    regState,
    (newRegState) => {
      registrationState.value = newRegState;
    },
    { immediate: true }
  );

  watch(
    trialDurationVal,
    (nVal) => {
      trialDuration.value = nVal;
    },
    { immediate: true }
  );

  // Calculate taxes if plan changes
  watchEffect(() => {
    if (
      selectedPlan.value &&
      registrationState.value?.[RegistrationStateNames.ZIP]
    ) {

      try {
        calculateSalesTax(
          selectedPlan.value?.value,
          registrationState.value[RegistrationStateNames.ZIP],
          selectedPlan.value.setupFee,
          priceState.value.hasTrialPromotion ? 1 : 0
        ).then(({ totalTax, trialTax }) => {
          taxes.value = priceState.value.hasTrialPromotion ? trialTax : totalTax;
          taxesFetched.value = true;
        })
      } catch(e) {
        console.error(e)
      }
    }
  });

  instantiated = true;
};

const usePrice = ($axios) => {
  if (!instantiated) {
    BillingService($axios);
    setupHook();
  }

  return {
    priceState,
    subscriptionLabel,
    subscriptionDescription,
    setupFeeDescription,
    taxes,
    isReady,
  };
};

export default usePrice;
