import {
  deleteCookie,
  setCookie,
  getQueryCookie,
} from '@/lib/cookie-helpers';
import CookieNames from '@/constants/CookieNames';
import { QueryParamNames } from '@/constants/QueryParams';
import { jwtExpired } from '@/plugins/axios';
import api, { setToken } from '@/services/api';
import { signWithHmac } from '@/services/Registration';
import useCurrentUser from '@/hooks/useCurrentUser';
import Analytics from '@/services/Analytics';

let currentUserHook;

const TrackEvents = {
  RESUME_ATTEMPTED: 'session_resume_attempt',
  RESUME_SUCCESS: 'session_resume_success',
  RESUME_FAILURE: 'session_resume_failure',
}

const ResumeMethods = {
  BY_TOKEN: 'access-token',
  BY_RE_REGISTER: 'register',
}

type RefreshedUser = any | undefined; // Just for easy reading

const refreshSelf = async (): Promise<RefreshedUser> => {
  try {
    const res = await api.get('/v0/app/my/self');

    if (res.data) {
      const user = res.data;

      return user;
    }

    return null;
  } catch (error) {
    return null;
  }
};

const setTokenAndRefreshCurrentUser = async (
  token: string
): Promise<RefreshedUser> => {
  setCookie(CookieNames.ACCESS_TOKEN, token);
  setToken(token, 'Bearer');
  try {
    const user = await refreshSelf();

    return user;
  } catch (error) {
    console.error(error);
    return;
  }
};

const refreshUserByToken = async (token: string): Promise<RefreshedUser> => {
  const isTokenExpired = jwtExpired(token, 3000);

  if (isTokenExpired) {
    deleteCookie(CookieNames.ACCESS_TOKEN);

    return;
  }

  try {
    const user = await setTokenAndRefreshCurrentUser(token);

    return user;
  } catch (error) {
    return;
  }
};

const refreshAccessToken = async (
  companyName: string,
  email: string,
): Promise<string> => {
  let response;

  const decodedEmail = decodeURIComponent(email);

  const company = {
    name: decodeURIComponent(companyName),
  };
  const user = {
    email: decodedEmail,
  };

  try {
    const uuidToken = await signWithHmac(
      decodedEmail,
      process.env.UUID_TOKEN_SECRET
    );

    response = await api.post(
      `${process.env.PUBLICAPI_URL}/v0/customer/register`,
      {
        company,
        user,
        uuid: uuidToken,
      }
    );

    return response.data?.token;
  } catch (err) {
    return '';
  }
};

const resumeSessionBySecondary = async (
  companyName,
  email,
): Promise<RefreshedUser> => {
  const refreshedToken = await refreshAccessToken(companyName, email);

  if (!refreshedToken) {
    return;
  }

  const user = await refreshUserByToken(refreshedToken);

  return user;
};

const resumeSession = async (): Promise<RefreshedUser> => {
  const queryCookie = getQueryCookie();
  const token = queryCookie[QueryParamNames.ACCESS_TOKEN] || '';
  const email = queryCookie[QueryParamNames.EMAIL] || '';
  const companyName = queryCookie[QueryParamNames.COMPANY_NAME] || '';

  const hasToken = !!token;
  const hasSecondaryResumePreReqs = !!(email && companyName);
  const shouldAttemptResume = hasToken || hasSecondaryResumePreReqs;

  
	let user;
  const trackData = {
    query: queryCookie,
    hasToken,
    hasSecondaryResumePreReqs,
  }

  let method;

  if (shouldAttemptResume) {
  Analytics.track(TrackEvents.RESUME_ATTEMPTED, trackData)

    if (hasToken) {
      user = await refreshUserByToken(token);
      method = ResumeMethods.BY_TOKEN;
    }

    if (!user && hasSecondaryResumePreReqs) {
      user = await resumeSessionBySecondary(companyName, email);
      method = ResumeMethods.BY_RE_REGISTER;
    }
  }

  if (user) {
    currentUserHook?.setCurrentUser(user)
    Analytics.track(TrackEvents.RESUME_SUCCESS, {
      ...trackData,
      method,
      user,
    })
  } else {
    Analytics.track(TrackEvents.RESUME_FAILURE, trackData)
  }


  return user;
};

const useResumeSession = () => {
  currentUserHook = useCurrentUser()
  return {
    resumeSession,
  };
};

export default useResumeSession;
