<template>
  <div>
    <main id="self-service-content-v2" class="h-screen overflow-x-hidden bg-gray-7">
      <Nuxt />
    </main>
    <!-- Artsai Tracking Pixel -->
    <TrackingPixel
      pixel="https://data.adxcel-ec2.com/pixel/?ad_log=referer&action=misc&pixid=047b84a4-fac9-4150-8f4d-01006d162e76"
    />
    <!-- Claritas Tracking Pixel -->
    <TrackingPixel
      :pixel="`https://trkn.us/pixel/conv/ppt=19232;g=visit;gid=44723;ord=${randomNumber}`"
    />
    <!-- AudioHook Tracking Pixel -->
    <TrackingPixel
      pixel="https://listen.audiohook.com/b4d81c66-0b35-41e6-9c8c-9d5de456305b/pixel.png?type=pageview"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import TrackingPixel from '@/components/Marketing/TrackingPixel.vue';

export default defineComponent({
  name: 'ssuV2',
  components: { TrackingPixel },
  setup() {
    // Claritas requires a random number for their orderID to prevent duplicate data.
    const randomNumber = ref(Math.floor(Math.random() * 1000000));

    return {
      randomNumber,
    };
  },
});
</script>
