






















import { BaseIcon } from '@bambeehr/pollen';
import { defineComponent } from '@nuxtjs/composition-api';
import ShoppingCartLineLabel from '@/modules/SelfServiceRegistration/components/ShoppingCart/ShoppingCartLineLabel.vue';

export default defineComponent({
  name: 'ShoppingCartAccordionItem',
  components: {
    BaseIcon,
    ShoppingCartLineLabel,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: 'chevronRight',
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const toggle = () => {
      emit('input', !props.value);
    };

    return {
      toggle,
    };
  },
});
