


















































import { defineComponent } from '@nuxtjs/composition-api';
import { TypeBody, AnnularThrobber } from '@bambeehr/pollen';
import currency from '@bambeehr/currency';
import ShoppingCartLineLabel from '@/modules/SelfServiceRegistration/components/ShoppingCart/ShoppingCartLineLabel.vue';

export default defineComponent({
  name: 'ShoppingCartLineItem',
  components: {
    ShoppingCartLineLabel,
    TypeBody,
    AnnularThrobber,
  },
  props: {
    price: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default:'',
    },
    originalPrice: {
      type: Number,
      default: 0,
    },
    description: {
      type: String,
      default: undefined,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    
    bold: {
      type: Boolean,
      default: false,
    },
    calculating: {
      type: Boolean,
      default: false,
    },
    priceDescriptor: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      formatCurrency: (value, precision) =>
        currency(value).format({ precision }),
    };
  },
});
