<template>
  <RegistrationContainerAlt v-if="$matchMedia.sm">
    <InfoPanel
      :title="infoTitle"
      description="All states now require companies to have anti-harassment, anti-discrimination and workplace safety policies on file for every employee. Most states also require online training to accompany each policy."
    />
    <RegistrationQuestionForm
      card
      :button-disabled="isDisabled"
      :button-id="buttonId"
      :title="questionTitle"
      @submit="handleSubmit"
    >
      <SelectInput
        ref="focus"
        class="mb-3"
        size="large"
        placeholder="Industry"
        :value="value"
        :options="options"
        :show-reset="false"
        :searchable="false"
        data-qa="industry-select-input"
        @input="handleInput"
      />
    </RegistrationQuestionForm>
  </RegistrationContainerAlt>
  <RegistrationContainer v-else>
    <PurpleInfoPanel
      :title="infoTitle"
      description="All states now require companies to have anti-harassment, anti-discrimination and workplace safety policies on file for every employee. Most states also require online training to accompany each policy."
    />
    <RegistrationQuestionForm
      :button-disabled="isDisabled"
      :button-id="buttonId"
      :title="questionTitle"
      @submit="handleSubmit"
    >
      <SelectInput
        ref="focus"
        class="mb-3"
        size="large"
        placeholder="Industry"
        :value="value"
        :options="options"
        :show-reset="false"
        :searchable="false"
        @input="handleInput"
      />
    </RegistrationQuestionForm>
  </RegistrationContainer>
</template>

<script>
import { SelectInput } from '@bambeehr/pollen';
import { computed, ref } from '@nuxtjs/composition-api';
import InfoPanel from '../InfoPanel';
import PurpleInfoPanel from '../PurpleInfoPanel';
import RegistrationContainer from '../RegistrationContainer';

import RegistrationContainerAlt from '../alternative/RegistrationContainerAlt';
import RegistrationQuestionForm from '../RegistrationQuestionForm';
import useSelfService from '../../hooks/useSelfService';
import Industries from '@/constants/Industries';
import UsStates from '@/constants/UsStates';
import useHandlerOnEnter from '@/hooks/useHandlerOnEnter';
import Analytics from '@/services/Analytics';
import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration.js';

const FIELD_NAME = 'industry';

export default {
  components: {
    InfoPanel,
    RegistrationContainerAlt,
    RegistrationQuestionForm,
    SelectInput,
    PurpleInfoPanel,
    RegistrationContainer
  },

  setup(_, { emit }) {
    const { state: registration, updateRegistration } = useRegistration();
    const focus = ref(null);
    const options = Industries.map(({ industry }) => industry);

    const isDisabled = computed(() => !registration[FIELD_NAME]);

    const field = computed(() => registration[FIELD_NAME]);

    const infoTitle = computed(
      () =>
        `Great, we have HR Managers with expertise in ${
          UsStates[registration.state] || 'your area'
        }`
    );

    const questionTitle = computed(
      () => `What industry does ${registration[RegistrationStateNames.COMPANY_NAME]} work in?`
    );

    const handleInput = (e) => {
      updateRegistration({[RegistrationStateNames.INDUSTRY]: e})
    };

    const handleSubmit = () => {
      Analytics.track('industry_submitted', {
        industry: field.value,
      });
      if (!field.value) {
        return;
      }
      emit('submit', { [RegistrationStateNames.INDUSTRY]: field.value });
    };

    useHandlerOnEnter(handleSubmit);

    return {
      buttonId: RegistrationStateNames.INDUSTRY,
      focus,
      handleInput,
      handleSubmit,
      infoTitle,
      isDisabled,
      options,
      questionTitle,
      value: field,
    };
  },
};
</script>

<style scoped>
>>> .vs__selected-options {
  @apply flex-nowrap;
}

>>> .select-input__selected {
  @apply whitespace-nowrap;
}
</style>
