<template>
  <RegistrationContainerAlt v-if="$matchMedia.sm">
    <InfoPanel
      title="Let's learn more about your business"
      description="In order to match you with the best HR Manager for your business, we need to gather some information about you and your business"
      tag="Takes 60 seconds"
    />
    <RegistrationQuestionForm
      card
      :button-id="buttonId"
      title="How many employees does your <span style='white-space: nowrap;'>business have?</span>"
      @submit="handleSubmit"
    >
      <EmployeeDropdown
        class="mb-4 text-center"
        :is-invalid="isInvalid"
        :size="$matchMedia.md ? 'normal' : 'large'"
        placeholder="Number of employees"
        @input="handleInput"
      />
    </RegistrationQuestionForm>
  </RegistrationContainerAlt>
  <RegistrationContainer v-else>
    <PurpleInfoPanel
      title="Let's learn more about your business"
      description="In order to match you with the best HR Manager for your 
      business, we need to gather some information about you and your company."
      tag="Takes 60 seconds"
    />
    <RegistrationQuestionForm
      :button-id="buttonId"
      title="How many employees does your <span style='white-space: nowrap;'>business have?</span>"
      @submit="handleSubmit"
    >
      <EmployeeDropdown
        class="mb-4 text-center"
        :is-invalid="isInvalid"
        :size="$matchMedia.md ? 'normal' : 'large'"
        placeholder="Number of employees"
        @input="handleInput"
      />
    </RegistrationQuestionForm>
  </RegistrationContainer>
</template>

<script>
import { computed, ref, useRoute } from '@nuxtjs/composition-api';

import InfoPanel from '../InfoPanel';
import PurpleInfoPanel from '../PurpleInfoPanel';

import RegistrationQuestionForm from '../RegistrationQuestionForm';
import RegistrationContainer from '../RegistrationContainer';
import RegistrationContainerAlt from '../alternative/RegistrationContainerAlt';
import useSelfService from '../../hooks/useSelfService';
import EmployeeDropdown from '@/components/EmployeeDropdown';
import useHandlerOnEnter from '@/hooks/useHandlerOnEnter';
import useRegistration, { RegistrationStateNames } from '@/modules/SelfServiceRegistration/hooks/useRegistration.js'; // TODO: consolidate with useRegistration above
import SelfServicePages from '@/modules/SelfServiceRegistration/constants/SelfServicePages';
import Analytics from '@/services/Analytics';
import { EmployeeNotLookingOption } from '@/constants/Registration'

export default {
  name: 'CompanySize',
  components: {
    PurpleInfoPanel,
    EmployeeDropdown,
    InfoPanel,
    RegistrationContainerAlt,
    RegistrationContainer,
    RegistrationQuestionForm,
  },

  setup(_, { emit }) {    
    const isInvalid = ref(false);
    const route = useRoute();

    const { state: registration } = useRegistration();

    const field = computed(() => registration[RegistrationStateNames.NUM_EMPLOYEES]);

    const handleInput = (e) => {

      if (e) {
        isInvalid.value = false;
      }
    };

    const handleSubmit = () => {
      if (!field.value) {
        return (isInvalid.value = true);
        // eslint-disable-next-line no-else-return
      } else {
        const analyticsEventName = 'company_size_submitted'

        // If the user submits that they are not an employer, then track it and redirect
        if (field.value === EmployeeNotLookingOption.value) {
          const analyticsProps = {
            size: null,
            is_employee: true,
          }
          Analytics.track(analyticsEventName, analyticsProps);
          window.location.href = `https://${process.env.APP}`;
          return;
        }

        const analyticsProps = {
          size: field.value,
          is_employee: false,
        }

        Analytics.track(analyticsEventName, analyticsProps);


        window?.analytics?.page({
          url: `${window.location.origin}/registration/${SelfServicePages[0].slug}`,
          path: `/registration/${SelfServicePages[0].slug}`,
          referrer: `${window.location.orgin}${route.value.path}`,
        });

        emit('submit', {
          [RegistrationStateNames.NUM_EMPLOYEES]: field.value,
        });
      }
    };

    useHandlerOnEnter(handleSubmit);

    return {
      buttonId: RegistrationStateNames.NUM_EMPLOYEES,
      focus,
      handleInput,
      handleSubmit,
      isInvalid,
      value: field,
    };
  },
};
</script>
