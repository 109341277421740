const Campaign = Object.freeze({
  FREE_TRIAL: 'free-trial',
  RADIO: 'radio',
});

export const promotionType = {
  DOLLAR_TRIAL: 'dollar_trial',
  SETUP_FEE_DISCOUNT: 'setup_fee_discount',
  THREE_MONTH_DOLLAR_TRIAL: 'three_month_dollar_trial',
};

export default Campaign;
