<template>
  <PurpleHexContainer
    class="relative hex-container flex flex-col p-6 sm:p-0 sm:h-screen w-full sm:w-3/6 text-white"
  >
    <IconButton
      v-if="showBackButton"
      class="sm:hidden top-7 absolute text-18"
      flat
      icon="chevronLeft"
      variant="white"
      @click="goToPreviousPage"
    />
    <BambeeLogo
      class="self-center sm:self-start h-8 sm:ml-12 sm:mt-6 sm:h-10 md:h-24 mb-6"
    />

    <div
      class="flex flex-col z-10 sm:bg-white sm:bg-opacity-20 sm:p-6 sm:rounded-lg sm:max-w-sm sm:absolute sm:top-28 sm:my-auto lg:top-40 lg:left-60"
    >
      <TypeDisplay class="font-bold text-18 mb-2 md:text-28">{{
        title
      }}</TypeDisplay>
      <TypeBody class="text-14 md:text-16 mb-4">
        <span v-html="description" />
      </TypeBody>
      <BaseTag v-if="tag" class="self-start" color="inevitable">{{
        tag
      }}</BaseTag>
      <!-- Needed for contact information screen -->
      <slot name="contact-info" />
      <div class="quote-tail absolute opacity-20"></div>
    </div>
    <picture v-if="imageName" class="hidden lg:block absolute bottom-0 left-0">
      <source
        :srcset="
          require(`~/assets/images/self-service-checkout/hrm-${imageName}.png`)
        "
        media="(min-width: 830px)"
        type="image/png"
      />
      <img
        :src="
          require(`~/assets/images/self-service-checkout/hrm-${imageName}-sm.png`)
        "
        style="max-height: 620px"
        alt="human resources manager"
      />
    </picture>
  </PurpleHexContainer>
</template>

<script>
import { BaseTag, IconButton, TypeBody, TypeDisplay } from '@bambeehr/pollen';

import useSelfService from '../hooks/useSelfService';
import BambeeLogo from '@/components/BambeeLogo.vue';
import PurpleHexContainer from '@/components/PurpleHexContainer';

export default {
  components: {
    BambeeLogo,
    BaseTag,
    IconButton,
    PurpleHexContainer,
    TypeBody,
    TypeDisplay,
  },

  props: {
    description: {
      type: String,
      required: true,
    },
    imageName: {
      type: String,
      validator: (value) => {
        return [
          null,
          'ashley',
          'jordan',
          'judith',
          'katherine',
          'raymond',
        ].includes(value);
      },
      required: false,
      default: null,
    },
    tag: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
  },

  setup() {
    const { currentPageIndex, goToPreviousPage, showBackButton } =
      useSelfService();

    return {
      currentPageIndex,
      goToPreviousPage,
      showBackButton,
    };
  },
};
</script>

<style scoped>
.hex-container {
  min-height: 236px;
}

.quote-tail {
  background: white;
  border-bottom: 10px;
  border-right: 20px solid white;
  border-top: 10px;
  left: -20px;
  position: absolute;
  top: 16px;
}
</style>
