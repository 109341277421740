<template>
  <RegistrationContainerAlt v-if="$matchMedia.sm">
    <InfoPanel
      class="px-20"
      title="How can we help?"
      description="To best assist you, please let us know why you've come to Bambee..."
    />
    <RegistrationQuestionForm
      card
      :button-id="buttonId"
      title="What brought you to bambee?"
      :button-disabled="isDisabled"
      @submit="handleSubmit"
    >
      <ReasonDropdown
        ref="focus"
        input-size="large"
        :dropdown-label="null"
        data-qa="wizard-card-what-brought-you-input"
      />
    </RegistrationQuestionForm>
  </RegistrationContainerAlt>
  <RegistrationContainer v-else>
    <PurpleInfoPanel
      title="How can we help?"
      description="To best assist you, please let us know why you've come to Bambee..."
    />
    <RegistrationQuestionForm
      :button-id="buttonId"
      title="What brought you to bambee?"
      :button-disabled="isDisabled"
      @submit="handleSubmit"
    >
      <ReasonDropdown
        ref="focus"
        input-size="large"
        :dropdown-label="null"
        data-qa="wizard-card-what-brought-you-input"
      />
    </RegistrationQuestionForm>
  </RegistrationContainer>
</template>

<script>
import { computed, nextTick, onMounted, ref } from '@nuxtjs/composition-api';

import InfoPanel from '../InfoPanel';
import PurpleInfoPanel from '../PurpleInfoPanel';
import RegistrationContainer from '../RegistrationContainer';
import RegistrationContainerAlt from '../alternative/RegistrationContainerAlt';
import RegistrationQuestionForm from '../RegistrationQuestionForm';
import useSelfService from '../../hooks/useSelfService';
import ReasonDropdown from '@/components/ReasonDropdown';

import useRegistration, { RegistrationStateNames } from '@/modules/SelfServiceRegistration/hooks/useRegistration.js';
import useHandlerOnEnter from '@/hooks/useHandlerOnEnter';
import Analytics from '@/services/Analytics';

const focusElement = (element) => {
  element.focus();
};

export default {
  components: {
    InfoPanel,
    RegistrationContainerAlt,
    RegistrationQuestionForm,
    ReasonDropdown,
    PurpleInfoPanel,
    RegistrationContainer
  },

  setup(_, { emit }) {
    const { state } = useRegistration();
    const focus = ref(null);
    const isBusy = ref(false);

    onMounted(() =>
      // Causes input to focus after page transition
      nextTick(() => {
        focusElement(focus.value.$el);
      })
    );

    const field = computed(
      () => state[RegistrationStateNames.WHAT_BROUGHT_YOU_TO_BAMBEE]
    );

    const isDisabled = computed(() => !field.value);

    const handleSubmit = () => {
      Analytics.track('what-brought-you-submitted', {
        what_brought_you_to_bambee: field.value,
      });
      if (isDisabled.value || isBusy.value || !field.value) {
        return;
      }
      isBusy.value = true;
      
      emit('submit', {
        [RegistrationStateNames.WHAT_BROUGHT_YOU_TO_BAMBEE]: field.value,
      });
    };

    useHandlerOnEnter(handleSubmit);

    return {
      buttonId: RegistrationStateNames.WHAT_BROUGHT_YOU_TO_BAMBEE,
      focus,
      handleSubmit,
      isDisabled,
      value: field,
    };
  },
};
</script>
<style scoped>
.vs__search {
  display: none;
}
</style>
