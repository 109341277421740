<template>
  <div class="home-nav" :class="banner ? 'has-banner' : ''">
    <client-only>
      <component :is="banner" v-if="banner && offsetTop === 0" :brand="brand" />
    </client-only>
    <div
      class="group z-40 bg-white text-primary"
      :class="
        topNavTrigger ? 'text-primary bg-white' : 'bg-transparent text-white'
      "
    >
      <nav :class="topNavTrigger ? 'shadow-md' : ''">
        <div
          class="flex items-center justify-between max-w-7xl mx-auto px-4 py-8 sm:py-4"
        >
          <div class="flex items-center">
            <BambeeLogo color="primary" class="bambee-logo mr-1 md:mr-4" />
          </div>
          <div class="flex items-center">
            <client-only>
              <div class="relative actions flex items-center mr-2">
                <BaseButton
                  :flat="true"
                  class="text-white group-hover:text-primary mr-2"
                  :class="topNavTrigger ? 'text-primary' : 'text-white'"
                  @click="sendTo('app')"
                  >Sign In</BaseButton
                >
                <BaseButton
                  variant="secondary"
                  :class="offsetTop > 0 ? 'mr-4' : 'mr-0'"
                  @click="goToTryBambeeNow()"
                >
                  Try Bambee Now
                </BaseButton>
                <AvatarImage
                  image="/assets/images/market/beautiful-people/ashley-medows.jpg"
                  class="absolute-image cursor-pointer absolute -right-2"
                  :class="offsetTop > 0 ? 'block' : 'hidden'"
                  @click="goToTryBambeeNow()"
                />
              </div>
            </client-only>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  useRoute,
  useRouter,
} from '@nuxtjs/composition-api';
import {
  AvatarImage,
  BaseButton,
  TypeHeading,
  constants,
} from '@bambeehr/pollen';
import BambeeLogo from '@/modules/HomePage/components/Navigation/BambeeLogo.vue';
import Campaign from '@/modules/SplashPage/constants/Campaign';
import Partners from '@/modules/SplashPage/constants/Partners';
import { navItems } from '@/modules/HomePage/constants';
import useGlobalSettings from '@/hooks/useGlobalSettings';
import usePartnerPromo from '@/modules/SplashPage/hooks/usePartnerPromo';

const FreeTrialBanner = () =>
  import('@/modules/SplashPage/components/FreeTrial/FreeTrialBanner');
const RadioBanner = () => import('@/modules/SplashPage/components/RadioBanner');
const PartnerBanner = () =>
  import('@/modules/SplashPage/components/Partners/PartnerBanner');

const { Typography } = constants;

export default defineComponent({
  name: 'HomeNav',
  Typography,
  components: {
    AvatarImage,
    BambeeLogo,
    BaseButton,
    TypeHeading,
  },
  setup() {
    const { isAPartnerPath } = usePartnerPromo();
    const panel = ref(null);
    const offsetTop = ref(0);
    const route = useRoute();
    const router = useRouter();
    const mobileMenuToggle = ref(false);
    const navigationPanelToggle = ref(false);
    const { getHeader } = useGlobalSettings();
    const toggleNav = ref(null);
    const setPanel = (key) => {
      if (key) {
        navigationPanelToggle.value = true;
      } else {
        navigationPanelToggle.value = false;
        mobileMenuToggle.value = false;
      }
      panel.value = key;
    };

    const topNavTrigger = computed(
      () =>
        offsetTop.value > 0 ||
        mobileMenuToggle.value ||
        navigationPanelToggle.value ||
        toggleNav.value
    );

    const sendTo = (key) => {
      switch (key) {
        case 'app':
          window.location.href = `https://${process.env.APP}`;
          break;
      }
    };

    const goToTryBambeeNow = () => {
      router.push('/registration/company-size/');
    };

    const onScroll = () => {
      offsetTop.value =
        window.pageYOffset || document.documentElement.scrollTop;
    };

    onMounted(() => {
      window.addEventListener('scroll', onScroll);
      window.addEventListener('touchstart', onScroll);
      window.addEventListener('touchmove', onScroll);
      offsetTop.value =
        window.pageYOffset || document.documentElement.scrollTop;
      const { topNavToggle } = getHeader;
      toggleNav.value = topNavToggle;
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('touchstart', onScroll);
      window.removeEventListener('touchmove', onScroll);
    });

    const toggleMobileMenu = () => {
      mobileMenuToggle.value = !mobileMenuToggle.value;
    };

    const banner = computed(() => {
      if (route.value.name === Campaign.FREE_TRIAL) {
        return FreeTrialBanner;
      }
      if (
        (route.value.query.utm_medium === 'sem-br' &&
          route.value.name === Campaign.RADIO) ||
        (route.value.name === Campaign.RADIO &&
          route.value.query.utm_medium !== 'sem-br')
      ) {
        return RadioBanner;
      }
      if (isAPartnerPath.value) {
        return PartnerBanner;
      }
      return null;
    });

    const brand = computed(() => route.value.params.brand || route.value.name);
    const legacyPages = computed(() => {
      if (
        [
          'hr',
          'terminations',
          'onboarding',
          'radio',
          'hr-policies',
          'square',
        ].includes(route.value?.name) ||
        [
          Partners.DEEL,
          Partners.REMOTE,
          Partners.BUILT_FIRST,
          Partners.BREX,
          Partners.PAYROLLSETUP,
        ].includes(route.value?.params?.brand) ||
        route.value?.params?.brand
      ) {
        return true;
      }
      return false;
    });

    return {
      navItems,
      setPanel,
      sendTo,
      panel,
      goToTryBambeeNow,
      onScroll,
      offsetTop,
      mobileMenuToggle,
      toggleMobileMenu,
      navigationPanelToggle,
      topNavTrigger,
      banner,
      brand,
      legacyPages,
    };
  },
});
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .bambee-logo >>> svg {
    width: 140px;
    height: 50px;
  }
}

@media (hover: hover) {
  .group:hover .mobile-menu-toggle >>> .center::before,
  .group:hover .mobile-menu-toggle >>> .center::after,
  .group:hover .mobile-menu-toggle >>> .center div {
    @apply bg-primary;
  }
}
</style>
