<template>
  <nuxt />
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'empty',
});
</script>
