<template>
  <RegistrationContainerAlt v-if="$matchMedia.sm">
    <InfoPanel
      title="HR Fun Fact"
      description="Where your company is located will help us determine which HR practices and HR policies your business and employees should adopt."
    />
    <RegistrationQuestionForm
      card
      :button-disabled="isDisabled"
      :button-id="buttonId"
      :title="questionTitle"
      @submit="handleSubmit"
    >
      <TextInput
        ref="focus"
        inputmode="numeric"
        :value="value"
        size="large"
        placeholder="Zip Code"
        pattern="[0-9]*"
        maxlength="5"
        data-qa="wizard-card-zip-code-input"
        @input="handleInput"
      />
    </RegistrationQuestionForm>
  </RegistrationContainerAlt>
  <RegistrationContainer v-else>
    <PurpleInfoPanel
      title="HR Fun Fact"
      description="Where your company is located will help us determine which HR practices and HR policies your business and employees should adopt."
    />
    <RegistrationQuestionForm
      :button-disabled="isDisabled"
      :button-id="buttonId"
      :title="questionTitle"
      @submit="handleSubmit"
    >
      <TextInput
        ref="focus"
        inputmode="numeric"
        :value="value"
        size="large"
        placeholder="Zip Code"
        pattern="[0-9]*"
        maxlength="5"
        @input="handleInput"
        @blur="handleBlur"
      />
    </RegistrationQuestionForm>
  </RegistrationContainer>
</template>

<script>
import { TextInput } from '@bambeehr/pollen';
import { computed, nextTick, onMounted, ref } from '@nuxtjs/composition-api';

import InfoPanel from '../InfoPanel';
import PurpleInfoPanel from '../PurpleInfoPanel';
import RegistrationContainer from '../RegistrationContainer';

import RegistrationContainerAlt from '../alternative/RegistrationContainerAlt';
import RegistrationQuestionForm from '../RegistrationQuestionForm';
import useSelfService from '../../hooks/useSelfService';
import { validateZipCode } from '@/services/ZipCode';
import isValidZipCode from '@/utils/isValidZipCode';
import useHandlerOnEnter from '@/hooks/useHandlerOnEnter';
import Analytics from '@/services/Analytics';
import useRegistration, { RegistrationStateNames } from '@/modules/SelfServiceRegistration/hooks/useRegistration.js';


const focusElement = (element) => {
  element.focus();
};

export default {
  components: {
    InfoPanel,
    RegistrationContainerAlt,
    RegistrationQuestionForm,
    TextInput,
    PurpleInfoPanel,
    RegistrationContainer
  },

  setup(_, { emit }) {
    const { state: registration, updateRegistration } = useRegistration();
    const focus = ref(null);
    const isBusy = ref(false);

    onMounted(() =>
      // (kevin.lee)
      // Causes input to focus after page transition
      nextTick(() => {
        focusElement(focus.value.$el);
      })
    );

    const isDisabled = computed(
      () => !isValidZipCode(registration[RegistrationStateNames.ZIP]) || isBusy.value
    );

    const field = computed(() => registration[RegistrationStateNames.ZIP]);

    const questionTitle = computed(
      () => `Where is ${registration.name} located?`
    );

    const handleInput = (e) => {
      updateRegistration({[RegistrationStateNames.ZIP]: e.slice(0, 5)});
    };

    const handleSubmit = async () => {
      Analytics.track('zipcode_submitted', { zipcode: field.value });
      let stateId = ''
      if (isDisabled.value || isBusy.value || !field.value) {
        return;
      }
      isBusy.value = true;
      try {
        const { stateId: returnedStateId } = await validateZipCode(registration[RegistrationStateNames.ZIP]);
        stateId = returnedStateId || ''
      } catch (err) {
      } finally {
        emit('submit', { 
          [RegistrationStateNames.ZIP]: field.value,
          [RegistrationStateNames.STATE]: stateId
        });
      }
    };

    useHandlerOnEnter(handleSubmit);
    const handleBlur = () => {
      // On mobile, there is a bug where the keyboard pushes the content too far down. This ensures that the content is scrolled back up when the keyboard is closed.
      window?.scrollTo(0, 0);
    };


    return {
      buttonId: RegistrationStateNames.ZIP,
      focus,
      handleInput,
      handleSubmit,
      isDisabled,
      questionTitle,
      value: field,
      handleBlur,
    };
  },
};
</script>
