import api from '@/services/api';

let cached;

export const fetchPlans = async (options = {}) => {
  if (cached) {
    return cached
  }

  const { data } = await api.get('/billing/billing/v2/price-plans-sale', {
    params: options,
  });

  const { data: liteData } = await api.get(
    '/billing/billing/v2/core-plans/lite',
    {
      params: options,
    }
  );
  
  const combined = [...data, ...liteData];
  cached = combined;

  return combined;
};

export default {
  fetchPlans,
};
