import { computed, ref, readonly } from '@nuxtjs/composition-api';
import capitalize from 'lodash/capitalize';
import PricePlans from '@/services/PricePlans';
import staticPlans from '@/plans.json';
import { BillingPeriod } from '@/constants/Billing';

const tiersWithDedicatedMonthlyDefault = [0, 1]; // Currently all else should default to annual


export enum PlanProductKey {
  LITE = 'LITE',
  DEDICATED = 'CORE',
  ELITE = 'ELITE',
}

export enum PayrollProductKey {
  BASIC = 'BASIC',
  PREMIUM = 'PREMIUM',
}

type PlanDescription = {
  title: string;
  header: string;
  description: string;
  priceCopy: string;
  features: string[];
};

type IPlan = {
  productKey: PlanProductKey;
  partner: string;
  version: string;
  status: string;
  annual: boolean;
  tierNumber: number;
  name: string;
  value: string;
  priceId: string;
  employeeSizeMapping: string;
  minSize: number;
  maxSize: number;
  pause: boolean;
  chatOnly: boolean;
  internal: boolean;
  regulated: boolean;
  price: number;
  monthlyPrice: number;
  setupFee: number;
  setupFeeDiscount: number;
  billingPeriod: string;
  volumeBased: boolean;
  collectTax: boolean;
  tags?: any[];
};

const isLoading = ref(false);
const allPlans = ref([] as IPlan[]);

const preparePlan = (plan) => ({
  employeeSizeName:
    capitalize(plan.employeeSizeMapping?.replace('-', ' - ')) || '',
  ...plan,
});

const fetchPlans = async (params = {}) => {
  if (isLoading.value) {
    return;
  }
  isLoading.value = true;

  const plans = await PricePlans.fetchPlans(params);

  allPlans.value = plans
    .map(preparePlan)
    .filter((plan) => plan.employeeSizeMapping !== 'franchise');
  isLoading.value = false;
};

/**
 * COMPUTED
 */

const activePlans = computed(() =>
  allPlans.value.filter(({ status }) => status === 'active')
);

const activeDedicatedPlans = computed(() =>{  
  const plans = activePlans.value.filter(
    (plan: any) => plan.productKey === PlanProductKey.DEDICATED
  )

  return plans;
});
const activeDedicatedMonthlyPlans = computed(() =>{  
  const plans = activeDedicatedPlans.value.filter(
    (plan: any) => plan.billingPeriod === BillingPeriod.MONTH
  )

  return plans;
});

const activeElitePlans = computed(() =>
  activePlans.value.filter(
    (plan: any) => plan.productKey === PlanProductKey.ELITE
  )
);

const bambeeLitePlans = computed(() =>
  allPlans.value.filter((plan: any) => plan.productKey === PlanProductKey.LITE)
);

const activeLitePlans = computed(() =>
  activePlans.value.filter(
    (plan: any) => plan.productKey === PlanProductKey.LITE

  )
);

// Currently set to always be monthly
const bambeeLitePlan = computed(() =>
  activeLitePlans.value.find(
    (plan: any) => plan.billingPeriod === BillingPeriod.MONTH
  )
);

const planTiers = computed(() =>
  activePlans.value
    .filter(({ billingPeriod }) => billingPeriod === BillingPeriod.MONTH)
    // @ts-ignore
    .sort((a, b) => a.maxSize - b.maxSize)
);

const annualPlans = computed(() =>
  activePlans.value
    .filter(({ billingPeriod }) => billingPeriod === BillingPeriod.YEAR)
    // @ts-ignore
    .sort((a, b) => a.maxSize - b.maxSize)
);

const monthlyPlans = computed(() =>
  activePlans.value
    .filter(({ billingPeriod }) => billingPeriod === BillingPeriod.MONTH)
    // @ts-ignore
    .sort((a, b) => a.maxSize - b.maxSize)
);


/**
 * UTILITIES
 */

const getActivePlansByEmployeeCount = (numOfEmployees) =>
  activePlans.value.filter(
    (plan) => plan.employeeSizeMapping === numOfEmployees
  );

const getAnnualPlanByEmployeeRange = (numOfEmployees) =>
  getActivePlansByEmployeeCount(numOfEmployees).find(
    (p) => p?.billingPeriod === BillingPeriod.YEAR
  );

const getAppropriateDedicatedPlanByTier = (tierNumber = 0) => {
  let billingPeriod: string = BillingPeriod.YEAR;

  // if (tiersWithDedicatedMonthlyDefault.includes(tierNumber)) {
  if (true) {
    // For current test, everything should be monthly, normally it will be the comment above
    billingPeriod = BillingPeriod.MONTH;
  }

  // @ts-ignore
  return activeDedicatedPlans.value.find(
    (p) => p?.billingPeriod === billingPeriod && p?.tierNumber === tierNumber
  );
};


const getAppropriatePlanByEmployeeRange = (numOfEmployees) => {
  const activePlans = getActivePlansByEmployeeCount(numOfEmployees);
  // @ts-ignore
  const activeTier = activePlans[0]?.tierNumber;
  return getAppropriateDedicatedPlanByTier(activeTier);
};

const getPlanById = (id = '') => {
  return allPlans.value.find((plan) => plan.value === id);
};

const getEmployeeRangeByTier = (tier: number): string => {

  // @ts-ignore
  return activePlans.value.find(p=>p.tierNumber === tier)?.employeeSizeMapping || ''
}

const calculateSetupFeeWithDiscount = (setupFee, discount) => {
  return setupFee - setupFee * discount;
};

/**
 * STATE
 */


const selectedPlanId = ref('');
const waivedSetupFee = ref(false);
const dollarTrial = ref(true); // TODO: Switch to hasTrial
const selectedPlan = computed(() => getPlanById(selectedPlanId.value));

const selectedSetupFee = computed(() => {
  const setupFee =
    waivedSetupFee.value || dollarTrial.value
      ? 0
      : // @ts-ignore
        selectedPlan.value?.setupFee;

  return calculateSetupFeeWithDiscount(
    setupFee,
    // @ts-ignore
    selectedPlan.value?.setupFeeDiscount
  );
});

const selectedTotalSubscriptionDue = computed(() => {
  // @ts-ignore
  const monthlyPrice = selectedPlan.value?.monthlyPrice || 0;

  return selectedPlan.value?.annual ? monthlyPrice * 12 : monthlyPrice;
});

const selectedTotalPaymentDue = computed(() => {
  return selectedTotalSubscriptionDue.value + selectedSetupFee.value;
});

const setSelectedPlanById = (id) => {
  if (selectedPlanId.value !== id) {
    selectedPlanId.value = id;
  }
};

const removeSelectedPlan = () =>{
  selectedPlanId.value = ''
}

const setWaivedSetupFee = (waived = false) => {
  waivedSetupFee.value = waived;
};

const isReady = computed(() => {
  return !isLoading.value && !!activePlans.value.length;
});


const usePricePlans = (
  options = {
    force: false,
    params: {},
  }
) => {
  if (options.force || !allPlans.value.length) {
    fetchPlans(options.params || {});
  }

  return {
    isLoading: readonly(isLoading),
    isReady,
    plans: activePlans,
    dedicatedPlans: activeDedicatedPlans,
    elitePlans: activeElitePlans,
    monthlyPlans: activeDedicatedMonthlyPlans,
    annualPlans,
    tiers: planTiers,
    getAnnualPlanByEmployeeRange,
    getAppropriatePlanByEmployeeRange,
    getActivePlansByEmployeeCount,
    selectedPlanId,
    removeSelectedPlan,
    getAppropriateDedicatedPlanByTier,
    getEmployeeRangeByTier,
    getPlanById,
    selectedPlan,
    selectedSetupFee,
    selectedTotalSubscriptionDue,
    selectedTotalPaymentDue,
    setSelectedPlanById,
    setWaivedSetupFee,
    calculateSetupFeeWithDiscount,
    bambeeLitePlan,
    bambeeLitePlans,
  };
};

export default usePricePlans;
