<template>
  <div>
    <!-- Conversion Pixel -->
    <img :src="pixel" width="0" height="0" border="0" />
  </div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'TrackingPixel',
  props: {
    pixel: {
      type: String,
      required: true,
    },
  },
});
</script>
