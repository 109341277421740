<template>
  <div
    class="navigation-panel shadow-2"
    @mouseleave.stop="$emit('set-panel', null)"
  >
    <div class="max-w-7xl mx-auto grid grid-cols-12 gap-8 w-100 py-8 px-6">
      <div
        v-for="(section, index) in navPanel"
        :key="`panel-${index}`"
        :class="panelClasses(section.label)"
      >
        <div v-if="section.label">
          <TypeOverline variant="large" class="mb-[18px]">
            <span :class="panelHeading">{{ section.label }}</span>
          </TypeOverline>
          <BaseDivider class="mb-5" />
        </div>
        <div class="panel-items grid grid-cols-12 gap-6 w-100">
          <NavigationPanelItem
            v-for="(item, pI) in section.items"
            :key="`panel-item-${pI}`"
            v-bind="item"
            class="col-span-12"
            :class="panelItemClasses(section.label)"
            :panelItemTitle="panelItemTitle"
            :panelItemBody="panelItemBody"
            @reset="$emit('set-panel', null)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseDivider, TypeOverline } from '@bambeehr/pollen';
import { NavPanels } from '../../constants';
import NavigationPanelItem from './NavigationPanelItem.vue';
import { defineComponent } from '@nuxtjs/composition-api';

const panelNames = Object.freeze({
  MANAGER: 'manager',
  ABOUT: 'about',
});

const panelLabels = Object.freeze({
  FOR_YOUR_EMPLOYEES: 'FOR YOUR EMPLOYEES',
  FOR_YOUR_BUSINESS: 'FOR YOUR BUSINESS',
  WHO_WE_ARE: 'WHO WE ARE',
});

export default defineComponent({
  name: 'NavigationPanel',

  components: {
    BaseDivider,
    NavigationPanelItem,
    TypeOverline,
  },
  props: {
    panel: {
      type: String,
      default: null,
    },
    panelHeading: {
      type: String,
      required: true,
    },
    panelItemTitle: {
      type: String,
      required: true,
    },
    panelItemBody: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const panelClasses = (label) => {
      let classes = 'col-span-12 lg:col-span-6';
      if (
        props.panel === panelNames.MANAGER &&
        label === panelLabels.FOR_YOUR_EMPLOYEES
      ) {
        classes = 'col-span-12 lg:col-span-5';
      }
      if (
        props.panel === panelNames.MANAGER &&
        label === panelLabels.FOR_YOUR_BUSINESS
      ) {
        classes = 'col-span-12 lg:col-span-7';
      }

      if (
        props.panel === panelNames.ABOUT &&
        label === panelLabels.WHO_WE_ARE
      ) {
        classes = 'col-span-12 lg:col-span-12';
      }

      return classes;
    };

    const panelItemClasses = (label) => {
      let classes = 'col-span-6 lg:col-span-6';
      if (
        props.panel === panelNames.MANAGER &&
        label === panelLabels.FOR_YOUR_EMPLOYEES
      ) {
        classes = 'col-span-12';
      }
      if (
        props.panel === panelNames.MANAGER &&
        label === panelLabels.FOR_YOUR_BUSINESS
      ) {
        classes = 'col-span-6 lg:col-span-4';
      }

      if (
        props.panel === panelNames.ABOUT &&
        label === panelLabels.WHO_WE_ARE
      ) {
        classes = 'lg:col-span-3 lg:my-2 col-span-4 my-0 max-w-[15rem]';
      }

      return classes;
    };

    const { autopilotsBusiness, hrManager, payroll, about, resources } =
      NavPanels;

    return {
      panelClasses,
      panelItemClasses,
      autopilotsBusiness,
      hrManager,
      payroll,
      about,
      resources,
    };
  },
  computed: {
    navPanel() {
      switch (this.panel) {
        case 'autopilot':
          return this.autopilotsBusiness;
        case 'manager':
          return this.hrManager;
        case 'payroll-addon':
          return this.payroll;
        case 'about':
          return this.about;
        case 'resources':
          return this.resources;
        default:
          return [];
      }
    },
  },
});
</script>

<style scoped>
.navigation-panel {
  background-color: white;
  color: black;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05),
    0px 4px 8px rgba(0, 0, 0, 0.05), 0px 8px 16px rgba(0, 0, 0, 0.05);
  transition: opacity 100ms ease-in-out;
  z-index: 30;
}
</style>
