/**
 * NOTE: If we ever remove this file, relocate the DataDog logic as needed. 
 */
import { readonly, reactive } from '@nuxtjs/composition-api';
import { setUser } from '@/services/Datadog'

const state = reactive({
  // user
  user: {},
  userId: '',
  userName: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',

  // company
  company: {},
  companyId: '',
  companyName: '',
  pricePlan: '',
  industry: '',
  numOfEmployees: '',
  zip: '',
});

const setCurrentUser = (user = {}) => {
  const company = user._company || {};

  state.user = user;
  state.userId = user._id || '';
  state.userName = user.profile?.full_name || '';
  state.firstName = user.profile?.first_name || '';
  state.lastName = user.profile?.last_name || '';
  state.phone = user.profile?.phone || '';
  state.email = user._auth?.email || '';

  state.company = company;
  state.companyId = company._id || '';
  state.companyName = company.name || '';
  state.industry = company.profile?.industry || '';
  state.numOfEmployees = company.profile?.num_of_employees || '';
  state.pricePlan = company.plan || '';
  state.zip = company.profile?.zip || '';

  const userEmail = user._auth?.email || '';
  const testEmailPartials = [`@valid.com`, `@bambee.com`, `@test.com`]
  const DDUser = {
    companyId: company.id || '',
    companyName: company.name || '',
    userId: user._id || '',
    email: userEmail,
    name: user.profile?.full_name || '',
    isTest: userEmail && !!testEmailPartials.find(p=>userEmail.includes(p)),
    plan: company.plan || '',
    abTestName: process.env.AB_TEST_NAME || '',
    abTestVariant: process.env.AB_TEST_VARIANT || '',
  }
  setUser(DDUser)
};

const useCurrentUser = () => {
  return {
    currentUser: readonly(state),
    setCurrentUser,
  };
};

export default useCurrentUser;
