const Industries = Object.freeze([
  { industry: 'Architecture & Design', sub: [] },
  { industry: 'Automotive', sub: ['Mobile'] },
  { industry: 'Cannabis', sub: [] },
  { industry: 'Cleaning & Maintenance Services', sub: [] },
  { industry: 'Construction', sub: ['Fencing'] },
  { industry: 'Energy', sub: [] },
  { industry: 'Entertainment', sub: ['Production', 'Equipment Rental'] },
  {
    industry: 'Financial Services',
    sub: ['Accounting', 'Insurance', 'Foundation / Trust'],
  },
  { industry: 'Fitness', sub: [] },
  { industry: 'Food & Beverage', sub: [] },
  {
    industry: 'Healthcare',
    sub: ['Dental', 'Research', 'Technology', 'Treatment & Recovery'],
  },
  {
    industry: 'Home Services',
    sub: ['Landscaping', 'Painting', 'Pest Control', 'Tree Management'],
  },
  { industry: 'Hospitality', sub: [] },
  { industry: 'HR Consulting', sub: [] },
  { industry: 'Industrial & Engineering', sub: ['Aerospace', 'Research'] },
  { industry: 'Legal', sub: [] },
  {
    industry: 'Manufacturing',
    sub: ['Chemical Supplier', 'Consumer Electronics', 'Industrial'],
  },
  { industry: 'Marketing Agency', sub: [] },
  { industry: 'Occupational Safety', sub: [] },
  { industry: 'Personal Care', sub: [] },
  { industry: 'Real Estate', sub: ['Consumer', 'Corporate Development'] },
  { industry: 'Recruitment & Staffing', sub: [] },
  {
    industry: 'Retail',
    sub: ['Automotive', 'Consumer Electronics', 'Furniture', 'Pet'],
  },
  { industry: 'Security', sub: [] },
  {
    industry: 'Tech & Business Consulting',
    sub: [
      'Business Consulting',
      'Design & Development',
      'Infrastructure',
      'IT Consulting',
      'Management Consulting',
    ],
  },
  { industry: 'Transportation', sub: ['Rideshare'] },
  { industry: 'Other', sub: [] },
]);

export default Industries;
