const Partners = Object.freeze({
  SQUARE: 'square',
  HELLO_ALICE: 'hello-alice',
  DEEL: 'deel',
  BUILT_FIRST: 'builtfirst',
  REMOTE: 'remote',
  BREX: 'brex',
  ACCOUNTANT: '1800accountant',
  PAYROLLSETUP: 'payrollsetup',
  CONSTANTCONTACT: 'constantcontact',
  JP_MORGAN: 'jpmorgan',
  HELP_SCOUT: 'help-scout',
  BERKLEY_SELECT: 'berkley-select',
  AVIBRA: 'avibra',
  CHECKR: 'checkr',
  FO_AGENCY: 'fo-agency',
});

export default Partners;
