import Cookie from 'js-cookie';
import api, { setToken, setAccessCookie } from './api';
import CookieNames from '@/constants/CookieNames';
import Analytics from '@/services/Analytics';
import getTimezoneString from '@/utils/getTimezoneString';

export async function signWithHmac(message, key) {
  const encoder = new TextEncoder();

  // Import the HMAC key
  const hmacKey = await window.crypto.subtle.importKey(
    'raw',
    encoder.encode(key),
    {
      name: 'HMAC',
      hash: { name: 'SHA-256' }, // can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
    },
    false, // whether the key is extractable (i.e., can be used in exportKey)
    ['sign'] // can be any combination of "sign" and "verify"
  );

  // Create the HMAC signature
  const signature = await window.crypto.subtle.sign(
    'HMAC',
    hmacKey,
    encoder.encode(message)
  );

  // The signature is an ArrayBuffer. To display it, it can be converted to a hexadecimal string.
  const signatureArray = new Uint8Array(signature);
  const signatureHex = Array.from(signatureArray)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');

  return signatureHex;
}

export const createMarketingDTO = (additional = {}) => {
  const queryParams = Cookie.getJSON(CookieNames.QUERY_PARAMS) || {};

  return {
    testName: process.env.AB_TEST_NAME,
    testVariant: process.env.AB_TEST_VARIANT,
    trialOffered: true,
    query_params: {
      ...queryParams,
    },
    growsumo: {
      xid: additional.partnerStackId,
    },
    ...additional,
  }
}

export const post = async (fields) => {
  const user = {
    profile: {
      first_name: fields.firstName,
      last_name: fields.lastName,
      full_name: [fields.firstName, fields.lastName].join(' '),
      phone: fields.phone,
      timezone: getTimezoneString(),
    },
    email: fields.email,
    password: fields.password,
  };

  const company = {
    name: fields.name,
    profile: {
      industry: fields.industry,
      num_of_employees: fields.numOfEmployees,
      zip: fields.zip,
    },
    plan: fields.plan,
    marketing: createMarketingDTO(fields),
    employeeSizeFreeText: fields.employeeSizeFreeText,
    isMultiAccount: fields.isMultiAccount,
  };

  // Referrals
  const partnerReferralCode = Cookie.get(CookieNames.PARTNER_REFERRAL_CODE);
  const partnerKey = fields.partnerStackId || '';

  if (partnerReferralCode) {
    company.marketing.referredByCode = partnerReferralCode;
    company.marketing.query_params.source = 'Partner';
  }

  const uuidToken = await signWithHmac(
    fields.email,
    process.env.UUID_TOKEN_SECRET
  );

  const res = await api.post('/v0/customer/register', {
    user,
    company,
    partnerKey,
    uuid: uuidToken,
  });

  return res;
};
// These map to communication preferences. Sub_Type_8 is mobile, so we don't want that one true to begin with.
const trackRegistration = (user) => {
  Analytics.identifyUser(user, {
    Sub_Type_1: true,
    Sub_Type_2: true,
    Sub_Type_3: true,
    Sub_Type_4: true,
    Sub_Type_5: true,
    Sub_Type_6: true,
    Sub_Type_7: true,
    Sub_Type_8: false,
    Sub_Type_9: true,
    Sub_Type_10: true,
    Sub_Type_11: true,
    Sub_Type_12: true,
  });

  Analytics.track('Lead', {
    value: '0.01',
    currency: 'USD',
  });

  if (window.gtag) {
    window.gtag('set', 'user_data', {
      email: user._auth?.email,
      phone_number: `+1${user.profile?.phone}` /* Phone Number must be collected in the E.164 format, which means it must be 11 to 15 digits including a plus sign (+) prefix and country code with no dashes, parentheses, or spaces. Example: ‘+11231234567’ */,
    });

    window.gtag('event', 'conversion', {
      send_to: 'AW-799566356/JF5ICP24woUBEJTUof0C',
    });
  }

  if (window.trackable) {
    window.trackable('lead', {
      id: user._company._id,
      userId: user._id,
    });
  }
};

const register = async (fields) => {
  let currentUser = {};
  try {
    const { data } = await post(fields);

    const { user, token } = data;
    setAccessCookie(token);
    setToken(token, 'Bearer');
    trackRegistration(user);
    currentUser = user;
  } catch (err) {
    console.error(err);
    let message =
      'There was a problem registering this account. Please try again later.';
    if (!err.response) {
      throw new Error(message);
    }
    if (err.response.status === 409) {
      message =
        err.response.data ===
        'Invalid Registration. Please use Employee Signup.'
          ? 'Your account has already been registered in our system by your employer. Please use our Employee Signup to activate your account.'
          : 'An account with this email already exists.';
    } else {
      message =
        'There has been an error with our registration, please try again later.';
    }
    throw new Error(message);
  }

  return currentUser;
};
/**
 *
 *         export interface CompanyProfile {
 *             industry: string;
 *             subIndustry: string;
 *             num_of_employees: string;
 *             address: string;
 *             address2: string;
 *             zip: string;
 *             city: string;
 *             state: string;
 *             geo: Geo;
 *             phone: string;
 *             logo_url: string;
 *             payday: string;
 *             pay_frequency: string;
 *             dba: string;
 *             location_name: string;
 *             isFranchiseOwner: boolean;
 *           }
 *
 * @param name
 * @param profile
 * @returns {Promise<void>}
 */
export const updateCompanyProfile = async ({ name, profile, marketing }) => {
  const res = await api.post('/v0/app/my/self/update-company-profile', {
    company: {
      name,
      profile,
      marketing,
    },
  });

  return res.data;
};

export default {
  register,
  updateCompanyProfile,
};
