<template>
  <div class="relative cursor-pointer w-10 h-10">
    <div
      class="center"
      :class="[toggle ? 'active' : '', `is-${color}`]"
      @click="$emit('toggle', !toggle)"
    >
      <div></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    toggle: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
});
</script>

<style scoped>
.center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
}

.center::before,
.center::after,
.center div {
  border-radius: 3px;
  content: '';
  display: block;
  height: 3px;
  margin: 5px 0;
  transition: 0.5s;
}

.center.is-primary::before,
.center.is-primary::after,
.center.is-primary div {
  @apply bg-primary;
}

.center.is-white::before,
.center.is-white::after,
.center.is-white div {
  @apply bg-white;
}

.center.active::before {
  transform: translateY(8px) rotate(135deg);
}

.center.active::after {
  transform: translateY(-8px) rotate(-135deg);
}

.center.active div {
  transform: scale(0);
}

.center.is-primary.active::before,
.center.is-primary.active::after,
.center.is-primary.active div {
  @apply bg-primary;
}

.center.is-white.active::before,
.center.is-white.active::after,
.center.is-white.active div {
  @apply bg-white;
}
</style>
