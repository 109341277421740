export const QueryParamNames = {
    PAYMENT_PAGE_EXPERIENCE: 'p_exp', 
    TRIAL_DURATION_OVERRIDE: 'tdo', 
    ACCESS_TOKEN: 'access-token',
    EMAIL: 'email',
    COMPANY_NAME: 'company_name',
    TIER: 'tier',
} as const

export const PaymentPageExperiences = {
    BAMBEE_LITE_ONLY: 'bl_only',
    BAMBEE_LITE_DEFAULT: 'bl_default',
    WVPP: 'wvpp',
} as const

export const TrialDurationOverrides = {
    SEVEN: '7',
    FIFTEEN: '15',
    THIRTY: '30',
    SIXTY: '60',
    NINETY: '90',
} as const
