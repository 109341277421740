<template>
  <div class="bg-white shadow-sm rounded-lg overflow-hidden">
    <div
      v-if="$slots.banner"
      class="bg-primary-tint-6 text-primary px-8 py-1 text-center"
    >
      <TypeOverline variant="medium">
        <slot name="banner"></slot>
      </TypeOverline>
    </div>

    <div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { TypeOverline } from '@bambeehr/pollen';

export default defineComponent({
  name: 'BaseCard',
  components: { TypeOverline },
  props: {
    header: {
      type: String,
      default: '',
    },
  },
  setup(props, { slots }) {
    return {
      slots,
    };
  },
});
</script>
