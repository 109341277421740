import {
  readonly,
  ref,
  computed,
  useRoute,
  useContext,
} from '@nuxtjs/composition-api';
import Partners from '@/modules/SplashPage/constants/Partners';
import { error as DDError } from '@/services/Datadog';

let isInitialized;
let route;
let props;
let context;
let builderPartners;

const shouldShow = ref(false);
const isReady = ref(false);
const isOverlayVisible = ref(false);
const brand = computed(() => props?.companyName);

const fetchPartners = async (currentBrand) => {
  const { $axios } = context;

  // If no brand is passed in, we don't want to hit this endpoint.
  if (!currentBrand) {
    return currentBrand;
  }

  try {
    const { data } = await $axios.get(
      `${process.env.BUILDER_REST}/content/partnership?apiKey=${process.env.BUILDERIO}&fields=name&includeUnpublished=false&includeRefs=false&cacheSeconds=500`
    );

    builderPartners = data?.results?.map((p) => p.name);
    if (builderPartners) {
      shouldShow.value = true;
    }
    isReady.value = true;

    return builderPartners.includes(currentBrand);
  } catch (error) {
    DDError(`failed-partner-fetch`, error);

    return false;
  }
};

const parntersPaths = [
  Partners.SQUARE,
  Partners.HELLO_ALICE,
  Partners.DEEL,
  Partners.REMOTE,
  Partners.BUILT_FIRST,
  Partners.BREX,
  Partners.ACCOUNTANT,
  Partners.HELP_SCOUT,
  Partners.PAYROLLSETUP,
  Partners.CONSTANTCONTACT,
  Partners.JP_MORGAN,
  Partners.BERKLEY_SELECT,
  Partners.AVIBRA,
  Partners.CHECKR,
  Partners.FO_AGENCY,
];

const brandName = computed(() => {
  if (brand.value) {
    return brand.value;
  }

  switch (route?.value?.name) {
    case Partners.ACCOUNTANT:
      return '1-800Accountant';

    case Partners.PAYROLLSETUP:
      return `PayrollSetup.com`;

    case Partners.CONSTANTCONTACT:
      return `Constant Contact`;

    case Partners.JP_MORGAN:
      return `J.P. Morgan`;

    case Partners.HELLO_ALICE:
      return `Hello Alice`;

    case Partners.HELP_SCOUT:
      return `Help Scout`;

    case Partners.BERKLEY_SELECT:
      return `Berkley Select`;

    case Partners.AVIBRA:
      return `Avibra`;

    case Partners.CHECKR:
      return `Checkr.io`;

    case Partners.FO_AGENCY:
      return `FO Agency`;

    default:
      return route?.value?.params?.brand;
  }
});

const overlayType = ref();

const discount = computed(() => {
  return route?.value?.name === Partners.ACCOUNTANT ||
    Partners.HELLO_ALICE ||
    Partners.PAYROLLSETUP ||
    Partners.CONSTANTCONTACT ||
    Partners.HELP_SCOUT ||
    Partners.BERKLEY_SELECT ||
    Partners.AVIBRA ||
    Partners.CHECKR ||
    Partners.FO_AGENCY
    ? '50'
    : '100';
});

const isAccountant = computed(() => route?.value?.name === Partners.ACCOUNTANT);
const isHelloAlice = computed(
  () => route?.value?.name === Partners.HELLO_ALICE
);
const isPayroll = computed(() => route?.value?.name === Partners.PAYROLLSETUP);
const isJPMorgan = computed(() => route?.value?.name === Partners.JP_MORGAN);
const isHelpScout = computed(() => route?.value?.name === Partners.HELP_SCOUT);
const isConstantContact = computed(
  () => route?.value?.name === Partners.CONSTANTCONTACT
);
const isBerkleySelect = computed(
  () => route?.value?.name === Partners.BERKLEY_SELECT
);

const isAvibra = computed(() => route?.value?.name === Partners.AVIBRA);

const isFoAgency = computed(() => route?.value?.name === Partners.FO_AGENCY);

const isAPartnerPath = computed(
  () =>
    parntersPaths.find((p) => p === (route?.value?.name || '').toLowerCase()) ||
    // We do not want this check to be reactive. We want to check explicitly what was passed in.
    props?.companyName
);

const setupHook = () => {
  isInitialized = true;
  route = useRoute();
  context = useContext();
  if (isAPartnerPath.value) {
    isOverlayVisible.value = true;
    overlayType.value =
      brand.value || route?.value?.params?.brand || route?.value?.name;
  }
};

const hideOverlay = () => {
  isOverlayVisible.value = false;
};

const usePartnerPromo = (pageProps) => {
  // Because the data comes from a third party tool (Builder.io), we need to check
  // the props of the component consuming this hook to see if the page has certain props.
  // This will determine whether partnership components (Banner and modal) should show.
  props = pageProps;

  if (!isInitialized) {
    setupHook();
  }

  return {
    hideOverlay,
    overlayType,
    discount,
    brandName,
    isAccountant,
    isHelloAlice,
    isPayroll,
    isConstantContact,
    isAPartnerPath,
    isHelpScout,
    isJPMorgan,
    isBerkleySelect,
    isAvibra,
    isFoAgency,
    isReady,
    fetchPartners,
    shouldShow,
    isOverlayVisible: readonly(isOverlayVisible),
  };
};

export default usePartnerPromo;
