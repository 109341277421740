import Cookie from 'js-cookie';
import { useRoute } from '@nuxtjs/composition-api';
import CookieNames from '@/constants/CookieNames';
import CurrentUser from '@/services/CurrentUser';
import { setToken } from '@/services/api';
import { jwtExpired } from '@/plugins/axios';

let initialized;
let route;

const setAccessToken = () => {
  const token =
    route.value?.query?.[CookieNames.ACCESS_TOKEN] ||
    Cookie.get(CookieNames.ACCESS_TOKEN);

  if (token) {
    const isTokenExpired = jwtExpired(token, 3000);
    if (!isTokenExpired) {
      setToken(token, 'Bearer');
      Cookie.set(CookieNames.ACCESS_TOKEN, token);
    }

    return !!token;
  }
};

const fetchAPIUserData = async () => {
  try {
    const userData = await CurrentUser.get();

    return userData;
  } catch (err) {
    return null;
  }
};

const getLead = () => {
  return fetchAPIUserData();
};

const useLeadService = () => {
  if (!initialized) {
    route = useRoute();
    setAccessToken();
    initialized = true;
  }

  return {
    getLead,
  };
};

export default useLeadService;
