import Cookie from 'js-cookie';
import CookieNames from '@/constants/CookieNames';
import useCurrentUser from '@/hooks/useCurrentUser';

import { setToken } from '@/services/api';
import CurrentUser from '@/services/CurrentUser';

export default async () => {
  const accessToken = Cookie.get(CookieNames.ACCESS_TOKEN);
  if (accessToken) {
    const { setCurrentUser } = useCurrentUser();
    let currentUser = {};
    setToken(accessToken, 'Bearer');

    try {
      const res = await CurrentUser.get();
      currentUser = res?.data;
      setCurrentUser(currentUser);
    } catch (error) {
      console.error(new Error('Error fetching current user', error));
      if (error?.response?.status === 401) {
        Cookie.remove(CookieNames.ACCESS_TOKEN);
        setToken(false);
      }
    }
  }
};
