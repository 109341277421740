<template>
  <form
    class="p-8 sm:p-0 sm:pb-12 mx-auto relative sm:flex sm:flex-col sm:justify-center"
    :class="{ 'registration-card': card }"
    @submit.prevent="$emit('submit')"
  >
    <div
      class="sm:max-w-xl sm:mx-auto sm:flex-grow flex flex-col justify-center"
    >
      <SlimProgressBar
        class="mb-8 sm:mt-12 sm:max-w-xs sm:mx-auto"
        :current-step="currentPageIndex + 1"
        :total-steps="totalNumOfRegistrationPages"
      />
      <TypeDisplay
        tag="h1"
        class="text-28 text-center font-bold"
        :class="{ 'mb-2': subtitle, 'mb-6': !subtitle }"
        data-qa="wizard-card-question"
      >
        <span v-html="title"></span>
      </TypeDisplay>
      <TypeBody v-if="subtitle" tag="p" class="text-16 text-center mb-6">{{
        subtitle
      }}</TypeBody>
      <div class="sm:max-w-sm sm:mx-auto sm:w-full">
        <div class="mb-4">
          <slot></slot>
        </div>
        <AnnularThrobber v-if="loading" class="mb-6 block mx-auto" />
        <BaseButton
          v-else
          :id="buttonId"
          class="mb-6"
          :class="{ 'opacity-50 cursor-not-allowed': buttonDisabled }"
          block
          type="submit"
          size="large"
          variant="secondary"
          >{{ buttonText }}</BaseButton
        >
        <footer>
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>
  </form>
</template>

<script>
import {
  AnnularThrobber,
  BaseButton,
  TypeBody,
  TypeDisplay,
} from '@bambeehr/pollen';
import useSelfService from '../hooks/useSelfService';

import { SelfServicePreRegistrationPages } from '../constants/SelfServicePages';

import SlimProgressBar from './SlimProgressBar';

export default {
  components: {
    AnnularThrobber,
    BaseButton,
    SlimProgressBar,
    TypeBody,
    TypeDisplay,
  },
  props: {
    buttonDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    buttonId: {
      type: String,
      required: true,
    },

    buttonText: {
      type: String,
      required: false,
      default: 'Next',
    },
    card: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
  },

  setup() {
    const { currentPageIndex, goToPreviousPage } = useSelfService();
    const totalNumOfRegistrationPages = SelfServicePreRegistrationPages.length;

    return {
      currentPageIndex,
      goToPreviousPage,
      totalNumOfRegistrationPages,
    };
  },
};
</script>

<style scoped>
.registration-card {
  border-bottom: 1px solid#E0E0DF;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: 1px solid#E0E0DF;
  border-right: 1px solid#E0E0DF;
}
</style>
