const Referrers = Object.freeze([
  {
    label: 'Podcast',
    value: 'podcast',
  },
  {
    label: 'Radio (AM, FM, XM)',
    value: 'radio',
  },
  {
    label: 'Search Engine',
    value: 'search-engine',
  },
  {
    label: 'Smart Speaker (Alexa, Google Home, etc...)',
    value: 'smart-speaker',
  },
  {
    label: 'Social Media',
    value: 'social-media',
  },
  {
    label: 'Streaming Audio',
    value: 'streaming-audio',
  },
  {
    label: 'TV',
    value: 'tv',
  },
  {
    label: 'Friend/Colleague/Family',
    value: 'friend-colleague-family',
  },
  {
    label: 'Other',
    value: 'other',
  },
]);

export default Referrers;
