import api from '@/services/api';

export const get = () => {
  return api.get('/v0/app/my/self');
};

export const callMeNow = (companyId) => {
  return api.post(`/v0/companies/v1/company/${companyId}/call-me-now`, null);
};

export const setPassword = (password) => {
  return api.post('/v0/app/my/self/update-password', { password });
};

/**
 *  Retrieves a nonce consumable token to authenticate in app
 *  @function
 * @param {string} authToken
 * @return {Promise<{nonce: string; expiration: string;}>}
 * */
export const getConsumableTokenFromAuth = (token) => {
  return api
    .get('/auth/v3/token', { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => response.data);
};

export default {
  callMeNow,
  get,
};
