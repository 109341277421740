/**
 * Implements the VueMatchMedia plugin:
 * https://www.npmjs.com/package/@webqam/vue-match-media?activeTab=readme#breakpoint-syntax
 */
import Vue from 'vue';
import VueMatchMedia from '@webqam/vue-match-media';
import theme from '@bambeehr/pollen/src/tailwind/theme';

const { screens } = theme.default;

const breakpoints = Object.entries(screens).reduce(
  (obj, [key, value]) => ({
    ...obj,
    [key]: {
      breakpoint: value,
      defaultValue: !['lg', 'xl', '2xl'].includes(key),
    },
  }),
  {}
);

Vue.use(VueMatchMedia, { breakpoints });
