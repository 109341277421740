<template>
  <div class="outer-bar flex items-center">
    <div class="inner-bar" :style="innerBarStyle" />
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';

export default {
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },

  setup({ currentStep, totalSteps }) {
    const innerBarStyle = computed(() => {
      let progressPercent;
      if (currentStep > totalSteps) {
        progressPercent = 100;
      } else {
        progressPercent = (currentStep / totalSteps) * 100;
      }
      return `width: ${progressPercent}%`;
    });

    return {
      innerBarStyle,
    };
  },
};
</script>

<style scoped>
.outer-bar {
  background: #f1f3f2;
  border-radius: 999px;
  height: 8px;
  width: 100%;
}

.inner-bar {
  background: #6b4392;
  border-radius: 999px;
  height: 8px;
}
</style>
