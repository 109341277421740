/**
 * Adds a keyup event listener to a component. When the corresponding key is
 * pressed, the handler is executed.
 * @param {Array|String} keyCodes - an array or string of key codes
 * @param {function} handler - the handler function to execute.
 */
import { onMounted, onBeforeUnmount } from '@nuxtjs/composition-api';

const useKeyupHandler = (keyCodes = '', handler = () => {}) => {
  const codes = Array.isArray(keyCodes) ? keyCodes : keyCodes.split(' ');
  const eventListener = (e) => {
    if (codes.includes(e.code)) {
      handler();
    }
  };
  onMounted(() => {
    window.addEventListener('keyup', eventListener);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('keyup', eventListener);
  });
};

export default useKeyupHandler;
