






import { SelectInput } from '@bambeehr/pollen';
import { computed } from '@nuxtjs/composition-api';
import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration';
import useMatchMedia from '@/hooks/useMatchMedia';

export enum ReasonOptions {
  DEDICATED_WITH_STRATEGY = 'Dedicated HR Manager with HR Strategy',
  CALL_CENTER = 'HR Support/Call-Center Only',
  ACTIVE_HR_ISSUE = 'Active HR Issue',
  GENERAL = 'General HR Guidance & Support',
  COMPLIANCE_POLICIES = 'HR Compliance & Policies',
  EE_ONBOARDING_TRAINING = 'Employee Onboarding & Training',
  PERFORMANCE_MANAGEMENT = 'Performance Management',
  TERMINATIONS = 'Termination Guidance',
  WAGES = 'Wage and Hour Guidance',
  RECRUITING_HIRING = 'Recruiting & Hiring',
  OTHER = 'Other',
}

const options = [
  // ReasonOptions.DEDICATED_WITH_STRATEGY,
  // ReasonOptions.CALL_CENTER,
  ReasonOptions.ACTIVE_HR_ISSUE,
  ReasonOptions.GENERAL,
  ReasonOptions.COMPLIANCE_POLICIES,
  ReasonOptions.EE_ONBOARDING_TRAINING,
  ReasonOptions.PERFORMANCE_MANAGEMENT,
  ReasonOptions.TERMINATIONS,
  ReasonOptions.WAGES,
  ReasonOptions.RECRUITING_HIRING,
  ReasonOptions.OTHER,
];

export default {
  components: {
    SelectInput,
  },
  props: {
    dropdownLabel: {
      type: String,
      default: 'What brought you to Bambee?',
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Choose your reason',
    },
    inputSize: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { state, updateRegistration } = useRegistration();
    const bambeeRefferral = computed(() => state[RegistrationStateNames.WHAT_BROUGHT_YOU_TO_BAMBEE]);
    const matchMedia = useMatchMedia();
    const isLarge = computed(() => matchMedia.value.sm || matchMedia.value.xs);
    const size = computed(() => {
      let input;
      if (props.inputSize) {
        input = props.inputSize;
      } else {
        input = !isLarge.value ? 'large' : 'normal';
      }

      return input;
    });

    const handleInput = (input) => {
      updateRegistration({
        [RegistrationStateNames.WHAT_BROUGHT_YOU_TO_BAMBEE]: input,
      });
      emit('input', input);
    };

    return {
      handleInput,
      bambeeRefferral,
      isLarge,
      options,
      size,
    };
  },
};
