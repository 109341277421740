<template>
  <BaseCard :full-bleed="true" class="w-min rounded-lg">
    <div class="flex flex-col items-center p-12">
      <PaymentHighFive class="w-36 h-36 mb-5" />
      <TypeBody
        :variant="$options.constants.Typography.Body.LARGE"
        :weight="$options.constants.Typography.Weights.STRONG"
        class="whitespace-nowrap mb-4"
        tag="p"
      >
        Thanks for Joining Bambee!
      </TypeBody>
      <template v-for="(item, index) in checklist">
        <div
          :key="`checklist-item-${index}`"
          class="flex items-center mx-auto mb-4"
          style="width: 180px"
        >
          <CheckBold
            class="w-3 h-2 mr-3"
            :class="{
              'text-secondary': current >= index,
              'text-gray-5': current < index,
            }"
          />
          <TypeOverline :variant="$options.constants.Typography.Overline.SMALL">
            {{ item }}
          </TypeOverline>
        </div>
      </template>
      <AnnularThrobber class="my-6" size="sm" />
    </div>
  </BaseCard>
</template>

<script>
import {
  AnnularThrobber,
  BaseCard,
  TypeBody,
  TypeOverline,
  constants,
} from '@bambeehr/pollen';
import { onMounted, ref } from '@vue/composition-api';
import PaymentHighFive from '@/assets/images/self-service-checkout/payment-highfive.svg';
import CheckBold from '@/assets/images/self-service-checkout/check-bold.svg';

export default {
  name: 'PaymentProcessingLoader',
  constants,
  components: {
    AnnularThrobber,
    PaymentHighFive,
    BaseCard,
    TypeBody,
    TypeOverline,
    CheckBold,
  },
  setup(_, { emit }) {
    const current = ref(0);
    const checklist = [
      'processing payment',
      'configuring account',
      'assigning hr managers',
    ];

    onMounted(() => {
      const interval = () => {
        setInterval(() => {
          if (current.value === checklist.length) {
            clearInterval(interval);
            emit('animation-finished');
          }
          current.value = current.value + 1;
        }, 2500);
      };
      interval();
    });
    return {
      checklist,
      current,
    };
  },
};
</script>
