const Benefits = Object.freeze([
  'A Dedicated HR Manager',
  'A Comprehensive HR Audit',
  'HR Compliant Policies',
  'Employee Onboarding & Terminations',
  'Expert HR Guidance',
  'Staff Training & Development',
  'Work Off Your Plate & Peace of Mind!',
]);

export default Benefits;
