import { computed, ref, useRoute } from '@nuxtjs/composition-api';

const fetchRadioSources = () => import('@/constants/RadioSources.json');

let RadioSources;

const useRadioPromo = () => {
  const route = useRoute();
  const radioSource = ref('Radio');

  const isRadioPage = computed(() => route.value.name === 'radio');

  (async () => {
    if (!RadioSources) {
      RadioSources = await fetchRadioSources();
    }
    const { utm_campaign: campaign, utm_source: source } = route.value.query;
    const key = source === 'iheart-cpa' ? 'iheart' : campaign;
    radioSource.value = RadioSources[key] || 'Radio';
  })();

  return {
    isRadioPage,
    radioSource,
  };
};

export default useRadioPromo;
