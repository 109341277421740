<template>
    <TypeOverline  class="flex justify-center gap-1" :variant="$matchMedia.sm ? 'medium' :'small'" tag="div" >
      Online {{ trialDuration }} Day Trial
      <span v-if="offerDurationLeft"> • offer ends in {{ offerEndsIn }} </span>
    </TypeOverline>
</template>

<script>
import { ref } from '@nuxtjs/composition-api';
import { TypeOverline } from '@bambeehr/pollen';

import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration';
import useTrials from '@/hooks/useTrials';
import useTimer, { formatDuration } from '@/hooks/useTimer/useTimer';

export default {
  name: 'TrialTimer',
  components: {
    TypeOverline,
  },
  setup(_, { emit }) {
    const { state: registrationState, updateRegistration } = useRegistration();

    const { trialDuration } = useTrials();

    const targetOfferDurationInMinutes = 30;
    let offerDurationInSeconds = targetOfferDurationInMinutes * 60;

    let offerCreatedAt =
      registrationState[RegistrationStateNames.OFFER_CREATED_AT];

    if (!offerCreatedAt) {
      offerCreatedAt = new Date();
      updateRegistration({
        [RegistrationStateNames.OFFER_CREATED_AT]: offerCreatedAt,
      });
    }

    const diffBetweenOfferCreatedAndNowInSeconds = Math.floor(
      (new Date() - new Date(offerCreatedAt)) / 1000
    );

    // offerDurationInSeconds can never be less than 0
    offerDurationInSeconds = Math.max(
      0,
      offerDurationInSeconds - diffBetweenOfferCreatedAndNowInSeconds
    );

    const offerEndsIn = ref(formatDuration(offerDurationInSeconds));
    const offerDurationLeft = ref(offerDurationInSeconds);

    const handleTimerUpdate = ({ duration, timerStr }) => {
      offerEndsIn.value = timerStr;
      offerDurationLeft.value = duration;
    };

    const handleTimerComplete = () => {
      emit('complete');
    };

    const { timer } = useTimer(offerDurationInSeconds, {
      onUpdate: handleTimerUpdate,
      onComplete: handleTimerComplete,
    });

    timer.startCountdown();

    return {
      trialDuration,
      offerDurationLeft,
      offerEndsIn,
    };
  },
};
</script>
