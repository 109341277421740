const ContentfulEntryIDs = Object.freeze({
  COOKIE_POLICY: 'R1eimTYyvMbZAfMEouyJS',
  SALES_CALL_AVAILABILITY: '29Ovuy236s168HyRCg3C7e',
  PAGE_PRIVACY: '1HbZepzN2hdYjqQNofer2H',
  PAGE_TOS: '6Q9rm4GQJ4eoj9Vbj94tNx',
  PAGE_ABOUT: '4npwv5RxjWGcvDhZLlnrR4',
  PRICE_LEGAL: '2r9ebBWK96lqaXc9UyapMR',
  RADIO: '25ZEIKTJeLAPclUiFQ4X2V',
  STREAMING: '6epQUGP4Epom2Jr63ZH4k4',
  SMART_SPEAKER: '5CwAW21zpQGC8mZBqLrHOJ',
  PODCASTS: '2omesV6MMX14JwRL2YVv9s',
  RECESSION_PROOF_YOUR_BUSINESS: '35amkxKKigjcyf3DhfA8r5',
  HR_COMPLIANCE_CHECKLIST: 'JstyGFyDZ1zSSIvfF1UnZ',
  // https://app.contentful.com/spaces/no9f01lxa378/environments/master/entries/2mz5DFCu7sT1pftfvA9Bnr
  BUSINESS_HOURS: '2mz5DFCu7sT1pftfvA9Bnr',
});

export const LandingPageEntryIDs = Object.freeze({
  ONBOARDING: '01ISqiBrylXx7CyuDATXCI',
  HR_POLICIES: '4MOKzyrQxFOMxnA6Xev4Ht',
  TERMINATIONS: '79uYt4qjFC25xOKQaYt6g5',
  PEO: 'M4HTyAv1c3lecM7HWcxck',
  LABOR_LAWS: '6OmSBbvUu6glX37evjlyZN',
  HANDBOOK: '4AYmtLsede61FFxsnqPFdc',
});

export default ContentfulEntryIDs;
