const DateFormat = Object.freeze({
  // Ex: 02/01/2020
  MM_DD_YYYY: 'MM/dd/yyyy',
  // Ex: Mon 02/01/2020
  WD_MM_DD_YYYY: 'E MM/dd/yyyy',
  // Ex: 2020-02-01
  YYYY_MM_DD: 'yyyy-MM-dd',
  // Ex: Tuesday, December 21st, 2021
  EEEE_MMMM_DO_YYYYY: 'EEEE, MMMM do, yyyy',
  // Ex: December 21st, 2021
  MMMM_DO_YYYYY: 'MMMM do, yyyy',
  // Ex: 5:40pm
  TIME: 'h:mmaaa',
  // Ex: Saturday
  DAY_OF_WEEK: 'EEEE',
  // Ex: 25
  DAY_OF_MONTH: 'd',
  // Ex: 25th
  DAY_OF_MONTH_EXT: 'do',
  // Ex: March
  MONTH: 'MMMM',
});

export default DateFormat;
