<template>
  <PurpleHexContainer
    class="flex flex-col px-6 sm:rounded-t-lg h-44 justify-center text-white"
  >
    <TypeDisplay
      class="font-bold text-18 mb-2 md:text-23"
      data-qa="wizard-card-title"
      >{{ title }}</TypeDisplay
    >
    <TypeBody class="text-14 md:text-16">
      <span v-html="description" />
    </TypeBody>
    <BaseTag
      v-if="tag"
      class="base-tag self-start mt-4 text-white border-white"
      outline
      >{{ tag }}</BaseTag
    >
  </PurpleHexContainer>
</template>

<script>
import { BaseTag, TypeBody, TypeDisplay } from '@bambeehr/pollen';
import PurpleHexContainer from '@/components/PurpleHexContainer';

export default {
  components: {
    BaseTag,
    PurpleHexContainer,
    TypeBody,
    TypeDisplay,
  },
  props: {
    description: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
