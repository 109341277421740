import api from './api';

export const pay = async (companyId, payload) => {
  const { data } = await api.post(
    `/v0/companies/v1/company/${companyId}/plan/convert`,
    payload
  );

  return data;
};
export const updatePlan = async (payload) => {
  const { data } = await api.post(
    `/billing/billing/v2/self-service-core-plan-change`,
    payload
  );

  return data;
};

export default {
  pay,
};
