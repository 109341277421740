import CookieNames from '@/constants/CookieNames';
import { setCookie } from '@/lib/cookie-helpers';
import axios from 'axios';

const baseURL = process.env.PUBLICAPI_URL;

const api = axios.create({
  baseURL,
  withCredentials: true,
});

// Helper utilities taken from the nuxt axios module:
// https://github.com/nuxt-community/axios-module/blob/main/lib/plugin.js#L10-L22
export const setHeader = (name, value, scopes = 'common') => {
  for (const scope of Array.isArray(scopes) ? scopes : [scopes]) {
    if (!value) {
      delete api.defaults.headers[scope][name];
      return;
    }
    api.defaults.headers[scope][name] = value;
  }
};

export const setAccessCookie = (token) => {
  setCookie(CookieNames.ACCESS_TOKEN, token, {
    domain: process.env.PRIMARY_DOMAIN,
  });
};
export const setToken = (token, type, scopes = 'common') => {
  const value = !token ? null : (type ? type + ' ' : '') + token;
  setHeader('Authorization', value, scopes);
  setAccessCookie(token);
};


export default api;
