import SelfServicePages from '../constants/SelfServicePages';

export const goToPage = (page, router, prevPage, replaceHistory = false) => {
  if (!page) {
    return null;
  }
  if (process.browser || process.env.ENV === 'test') {
    window?.analytics?.page({
      url: `${window.location.origin}/registration/${page.slug}`,
      path: `/registration/${page.slug}`,
      referrer: `${window.location.origin}/registration/${prevPage?.slug}`,
    });

    if (replaceHistory) {
      router.replace(`/registration/${page.slug}`);
    } else {
      router.push(`/registration/${page.slug}`);
    }
  }
};

export const goToNextPage = (index, router, replaceHistory) => {
  const nextPageIndex = index + 1;
  const nextPage = SelfServicePages[nextPageIndex];

  if (!nextPage) return;

  const prevPage = SelfServicePages[index];
  goToPage(nextPage, router, prevPage, replaceHistory);
  return nextPage;
};

export const goToPreviousPage = (index, router) => {
  const previousPageIndex = index - 1;
  if (previousPageIndex < 0) {
    return goToPage(SelfServicePages[0], router, SelfServicePages[index]);
  } else {
    return goToPage(
      SelfServicePages[previousPageIndex],
      router,
      SelfServicePages[index]
    );
  }
};
