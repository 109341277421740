<template>
  <LayoutVertical :no-logo="toggleLogo">
    <template #left>
      <div class="flex items-center justify-center text-white p-8">
        <GlassInfoCard
          :title="infoTitle"
          :description="infoDescription"
          class="lg:ml-28"
        >
          <template #contact-info>
            <div class="hidden lg:block">
              <BaseDivider label="We've got you covered" class="mb-2" />
              <ul class="mb-4">
                <li
                  v-for="(item, i) in registrationSummary"
                  :key="item"
                  class="text-14 font-bold"
                  :class="{ 'mt-2': i }"
                >
                  {{ item }}
                </li>
              </ul>

              <BaseDivider label="What you get" class="mb-2" />
              <ul class="font-body-text font-medium">
                <li
                  v-for="(item, i) in items"
                  :key="item"
                  class="flex items-start"
                  :class="{ 'mt-3': i }"
                >
                  <IconButton
                    icon="check"
                    class="w-6 h-6 mr-4"
                    variant="secondary"
                  />
                  <span v-html="item" />
                </li>
              </ul>
            </div>
          </template>
        </GlassInfoCard>
        <picture
          class="hidden lg:block absolute bottom-0 lg:-left-8 2xl:left-0"
        >
          <source
            :srcset="
              require(`~/assets/images/self-service-checkout/hrm-raymond.png`)
            "
            media="(min-width: 830px)"
            type="image/png"
          />
          <img
            :src="
              require(`~/assets/images/self-service-checkout/hrm-raymond-sm.png`)
            "
            style="max-height: 620px"
            alt="human resources manager"
          />
        </picture>
      </div>
    </template>
    <template #right>
      <div class="flex items-center justify-center min-h-full">
        <RegistrationQuestionForm
          button-id="ssu-contactInfo"
          button-text="Continue"
          title="Add details to create your account"
          subtitle="We just need your full name, business email & phone number"
          :loading="isBusy"
          :button-disabled="buttonDisabled"
          :v="v$"
          @submit="handleSubmit"
        >
          <TextInput
            ref="focus"
            class="mb-4"
            :value="fullName"
            :invalid="v$.fullName.$error"
            size="large"
            required
            placeholder="Full Name"
            data-qa="contact-info-name-input"
            @input="handleInput($event, constants.FULL_NAME)"
            @blur="v$.fullName.$touch"
          />
          <TextInput
            class="mb-4"
            :value="email"
            :invalid="v$.email.$error"
            size="large"
            required
            placeholder="Business Email"
            type="email"
            data-qa="contact-info-email-input"
            @input="handleInput($event, constants.EMAIL)"
            @blur="v$.email.$touch"
          />
          <TextInput
            v-maska="'(###) ###-####'"
            class="mb-4"
            :value="phone"
            :invalid="v$.phone.$error"
            type="tel"
            size="large"
            required
            placeholder="Phone Number"
            data-qa="contact-info-phone-input"
            @input="handleInput($event, constants.PHONE)"
            @blur="v$.phone.$touch"
          />
          <SelectInput
            class="mb-4"
            size="large"
            placeholder="How Did You Hear About Us?"
            required
            :invalid="v$.howDidYouHear.$error"
            :value="howDidYouHear"
            :options="options"
            :show-reset="false"
            :searchable="false"
            data-qa="contact-info-hear-about-input"
            @input="handleInput($event, constants.HOW_DID_YOU_HEAR)"
            @blur="v$.howDidYouHear.$touch"
          />
          <SelectInput
            v-show="isShow && (isStreaming || isSmartSpeaker)"
            class="mb-2"
            size="large"
            :placeholder="referralPlaceholder"
            :value="station"
            :options="showList"
            :show-reset="false"
            :searchable="false"
            data-qa="contact-info-station-input"
            @input="handleInput($event, constants.STATION)"
          />
          <VueSimpleSuggest
            v-show="isSearchableShow"
            v-model="station"
            :max-suggestions="5"
            :list="showList"
            :filter-by-query="true"
            data-qa="contact-info-station-input"
            @input="handleInput($event, constants.STATION)"
            @blur="v$.howDidYouHear.$touch"
          >
            <input
              :placeholder="referralPlaceholder"
              class="w-full p-3 mb-4 border-[2px] border-[#e0e0e0] rounded-[0.25rem]"
            />
          </VueSimpleSuggest>
          <template #footer>
            <BaseBanner
              v-if="bannerError"
              variant="error"
              class="mb-4"
              @close="bannerError = null"
              >{{ bannerError }}
            </BaseBanner>
            <TermsAndConditionsInput
              v-model="termsAndConditions"
              class="mb-4"
            />
            <BaseBanner
              variant="warning"
              class="mb-4 text-center"
              :show-icon="false"
              :show-close="false"
            >
              Bambee securely manages your account information to discuss your
              HR needs.
            </BaseBanner>
            <SecuredDisclaimer class="pb-0" />
          </template>
        </RegistrationQuestionForm>
      </div>
    </template>
  </LayoutVertical>
</template>

<script>
import {
  BaseBanner,
  BaseDivider,
  IconButton,
  SelectInput,
  TextInput,
} from '@bambeehr/pollen';
import VueSimpleSuggest from 'vue-simple-suggest';
import 'vue-simple-suggest/dist/styles.css';
import { computed, onMounted, ref } from '@nuxtjs/composition-api';
import Cookie from 'js-cookie';
import { maska } from 'maska';
import RegistrationQuestionForm from '../RegistrationQuestionForm';
import GlassInfoCard from '@/modules/SelfServiceRegistration/components/GlassInfoCard.vue';
import LayoutVertical from '@/modules/SelfServiceRegistration/components/LayoutVertical.vue';
import CookieNames from '@/constants/CookieNames';
import TermsAndConditionsInput from '@/components/TermsAndConditionsInput';
import Benefits from '@/constants/Benefits';
import useHandlerOnEnter from '@/hooks/useHandlerOnEnter';
import useRegistrationForm from '@/modules/SelfServiceRegistration/hooks/useRegistrationForm';
import shuffleReferrers from '@/utils/shuffleReferrers';
import useRegistrationSummary from '@/hooks/useRegistrationSummary';
import useCurrentUser from '@/hooks/useCurrentUser';
import useMatchMedia from '@/hooks/useMatchMedia';
import MountainRegistrationTracking from '@/lib/global/MountainRegistrationTracking';
import { unmaskNumber, maskNumber } from '@/utils/maskNumbers';
import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration';
import useMediaStation from '@/modules/SelfServiceRegistration/hooks/useMediaStation';
import randomizeList from '@/modules/SelfServiceRegistration/utils/randomizeList';
import usePricePlans from '@/hooks/usePricePlans';
import Analytics from '@/services/Analytics';
import SecuredDisclaimer from '@/components/SecuredDisclaimer/SecuredDisclaimer';
import { error as DDError } from '@/services/Datadog'

const EMAIL = 'email';
const FULL_NAME = 'fullName';
const HOW_DID_YOU_HEAR = 'howDidYouHear';
const PHONE = 'phone';
const STATION = 'station';

const StationVariants = Object.freeze({
  RADIO: 'radio',
  SMART_SPEAKER: 'smart-speaker',
  STREAMING: 'streaming-audio',
  PODCAST: 'podcast',
});

export default {
  directives: { maska },
  components: {
    BaseBanner,
    BaseDivider,
    GlassInfoCard,
    IconButton,
    LayoutVertical,
    RegistrationQuestionForm,
    SelectInput,
    TermsAndConditionsInput,
    TextInput,
    VueSimpleSuggest,
    SecuredDisclaimer,
  },
  setup(_, { emit }) {
    const { v$ } = useRegistrationForm();
    const {
      state: registrationState,
      updateRegistration,
      register,
    } = useRegistration();
    const { radioList, streamingList, podcastList, smartSpeakerList } =
      useMediaStation();
    const { setSelectedPlanById } =
      usePricePlans();

    const { registrationSummary } = useRegistrationSummary();
    const { setCurrentUser } = useCurrentUser();
    const matchMedia = useMatchMedia();
    const station = ref();

    const isBusy = ref(false);
    const focus = ref(null);
    const items = ref([]);
    const termsAndConditions = ref(true);
    const bannerError = ref(null);
    const partnerReferral = computed(() =>
      Cookie.get(CookieNames.PARTNER_REFERRAL_CODE)
    );

    onMounted(() => {
      focus.value?.$el.focus();
      if (partnerReferral.value) {
        updateRegistration({
          [RegistrationStateNames.HOW_HEARD]: partnerReferral.value,
        });
      }
    });

    const fullName = computed(
      () => registrationState[RegistrationStateNames.FULL_NAME]
    );
    const phone = computed(() =>
      registrationState[RegistrationStateNames.PHONE]
        ? maskNumber(registrationState[RegistrationStateNames.PHONE])
        : ''
    );
    const email = computed(
      () => registrationState[RegistrationStateNames.EMAIL]
    );
    const howDidYouHear = computed(
      () => registrationState[RegistrationStateNames.HOW_HEARD]
    );

    const buttonDisabled = computed(
      () =>
        !termsAndConditions.value ||
        !fullName.value ||
        !phone.value ||
        !email.value
    );
    const showList = computed(() => {
      switch (howDidYouHear.value) {
        case StationVariants.RADIO:
          return randomizeList(radioList.value);
        case StationVariants.STREAMING:
          return randomizeList(streamingList.value);
        case StationVariants.PODCAST:
          return randomizeList(podcastList.value);
        case StationVariants.SMART_SPEAKER:
          return randomizeList(smartSpeakerList.value);
        default:
          return [];
      }
    });

    const referralPlaceholder = computed(() => {
      switch (howDidYouHear.value) {
        case StationVariants.RADIO:
          return 'What show/station did you hear us on? (optional)';
        case StationVariants.STREAMING:
          return 'What network did you hear us on? (optional)';
        default:
          return 'What show did you hear us on? (optional)';
      }
    });

    items.value = Benefits;
    const infoDescription = computed(
      () =>
        `The bad news is, the ${
          registrationState[RegistrationStateNames.INDUSTRY]
        } industry must comply with specific HR compliance standards. <b>The good news</b> is we've got you covered.`
    );
    const infoTitle = computed(
      () => `We know ${registrationState[RegistrationStateNames.INDUSTRY]}`
    );

    const setValues = (values) => {
      Object.entries(values).forEach(([key, value]) => {
        if (key === 'fullName' && value) {
          updateRegistration({
            [RegistrationStateNames.FIRST_NAME]: value?.split(' ')[0],
            [RegistrationStateNames.LAST_NAME]: value
              ?.split(' ')
              .slice(1)
              .join(' '),
          });
        } else {
          updateRegistration({ [key]: value });
        }
      });
    };

    const handleInput = (value, key) => {
      if (key === PHONE) {
        updateRegistration({ [key]: unmaskNumber(value) });
      } else {
        updateRegistration({ [key]: value });
      }
      if (key === STATION) {
        station.value = value;
      }
    };

    const isShow = computed(() => {
      return (
        howDidYouHear.value === StationVariants.RADIO ||
        howDidYouHear.value === StationVariants.SMART_SPEAKER ||
        howDidYouHear.value === StationVariants.STREAMING ||
        howDidYouHear.value === StationVariants.PODCAST
      );
    });

    const isStreaming = computed(() => {
      return howDidYouHear.value === StationVariants.STREAMING;
    });
    const isSmartSpeaker = computed(() => {
      return howDidYouHear.value === StationVariants.SMART_SPEAKER;
    });


    const handleSubmit = async () => {
      if (isBusy.value) {
        return;
      }

      setSelectedPlanById(registrationState[RegistrationStateNames.PLAN_ID]); // Sets for the routing logic to respond to. 

      updateRegistration({
        [RegistrationStateNames.LEAD_DEVICE_TYPE]: !matchMedia.value.sm
          ? 'mobile'
          : 'desktop',
      });

      const values = {
        fullName: fullName.value,
        phone: unmaskNumber(phone.value),
        email: email.value,
        howDidYouHear: howDidYouHear.value,
        station: station.value,
      };

      v$.value.$touch();
      if (v$.value.$invalid) {
        bannerError.value = v$.value.$errors[0].$message;
        Analytics.track('Form-Validation', {
          fullName: fullName.value,
          phone: phone.value,
          email: email.value,
          howDidYouHear: howDidYouHear.value,
          station: station.value,
          errors: v$.value.$errors,
        });

        return;
      }
      if (!termsAndConditions.value) {
        bannerError.value = 'You must agree to the terms and conditions.';

        return;
      }
      bannerError.value = null;
      setValues(values);
      try {
        isBusy.value = true;
        const currentUser = await register(registrationState);
        updateRegistration({
          [RegistrationStateNames.USER_ID]: currentUser._id,
        });
        Analytics.track('self-registration', {
          registrationState,
        });
        const whatBroughtYouHere = {
          what_brought_you_to_bambee:
            registrationState[RegistrationStateNames.WHAT_BROUGHT_YOU_TO_BAMBEE],
        };

        setCurrentUser(currentUser);

        if (process.browser) {
          MountainRegistrationTracking(currentUser._id);
        }

        emit('submit', {
          [RegistrationStateNames.HAS_VALID_TOKEN]: true,
          [RegistrationStateNames.FULL_NAME]: values.fullName,
          [RegistrationStateNames.PHONE]: values.phone,
          [RegistrationStateNames.EMAIL]: values.email,
          [RegistrationStateNames.HOW_HEARD]: values.howDidYouHear,
          [RegistrationStateNames.COMPANY_ID]: currentUser?._company?._id,
        });
        Cookie.set(CookieNames.COMPANY_ID, currentUser?._company?._id);
        Cookie.set(CookieNames.PHONE, phone.value);
        Cookie.set(CookieNames.USER_ID, currentUser._id);

        Analytics.track(
          'what_brought_you_here',
          {
            companyId: registrationState.companyId,
            what_brought_you_to_bambee: JSON.stringify(whatBroughtYouHere),
          },
          {
            Salesforce: true,
          }
        );

        if (window.lintrk) {
          // This function only exists in prod.
          window?.lintrk('track', {
            conversion_id:
              process.env.ENV === 'production' ? 11954820 : 12122948,
          });
        }
      } catch (err) {
        DDError(`failed-registration`, err);
        bannerError.value = err;
        Analytics.track('registration-error', {
          error: err,
          registrationState,
        });
      }
      isBusy.value = false;
    };

    const hearAboutOptions = computed(() =>
      partnerReferral.value
        ? shuffleReferrers(partnerReferral.value)
        : shuffleReferrers()
    );

    const isSearchableShow = computed(() => {
      return isShow.value && !isStreaming.value && !isSmartSpeaker.value;
    });

    useHandlerOnEnter(handleSubmit);
    const toggleLogo = computed(
      () => matchMedia.value.xl || !matchMedia.value.lg
    );

    return {
      bannerError,
      constants: {
        EMAIL,
        FULL_NAME,
        HOW_DID_YOU_HEAR,
        PHONE,
        STATION,
      },
      email,
      randomizeList,
      focus,
      fullName,
      handleInput,
      handleSubmit,
      howDidYouHear,
      infoDescription,
      infoTitle,
      isBusy,
      partnerReferral,
      items,
      phone,
      options: hearAboutOptions,
      registrationSummary,
      termsAndConditions,
      toggleLogo,
      matchMedia,
      v$,
      station,
      showList,
      isShow,
      referralPlaceholder,
      isStreaming,
      isSmartSpeaker,
      isSearchableShow,
      buttonDisabled,
    };
  },
};
</script>
<style scoped>
>>> .vs__selected-options {
  @apply flex-nowrap;
}

>>> .select-input__selected {
  @apply whitespace-nowrap;
}
</style>
