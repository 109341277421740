import { computed, useRoute } from '@nuxtjs/composition-api';

const useFreeTrialPromo = () => {
  const route = useRoute();

  const isFreeTrialPage = computed(() => route.value.name === 'free-trial');

  return { isFreeTrialPage };
};

export default useFreeTrialPromo;
