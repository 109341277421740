let axios;

export interface TaxBreakdown {
  totalTax: number;
  trialTax: number;
}

export const calculateSalesTax = async (
  planId: string,
  zip: string,
  setupFee: number,
  trial?: number
): Promise<TaxBreakdown | undefined> => {
  if (!axios) {
    console.error('axios is not defined');

    return;
  }
  try {
    const { data } = await axios.post(
      `${process.env.PUBLICAPI_URL}/billing/billing/v2/preview/setup-by-plan-id/${planId}`,
      {
        zipCode: zip,
        setupFee,
        trial,
      }
    );

    const { totalTax = 0, trialTax = 0 }: TaxBreakdown = data;

    return {
      totalTax: totalTax / 100,
      trialTax: trialTax / 100,
    };
  } catch (err) {
    // Return 0 if there is an error so the user is not blocked from purchasing.
    return {
      totalTax: 0,
      trialTax: 0,
    };
  }
};

export default ($axios) => {
  if (!axios) {
    axios = $axios;
  }
};
