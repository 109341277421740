<template>
  <div class="relative flex flex-col items-center h-full">
    <BambeeLogo color="primary" class="mt-6 mb-10" />
    <transition name="page-swap" mode="in-out">
      <div v-show="showContent" class="max-w-3xl">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { onMounted, ref } from '@nuxtjs/composition-api';

import useSelfService from '../../hooks/useSelfService';
import BambeeLogo from '@/components/BambeeLogo';

export default {
  components: { BambeeLogo },

  setup() {
    const { currentPageIndex, goToPreviousPage } = useSelfService();
    const showContent = ref(false);

    onMounted(() => {
      showContent.value = true;
    });

    return {
      currentPageIndex,
      goToPreviousPage,
      showContent,
    };
  },
};
</script>

<style scoped>
.page-swap-enter-active {
  animation: page-enter 1s;
}

@keyframes page-enter {
  0% {
    opacity: 0;
    transform: translate3d(0, 80px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>
