export const getFromLocalStorage = (key) => {
  if (process.browser) {
    if (window?.localStorage) {
      return localStorage[key];
    } else {
      return null;
    }
  }
};

export const saveInLocalStorage = (key, value) => {
  if (process.browser) {
    if (window?.localStorage) {
      localStorage.setItem(key, value);
    }
  }
};

export default null;
