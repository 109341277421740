<template>
  <div
    class="px-2 sm:px-8 sm:bg-white sm:bg-opacity-20 sm:p-6 sm:rounded-lg sm:max-w-md"
  >
    <TypeDisplay
      class="font-bold text-18 mb-2 md:text-28"
      tag="p"
      data-qa="glass-info-title"
      >{{ title }}</TypeDisplay
    >
    <TypeBody class="text-14 md:text-16 mb-4" tag="p">
      <span v-html="description" />
    </TypeBody>
    <BaseTag v-if="tag" class="self-start" color="inevitable">{{
      tag
    }}</BaseTag>
    <!-- Needed for contact information screen -->
    <slot name="contact-info" />
    <div class="quote-tail absolute opacity-20"></div>
  </div>
</template>

<script>
import { TypeDisplay, TypeBody, BaseTag } from '@bambeehr/pollen';
export default {
  name: 'GlassInfoCard',
  components: { TypeDisplay, TypeBody, BaseTag },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: '',
    },
  },
};
</script>
