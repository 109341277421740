






import {
  computed,
  defineComponent,
  useContext,
} from '@nuxtjs/composition-api';
import ShoppingCart, { LineItem } from '@/modules/SelfServiceRegistration/components/ShoppingCart/ShoppingCart.vue'
import usePrice from '@/hooks/usePrice';
import useTrials from '@/hooks/useTrials';
import { TypeBody } from '@bambeehr/pollen';

export default defineComponent({
  name: 'CoreSubscriptionShoppingCart',
  components: {
    ShoppingCart,
    TypeBody,
  },
  setup(props) {
    // @ts-ignore
    const { $axios } = useContext();

    const {
      priceState,
      taxes,
      isReady,
    } = usePrice($axios);

    const { trialDuration } = useTrials();


    const trialLabel = computed(() => {
      return`${trialDuration.value} Day Trial`;
    });

    const lineItems = computed(() => {

      const trial: LineItem = {
        label: trialLabel.value,
        price: priceState.value.subscriptionPrice as number,
      }

      const setupFee: LineItem = {
        label: `One-Time HR Setup Fee`,
        originalPrice: priceState.value.originalSetupFee,
        price: priceState.value.setupFee,
      }

      const taxesItem: LineItem = {
        label: `Estimated Sales Tax`,
        tooltip: `The final tax and total is determined by your business address.`,
        price: taxes.value,
        type: 'tax',
      }


      return [
        trial,
        setupFee,
        taxesItem,
      ]
    })

    const totalItem = computed(() => {
      return {
        label: `Total Due Today`,
        price: priceState.value.total,
      }
    })

    return {
      isReady,
      lineItems,
      totalItem,
    };
  },
});
