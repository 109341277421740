<template>
  <div class="flex flex-col">
    <MarketNav class="top-nav fixed left-0 right-0 top-0 z-50" />
    <main id="main-content" class="flex-grow">
      <Nuxt />
    </main>
    <client-only>
      <TheFooter />
    </client-only>
    <!-- Artsai Tracking Pixel -->
    <TrackingPixel
      pixel="https://data.adxcel-ec2.com/pixel/?ad_log=referer&action=misc&pixid=047b84a4-fac9-4150-8f4d-01006d162e76"
    />
    <!-- Claritas Tracking Pixel -->
    <TrackingPixel
      :pixel="`https://trkn.us/pixel/conv/ppt=19232;g=visit;gid=44723;ord=${randomNumber}`"
    />
    <!-- AudioHook Tracking Pixel -->
    <TrackingPixel
      pixel="https://listen.audiohook.com/b4d81c66-0b35-41e6-9c8c-9d5de456305b/pixel.png?type=pageview"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';

import TheFooter from '@/components/Navigation/TheFooter';
import MarketNav from '@/components/Navigation/MarketNav';
import TrackingPixel from '@/components/Marketing/TrackingPixel.vue';

export default defineComponent({
  components: {
    MarketNav,
    TheFooter,
    TrackingPixel,
  },
  setup() {
    // Claritas requires a random number for their orderID to prevent duplicate data.
    const randomNumber = ref(Math.floor(Math.random() * 1000000));

    return {
      randomNumber,
    };
  },
});
</script>
