<template>
  <div v-if="link" class="w-full bg-primary text-center text-white p-2 transition-all bottom-0 fixed font-body-large flex items-center gap-2 justify-center" >
      <span>Ready to learn more?
      </span>
      <BaseButton variant="inverted-primary" tag="a" :href="link" target="_blank">
        <b>More Information</b>
      </BaseButton>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import useRegistration, { RegistrationStateNames } from '@/modules/SelfServiceRegistration/hooks/useRegistration';
import { QueryParamNames } from '@/constants/QueryParams';
import { getCookie } from '@/lib/cookie-helpers';
import CookieNames from '@/constants/CookieNames';
import { BaseButton } from '@bambeehr/pollen'

const base = `https://join.bambee.com/rc/01/overview`
export default defineComponent({
  components: {
    BaseButton
  },
  setup() {
    const { state } = useRegistration();

    const link = computed(() => {
      const params = {
        [QueryParamNames.ACCESS_TOKEN]: getCookie(CookieNames.ACCESS_TOKEN),
        [QueryParamNames.COMPANY_NAME]: state[RegistrationStateNames.COMPANY_NAME],
        [QueryParamNames.EMAIL]: state[RegistrationStateNames.EMAIL],
      }
      
      const hasAllRequiredParams = Object.values(params).every((v)=>!!v)      
      
      if (!hasAllRequiredParams) {
        return ''
      }
      
      const paramsStr = Object.entries(params).map(([k,v])=>`${k}=${v}`).join('&')

      return encodeURI(`${base}?${paramsStr}`)
    })



    return {
      link,
    };
  },
});
</script>
