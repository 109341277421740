import format from 'date-fns/format';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Contentful from '@/services/Contentful';
import { getCookie, setCookie } from '@/lib/cookie-helpers';
import Analytics from '@/services/Analytics';
import useCurrentUser from '@/hooks/useCurrentUser'
import ContentfulEntryIDs from '@/constants/ContentfulEntryIDs'


import {
    computed,
    reactive,
    ref,
} from '@nuxtjs/composition-api';


let initialized;

const dependencyReadiness = reactive({
    contentful: false,
    userHook: false,
    ip: false,
});

const policy = reactive<{
    header: string;
    body: string;
    version: string;
    updatedDate: string;
    formattedUpdatedDate: string;
    bannerCopy: string;
    agreeCta: string;
}>({
    header: '',
    body: '',
    version: '',
    updatedDate: '',
    formattedUpdatedDate: '',
    bannerCopy: '',
    agreeCta: '',
});

const dependencies = reactive(({
    currentUser: null,
    ip: ''
}))

const cookieStr = computed(() => `cookie-policy-v-${policy.version}`)

const hasAcknowledged = ref(false);

const acknowledge = () => {
    hasAcknowledged.value = true;
    setCookie(cookieStr.value, 'true')
}

const isReady = computed(() => {
    return Object.values(dependencyReadiness).every((v) => !!v);
});

const setupHook = async () => {
    fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
            dependencies.ip = data.ip;

        }).finally(() => {
            dependencyReadiness.ip = true;
        })


    const content = await Contentful.getEntry(
        ContentfulEntryIDs.COOKIE_POLICY
    );

    if (content) {
        policy.header = content.fields.header;
        policy.body = documentToHtmlString(content.fields.body);
        policy.bannerCopy = documentToHtmlString(content.fields.bannerCopy);
        policy.updatedDate = content.fields.updatedDate;
        policy.agreeCta = content.fields.agreeCta || 'Acknowledge';
        policy.version = format(
            new Date(content.fields.updatedDate),
            'MMddyyyyHHmm'
        );

        policy.formattedUpdatedDate = format(
            new Date(content.fields.updatedDate),
            'MMMM dd, yyyy'
        );

        const cookie = getCookie(cookieStr.value);
        hasAcknowledged.value = cookie === 'true'
        dependencyReadiness.contentful = true;
    }

    const { currentUser } = useCurrentUser();
    // @ts-ignore
    dependencies.currentUser = currentUser;
    // @ts-ignore
    dependencyReadiness.userHook = true
};

const trackCookieEvent = (eventName, additionalContext = {}) => {
    Analytics.track(eventName, {
        ...additionalContext,
        lastUpdated: policy.updatedDate,
        // @ts-ignore
        userId: dependencies.currentUser?.userId || '',
        // @ts-ignore
        companyId: dependencies.currentUser?.companyId || '',
        ip: dependencies.ip
    });
}

const useCookiePolicy = (forceTest = false) => {
    if (!initialized || forceTest) {
        initialized = true;
        setupHook();
    }

    return {
        isReady,
        hasAcknowledged,
        acknowledge,
        policy,
        trackCookieEvent,
    };
};

export default useCookiePolicy;
