

















import {
  computed,
  defineComponent,
  onMounted,
  useRoute,
} from '@nuxtjs/composition-api';
import { TypeDisplay, BaseIcon } from '@bambeehr/pollen';
import Analytics from '@/services/Analytics';

export default defineComponent({
  name: 'ErrorLayout',
  props: ['error'],
  layout: 'error',
  components: {
    TypeDisplay,
    BaseIcon,
  },
  setup(props) {
    const route = useRoute();
    const is404 = computed(() => props?.error?.statusCode === 404);

    onMounted(() =>
      Analytics.track('fallback-page-entry', { path: route.value.path })
    );

    return {
      is404,
    };
  },
});
