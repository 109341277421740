import redirects from '../constants/redirects.json';

export default function ({ redirect, route }) {
  const lowerCasePath = route.path.toLowerCase();

  if (route.path) {
    const match = redirects.find((r) => r.from === lowerCasePath);

    if (match?.from?.length > 1) {
      redirect(match.to);
    }
  }
}
