/**
 * Due to SSR and packages we have, we will often end up with 2 instantiations of window methods. 
 * This is caching so that we will always reference the SAME instance of RUM 
 */
let _DD_RUM;
let checkInterval: NodeJS.Timeout;

type DDUser = {
    companyId?: string,
    companyName?: string,
    userId?: string,
    email?: string,
    name?: string,
    isTest?: boolean,
    plan?: string,
    abTestName?: string,
    abTestVariant?: string,
}
let _user: DDUser = {}

const initializeDD = () => {
    window?.DD_RUM?.onReady(()=>{
        if (!_DD_RUM) {
            _DD_RUM = window?.DD_RUM;
            clearInterval(checkInterval);
        }
    })
}

checkInterval = setInterval(initializeDD, 10);
initializeDD();


export const setUser = (user:DDUser = {}) => {
    _user = {
        ..._user,
        ...user,
    }
    _DD_RUM?.setUser(_user);
}

export const action = (event, additionalContext = {}) => {
    _DD_RUM?.addAction(event, additionalContext);
}


export const error = (name: string = 'Error occurred', errObj?: Error) => {
    // @ts-ignore
    _DD_RUM?.addError(new Error(...[name, errObj])); 
}
