/**
 * A custom masking/unmasking tool to be used with Maska + Pollen Text Input.
 * Because the Maska plugin is working on top of our pollen components, it is difficult
 * to get the RAW number (unmasked number) from our Pollen Input fields.
 *
 * Until we update Pollen using the Maska Plugin, this utility is needed to work ontop of the
 * Maska plugin to keep our number data stripped of any formatting when sending data to our BE
 *
 */

export const unmaskNumber = (value) => {
  return value.replace(/\D/g, '');
};

/**
 * Needed a manual masking tool to initialize(format) data if it already exists
 */
export const maskNumber = (value) => {
  const arr = value.split('');
  arr.splice(0, 0, '(');
  arr.splice(4, 0, ') ');
  arr.splice(8, 0, '-');
  return arr.join('');
};
