<template>
  <div class="stripe-component">
    <div class="field credit_card_wrapper">
      <div class="cc_number_card flat flex items-center h-full pt-05 mb-4">
        <slot> </slot>
      </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
export default {
  data() {
    return {
      declined: false,
      card: null,
    };
  },
};
</script>
<style scoped>
/* @import '~assets/css/_shared.scss'; */

.credit_card_wrapper {
  background-color: #fff;
  border: 2px solid #dfdee0;
  border-radius: 5px;
  height: 54px;
  margin-bottom: 0.5rem;
}

.cc_number_card#card-element,
.cc_number_card#payment-card-field {
  padding: 0 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.cc_number_card {
  --tw-ring-offset-width: 0;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;

  height: 100%;
  position: relative;
}

.stripe-component.is-error {
  .field.credit_card_wrapper {
    border: 1px solid red;
  }

  label {
    color: red;
  }
}
</style>
