

















import { defineComponent } from '@nuxtjs/composition-api';
import { TypeBody, BaseIcon, TypeOverline, TypeDisplay, TypeHeading } from '@bambeehr/pollen';

export default defineComponent({
  name: 'SubscriptionBenefits',
  components: {
    TypeBody,
    BaseIcon,
    TypeOverline,
    TypeDisplay,
    TypeHeading, 
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    benefits: {
      type: Array,
      default: [],
    }
  },

});
