import { ProductKey, PayrollPlanModel } from '@/gql/generated';
import { computed, ref } from '@nuxtjs/composition-api';
import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration';

import Analytics from '@/services/Analytics';
import { PayrollTrackEvents } from '@/modules/SelfServiceRegistration/constants/TrackEvents';
import api from '@/services/api';

let init;
let state;

const isLoading = ref(false);
const currentTier = ref();
const preFilteredAddons = ref<PayrollPlanModel[]>([]);

const eligibleAddOns = computed(() => {
  // if the company is tier 4 or above, they are not eligible for Payroll Basic
  if (currentTier.value >= 4) {
    return preFilteredAddons.value.filter(
      (addon) => addon.productKey !== ProductKey.PayrollBasic
    );
  }

  return preFilteredAddons.value || [];
});

const setupHook = async (force) => {
  isLoading.value = true;
  const { state: registrationState } = useRegistration();
  state = registrationState;
  currentTier.value = registrationState[RegistrationStateNames.TIER_NUMBER];

  try {
    await api
      .get(
        `/billing/addons/eligible/${
          registrationState[RegistrationStateNames.COMPANY_ID]
        }`
      )
      .then((res) => {
        preFilteredAddons.value = res.data.payroll;
      });
  } catch (error) {
    window.DD_RUM.addError('Could not fetch eligible addons', {
      error,
    });
  }
  isLoading.value = false;
};

const refresh = async () => {
  await setupHook(true);
};

const purchasePayrollProduct = async (productOption) => {
  const newProduct = eligibleAddOns.value?.find(
    (addon) => addon.productKey === productOption
  );

  if (!newProduct) return;

  const companyId = state[RegistrationStateNames.COMPANY_ID];

  const trackPayload = {
    planValue: newProduct.value,
  };

  Analytics.track(PayrollTrackEvents.PURCHASE_PLAN_INITIALIZED, trackPayload);
  let response;

  try {
    await api
      .post(`/billing/subscriptions/add`, {
        companyId,
        planValue: newProduct.value,
      })
      .then((res) => {
        response = res.data;
        Analytics.track(PayrollTrackEvents.PURCHASE_PLAN_SUCCESS, trackPayload);

        refresh();
      });
  } catch (error) {
    Analytics.track(PayrollTrackEvents.PURCHASE_PLAN_ERROR, trackPayload);
    response = error;
  }

  return response;
};

const useAddons = (force = false) => {
  if (!init || force) {
    setupHook(force);
  }

  return {
    isLoading,
    eligibleAddOns,

    // methods
    purchasePayrollProduct,
  };
};


export default useAddons;
