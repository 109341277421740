/* eslint-disable */
export default () => {
  window.onNuxtReady(() => {
    const gs = document.createElement('script');
    gs.src = 'https://snippet.growsumo.com/growsumo.min.js';
    gs.type = 'text/javascript';
    gs.async = 'true';
    gs.onload = gs.onreadystatechange = function () {
      const rs = this.readyState;
      if (rs && rs != 'complete' && rs != 'loaded') return;

      try {
        growsumo._initialize(process.env.GROWSUMO_PUBLIC_KEY);
        if (typeof growsumoInit === 'function') {
          growsumoInit();
        }
      } catch (e) {}
    };
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(gs, s);
  });
};
