<template>
  <div class="bg-gray-7">
    <main
      id="self-service-content"
      :class="!isPayment && 'overflow-x-hidden h-screen'"
    >
      <Nuxt />
    </main>
    <CookiePolicyBanner />
    <!-- Artsai Tracking Pixel -->
    <TrackingPixel
      pixel="https://data.adxcel-ec2.com/pixel/?ad_log=referer&action=misc&pixid=047b84a4-fac9-4150-8f4d-01006d162e76"
    />
    <!-- Claritas Tracking Pixel -->
    <TrackingPixel
      :pixel="`https://trkn.us/pixel/conv/ppt=19232;g=visit;gid=44723;ord=${randomNumber}`"
    />
    <!-- AudioHook Tracking Pixel -->
    <TrackingPixel
      pixel="https://listen.audiohook.com/b4d81c66-0b35-41e6-9c8c-9d5de456305b/pixel.png?type=pageview"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  useRoute,
  computed,
} from '@nuxtjs/composition-api';
import TrackingPixel from '@/components/Marketing/TrackingPixel.vue';
import useSelfService from '@/modules/SelfServiceRegistration/hooks/useSelfService';
import CookiePolicyBanner from '@/modules/CookiePolicy/components/CookiePolicyBanner/CookiePolicyBanner.vue'


export default defineComponent({
  name: 'Ssu',
  components: { TrackingPixel, CookiePolicyBanner },
  setup() {
    // Claritas requires a random number for their orderID to prevent duplicate data.
    const randomNumber = ref(Math.floor(Math.random() * 1000000));
    const route = useRoute();
    const { isSelfService } = useSelfService();
    

    const isPayment = computed(
      () => route.value.path.includes('payment') && isSelfService.value
    );

    return {
      randomNumber,
      isPayment,
    };
  },
});
</script>
