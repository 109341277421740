<template>
  <div
    v-if="!noHero"
    class="background relative flex items-center justify-center bland-hero bg-primary py-20 z-10"
    :class="[getHeader.heroImage ? 'has-image' : 'no-image', overlay]"
    :style="bgImage"
  >
    <div
      class="relative lg:max-w-7xl content flex items-center justify-center z-20 px-8 my-10 sm:my-24"
    >
      <HeroRegistration :registration="getHeader.registration">
        <div class="text-block text-center" :class="alignment">
          <img
            v-if="headerImage"
            :src="headerImage"
            class="mx-auto lg:mr-auto lg:ml-0 mb-4"
          />
          <TypeDisplay
            :variant="$options.Typography.Display.PETA"
            :weight="$options.Typography.Weights.STRONG"
            tag="p"
            class="text-white mb-8"
            >{{ header }}</TypeDisplay
          >
          <TypeDisplay
            v-if="getHeader.subheader"
            :variant="$options.Typography.Display.MEGA"
            :weight="$options.Typography.Weights.REGULAR"
            tag="p"
            class="text-white leading-6"
            >{{ subheader }}</TypeDisplay
          >
        </div>
      </HeroRegistration>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { TypeDisplay, constants } from '@bambeehr/pollen';
import BgHexLight from '@/assets/images/bg-hex-light.png';
import HeroRegistration from '@/components/HeroRegistration.vue';
import useGlobalSettings from '@/hooks/useGlobalSettings';
import HomeNav from '../modules/HomePage/components/Navigation/HomeNav.vue';
const { Typography } = constants;

export default defineComponent({
  name: 'BlandHero',
  Typography,
  components: {
    HeroRegistration,
    TypeDisplay,
    HomeNav,
  },
  setup() {
    const { getHeader } = useGlobalSettings();
    const bgImage = computed(() =>
      getHeader.heroImage && !getHeader.noHero
        ? `background-image: url(${getHeader.heroImage})`
        : `background-image: url(${BgHexLight})`
    );

    const header = computed(() => getHeader.header);
    const subheader = computed(() => getHeader.subheader);
    const headerImage = computed(() => getHeader.headerImage);

    const noHero = computed(() => getHeader.noHero);

    const overlay = computed(() => (getHeader.noOverlay ? 'no-overlay' : ''));

    const alignment = computed(() =>
      getHeader.registration
        ? 'lg:text-left lg:pr-10'
        : 'lg:text-center max-w-3xl mx-auto'
    );

    return {
      bgImage,
      getHeader,
      alignment,
      overlay,
      noHero,
      header,
      subheader,
      headerImage,
    };
  },
});
</script>

<style scoped>
.background.no-image {
  background-position: center center;
  background-repeat: repeat;
  background-size: 75px, auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.background.no-image::after {
  opacity: 0.5;
}
.background.has-image {
  background-repeat: no-repeat;
  background-size: cover;
}
.background.has-image::after {
  background-image: linear-gradient(243deg, #481d72 2%, #481d72);
  content: '';
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.background.no-overlay::after {
  display: none;
}
</style>
